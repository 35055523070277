export default {
  Campaign_Page_Management: "Quản lý Trang Chiến Dịch",
  Title_Name: "Tên Tiêu Đề",
  _Campaign_Type: "Loại Chiến Dịch",
  Campaign_Time: "Thời Gian Chiến Dịch",
  Add_New: "Thêm Mới",
  Thai: "Tiếng Thái",
  Vnvi: "tiếng việt",
  English: "Tiếng Anh",
  Banner_Image: "Ảnh Banner",
  Configuration_Page_Link: "Cấu Hình Liên Kết Trang",
  Delete: "Xóa",
  Confirm_Deletion: "Xác Nhận Xóa",
  Are_you_sure_you_want_to_delete_this_campaign_page_:
    "Bạn có chắc chắn muốn xóa trang chiến dịch này không?",
  This_link_is_already_active_on_the_homepage__Please_turn_it_off_before_editing_or_deleting_:
    "Liên kết này đã được kích hoạt trên trang chính. Vui lòng tắt nó trước khi chỉnh sửa hoặc xóa.",
  Recommended_size__Width_750_pixels__size_limit_2MB__It_supports_only_png__jpg__jpeg__gif_formats__Only_one_image_can_be_uploaded_at_a_time_:
    "Kích thước đề xuất: Chiều rộng 750 pixel, giới hạn kích thước 2MB. Nó chỉ hỗ trợ các định dạng png, jpg, jpeg, gif. Chỉ có thể tải lên một ảnh mỗi lần.",
  _Background_Color_: "Màu Nền:",
  Default_background_color___f5f5f5: "Màu nền mặc định: #f5f5f5",
  Switch_Campaign_Type: "Chuyển Đổi Loại Chiến Dịch",
  Switching_the_type_will_not_save_the_settings_of_the_original_type__Do_you_want_to_continue_:
    "Chuyển đổi loại này sẽ không lưu các thiết lập của loại ban đầu. Bạn có muốn tiếp tục không?",
  Cancel: "Hủy",
  Confirm: "Xác Nhận",
  Product_List_Configuration: "Cấu Hình Danh Sách Sản Phẩm",
  _Product_List_ID_: "ID Danh Sách Sản Phẩm:",
  Product_list_ID_can_be_found_in_the_Operation_Management___Product_List_:
    "ID danh sách sản phẩm có thể được tìm thấy trong Quản Lý Hoạt Động - Danh Sách Sản Phẩm.",
  Save: "Lưu",
  Save_and_Preview: "Lưu và Xem Trước",
  After_cancellation__the_content_you_edited_will_not_be_saved__Do_you_want_to_continue_:
    "Sau khi hủy, nội dung bạn chỉnh sửa sẽ không được lưu. Bạn có muốn tiếp tục không?",
  Product_List: "Danh Sách Sản Phẩm",
  Campaign_Image: "Hình Ảnh Chiến Dịch",
  Title: "Tiêu Đề",
  _Title__Thai__: "Tiêu Đề (Tiếng Thái):",
  _Title__English__: "Tiêu Đề (Tiếng Anh):",
  _Title__VN__: "Tiêu đề (tiếng việt)：",
  Basic_Information_Setting: "Thiết Lập Thông Tin Cơ Bản",
  New_Edit_Campaign_Page: "Tạo/Sửa Trang Chiến Dịch"
};
