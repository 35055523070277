export default {
  TAP销售类目: "TAP Sales Categories",
  全平台销售类目: "All Platform Sales Category",
  手动添加: "Manual Add",
  APP注册: "App Registration",
  达人公海: "Creator Marketplace",
  跟进记录: "Follow-up Record",
  是否意愿申样: "Willing to Apply",
  每条视频报价: "Video Quote",
  达人来源: "Creator Source",
  建联渠道: "Contact Channel",
  请至少输入五个字符: "Please enter at least five characters to follow up the creator",
  minPriceLessThanMaxPrice: "The minimum amount must be ≤ the maximum amount!",
  TT昵称: "TikTok Name",
  TT唯一名: "Unique Name",
  MCN: "MCN",
  视频平均播放: "Avg. Video Views",
  粉丝范围: "Follower Range",
  月销GMV: "30-Day GMV",
  月销视频GMV: "Monthly Video GMV",
  月销直播GMV: "Monthly Live GMV",
  销售类目Top3: "Top 3 Sales Categories",
  销售类目: "Sales Categories",
  联系LINE: "Contact LINE",
  联系WhatsAPP: "Contact WhatsApp",
  联系邮箱: "Email",
  公海: "Creator Marketplace",
  建联: "Follow",
  已建联: "已建联",
  建联成功Tips: "Connection Successful! You can {{btn}}",
  前往跟进达人查看: "check in [Follow Creator]",
  建联失败Tips: "建联失败！当前达人已被建联，请刷新列表",
  建联失败: "Connection Failed! The creator has already been connected, please refresh the list.",
  联系渠道: "Contact Channel",
  暂不明确: "Not Clear Yet",
  建联达人: "New Creator",
  报价金额: "Quoted Amount",
  "30GMV": "30-Day GMV",
  UserId: "User ID",
  Label: "Tag",
  RegisterTime: "Register Time",
  ScreenshotData: "Showcase sales screenshots",
  followUp: "PIC",
  lineId: "Line ID",
  Edit_Label: "Edit tag",
  UserList: "User List",
  sureModifyFans: "Make sure to revise the number of followers",
  followUpPerson: "Confirm to modify the follow-up person",
  bindGoods: "Cooperation products",
  productImage: "Product Image",
  productName: "Product Name",
  productId: "Product ID",
  anchorSales: "Creator Sales",
  activityTime: "Campaign Time",
  activityId: "Activity ID",
  activityStatus: "Campaign Status",
  onSaleStatus: "On Sale Status",
  offlineUser: "offline users",

  All: "All",
  toRelease: "Waiting for release",
  beginInAMinute: "About to start",
  Ongoing: "Ongoing",
  Closed: "Closed",

  invalid: "Expired",
  video: "TikTok Video",
  UsingModules: "Activation Product",
  TotalLikes: "Total likes",
  NumberOfVideos: "Video",
  TotalVisits: "Total views",
  NumberOfFans: "Followers",
  ViewsToVideo: "Video View-视频",
  ViewsLive: "Live View",
  AverageLikeVideo: "Avg. video likes",
  AverageLikeLive: "Avg. livestream likes",
  GPMVideo: "Video GPM",
  GPMLive: "Live GPM",
  uchoiceUser: "Uchoice User",
  ttUser: "TikTok User",
  identity: "Identity",
  uchoicettUser: "uchoice-tt用户",
  Whetherttauthorized: "Tiktok Authorize?",
  ttUserName: "TikTok User Name",
  ttId: "TikTok ID",
  sku: "Specifications",
  itemLink: "Product Link",
  applyTime: "Application Date",
  salesScreenshot: "GMV screenshots",
  系统更新: "Updated by System",
  系统获取: "System Obtained",
  上传橱窗证明: "Manually Upload Showcase Proof",
  GMV数据: "Transaction Volume Data",
  无需上传: "No Upload Required",
  已审核: "Reviewed",
  JanuaryGMV: "GMV in the Last Month",
  RecipientName: "Receiver's Name",
  RecipientTel: "Receiver's Phone Number",
  RecipientAddress: "Receiver's Address",
  ttAuthorizationTime: "Authorize time",
  ttNumberOfFans: "TikTok followers",
  GMPToVideoLive: "GPM (Video/Live)",
  ViewsToVideoLive: "Video PV/Live UV",
  Viewingtimes: "观看次数（视频/直播）",
  AverageLikeLiveVideo: "Avg. likes (Video/Live)",
  Videolist: "Video List",
  TotalNumberOfVideos: "Video",
  FieldAccess: "Avg. video views",
  AverageLikesPerGame: "Avg. video likes",
  AverageNumberOfComments: "Avg. video comments",
  AverageNumberOfForwardingInTheField: "Avg. video shares",
  AverageShare: "Avg. video shares",
  videoid: "Video ID",
  ReleaseTime: "Post time",
  duration: "Duration(s)",
  NumberOfLikes: "Likes",
  NumberOfComments: "Comments",
  NumberOfVisits: "Views",
  NumberOfShares: "Shares",
  NumberOfForwards: "Shares",
  WithLinkOrNot: "Videos with product?",
  yes: "Y",
  no: "N",

  userTitle: "PIC Creator",
  userID: "User ID",
  userPhone: "phone number",
  registerUserPhone: "Register Phone Number",
  telephone: "Contact Phone Number",
  EntryTime: "Request Time",
  RegistraTime: "Registeration time",
  integralSet: "Coins configuration",
  confirm: "Confirm to submit for review",
  uliveTitle: "Ulive user account management",
  LastOnlineTime: "Last time active",
  shopName: "Store name ",
  facebook: "Facebook Page Name",
  BindTime: "Connecting duration",

  OpenImportLink: "Open import link",
  目标用户: "Target Users",
  目标用户无商品: "Target Users without Products",
  目标用户有商品: "Target Users with Products",
  editTelephone: "Do you want to modify the contact phone number?",

  修改TT用户名: "Modify TikTok Username",
  正在修改xxx的TT用户名: "Modifying TikTok Username for xxx",
  新TT用户名: "New TikTok Username",
  确认修改TT用户名: "Confirm Username Change",
  "原用户名xxx将改为xxx,你还要继续吗?":
    "The original username xxx will be changed to xxx Do you want to continue?",
  修改的TT用户名重复: "The modified TikTok username is a duplicate.",
  xxx已存在如确认修改用户及样品订单数据将会合并你还要继续吗:
    "xxx already exists. If you confirm the change, user and sample order data will be merged. Do you want to continue?",
  新增用户: "Add New User",
  新增线下已建联的用户: "Add New Offline Connected User",
  联系方式: "Contact Information:",
  请输入电话号码: "Please enter phone number",
  或者: "or",
  "请输入LINE ID": "Please enter LINE ID",
  标签: "Labels",
  必填: "Required",
  "若不填LINE ID必填": "If not filled, LINE ID is required",
  若不填电话必填: "If not filled, phone number is required",
  手机或line必填一个: "Please enter phone number or LINE ID",
  Reauthorize: "Reauthorize",
  类目: "Category",
  橱窗销售截图: "Showcase Screenshots",
  橱窗月销售GMV: "GMV in the Last Month",

  达人链接管理: "Creator's product link management",
  链接管理: "Link management",
  合作链接: "Cooperation product link",
  被替换商品: "Replaced product link",
  下架商品: "Invalid products",
  活动过期链接: "Expired product links",
  合作过商品总数: "Number of products for cooperation",
  操作: "Operate",
  查看详情: "View details",
  链接状态: "Link status",
  合作中: "Cooperation ongoing",
  商品下架: "Invalid products",
  被替换: "Replaced",
  活动过期: "Expired campaign",
  同商品合作次数: "Cooperation times for the same product",
  是否授权: "Whether Authorized",
  已授权: "Authorized",
  未授权: "Unauthorized",
  活动过期商品: "Expired Product Activity",
  达人佣金率: "Creator Commission Rate",
  达人佣金: "Creator Commission",
  链接: "Link",
  当前状态: "Current Status",

  视频: "Video",
  直播: "Live"
};
