export default {
  Actual_Sales: "Doanh số thực tế",
  Export: "Xuất",
  English: "Tiếng Anh",
  Thai: "Tiếng Thái",
  Vnvi: "tiếng việt",
  Please_filter_the_Youpik_category_and_Lazada_category_first:
    "Vui lòng lọc danh mục Youpik và danh mục Lazada trước",
  Ranking_Setting: "Cài đặt Xếp hạng",
  Youpik_Category: "Danh mục Youpik",
  Lazada_Category: "Danh mục Lazada",
  Customized_Category: "Danh mục Tùy chỉnh",
  Add: "Thêm",
  All: "Tất cả",
  Not_start: "Chưa bắt đầu",
  Ongoing: "Đang diễn ra",
  Closed: "Đã đóng",
  Number_of_Products: "Số lượng Sản phẩm",
  Create_Time: "Thời gian Tạo",
  Valid_Time: "Thời gian Hiệu lực",
  Ranking_Link: "Liên kết Xếp hạng",
  Status: "Trạng thái",
  Action: "Thao tác",
  Edit: "Chỉnh sửa",
  Delete: "Xóa",
  Rank: "Xếp hạng",
  Search: "Tìm kiếm",
  Reset: "Đặt lại",
  Preview: "Xem trước",
  Total: "Tổng",
  Page: "20/ Trang",
  Go_to___: "Đi đến...",
  Remark: "Ghi chú",
  Save: "Lưu",
  Timed: "Đặt thời gian",
  Open: "Mở",
  Close: "Đóng",
  Rename: "Đổi tên",
  Product_Image: "Hình ảnh Sản phẩm",
  Product_ID: "ID Sản phẩm",
  Product_Name: "Tên Sản phẩm",
  Sale_Price: "Giá bán",
  Commission_Rate: "Tỷ lệ hoa hồng",
  PDP_Page: "Trang PDP",
  All_best_seller_product_related_to_this_category_will_be_deleted__is_it_confirmed_:
    "Tất cả sản phẩm bán chạy nhất liên quan đến danh mục này sẽ bị xóa, bạn có chắc chắn không?",
  Confirm: "Xác nhận",
  Cancel: "Hủy",
  This_category_already_exists: "Danh mục này đã tồn tại",
  Please_input_product_ID: "Vui lòng nhập ID sản phẩm",
  Select_All: "Chọn tất cả",
  Add_in_batches: "Thêm theo lô",
  Added: "Đã thêm",
  Ranking_Add: "Thêm Xếp hạng",
  Please_input: "Vui lòng nhập",
  closeStatus: "Sau khi đóng, không thể mở lại. Bạn có chắc chắn không?",
  inputCustomized_Category: "Vui lòng nhập một danh mục tùy chỉnh",
  goodIdExistence: "Thêm không thành công, sản phẩm đã xuất hiện trong các xếp hạng khác",
  screenCategory: "Vui lòng lọc danh mục Youpik và danh mục Lazada trước",
  最多添加20个: "Thêm tối đa 20 sản phẩm, vui lòng chọn một cách cẩn thận"
};
