export default {
  Require: "Trường này là bắt buộc",
  requiredUploadpicture: "Vui lòng tải lên hình ảnh",
  pleaseEnterCorrectFormat: "Vui lòng sử dụng định dạng đúng",
  requireName: "Yêu cầu Tên",
  requireMobile: "Yêu cầu Số điện thoại di động",
  requireSellerType: "Vui lòng chọn Loại người bán",
  requireStoreLocation: "Vui lòng chọn Vị trí cửa hàng",
  requireAccountType: "Vui lòng chọn Loại tài khoản",
  requireCheckRead: "Vui lòng đồng ý với các điều khoản và điều kiện",
  requireCategory: "Vui lòng chọn Danh mục",
  requireItemId: "Vui lòng nhập Mã mặt hàng",
  requireItemName: "Vui lòng nhập Tên mặt hàng",
  requireSellerSKU: "Vui lòng nhập Seller SKU",
  requireSotreSKU: "Vui lòng nhập Shop SKU",
  requirePassword: "Yêu cầu Mật khẩu",
  noEmptyUsername: "Tài khoản không thể để trống", // 账号不能为空
  Select: "Vui lòng chọn",
  Please_input: "Vui lòng nhập",
  closeTip: "Rời khỏi trang hiện tại, thông tin sẽ không được lưu lại",
  Tip: "Mẹo"
};
