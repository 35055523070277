export default {
  settle: "ลงทะเบียน",
  settleIn: "ลงทะเบียนผู้ขาย Youpik",
  accountType: "ประเภทบัญชี",
  personalAccount: "ผู้ขายบุคคลธรรมดา",
  personalAccountTip: "สำหรับผู้ขายบุคคลธรรมดาจำเป็นต้องอายุครบ 18 ปีบริบูรณ์และต้องแสดงบัตรประจำตัวประชาชน",
  enterpriseAccount: "ผู้ขายนิติบุคคล",
  enterpriseAccountTip: "กรุณาตรวจสอบใบอนุญาตประกอบการของคุณว่ายังไม่หมดอายุ เพื่อยื่นอนุมัติร้านค้าใน Youpik",
  sellerType: "ประเภทผู้ขาย",
  requireSellerType: "กรุณาเลือกประเภทผู้ขาย",
  CrossBorderSeller: "ผู้ขายต่างประเทศ",
  OrdinarySeller: "ผู้ขายทั่วไป",
  BrandSeller: "ผู้ขายสินค้าแบรนด์",
  ShopLocation: "ตำแหน่งที่ตั้งร้านค้า",
  StoreName: "ชื่อร้านค้า",
  requireShopLocation: "กรุณาเลือกตำแหน่งที่ตั้งร้านค้า",
  haveReferralCode: "ฉันมีรหัสอ้างอิง",
  referralCodeOptional: "รหัสอ้างอิง(ไม่จำเป็น)",
  ConfirmationInformation: "กรุณายอมรับข้อตกลงและเงื่อนไข",
  AccountSettle: "เข้าร่วมผู้ขาย",
  FillStorageInfo: "กรอกข้อมูลคลังสินค้า",
  FillIdAndBank: "กรอกบัตรประจำตัวประชาชนและข้อมูลบัญชีธนาคาร",
  Password: "รหัสผ่าน",
  ConfirmPassword: "ยืนยันรหัสผ่าน",
  Email: "อีเมล์",
  MobileCode: "รหัสยืนยัน SMS",
  SlideToVerify: "เลื่อนเพื่อยืนยัน",
  PrevStup: "ก่อนหน้านี้",
  NextStep: "ถัดไป",
  AddressBook: "สมุดที่อยู่",
  CloneStorageAddress: "เหมือนกับที่อยู่คลังสินค้า",
  Address: "ที่อยู่",
  CountryRegion: "ประเทศ/เขตพื้นที่",
  Province: "จังหวัด",
  District: "อำเภอ/เขต",
  PostalCode: "รหัสไปรษณีย์",
  exportAllTip: "The operation is successful, please check the export record"
};
