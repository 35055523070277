import { getLocale, history } from "umi";
import moment from "moment";
import dayjs from "dayjs";
import { message } from "antd";

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(false);
    }, time);
  });

export const isBuildProduction: boolean = process.env.NODE_ENV === "production";

export const isProduction: boolean = window.location.host === "ops.uchoice.pro";
export const isPre: boolean = window.location.host === "ops.pre.uchoice.youpik.dev";

export const openNewWindow = (pathname: string): void => {
  window.open(window.location.origin + history.createHref({ pathname }));
};

export const refreshGoPath = (pathname: string): void => {
  window.open(window.location.origin + history.createHref({ pathname }), "_self");
};

export function formatTimeForMoment(time: any, format = "YYYY-MM-DD HH:mm:ss") {
  if (!time) {
    return "-";
  }
  return moment(time).format(format);
}

// excel字节流解析
export function excelParse(res: any, prefix?: string, name?: string) {
  const blob = new Blob([res.data]);
  if (blob.size === 0) {
    return;
  }
  const date = formatTimeForMoment(new Date());
  const fileName = name || `${prefix || ""}${date}.xls`;
  if (
    res.data.type === "application/vnd.ms-excel" ||
    res.data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    if ("download" in document.createElement("a")) {
      // 非IE下载
      const elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    } else {
      // IE10+下载
      (navigator as any).msSaveBlob(blob, fileName);
    }
  } else {
    const reader: any = new FileReader();
    reader.onload = function (event: any) {
      const msg = JSON.parse(reader.result).message;
      message.warning(msg); // 将错误信息显示出来
    };
    reader.readAsText(res.data);
  }
}

export const closeCurrentPage = () => {
  const win: any = window.open("about:blank", "_self");
  win.close();
};

export const handleLocaleTime = (time: number) => {
  return time + (new Date().getTimezoneOffset() / 60 / -1 - 7) * 60 * 60 * 1000;
};

export const formatNumber = (num: number = 0) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num.toString();
};

// 自定义的月份缩写映射
const monthAbbr = {
  "en-US": [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dec."
  ],
  "th-TH": [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค."
  ],
  "vi-VN": [
    "Thg 1",
    "Thg 2",
    "Thg 3",
    "Thg 4",
    "Thg 5",
    "Thg 6",
    "Thg 7",
    "Thg 8",
    "Thg 9",
    "Thg 10",
    "Thg 11",
    "Thg 12"
  ],
  "zh-CN": ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
};

export const formatDate = (date) => {
  const language = getLocale();
  const day = dayjs(date).format("DD");
  const month = dayjs(date).month(); // 0-based month
  const year = dayjs(date).format("YYYY");

  if (!monthAbbr[language]) {
    throw new Error(`Language '${language}' is not supported.`);
  }

  return language === "zh-CN"
    ? dayjs(date).format("YYYY/MM/DD")
    : `${day} ${monthAbbr[language][month]} ${year}`;
};
