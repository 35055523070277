export default {
  首页: "首页",
  绩效管理: "绩效管理",
  "绩效管理.PIC绩效": "PIC绩效",
  新增: "新增",
  编辑: "编辑",
  运营管理: "运营管理",
  "运营管理.首页配置": "首页配置",
  登录: "登录",
  "运营管理.添加": "新增",
  "运营管理.编辑": "编辑",
  "运营管理.详情": "详情",
  "运营管理.瀑布流配置": "瀑布流配置",
  样品申请: "样品申请",
  "订单管理.sampleApplication": "样品申请",
  "运营管理.排行榜配置": "排行榜配置",
  "运营管理.排行榜新增": "排行榜新增",
  "运营管理.排行榜编辑": "排行榜编辑",
  "运营管理.新人样品池配置": "新人样品池配置",
  "运营管理.动态页配置": "动态页配置",
  用户管理: "用户管理",
  "用户管理.uChoice用户": "跟进达人",
  "用户管理.达人公海": "达人公海",
  "用户管理.跟进达人": "跟进达人",
  "运营管理.banner配置": "banner配置",
  商品管理: "TAP商品",
  "商品管理.TT商品": "TT商品",
  "商品管理.普通活动": "普通活动",
  "商品管理.明星活动": "明星活动",
  "商品管理.商品集": "商品集",
  "用户管理.标签管理": "标签管理",
  "用户管理.达人链接管理": "达人链接管理",
  "用户管理.达人链接管理详情": "达人链接管理",
  "运营管理.uChoice&TT样品申请门槛设置": "样品申请门槛",
  "运营管理.投流管理": "投流管理",
  销售报表: "销售报表",
  "销售报表.达人分析": "达人分析",
  "销售报表.商品分析": "商品分析",
  "销售报表.商家分析": "商家分析",
  "销售报表.视频履约分析": "视频履约分析",
  // 供应链
  供应链管理: "供应链管理",
  供应链管理新增: "供应链管理-新增",
  供应链管理编辑: "供应链管理-编辑",
  消息通知: "消息通知",
  "消息通知.系统推送": "系统推送",
  "消息通知.自定义推送": "自定义推送"
};
