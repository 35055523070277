export default {
  Cancel: "Hủy chỉnh sửa",
  Save: "Lưu",
  ConfirmCloseTitle: "Bạn có chắc chắn muốn đóng không?",
  ConfirmCloseDesc: "Nội dung bảng không thể khôi phục sau khi đóng",
  addUserTitle: "Nhập người dùng",
  addSampleTitle: "Nhập yêu cầu mẫu",
  addPersonTitle: "Nhập thông tin vận chuyển",
  addSupplyTitle1: "Nhập",
  addSupplyTitle2: "cửa hàng của nhà cung cấp",
  InsertRow: "Chèn hàng",
  TipNotEmpty: "Không có nội dung hợp lệ để tải lên",
  可搜索区域或邮编: "Bạn có thể tìm kiếm theo khu vực hoặc mã bưu chính.",
  待发货: "Chờ giao hàng",
  履约跟进: "Hiệu suất bán hàng",
  已发货: "Đã giao hàng",
  "10条样品申请等待您的审核与发货":
    '<span style="color: red;">{num} đơn đề nghị mẫu </span>đang chờ bạn duyệt và giao hàng!',
  "X条带货视频可投流打造单品爆款，投流后可以增长您店铺的单品销量哦！":
    '<span style="color: red;">{num} video </span>có thể chạy quảng cáo để tạo ra sản phẩm hot. Sau khi quảng cáo, bạn có thể tăng doanh số bán hàng của các sản phẩm trong cửa hàng TikTok của mình!',
  "如因快递错发、丢包、破损需要补发的样品申请，商家可以在此增补订单":
    'Nếu có đơn đề nghị mẫu cần bổ sung do <span style="color: red;">giao nhầm, mất hoặc hỏng</span> hàng, người bán có thể bổ sung số vận đơn ở đây.',
  "当前列表内容编辑未提交，若不保存所有编辑的内容将失效，是否保存编辑内容？":
    "Nội dung chỉnh sửa trong danh sách hiện tại chưa được gửi. Nếu không lưu, tất cả nội dung đã chỉnh sửa sẽ bị mất. Bạn có muốn lưu nội dung đã chỉnh sửa không?",

  ["add_sheet.Error"]: "Lỗi",

  ["add_user.tt用户名"]: "TikTok User Name",
  ["add_user.跟进人"]: "PIC",
  ["add_user.联系电话"]: "Số Điện Thoại Liên Hệ",
  ["add_user.lineId"]: "LINE ID",
  ["add_sample.达人名称"]: "Tên Người Tạo",
  ["add_sample.申请日期"]: "Ngày Đăng Ký",
  ["add_sample.item id"]: "Mã Sản Phẩm",
  ["add_sample.规格"]: "Đặc Điểm Kỹ Thuật",
  ["add_sample.数量"]: "Số Lượng",
  ["add_sample.商品链接"]: "Liên Kết Sản Phẩm",
  ["add_sample.橱销截图"]: "Ảnh Màn Hình Trưng Bày",
  ["add_sample.30天成交件数"]: "Số lượng giao dịch trong 30 ngày",
  ["add_sample.近一个月gmv"]: "GMV Trong Tháng Qua",
  ["add_sample.收件人姓名"]: "Tên Người Nhận",
  ["add_sample.收件人电话"]: "Số Điện Thoại Người Nhận",
  ["add_sample.收件地址"]: "Địa Chỉ Người Nhận",
  ["add_sample.地区"]: "Quận",
  ["add_sample.详细地址"]: "Địa Chỉ Chi Tiết",
  ["add_sample.可履约方式"]: "Loại Nội Dung",
  ["add_sample.视频"]: "Video",
  ["add_sample.直播"]: "LIVE",
  ["add_sample.视频+直播"]: "Video & Livestream",

  ["add_person.订单号"]: "Mã Đơn Hàng",
  ["add_person.主播名"]: "Tên Người Dùng TikTok",
  ["add_person.商品类目"]: "Tên Danh Mục",
  ["add_person.商品名称"]: "Tên Sản Phẩm",
  ["add_person.商品图"]: "Hình Ảnh Sản Phẩm",
  ["add_person.item id"]: "Mã Sản Phẩm",
  ["add_person.价格"]: "Giá",
  ["add_person.店铺名称"]: "Tên Cửa Hàng",
  ["add_person.商品链接"]: "Liên Kết Sản Phẩm",
  ["add_person.规格"]: "Đặc Điểm Kỹ Thuật",
  ["add_person.数量"]: "Số Lượng",
  ["add_person.video/live"]: "Video/Trực Tiếp",
  ["add_person.收件人姓名"]: "Tên Người Nhận",
  ["add_person.收件人电话"]: "Số Điện Thoại Người Nhận",
  ["add_person.收件地址"]: "Địa Chỉ Người Nhận",
  ["add_person.橱销截图"]: "Ảnh Màn Hình Trưng Bày",
  ["add_person.近一个月gmv"]: "GMV Trong Tháng Qua",
  ["add_person.follow"]: "Người Theo Dõi",
  ["add_person.物流单号"]: "Số Theo Dõi",
  ["add_person.视频链接"]: "Liên Kết Video",
  ["add_person.直播时间"]: "Thời Gian Trực Tiếp",
  ["add_person.同意/拒绝"]: "Đồng Ý/Từ Chối",
  ["add_person.同意"]: "Đồng Ý",
  ["add_person.拒绝"]: "Từ Chối",
  ["add_person.MCN"]: "MCN",
  ["add_person.跟进人"]: "PIC",
  ["add_person.投流视频ID"]: "Mã Video Quảng Cáo",
  ["add_person.履约出单数"]: "Số Đơn Thực Hiện",
  ["add_person.履约GMV"]: "GMV Thực Hiện",
  ["add_person.投流状态"]: "Trạng Thái Quảng Cáo Video",
  ["add_person.订单来源"]: "Nguồn Đơn Hàng",
  ["add_person.样品申请信息"]: "Thông Tin Yêu Cầu Mẫu",
  ["add_person.商家样品审核跟进表"]: "Bảng Theo Dõi Xét Duyệt Mẫu Của Nhà Cung Cấp",
  ["add_person.商家投流跟进表"]: "Bảng Theo Dõi Quảng Cáo Video Của Nhà Cung Cấp",
  ["add_person.申请时间"]: "Ngày Yêu Cầu",
  ["add_person.备注"]: "Ghi Chú",
  ["add_person.拒绝原因"]: "Lý Do Từ Chối",
  ["add_person.收件地址省"]: "Tỉnh/Thành Phố",
  ["add_person.收件地址市"]: "Quận/Huyện",
  ["add_person.收件地址区"]: "Phường/Xã",
  ["add_person.邮编"]: "Mã Bưu Chính",
  ["add_person.Content ID"]: "Content ID",
  ["add_person.当月视频销量"]: "Doanh Số Video Trong Tháng Này",
  ["add_person.上周视频销量"]: "Doanh Số Video Tuần Trước",
  ["add_person.上月视频销量"]: "Doanh Số Video Tháng Trước",
  ["add_person.Ads Code"]: "Mã Quảng Cáo",
  ["add_person.增补物流单号"]: "Số Theo Dõi Bổ Sung",

  ["add_supply.店铺id"]: "ID Cửa Hàng"
};
