export default {
  反馈方向: "ประเภทของข้อเสนอแนะ",
  问题反馈: "ข้อเสนอแนะ",
  功能建议: "คำแนะนำเกี่ยวกับระบบ",
  反馈标题: "หัวข้อ",
  "一句话概括您的反馈…": "สรุปความคิดเห็นของคุณในประโยคเดียว...",
  内容超出字符数量上限: "เนื้อหาเกินจำนวนอักขระที่กำหนด",
  请输入反馈标题: "กรุณาใส่หัวข้อข้อเสนอแนะ",
  上传附件: "อัพโหลดไฟล์แนบ",
  点击或将文件拖拽到这里上传: "คลิกหรือลากและวางไฟล์ที่นี่เพื่ออัปโหลด",
  "支持扩展名: .jpg .png .mp4": "รองรับไฟล์: .jpg .png .mp4",
  "图片最多上传10张，视频仅支持1个": "อัปโหลดได้สูงสุด 10 ภาพ วิดีโอ 1 รายการเท่านั้น",
  所有文件大小不超过20MB: "และขนาดของไฟล์ทั้งหมดไม่ควรเกิน 20MB",
  请上传至少1个附件: "โปรดอัปโหลดไฟล์แนบอย่างน้อย 1 ไฟล์",
  "不可添加，最多上传10张图片": "ไม่สามารถเพิ่มได้ อัปโหลดได้สูงสุด 10 ภาพ",
  "不可添加，图片和视频不可同时上传": "รูปภาพและวิดีโอไม่สามารถอัปโหลดพร้อมกันได้",
  "不可添加，最多上传1个视频": " อัปโหลดวิดีโอได้สูงสุด 1 วิดีโอ",
  "不可添加，仅支持 .jpg .png .mp4格式添加": "ไม่สามารถเพิ่มได้ รองรับเฉพาะไฟล์ .jpg .png .mp4",
  "不可添加，文件超出大小，最大不超过20MB": "เพิ่มไม่ได้ ไฟล์เกินขนาด สูงสุดไม่เกิน 20MB",
  反馈描述: "คำอธิบายข้อเสนอแนะ",
  "系统问题或功能建议，描述越清楚能让开发者更快定位问题哦~":
    "ปัญหาหรือคำแนะนำเกี่ยวกับระบบ กรุณาพิมพ์คำอธิบายที่ชัดเจน ผู้พัฒนาสามารถค้นหาปัญหาได้เร็วยิ่งขึ้น~",
  "联系电话/LINE": "เบอร์ติดต่อ/LINE",
  "请留下您的手机号或LINE ID吧~": "กรุณาฝากเบอร์มือถือหรือ LINE ID~",
  提交并继续反馈: "ส่งข้อเสนอแนะและดำเนินการต่อ",
  待受理: "ได้รับ",
  处理中: "ดำเนินการ",
  已完结: "สำเร็จ",
  已接收: "ยังไม่ได้กรอกข้อมูล",
  新回复: "ตอบใหม่",
  您还没提交任何反馈: "คุณยังไม่ได้ส่งข้อเสนอแนะใด ๆ",
  我们乐意能听到您的反馈与建议: "เรายินดีรับฟังความคิดเห็นและข้อเสนอแนะของคุณ",
  "您的反馈建议是我们前进的动力…": "คำติชมของคุณเป็นแรงผลักดันให้เราก้าวไปข้างหน้า...",
  联系方式: "ช่องทางติดต่อ",
  附件: "เอกสารประกอบ",
  跟进一下: "ติดตาม",
  反馈进度表: "ข้อเสนอแนะ",
  等待管理员接收: "รอแอดมินดำเนินการ",
  等待反馈问题处理: "รอการตอบกลับจากแอดมิน",
  等待反馈问题完结: "การตอบรับเสร็จสิ้น",
  等待管理员接收后处理反馈: "รอผู้ดูแลระบบรับข้อเสนอแนะ",
  反馈跟进区: "การติดตามผลตอบรับ",
  "写下你的评论…": "แสดงความคิดเห็นของคุณที่นี่...",
  添加图片: "เพิ่มรูปภาพ",
  Ulive管理员: "ผู้ดูแลระบบ Ulive",
  我: "ผม",
  刚刚: "เมื่อครู่นี้",
  "你确定要删除该评论吗？": "คุณแน่ใจหรือว่าต้องการลบความคิดเห็นนี้?",
  "未填写内容。": "ยังไม่ได้กรอกข้อมูล",
  反馈时间: "เวลายืนยัน",
  处理状态: "สถานะ",
  "没有更多评论啦~": "ไม่มีความคิดเห็นเพิ่มเติม",
  提交反馈: "ยืนยัน",
  详情: "รายละเอียด",
  Ulive团队: "Ulive Admin"
};
