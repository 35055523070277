export default {
  Product_Ranking_List: "Product Ranking List",
  List_ID_: "List ID：",
  List_Name_: "List Name：",
  On___Off: "On / Off",
  Please_Select_Here: "Please Select Here",
  New_List: "New List",
  List_ID: "List ID",
  List_Name: "List Name",
  Banner_image: "Banner image",
  Sub_list: "Sub list",
  Status: "Status",
  Sort: "Sort",
  Action: "Action",
  Off: "Off",
  On: "On",
  Preview: "Preview",
  Edit: "Edit",
  On: "On",
  Off: "Off",
  Please_try_to_edit_it_after_you_turn_the_list_off_:
    "Please try to edit it after you turn the list off.",
  Confirm_to_turn_it_off: "Confirm to turn it off",
  Are_you_sure_that_you_want_to_turn_the_list_off_:
    "Are you sure that you want to turn the list off?",
  Cancel: "Cancel",
  Confirm: "Confirm",
  Turn_on_the_list: "Turn on the list",
  If_you_choose_to_change_the_active_banners__the_original_banner_will_be_invalid_:
    "If you choose to change the active banners, the original banner will be invalid.",
  Available: "Available",
  Select: "Select",
  Cancel: "Cancel",
  Confirm: "Confirm",
  Change_confirmation: "Change confirmation",
  Are_you_sure_that_you_change_the_original_list_which_named__xxx__instead__It_will_be_turned_off_after_you_changed_it_:
    "Are you sure that you change the original list which named # instead? It will be turned off after you changed it.",
  English_Version: "English Version",
  Thai_Version: "Thai Version",
  Vi_Version: "Vietnamese Version",
  New___Edit_Ranking_List: "New / Edit Ranking List",
  List_Name__English__: "List Name (English)：",
  List_Name__Thai__: "List Name (Thai)：",
  List_Name__VN__: "List Introduction (Vietnamese)：",
  Maximum_10_characters: "Maximum 10 characters",
  Required_to_fill_in_: "Required to fill in.",
  Homepage_Banner_: "Homepage Banner：",
  Self_designed_Banner: "Self-designed Banner",
  No: "No",
  Yes: "Yes",
  Ranking_List_Banner_: "Ranking List Banner：",
  Main_Color_: "Main Color：",
  Sub_list: "Sub-list",
  Sub_list_1_Name__English__: "Sub-list # Name (English)：",
  Sub_list_1_Name__Thai__: "Sub-list # Name (Thai)：",
  Product_List_ID_: "Product List ID：",
  Numbers_only: "Numbers only",
  Product_List_ID_does_not_exist: "Product List ID does not exist",
  You_can_find_Product_List_ID_at_Operational_Management_Product_List_Management_:
    "You can find Product List ID at Operational Management-Product List Management.",
  New_sub_list__4_sub_list_can_be_added_: "New sub-list (# sub-list can be added)",
  Image_suggestion__375_200_px_and_2M_as_maximum_size:
    "Image suggestion: 375*200 px and 2M as maximum size",
  Delete_List: "Delete List",
  Delete_confirmation: "Delete confirmation",
  Are_you_sure_that_you_want_to_delete_the_list_: "Are you sure that you want to delete the list?",
  Cancel: "Cancel",
  Confirm: "Confirm",
  Save_and_Preview: "Save and Preview",
  Save: "Save",
  Delete_failed: "Delete failed",
  Failed_reason__This_list_is_on__Please_try_it_again_after_you_turn_it_off_:
    "Failed reason: This list is on. Please try it again after you turn it off.",
  Save_succeed_: "Save succeed.",
  Delete_succeed_: "Delete succeed.",
  Image_upload_failed: "Image upload failed",
  Failed_reason__it_exceeds_maximum_size_as_2M_: "Failed reason: it exceeds maximum size as 2M.",
  Save_failed: "Save failed",
  Failed_reason__This_list_is_on__Please_try_it_again_after_you_turn_it_off_:
    "Failed reason: This list is on. Please try it again after you turn it off.",
  Edit_Again: "Edit Again",
  Complete: "Complete",
  Delete: "Delete",
  榜单简介英文: "List Introduction (English)：",
  榜单简介泰语: "List Introduction (Thai)：",
  榜单简介越南: "คำอธิบาย",
  榜单简介: "List Introduction (Vietnamese)",
  sampleAmountTips:
    "The sample amount is SKU unit price * applied quantity. If not filled in, it is considered infinite with no amount limit.",
  sampleFulfillmentTips:
    "The sample amount is fulfilled sample request quantity / shipped quantity. If not filled in, it is considered 0%, with no fulfillment rate limit."
};
