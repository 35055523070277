export default {
  Reset: "Reset",
  name: "Name",
  ImgTip:
      "Recommended size: 588*720 Pixels, the size is limited to 2MB; only png, jpg, jpeg, and gif formats are supported. Only one can be uploaded at a time. ",
  id: "ID",
  status: "Status",
  ID: "ID",
  names: "Name",
  img: "Image",
  time: "Time",
  IDUrl: "Product/URL/Link",
  sort: "Sort",
  popupMode: "Type",
  popUpImg: "Image",
  popUpTime: "Time",
  popUpMode: "Type",
  popUpSort: "Order",
  OnceDay: "once a day",
  Once: "only once",
  always: "always"
};
