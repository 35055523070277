export default {
  Actual_Sales: "ยอดขายจริง",
  Export: "ดาวน์โหลด",
  English: "ภาษาอังกฤษ",
  Thai: "ภาษาไทย",
  Vnvi: "ภาษาเวียดนาม",
  Please_filter_the_Youpik_category_and_Lazada_category_first:
    "ตัวกรอง สินค้า Youpik หรือสินค้า Lazada",
  Ranking_Setting: "การตั้งค่า สินค้าขายดี",
  Youpik_Category: "หมวดหมู่สินค้า Youpik",
  Lazada_Category: "หมวดหมู่สินค้า Lazada",
  Customized_Category: "กำหนดหมวดหมู่เอง",
  Add: "เพิ่ม",
  All: "ทั้งหมด",
  Not_start: "ยังไม่เริ่ม",
  Ongoing: "เริ่ม",
  Closed: "จบ",
  Number_of_Products: "จำนวนสินค้า",
  Create_Time: "เวลาที่สร้าง",
  Valid_Time: "เวลาเริ่มต้น",
  Ranking_Link: "ลิงก์ลำดับสินค้า",
  Status: "สถานะ",
  Action: "ดำเนินการ",
  Edit: "แก้ไข",
  Delete: "ลบ",
  Rank: "ลำดับ",
  Search: "ค้นหา",
  Reset: "รีเซ็ต",
  Preview: "ดูตัวอย่าง",
  Total: "ทั้งหมด 100 หน้า",
  Page: "20/หน้า",
  Go_to___: "ไปที่",
  Remark: "หมายเหตุ",
  Save: "บันทึก",
  Timed: "เวลา",
  Open: "เปิด",
  Close: "ปิด",
  Rename: "เปลี่ยนชื่อ",
  Product_Image: "ภาพสินค้า",
  Product_ID: "รหัสสินค้า",
  Product_Name: "ชื่อสินค้า",
  Sale_Price: "ราคาขาย",
  Commission_Rate: "อัตราค่าคอมมิชชั่น",
  PDP_Page: "หน้า PDP",
  All_best_seller_product_related_to_this_category_will_be_deleted__is_it_confirmed_:
    "จะลบสินค้าขายดี ทั้งหมดหรือไม่",
  Confirm: "ยืนยัน",
  Cancel: "ยกเลิก",
  This_category_already_exists: "หมวดหมู่นี้มีอยู่แล้ว",
  Please_input_product_ID: "กรุณากรอก ID ผลิตภัณฑ์",
  Select_All: "เลือกทั้งหมด",
  Add_in_batches: "เพิ่มแบช",
  Added: "เพิ่ม",
  Ranking_Add: "เพิ่มการจัดอันดับ",
  Please_input: "กรุณาใส่ข้อมูล",
  closeStatus: "หลังจากปิดแล้วจะไม่สามารถเปิดได้อีก คุณแน่ใจหรือไม่",
  inputCustomized_Category: "กรุณากรอกหมวดหมู่ที่กำหนดเอง",
  goodIdExistence: "เพิ่มสินค้าไม่สำเร็จ เนื่องจากสินค้านี้อยู่ในลิสต์สินค้าขายดีแล้ว",
  screenCategory: "请ตัวกรอง สินค้า Youpik หรือสินค้า Lazada",
  最多添加20个: "สามารถเพิ่มสินค้าได้สูงสุด 20 รายการ "
};
