export default {
  total: "ทั้งหมด",
  pleaseInput: "โปรดกรอก",
  pleaseSelect: "กรุณาเลือก",
  expand: "ขยาย",
  collapse: "ย่อ",
  importShop: "Import",
  exportTemplate: "Export",
  image: "ภาพสินค้า",
  name: "ชือรายการ",
  price: "ราคา",
  commission: "ค่าคอมมิชชั่น",
  commissionRate: "ค่าคอมโดยประมาณ",
  status: "สถานะ",
  sort: "ลำดับ",
  upSelf: "เปิดใช้รายการสินค้า",
  downSelf: "ยกเลิกรายการสินค้า",
  itemId: "หมายเลขสินค้า",
  Sort: "ลำดับ",
  Reject: "ปฏิเสธคำขอ",
  Submitted: "เวลาที่ยื่น",
  Passed: "กำลังจัดส่ง",
  Delivered: "จัดส่งแล้ว",
  Completed: "สำเร็จแล้ว",
  CheckLogistics: "ตรวจสอบขนส่ง",
  TT商品: "สินค้า Tiktok",
  支持扩展名: "รองรับไฟล์"
};
