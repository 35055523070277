export default {
  Sample_Application: "Ứng dụng Mẫu",
  TAP_Sales_Report: "Báo cáo Doanh số TAP",
  Creator_Analysis: "Phân tích Nhà sáng tạo",
  Product_Analysis: "Phân tích Sản phẩm",
  Seller_Analysis: "Phân tích Người bán",
  Creator_Name_: "Tên Nhà sáng tạo",
  Please_enter: "Vui lòng nhập",
  Order_Creation_Date_: "Ngày Tạo Đơn hàng",
  Export_Report: "Xuất Báo cáo",
  Data_last_updated_on__mm___dd____yy____hh_mm___BKK_time_zone_:
    "Dữ liệu cập nhật lần cuối vào mm dd, yy, HH:MM (múi giờ BKK)",
  Creator_with_Sales: "Doanh số của Nhà sáng tạo",
  Authorized_Creator: "Người tạo được ủy quyền",
  Unauthorized_Creator: "Người tạo không được ủy quyền",
  GMV: "GMV",
  Completed_GMV: "GMV đã Hoàn thành",
  Cancelled_GMV: "GMV đã Hủy",
  Progressing_GMV: "GMV đang Tiến triển",
  Completed_Sales: "Doanh số đã Hoàn thành",
  Live_Streaming___Completed_GMV: "Livestreaming - GMV đã Hoàn thành",
  Video___Completed_GMV: "Video - GMV đã Hoàn thành",
  Showcase___Completed_GMV: "Trưng bày - GMV đã Hoàn thành",
  Product_Information: "Thông tin Sản phẩm",
  Product_ID_: "ID Sản phẩm",
  Product_Price: "Giá Sản phẩm",
  Seller: "Người bán",
  Sales_Volume: "Số lượng bán",
  Store_Code_: "Mã cửa hàng",
  Seller_Name_: "Tên Người bán",
  Seller_Information: "Thông tin Người bán",
  Actions: "Thao tác",
  View_Sales_Products: "Xem Sản phẩm Bán chạy",
  Sales_Products_of_Store_Named_xxxxx: "Sản phẩm Bán chạy của Cửa hàng tên #",
  saleByCreator: "Sản phẩm bán của #",
  orderNum: "Đơn hàng",
  订单日期: "Ngày Đặt hàng",
  带货达人: "Nhà sáng tạo",
  所属商家: "Thông tin Người bán",
  投流状态: "Trạng thái Quảng cáo",
  未投流: "Chưa Quảng cáo",
  已投流: "Đã Quảng cáo"
};
