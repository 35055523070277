export default {
  Active: "Hoạt động", // 活动
  BaseSet: "Cài đặt Thông tin Cơ bản",
  RuleSet: "Cài đặt Quy tắc Đăng ký", // 报名规则设置
  InfoSet: "Cài đặt Thông tin Đăng ký", // 报名信息设置
  PageSet: "Cài đặt Trang Chiến dịch",
  // 补贴
  CampaignSubsidy: "Phụ cấp Chiến dịch",
  CommissionCompensationRote: "Phần trăm Chiết khấu",
  BatchCampaignSubsidies: "Thiết lập phụ cấp chiến dịch hàng loạt",
  BatchCommissionCompensation: "Thiết lập chiết khấu hoa hồng hàng loạt",
  CampaignSubsidyAmountSettings: "Phụ cấp Chiến dịch",
  CampaignSubsidyTip1:
    "1. Phụ cấp chiến dịch đề cập đến đơn hàng được tạo ra bằng cách bán với giá chiến dịch trong suốt thời gian chiến dịch, và nền tảng sẽ tự động bồi thường cho người bán chi phí theo thiết lập (số tiền phụ cấp * số lượng) của sản phẩm khi người bán thanh toán.",
  CampaignSubsidyTip2: "2. Đặt số tiền là tiền phụ cấp cho một SKU.",
  CampaignSubsidyTip3: "3. Không thể chỉnh sửa sau khi chiến dịch bắt đầu.",
  SubsidyAmount: "Số tiền phụ cấp",
  CampaignSubsidyRule: "Số tiền phụ cấp phải lớn hơn 0, tối đa 2 chữ số thập phân",
  SetCampaignSubsidies: "Thiết lập Phụ cấp Chiến dịch",
  MoreThanPrice:
    "Số tiền phụ cấp sản phẩm của chiến dịch vượt quá giá bán gốc của sản phẩm, Bạn có muốn tiếp tục không? ",
  CampaignSubsidySuccessfully: "Thiết lập thành công",
  continue: "TIẾP TỤC",
  CommissionCompensationRatioSetting: "Thiết lập phần trăm chiết khấu",
  CommissionCompensationTip1:
    "1. Phần trăm chiết khấu đề cập đến đơn hàng được tạo ra bằng cách bán với giá chiến dịch trong suốt thời gian chiến dịch, và nền tảng sẽ tự động bồi thường cho người bán phí theo thiết lập (phần trăm chiết khấu * giá sự kiện * số lượng mặt hàng) khi người bán thanh toán.",
  CommissionCompensationTip2: "2. Đặt số tiền là tiền phụ cấp cho một SKU.",
  CommissionCompensationTip3: "3. Không thể chỉnh sửa sau khi chiến dịch bắt đầu.",
  CommissionCompensation: "Phụ cấp Hoa hồng",
  CommissionCompensationRule: "Bồi thường phải lớn hơn 0, nhỏ hơn 100, tối đa 2 chữ số thập phân",
  CommissionCompensationSuccessfully: "Thiết lập thành công",
  Set: "Thiết lập",
  Edit: "Chỉnh sửa",
  CommissionSet: "Cài đặt Quy tắc Chiết khấu",
  CommissionRate: "Hoa hồng Tiếp thị YPK",
  SelectProduct: "Chọn sản phẩm",
  CommodityRemoval: "Bạn có chắc muốn loại bỏ mục này khỏi chiến dịch này không??",
  NO: "Không.",
  auditOngoing: "Deactive SC/Deactive Platform",
  auditpass: "On Shelf",
  Offshelf: "Deactive platform",
  sellerShelf: "Deactive SC",
  shoppRate: "Nhập giá trị lớn hơn 2",
  shoppMaxRate: "Nhập giá trị nhỏ hơn 100",
  Category: "Danh mục",

  InfoSetTip: "Thông tin đăng ký được xem bởi người bán SC", // sc卖家端查看到的报名信息
  ActiveTitle: "Tên Sự kiện",
  ActiveId: "ID Hoạt động", // 活动ID
  ActiveStatu: "Trạng thái Chiến dịch",
  ActivityType: "Loại Chiến dịch",
  ActiveTime: "Thời gian Chiến dịch",
  CreateTime: "Thời gian Tạo",
  ApplyTime: "Thời gian Đăng ký",
  All: "Tất cả",
  ActiveSeller: "Người bán Chiến dịch",
  ActiveGoods: "Sản phẩm Chiến dịch", // 活动商品
  NotLimit: "Không giới hạn",
  SomeItem: "Loại sản phẩm được chỉ định", // 指定商品类目
  NotStarted: "Chưa bắt đầu",
  Applying: "Đang đăng ký",
  Ongoing: "Đang diễn ra",
  Closed: "Đóng",
  AddActive: "Thêm sự kiện", // 添加活动
  Id: "ID",
  ActiveTimeName: "Tên khoảng thời gian",
  ActiveItemCount: "Số lượng mặt hàng",
  IsOpen: "Bạn có chắc muốn kích hoạt các mặt hàng không?",
  OpenTip: "Bạn có chắc muốn kích hoạt chiến dịch?",
  CloseTip: "Bạn có chắc muốn ngừng hoạt động chiến dịch?",
  InputItemid: "Vui lòng điền ID sản phẩm",
  InputSkuid: "Vui lòng điền ID SKU",
  ItemId: "ID Sản phẩm",
  Price: "Giá bán",
  Stock: "Số lượng tồn kho",
  Limit: "Số lượng mua giới hạn",
  ActivePrice: "giá sản phẩm",
  ActiveStock: "Kho sự kiện",
  SurplusStock: "Kho Sự kiện Còn lại",
  ActiveGoodsCount: "Số lượng sản phẩm có thể đăng ký", // 可报名商品数
  Banner: "Ảnh Banner", // Banner图片
  BannerTip:
    "Kích thước đề xuất: 350*210 pixel, giới hạn kích thước là 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg, gif", // 建议尺寸：350*210 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式
  ApplyTip: "Ghi chú cho việc đăng ký", // 报名须知
  BaseInfo: "Thông tin cơ bản",
  ActiveGoodsSet: "Sản phẩm Chiến dịch",
  ItemName: "Tên Sản phẩm",
  ShopName: "Tên Cửa hàng",
  ItemCategory: "Tên Danh mục",
  PendingReview: "Đang chờ duyệt",
  Passed: "Đã phê duyệt",
  Failed: "Bị từ chối",
  AddItem: "Thêm Sản phẩm",
  ReviewStatus: "Trạng thái phê duyệt",
  From: "Nguồn",
  AuditLogs: "Bản ghi kiểm toán", // 审核记录
  ConfirmPass: "Xác nhận", // 确认通过
  AuditFaile: "Không thông qua kiểm toán", // 审核不通过
  AuditPass: "Phê duyệt",
  RequireReason: "Vui lòng điền vào lý do", // 请填写原因
  BatchPass: "Phê duyệt hàng loạt", // 批量通过
  BatchFaile: "Từ chối hàng loạt", // 批量拒绝
  EditItemInfo: "Chỉnh sửa thông tin sản phẩm sự kiện", // 编辑活动商品信息
  PlaceChangeItem: "Vui lòng chọn sản phẩm để duyệt", // 请选择待审核商品
  Operator: "Người vận hành", // 操作人
  OperationTime: "Thời gian Vận hành", // 操作时间
  AuditResult: "Kết quả kiểm toán", // 审核结果
  FaileReason: "Lý do từ chối", // 拒绝原因
  BatchSet: "Thiết lập hàng loạt", // 批量设置
  ApplyAudit: "Đang chờ duyệt",
  ActivityModel: "Lựa chọn",
  ChangeModel: "Thay đổi kiểu",
  ToUse: "Sử dụng",
  PhoneView: "Xem trước",
  UploadTip: "Kích thước: Chiều rộng 750 px không vượt quá 2MB, Sử dụng tệp png, jpg, jpeg và gif",
  ImageTip:
    "Kích thước đề xuất: 327*120 Pixels, kích thước giới hạn là 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg, và gif. Chỉ có thể tải lên một lần. ",
  ShopStatus: "Trạng thái Người bán",
  AuditType: "Trạng thái Nền tảng",
  IsGift: "Youpik Choice",
  Sort: "Sắp xếp",
  Remove: "Xóa",
  BatchRemove: "Xóa hàng loạt",
  ConfirmRemove: "Bạn có chắc muốn xóa mục này không?",
  ConfirmBatchRemove: "Bạn có chắc muốn xóa các mục đã chọn không?",
  RequireBanner: "Vui lòng tải lên banner",
  ConfirmSave: "Xác nhận lưu?",
  ConfirmCancel: "Xác nhận hủy thay đổi？",
  Preview: "Xem trước",
  ActivityUrl: "Liên kết Chiến dịch",
  Selecting: "Đang chọn",
  ActivityDiscounts: "Chiết khấu Chiến dịch",
  SomeSeller: "Người bán được chọn",
  RuleError: "Thông tin nhập sai",
  SelectCategory: "Loại sản phẩm là bắt buộc",
  TimeError: "Thời gian đăng ký chiến dịch phải kết thúc trước khi chiến dịch bắt đầu",
  SkuStr: "Quy tắc Biến thể SKU",
  Discount: "Chiết khấu Chiến dịch",
  InputId: 'Vui lòng nhập ID người bán, vui lòng sử dụng "," để phân biệt ID người bán',
  PlaceId: "Vui lòng nhập ID người bán",
  LabelImage: "Dấu chiến dịch",
  ActivityCommission: "Hoạt động Chiết khấu",
  ActivityCommissionSet: "Thiết lập Chi phí Tiếp thị Youpik",
  ActivityCommissionRate: "% Chiết khấu",
  ActivityCommissionTip:
    "Sau khi thiết lập, chi phí sẽ thay đổi tùy theo chiến dịch trong thời gian chiến dịch",
  ActivityCommissionRule: "Thiết lập chiết khấu Tiếp thị Youpik＜100%",
  ExtensionCommissionRate: "% Chi phí Tiếp thị Youpik",
  PlatformCommissionRate: "% Chi phí Nền tảng",
  ConfirmUpdeteCommission: "Chi phí không thể chỉnh sửa sau khi lưu, Bạn có chắc muốn lưu không?",
  SeeItmeList: "Kiểm tra các mặt hàng liên quan",
  SkuId: "ID SKU",
  Statistics: "Đang tính toán",
  ConfirmDeleteLog: "Bạn có chắc muốn xóa?",
  ActivityBannerImage: "Ảnh Bìa Chiến dịch",
  ActivityBannerImageTip:
    "Kích thước: 200*200 pixels không vượt quá 2MB, Sử dụng tệp png, jpg, jpeg và gif",
  downModel: "Tải xuống Mẫu",
  UserId: "ID Người dùng",
  UserName: "Tên Người dùng",
  UserLevel: "Cấp độ Youpikker",
  superLevel: "Cấp độ VIP",
  Pcode: "Mã Mời",
  ActivityCompletionStatus: "Trạng thái Chiến dịch",
  ActivityRewardAmount: "Phần thưởng",
  isSettle: "Trạng thái Thanh toán",
  Settleed: "Đã thanh toán",
  NoSettle: "Chưa thanh toán",
  SettlementTime: "Thời gian Thanh toán",
  NoEnd: "Chưa Hoàn thành",
  End: "Đã hoàn thành",
  // 活动页面配置
  PageTitle: "Tiêu đề Sự kiện",
  Coupon: "ID Phiếu quà", // Voucher ID
  NoCoupon:
    "Trước thời điểm có thể nhận được phiếu quà, phiếu quà sẽ không được hiển thị, và chỉ ứng dụng có thể nhận / sử dụng phiếu quà",
  CouponAdd: "Thêm",
  VoucherType: "Loại Phiếu quà",
  VoucherName: "Tên Phiếu quà",
  Denomination: "Mệnh giá Phiếu quà",
  ConditionsUse: "Điều kiện Sử dụng",
  VStatus: "Trạng thái",
  CollectionTime: "Thời gian Thu thập",
  usageTime: "Thời gian Sử dụng",
  FreeShipping: "Miễn phí Vận chuyển",
  saveYpcTip: "Xác nhận lưu thông tin chỉnh sửa?",
  cancelYpcTip: "Rời khỏi trang hiện tại, thông tin sẽ không được lưu",
  confirmItemInfo: "Vui lòng xác nhận thông tin sản phẩm chiến dịch",
  placeselect: "Vui lòng chọn",
  all: "Tất cả",
  select: "Đã chọn",
  lookRecord: "Lịch sử",
  actTip: 'Thành công, vui lòng điều hướng đến "Lịch sử Nhập" để xem',
  toSelect: "Vui lòng chọn",
  useExcel: "Nhập tệp Excel",
  selectFile: "Chọn tệp",
  downloadModel: "Tải xuống mẫu",
  importModel: "Nhập mẫu",
  tip1: "Nhắc nhở nhập:",
  tip2: "1. Vui lòng tải xuống mẫu nhập và điền dữ liệu trước khi nhập;",
  tip3: "2. Kích thước tệp nhập không được vượt quá 2M;",
  tip4: "3. Số lượng sản phẩm nhập không được vượt quá 1000 mỗi lần;",
  timeisend: "Thời gian đăng ký đã kết thúc",
  exportHistory: "Lịch sử Nhập",
  createTime: "Thời gian Tạo",
  fileName: "Tên tệp",
  status: "Trạng thái",
  downloadFile: "Tải xuống tệp gốc",
  total: "Tổng",
  success: "Thành công",
  filled: "Thất bại",
  shopsku: "SKU Cửa hàng",
  reason: "Lý do",
  exportHistory2: "Lịch sử Xuất",
  callme: "Vui lòng liên hệ với chúng tôi",
  closeTip2: "Xác nhận đóng chiến dịch? Không thể mở lại sau khi đóng",
  openTimeTip: "Thời gian Bắt đầu Chiến dịch sớm hơn thời gian hiện tại, vui lòng điều chỉnh",
  openLengthTip: "Chiến dịch đã được mở một lần, không được phép mở lại",
  started: "Thời gian Chiến dịch đã bắt đầu, không hỗ trợ chỉnh sửa",
  timeTip: "Thời gian Bắt đầu không thể sớm hơn thời gian hiện tại",
  err20001: "Dữ liệu Excel trống",
  err20002: "Lỗi tiêu đề mẫu",
  err20003: "Lỗi đọc dữ liệu mẫu"
};
