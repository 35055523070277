// 运行时配置
import type { BasicLayoutProps } from "@ant-design/pro-components";

import HeaderContentRender from "@/components/Layout/HeaderContentRender";
import RightContentRender from "@/components/Layout/RightContentRender";
import HeaderTitleRender from "@/components/Layout/HeaderTitleRender";
import logo from "@/assets/logo.png";
import CustomMenuItem from "./components/CustomMenuItem";
import MenuFooterRender from "./components/Layout/MenuFooterRender";
import { userInfoStorage } from "./utils/storage";

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://next.umijs.org/docs/api/runtime-config#getinitialstate
export async function getInitialState(): Promise<IInitialStateProps> {
  const currentUser = JSON.parse(userInfoStorage.get() || "{}");

  return {
    settings: {
      collapsed: false
    },
    currentUser
  };
}

export const layout = (props: any): BasicLayoutProps => {
  const { initialState } = props;
  return {
    // title: "Console",
    // menuHeaderRender: (logo, title, props) => (
    //   <MenuHeaderRender logo={logo} title={title} props={props} />
    // ),
    logo,
    // menu: {
    //   locale: true
    // },
    collapsed: initialState?.settings?.collapsed,
    theme: "light",
    fixedHeader: true,
    fixSiderbar: true,
    menuFooterRender: () => <MenuFooterRender />,
    collapsedButtonRender: false,
    layout: "mix",
    rightContentRender: () => <RightContentRender />,
    headerContentRender: () => <HeaderContentRender />,
    navTheme: "light",
    headerTheme: "dark",
    headerHeight: 60,
    headerTitleRender: () => <HeaderTitleRender />,
    menuItemRender: (item, defaultDom, menuProps) => (
      <CustomMenuItem item={item} defaultDom={defaultDom} menuProps={menuProps} />
    ),
    subMenuItemRender: (item, defaultDom, menuProps) => (
      <CustomMenuItem item={item} defaultDom={defaultDom} menuProps={menuProps} />
    )
  };
};
