export default {
  bandTime: "绑定时间",
  Active: "活动",
  BaseSet: "基本信息设置",
  RuleSet: "报名规则设置",
  InfoSet: "报名信息设置",
  PageSet: "活动页面配置",
  // 补贴
  CampaignSubsidy: "活动补贴THB",
  CommissionCompensationRote: "佣金补贴%",
  BatchCampaignSubsidies: "批量设置活动补贴",
  BatchCommissionCompensation: "批量设置佣金补贴",
  CampaignSubsidyAmountSettings: "活动补贴金额设置",
  CampaignSubsidyTip1:
    "1、活动补贴是指活动期间按照活动价格销售产生的订单，由平台按照设置的补贴金额*商品数量在卖家结算时自动补偿给商家的费用",
  CampaignSubsidyTip2: "2、设置金额为单个SKU补贴费用",
  CampaignSubsidyTip3: "3、活动开始后不可编辑",
  SubsidyAmount: "补贴金额",
  CampaignSubsidyRule: "补贴金额必须大于0，最多2位小数",
  SetCampaignSubsidies: "设置活动补贴",
  MoreThanPrice: "商品补贴金额超过商品原销售价，你还要继续吗？",
  CampaignSubsidySuccessfully: "活动补贴设置成功",
  continue: "继续",
  CommissionCompensationRatioSetting: "佣金补贴比例设置",
  CommissionCompensationTip1:
    "1、佣金补贴是指活动期间按照活动价格销售产生的订单，由平台按照设置的佣金补贴比例*活动价格*商品数量在卖家结算时自动补偿给商家的费用",
  CommissionCompensationTip2: "2、设置金额为单个SKU补贴费用",
  CommissionCompensationTip3: "3、活动开始后不可编辑",
  CommissionCompensation: "佣金补贴",
  CommissionCompensationRule: "补贴必须大于0,小于100，最多2位小数",
  CommissionCompensationSuccessfully: "佣金补贴设置成功",
  Set: "设置",
  Edit: "修改",
  CommissionSet: "分佣规则设置",
  CommissionRate: "商品佣金率",
  SelectProduct: "选择商品",
  CommodityRemoval: "是否确认将商品移除该活动?",
  NO: "编号",
  auditOngoing: "商家下架/平台下架",
  auditpass: "上架",
  Offshelf: "平台下架",
  sellerShelf: "商家下架",
  shoppRate: "输入值要大于2",
  shoppMaxRate: "输入值要小于100",
  Category: "商品类目",
  //
  InfoSetTip: "sc卖家端查看到的报名信息",
  ActiveTitle: "活动名称",
  ActiveId: "活动ID",
  ActiveStatu: "活动状态",
  ActivityType: "活动类型",
  ActiveTime: "活动时间",
  CreateTime: "创建时间",
  ApplyTime: "报名时间",
  All: "全部",
  ActiveSeller: "活动商家",
  ActiveGoods: "活动商品",
  NotLimit: "不限",
  SomeItem: "指定商品类目",
  NotStarted: "未开始",
  Applying: "报名中",
  Ongoing: "进行中",
  Closed: "已结束",
  AddActive: "添加活动",
  Id: "ID",
  ActiveTimeName: "时间段名称",
  ActiveItemCount: "商品数量",
  IsOpen: "是否启用",
  OpenTip: "是否开启活动",
  CloseTip: "是否关闭活动",
  InputItemid: "输入商品ID搜索",
  InputSkuid: "输入SKU ID搜索",
  ItemId: "商品ID",
  Price: "销售价",
  Stock: "现有库存",
  Limit: "限购数量",
  ActivePrice: "活动价格",
  ActiveStock: "活动库存",
  SurplusStock: "活动剩余库存",
  ActiveGoodsCount: "可报名商品数",
  Banner: "Banner图片",
  BannerTip: "建议尺寸：350*210 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式",
  ApplyTip: "报名须知",
  BaseInfo: "基本信息",
  ActiveGoodsSet: "活动商品管理",
  ItemName: "商品名称",
  ShopName: "店铺名称",
  ItemCategory: "商品类目",
  PendingReview: "待审核",
  Passed: "已通过",
  Failed: "未通过",
  AddItem: "添加商品",
  ReviewStatus: "审核状态",
  From: "来源",
  AuditLogs: "审核记录",
  ConfirmPass: "确认通过",
  AuditFaile: "审核不通过",
  AuditPass: "审核通过",
  RequireReason: "请填写原因",
  BatchPass: "批量通过",
  BatchFaile: "批量拒绝",
  EditItemInfo: "编辑活动商品信息",
  PlaceChangeItem: "请选择待审核商品",
  Operator: "操作人",
  OperationTime: "操作时间",
  AuditResult: "审核结果",
  FaileReason: "拒绝原因",
  BatchSet: "批量设置",
  ApplyAudit: "报名审核",
  ActivityModel: "活动模板",
  ChangeModel: "更换模板",
  ToUse: "去使用",
  PhoneView: "预览视图",
  UploadTip: "建议尺寸：图片宽度750像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。",
  ImageTip: "建议尺寸：宽度327px高度120px，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。",
  ShopStatus: "店铺状态",
  AuditType: "平台状态",
  IsGift: "是否礼包",
  Sort: "排序",
  Remove: "移出",
  BatchRemove: "批量移出",
  ConfirmRemove: "确认移出此商品吗？",
  ConfirmBatchRemove: "确认批量移出选中商品吗？",
  RequireBanner: "请上传banner",
  ConfirmSave: "确认保存吗？",
  ConfirmCancel: "确认取消更改吗？",
  Preview: "预览",
  ActivityUrl: "活动链接",
  Selecting: "已选中",
  ActivityDiscounts: "活动折扣",
  SomeSeller: "指定商家",
  RuleError: "规则输入有误",
  SelectCategory: "未选择指定商品类目",
  TimeError: "报名结束时间不能大于活动开始时间",
  SkuStr: "规格属性",
  Discount: "活动折扣",
  InputId: "请输入商家 ID，多个用”,”隔开",
  PlaceId: "请输入商家ID",
  LabelImage: "活动标签",
  ActivityCommission: "活动佣金",
  ActivityCommissionSet: "店主推广佣金设置",
  ActivityCommissionRate: "店主佣金率",
  ActivityCommissionTip: "设置后活动期间，活动商品将按照该比例进行佣金计算",
  ActivityCommissionRule: "店主推广佣金率<100%",
  ExtensionCommissionRate: "店主推广佣金率",
  PlatformCommissionRate: "平台佣金率",
  ConfirmUpdeteCommission: "活动佣金设置后不可再次编辑,是否确认保存？",
  SeeItmeList: "查看关联商品",
  SkuId: "SKU ID",
  Statistics: "统计中",
  ConfirmDeleteLog: "确认删除活动记录？",
  ActivityBannerImage: "活动封面图",
  ActivityBannerImageTip: "建议尺寸：图片宽度200*200像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式",
  downModel: "下载模板",
  UserId: "用户ID",
  UserName: "用户名称",
  UserLevel: "用户等级",
  superLevel: "会员等级",
  Pcode: "邀请码",
  ActivityCompletionStatus: "活动完成状态",
  ActivityRewardAmount: "活动奖励金额",
  isSettle: "是否已结算",
  Settleed: "已结算",
  NoSettle: "待结算",
  SettlementTime: "结算时间",
  NoEnd: "未完成",
  End: "已完成",
  // 活动页面配置
  PageTitle: "活动标题:",
  Coupon: "优惠券ID",
  NoCoupon: "未到优惠券可领取时间优惠券不展示，仅APP可领取/使用优惠券",
  CouponAdd: "添加",
  VoucherType: "优惠券类型",
  VoucherName: "优惠券名称",
  Denomination: "优惠券面额",
  ConditionsUse: "使用条件",
  VStatus: "状态",
  CollectionTime: "领取时间",
  usageTime: "使用时间",
  FreeShipping: "免邮商品",
  saveYpcTip: "确定保存编辑信息？",
  cancelYpcTip: "离开当前页面，信息将不会保存。",
  confirmItemInfo: "请确认活动商品信息",
  placeselect: "请选择",
  all: "全部",
  select: "选中的",
  lookRecord: "查看记录",
  actTip: "操作成功，请到“导入历史”里查看",
  toSelect: "请勾选待导出商品",
  useExcel: "通过Excel导入",
  selectFile: "选择文件",
  downloadModel: "下载模板",
  importModel: "导入模板",
  tip1: "导入注意：",
  tip2: "1、请在导入前下载导入模板并填入数据；",
  tip3: "2、导入文件大小不能超过2M；",
  tip4: "3、每次导入商品不能超过1000个；",
  timeisend: "报名时间已结束",
  exportHistory: "导入历史",
  createTime: "创建时间",
  fileName: "文件名",
  status: "状态",
  downloadFile: "下载原始文件",
  total: "总共",
  success: "成功",
  filled: "失败",
  shopsku: "店铺sku",
  reason: "原因",
  exportHistory2: "导出历史",
  callme: "请联系我们",
  closeTip2: "确定关闭活动？关闭后不能再次开启",
  openTimeTip: "活动开始时间早于当前时间，无法开启；请调整",
  openLengthTip: "活动已开启过一次，不允许再次开启",
  started: "活动时间已开始，不支持编辑",
  timeTip: "开始时间不能早于当前时间",
  err20001: "excel数据为空",
  err20002: "模板表头错误",
  err20003: "读取模板数据异常"
};
