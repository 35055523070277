export default {
  tagName: "ชื่อแท็ก",
  fitPlatform: "แพลตฟอร์ม",
  tagLevel: "เลเวล",
  Level1: "เลเวล 1",
  Level2: "เลเวล 2",
  No: "หมายเลข",
  Target_Level1: "เป็นของเลเวล 1",
  Label_Members: "จำนวนคนที่แท็ก",
  Create_Time: "ตั้งเวลา",
  Operate: "ดำเนินการ",
  Edit: "แก้ไข",
  Delete: "ลบ",
  Add: "เพิ่ม",
  Add_Label: "เพิ่ม",
  Label_Already_Exist: "มีแท็กนี้อยู่แล้ว",
  Cannot_Delete_By_User_Before: "มีผู้ใช้จำนวน",
  Cannot_Delete_By_User_End: "คนอยู่ภายใต้แท็กนี้ จึงไม่สามารถลบได้",
  Cannot_Delete_By_Label_Before: "มีแท็ก",
  Cannot_Delete_By_Label_End: "ที่ยังอยู่ภายใต้เลเวล 1 จึงไม่สามารถลบได้",
  Edit_Label: "แก้ไข",
  All: "ทั้งหมด",
  Label: "แท็ก",
  Input_label_Name: "กรุณาใส่ชื่อแท็ก",
  Select_Target_Level1: "กรุณาเลือกหมวดหมู่ย่อยของเลเวล 1",
  Select_Label_Level: "กรุณาเลือกเลเวลของแท็ก",
  Select_Fit_Platform: "กรุณาเลือกแพลตฟอร์ม",
  基本信息标签: "ข้อมูลพื้นฐาน",
  荐品标签: "สินค้าแนะนำ",
  系统打标逻辑: "หลักการเลือกแท็ก"
};
