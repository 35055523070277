export default {
  merchantSubsidies: "Seller subsidy",
  pdpDescription: "PDP description",
  generate: "Generate",
  LazadaTwotyDaySales: "Lazada Sales (last 2 weeks)",
  LazadaHotSellingTrends: "Lazada Trending",
  AllianceSales: "Affiliate Sales",
  customSort: "Custom sorting",
  addConditions: "New",
  ascendingOrder: "Ascending",
  descendingOrder: "Descending",
  priority: "Priority",
  sortField: "Sorting field",
  order: "Sort",
  moreFourCondition: "You can add up to 4 conditions at most.",
  LazadaThirtyDaySales: "Lazada 30 days sales",
  updateSituation: "Update status",
  updated: "Updated",
  notUpdated: "Not Updated",
  WhoAdded: "Who added",
  Lazada下载导入: "link",
  Youpik: "Youpik",
  Users: "Users",
  // tiktok快速需求
  duplicateCommodity: "Repeated Items",
  repeat: "Reapted",
  notRepeated: "Not reapted",
  haveToBeSynchronizedTips:
    "* new event(s) is(are) waiting to be synchronized, please confirm the category.",
  CommissionRateRange: "Commission rate range",
  CreatorCommissionRate: "Commission rate for creator",
  CreatorCommission: "Commission for creator",
  classification: "Category",
  sellerStatus: "Seller",
  synchronization: "Synchronize",
  toRelease: "Waiting for release",
  beginInAMinute: "About to start",

  productId: "Product ID",
  YoupikShop: "Youpik Product",
  LazadaShop: "Lazada Product",
  lowToheight: "Price from low to high",
  heightTolow: "Price from high to low",
  saleHeightTolow: "Sales from high to low",
  sale: "sales",
  actualSale: "Actual Sales",
  lazadaCategore: "Lazada Category",

  CommissionHeightTolow: "Commission from high to low",
  commissionRateHeightTolow: "Commission rates from high to low",
  addtips: " items have been added",
  noAddShop:
    "Parcially add: Some items cannot be added as following reasons, but the rest are added successfully",
  // addFailed: '添加失败，请检查网络设置',
  failedID: "Failed product ID",
  failedReason: "Failure reason: The product has been inactive from Youpik.",
  pleasePutLink: "Please input the link",
  PackageWeight: "Package Weight",
  PackageInfoTitle: "Delivery&Warranty",
  PackageSize: "Package Dimensions",
  SpecialPrice: "Special Price",
  SpecialPriceTime: "Limited time promotion",
  AboutDetailItem: "Product Detail",
  Color: "Color",
  Price: "Price",
  AllItem: "All",
  OnlineItems: "Online",
  InReview: "PendingQC",
  ShelvesItem: "inactive",
  PlatformShelvesItem: "Suspend",
  Category: "Category",
  ItemId: "Product ID",
  ItemName: "Product Name",
  SellerSKU: "Seller SKU",
  StoreSKU: "Shop SKU",
  ItemImage: "Image",
  ItemInfo: "Product ID",
  ItemPrice: "Price",
  ItemStatus: "Status",
  StoreId: "Shop Id",
  CreateTime: "Created",
  Operation: "Actions",
  AlreadyStore: "Active",
  handleShlfTips: "Listing exceeds the configured quantity limit",
  NotInStore: "Inactive",
  SoldOut: "OOS",
  Update: "Update",
  Inventory: "Stock",
  Item: "Product",
  PlaceSeleteDeleteItem: "Please select item you want to delete",
  ItemProperties: "Product Properties",
  ItemTitle: "Product Name",
  TitlePlacehold: "Ex.Nikon Coolpix A300 Digital Camera",
  ItemEnTitle: "Product Name [EN]",
  EnTitlePlacehold: "Please enter product name in English",
  CategoryPlacehold: "Click the button to search for categories",
  SeleteCategory: "Select Category",
  Seletion: "Seletion",
  Brand: "Brand",
  HomeImgUrl: "Main Image",
  ItemContent: "Description",
  ItemContentEn: "English Long Description",
  ItemShortDesc: "Short Description",
  ItemShortDescEn: "Short Description [EN]",
  GetItems: "What‘s in the box",
  GetItemsEn: "What‘s in the box",
  ItemMainVariant: "Product main specifications", // 商品主规格
  MainVariantTip: "Please fill in the variant name, such as red, yellow",
  MainVariantSelete: "Main variant options",
  AddSelete: "Add selection",
  DeleteSelete: "Delete selection",
  ItemSecondVariant: "Secondary variant (Customize)",
  // ItemSecondAttribute: "次属性名称（自定义）",
  SecondVariantTip: "Please fill in the type of secondary variant, such as size",
  ClickUpload: "Click to Upload ",
  UploadTip:
    "The first picture is the cover, recommended to upload only one picture. A maximum of 8 pictures with a size between 500×500 and 2000×2000px.",

  AuditTipInfo: "Are you sure to submit the verified result?",
  RejectTipInfo: "Confirm to recall the application?",
  RejectTipReason: "To recall and get approval, please fill in at least 6 characters",
  StockNum: "Stock Number",
  Variants: "Variants",
  GifItem: "Youpik Choice",
  PlatformActiveSelect: "Select",
  AllPlatformActive: "All platform active",
  PlatformRejectActive: "Suspend",
  PlatformAgreeActive: "Platform active",
  BusinessActiveSelect: "Seller select to active",
  AllBusinessActive: "All seller active",
  BusinessRejectActive: "Seller deactive",
  BusinessAgreeActive: "Seller active",
  IsSellOut: "Is it out ot stock",
  AllSellOut: "All (whether out of stock)",
  SellOut: "Out Of Stock",
  NoSellOut: "Online product",
  ActiveItemTip: "Confirm to activate this product?",
  DisabledItemTip: "Confirm to deactivate this product？",
  AcitveGifItemTip: "Please confirm to set the product as a Youpikchoice product?",
  DisabledGifItemTip: "Please confirm to disable the Youpikchoice product?",
  SellPrice: "Sale Price",
  PostAuditTimeRange: "Submission time",
  AuditedTimeRange: "Approval completion time ",
  SeellerShopSku: "Shop SKU",
  AuditStatu: "Approval status",
  PostTime: "Submission time",
  AuditTime: "Verification period",
  AuditId: "Approval ID",
  SellerLoginName: "Seller Login Name",
  SellerId: "Seller ID",
  AuditOpinion: "Comment approved",
  ToBeReviewed: "To Be Reviewed",
  MachineAuditAgree: "Approve",
  MachineAuditReject: "Reject by system",
  PeopleAuditAgree: "Approve",
  PeopleAuditReject: "Reject",
  BeOverdueAudit: "Verification date expired",
  AutomaticGeneration: "Generated automatically",
  AuditAction: "Operation Approved",
  Long: "Length",
  Width: "Width",
  Height: "Height",
  PlaceInputAuditOpinion: "Please fill in the review comments",
  Nothing: "None",
  // lazada
  weight: "Sorts",
  homePageSelection: "Home Featured",
  itemWeight: "Commodity weight",
  setItemWeightTitle:
    "Please enter the weight of the product, the smaller the number, the higher the position",
  销量: "sales",
  更新时间: "update time",
  默认: "Default",
  uChoice链接: "uChoice Link",
  TikTok链接: "TikTok Link",
  "30天销量": "30-day Sales",
  TikTok总销量: "TikTok Total Sales"
};
