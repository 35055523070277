export default {
  Agree: "Agree",
  Reject: "Reject",
  Action: "Action",
  Search: "Search",
  Reset: "Reset",
  Add: "Add",
  NewAdd: "Add",
  Export: "Export",
  BulkExport: "Batch Export",
  Import: "Import",
  bulkImport: "Batch Import",
  Delete: "Delete",
  BulkDelete: "Batch delete",
  Edit: "Edit",
  copy: "Copy",
  TakeDown: "Inactive",
  Uploaded: "Active",
  More: "More",
  Cancel: "Cancel",
  Finish: "Finished",
  Submit: "Submit",
  Confirm: "Confirm",
  AllSelete: "Select All",
  ShowMore: "Show More",
  ShowLess: "Show Less",
  SeeDetail: "See Detail",
  Audit: "Approve",
  Audit2: "Verify",
  Detail: "Detail",
  Refuse: "Reject",
  Adopt: "Passed",
  Save: "Save",
  Tip: "Tip",
  uploadFile: "upload files",
  clearAll: "Empty",
  select: "select",
  refresh: "refresh",
  获取分享链接: "Get Share Link"
};
