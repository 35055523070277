export default {
  新人样品池配置: "New Comer Sample Pool Configuration",
  保存: "Save",
  "保存后瀑布流ID将生效，你还要继续吗？":
    '<span style="color: red;">The Product list ID will take effect after saving</span>, do you want to continue?',
  确认保存: "Confirm Save",
  "保存失败：瀑布流不存在！": "Save Failed: Product list ID does not exist!",
  "操作成功！": "Operation Successful!",
  继续: "Continue",
  取消: "Cancel"
};
