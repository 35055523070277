export default {
  // 卖家结算
  confirmStatus: "Are you sure to mark payment as paid?",
  WalletBalance: "Balance",
  orderBill: "Order Bill",
  rewardBill: "Incentive bill",
  CompleteTime: "Time",
  WithdrawalApplication: "Withdrawal Management",
  AmountReceived: "Amount received",
  WithdrawalNumber: "Ref. ID",
  WithdrawalAmounts: "Amount",
  PaymentSuccessful: "Payment successful",
  CanceledPayment: "Canceled Payment",
  "Total Cashback (L0,1)": "Confirmed Cashback for L0,L1/ Comfirmed Commission for L2,L3",
  "Unpaid": "Pending ",
  tax: "ภาษี",
  WithdrawalTime: "Withdrawal time",
  AmountBeforeTax: "Amount before tax",
  // 下载翻译发票
  downloadInvoice: "Download Tax Invoice",
  batchDownloadInvoice: "Download Tax Invoices in bulk",
  selectBillTime: "Please choose the statement period！",
  CompressedPackage: "PDF zip",
  // 注册活动奖励翻译
  RewardType: "Reward Type",
  UpgradingReward: "Upgrading Reward",
  RegisteringReward: "Registering Reward",
  // 卖家结算
  // 店主结算翻译
  shopId: "Youpikker ID",
  shopInviteCode: "Youpikker Invitation Code",
  upgradeTime: "Upgrade Time",
  upgradeLevel: "Upgrade Level",
  preTaxCommission: "Pre-tax Commission",
  afterTaxCommission: "After-tax Commission",
  orderAmount: "Order bill amounts",
  upgradeId: "Reward Bill id",
  upgradeAmount: "Reward Bill Amount",
  downloadOrderBill: "Download order bill",
  downloadUpgareBill: "Download the upgrading bill",
  // 卖家结算
  // 翻译
  LocalSellers: "Local Sellers",
  payoutReason: "Reason for refund failure",
  InPayment: "Paying",
  BillStatusPrompt: "The following bills are in payment status and cannot be changed",
  Processing: "Processing",
  allTransfer: "Total transfer",
  strip: "strip",
  ManualPayment: "Manual payment",
  ksherPayout: "ksher payout",
  Reprint: "repay",
  noDBS: "Non-DBS seller bill",
  DBS: "DBS seller bill",
  PaymentFailed: "Payment failed",
  transfer: "transfer",
  reTransfer: "retransfer",
  mode: "Payment method",
  BatchTransfer: "Batch transfer",
  transferType: "Please select a transfer type",
  thisPage: "this page",
  ServiceCharge: "Fee",
  TotalTransfer: "Total transfer",
  ConfirmTransfer: "Confirm transfer",
  pleaseSelect: "please choose",
  BillAmount: "Bill amount",
  transferAmount: "transfer amount",
  historicalArrears: "historical arrears",
  cumulativeAmount: "Cumulative amount",
  notMeetingThePaymentConditions: "not meet payment conditions",

  //
  PaymentPreview: "Payout Preview",
  AlreadyPaymentAmount: "Paid Amount",
  NotPaymentAmount: "Unpaid Amount",
  All: "All",
  SettlementStatus: "Status",
  BillingId: "Statement ID",
  BillingCycle: "Statement Period",
  SellerType: "Seller Type",
  SellerId: "Seller ID",
  SellerName: "Seller Name",
  StatementCycle: "Statement Period",
  SettlementAmount: "Current Statemnet amount",
  AdvanceAmountOfPreviousPeriod: "Advanced payment amount ",
  PaymentAccountName: " Account Name",
  PaymentAccountNumber: " Account Number",
  PaymentBank: "Bank name",
  BillingDetail: "Billing Detail",
  ChagneStatus: "Change Status",
  LocalPersonalSeller: "Local Personal Seller",
  LocalEnterpriseSeller: "Local Enterprise Seller",
  CrossBorderPersonalSeller: "Cross Border Personal Seller",
  CrossBorderEnterpriseSeller: "Cross Border Company Seller",
  SellerSettlement: "Seller Settlement",
  AlreadySettlementTip: "Insist to change settlement status to paid?",
  NotSettlementTip: "Insist to change settlement status to unpaid?",
  LastWeek: "Last week",
  LastOneMonth: "Last one month",
  LastThreeMarch: "Last three month",
  ToBePayment: "Waiting to be paid",
  AlreadyPeyment: "Paid",
  // 卖家账单详情
  OrderNo: "Order no",
  ItemId: "Item ID",
  ItemSkuId: "Sku ID",
  OrderDate: "Order Date",
  WholesalePrice: "WholesalePrice",
  ItemNum: "Quantity",
  Freight: "Freight",
  PlatformDiscount: "Platform Offers", // 平台优惠
  SellerDiscount: "Merchant Offers", // 商家优惠
  ActualAmountPaid: "Actual payment amount", // 实际支付金额
  PaymentProcessingFee: "pay processing fees", // 支付手续费
  PlatformCommission: "Platform commission", // 平台佣金
  PromotionCommission: "Shopkeeper commission", // 店主佣金
  OrderDetail: "order details", // 订单详情
  SellerBillingDetail: "Seller bill details", // 卖家账单详情
  YoupikFee: "Youpik",
  // 店主结算
  ShopkeeperId: "Youpikker ID", // 店主ID
  ShopkeeperName: "Youpikker's name", // 店主名称
  ShopkeeperLevel: "Youpikker level", // 店主等级
  LastCycleDeductionCommisson: "Commission deduction in the previous period", // 上周期扣除佣金
  SLIReward: "SLI Reward",
  Status: "Status",
  CampaignRewards: "Campaign Rewards",
  PastCompensationAmount: "Past compensation amount",
  ReverseYoupik: "Reverse Youpik Marketing Commission",
  ShopkeeperSettlement: "Shopkeeper settlement", // 店主结算
  BankCode: "Bank Code",
  ExportTip: "Are you sure to export file?",
  NotSupportFile: "File not supported",
  ImportTip: "Are you sure to import file?",
  // 店主账单详情
  ItemAmount: "Total Item Amount",
  ItemActualAmountPaid: "Actual Amount Paid",
  commissionRate: "Commission Rate",
  PreTaxCommissionAmount: "PreTax Commission Amount",
  NextTaxCommissionAmout: "Next Tax Commission Amout",
  ShopkeeperSubCommission: "Sub-commission Detail",
  Detail: "Detail",
  OrdinaryItem: "Normal Products",
  GifItem: "Youpik Choice Products",
  ypcPlus: "YPK+ Choice Products",
  lazadaGood: "Lazada",
  SubCommissionAmount: "Commissoin amount",
  ShopkeeperBillingDetail: "Owner bill detail",
  AlreadySettlement: "Paid",
  NotSettlement: "Unpaid",
  ItemType: "Product Type",
  crossBorderSeller: "Overseas Sellers",
  localSeller: "Local Sellers",
  billOverView: "Statements Overview",
  transactionOverview: "Transaction Overview",
  dbsSeller: "DBS Sellers",
  paymentTime: "Payment Time",
  orderId: "Order ID",
  ietTip: "The sum of all expenses, and the actual settlement amount shall be subject to the details of the bill",
  incomeTip: "The sum of all positive expense items, and the actual settlement amount shall be subject to the details of the bill",
  expenditureTip: "The sum of all negative expenses, and the actual settlement amount shall be subject to the details of the bill",
  totalAmount: "Total Amount",
  income: "Income",
  expenditure: "Cost",
  transactionDate: "Transaction Date",
  feeName: "Expense Name",
  transactionNum: "Transaction NO.",
  amount: "Amount",
  accountingPeriod: "Billing Period",
  expenseType: "Type of Expenses",
  expenseName: "Expense Name",
  expenseAmount: "Expense Amount",
  salesAmount: "Sales Amount",
  openingBalance: "Opening Balance",
  total: "Total",
  returnRefundAmount: "Return/Refund Amount",
  returnRefundshopp: "Reverse Item Price Credit",
  returnRefundBug: "Reverse Shipping Fee (Paid By Customer)(VAT)",
  actSubsidyRefund: "Reverse Campaign Subsidy",
  actSubsidyComRefund: "Reverse Commission Subsidy",
  returnRefundSure: "Reverse Shipping Fee (Paid By Seller)（VAT）",
  PlatformShopp: "Reverse Seller Voucher discount",
  platformcommission: "Reverse Youpik Platform Commission (Including 7% VAT)",
  shopkeepercommission: "Reverse Youpik Marketing Commission (Including 7% VAT)",
  compensatecommission: "Reverse Promotional Platform Charges Voucher reimbursed to Seller",
  paymentAmount: "Payment Amount",
  closingBalance: "Closing balance",
  download: "Download",
  bankAddress: "Open Bank Address",
  serviceCharge: "Services Fee",
  handlingRate: "% Services Fee",
  shopDiscount: "Seller Voucher Discount",
  actualFreight: "Shipping Fee Paid by Seller (Including 7% VAT)",
  ActualPaymentAmount: "Actual payment amount",
  date: "Date",
  username: "User Name",
  reportName: "Sheet Name",
  status: "Status",
  history: "History",
  itemPriceCredit: "Item Price Credit",
  ShopCoupon: "Seller Voucher discount",
  CompensatedPlatform: "Promotional Platform Charges Voucher reimbursed to Seller",
  Compensatedfreight: "Promotional Platform Charges Shipping reimbursed to Seller",
  CompensatedPlatformrefund: "Compensated platform voucher refund",
  Compensatedfreightrefund: "Reverse Promotional Platform Charges Shipping reimbursed to Seller",
  shippingFeePaidbyCustomer: "Shipping Fee (Paid By Customer)(VAT)",
  commission: "Youpik Platform Commission (Including 7% VAT) ",
  youpikMarketingCommission: "Youpik Marketing Commission (Including 7% VAT)",
  PaymentAmount: "Current amount",
  paymentFee: "Payment Fee（VAT）",
  financeSettlement: "The settlement status is the sum of the current bill amount that has been paid",
  financeNoSettlement: "The settlement status is the sum of the current bill amount of the pending payment",
  ShowCurrentBill: "Show current bill",
  CompensationAmountTips: "Due to system problems, the amount of payment underpayment will be made up during the current period",
  AmountNotDeductedTips: "Unsettled expenses and some unsettled amounts arising from the reverse of the previous period",
  openingBalanceTips: "The sum of all the details in the current bill (except the unsettled amount in the past) COD orders",
  dbsOpeningBalanceTips:
      "The sum of all the details in the current bill (except the unsettled amount in the past) COD orders, product sales prices, buyer‘s freight, store coupons, payment of handling fees without billing settlement; you can view this in the exported detailed table Part of the data",
  closingBalanceTips: "unsettled amount in previous period + amount in current period",
  salesNO: "",
  CompensationAmount: " Under payment amount",
  ActivitySubsidy: "Campaign Subsidy",
  SubsidizedPlatform: "Commission Compensation ",
  overpaymentDeduction: "Unsettled amount in previous period",
  AmountNotDeducted: "Over payment amount",
  // lazada
  lazadaOrders: "Total number of lazada orders",
  totalCommission: "Total commission",
  ordersOfLastMonth: "Number of orders last month",
  commissionLastMonth: "Commission last month",
  thisMonthOrders: "Number of orders this month",
  thisMonthCommission: "Commission this month",
  reconciliationCycle: "reconciliation cycle",
  collection: "Collection",
  collectionRecord: "Receipt records",
  received: "Received",
  unReceived: "Uncollected",
  partialReceived: "Partial collection",
  inputCollectionAmount: "Please enter the payment amount",
  itemSource: "commodity source",
  lazadaPromoteUnion: "lazada affiliate",
  commissionAmount: "Commission amount"
};
