import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./index.less";

interface ITipProps {
  content: string;
}

const Tip: React.FC<ITipProps> = ({
  content
}) => {
  return <div className={styles.tip_container}>
    <div className={styles.tip_icon_container}>
      <ExclamationCircleOutlined className={styles.tip_icon} />
    </div>
    <div className={styles.tip_label}>{content}</div>
  </div>;
};

export default Tip;
