export default {
  settle: "Sign Up",
  settleIn: "Youpik Merchant registration",
  accountType: "Account Type",
  personalAccount: "Individual",
  personalAccountTip: "To become an individual seller, you need to be at least 18 years old and provide identification card.",
  enterpriseAccount: "Corporate",
  enterpriseAccountTip: "Please make sure your business license is valid in order to join Youpik",
  sellerType: "Seller Type",
  requireSellerType: "Please Select Seller type",
  CrossBorderSeller: "Cross Border seller",
  OrdinarySeller: "Local Seller",
  BrandSeller: "Brand Seller",
  ShopLocation: "Shop Location",
  StoreName: "Shop Name",
  requireShopLocation: "Please Select",
  haveReferralCode: "I have a referral Code",
  referralCodeOptional: "ReferralCode(Optional)",
  ConfirmationInformation: "Please agree to the terms and conditions",
  AccountSettle: "Seller Settled",
  FillStorageInfo: "Please fill in Warehouse Information",
  FillIdAndBank: "Please fill in ID card and Bank Account",
  Password: "Password",
  ConfirmPassword: "Confirm Password",
  Email: "Email Address",
  MobileCode: "SMS Code",
  SlideToVerify: "Slide To Verify",
  PrevStup: "Previous",
  NextStep: "Next",
  AddressBook: "Address Book",
  CloneStorageAddress: "Same as warehouse address",
  Address: "Address",
  CountryRegion: "Country/Region",
  Province: "Province",
  District: "District",
  PostalCode: "Postal Code",
  exportAllTip: "The operation is successful, please check the export record"
};
