export default {
  tagName: "标签名称",
  fitPlatform: "适用平台",
  tagLevel: "标签等级",
  Level1: "一级",
  Level2: "二级",
  No: "编号",
  Target_Level1: "对应一级",
  Label_Members: "标签人数",
  Create_Time: "创建时间",
  Operate: "操作",
  Edit: "编辑",
  Delete: "删除",
  Add: "新增",
  Add_Label: "新增标签",
  Label_Already_Exist: "该标签已存在",
  Cannot_Delete_By_User_Before: "该标签下仍有",
  Cannot_Delete_By_User_End: "个用户，无法删除",
  Cannot_Delete_By_Label_Before: "该一级标签下仍有",
  Cannot_Delete_By_Label_End: "个二级标签，无法删除",
  Edit_Label: "编辑标签",
  All: "全部",
  Label: "标签",
  Input_label_Name: "请输入标签名称",
  Select_Target_Level1: "请选择对应一级",
  Select_Label_Level: "请选择标签等级",
  Select_Fit_Platform: "请选择适用平台",
  基本信息标签: "基本信息标签",
  荐品标签: "荐品标签",
  系统打标逻辑: "系统打标逻辑"
};
