import { Modal, Button, Input, InputNumber, Table, Image, message, Form } from "antd";
import styles from "./index.less";
import { useIntl } from "umi";
import React, { useState, useEffect, useImperativeHandle } from "react";
import api from "@/api/waterfall";
import { formatPrice } from "@/utils/format";
import NumberInput from "@/components/NumberInput";

interface Props {
  visible: boolean
  onHide: () => void
  onSelected?: (ids: number[]) => void
}

export const SelectProductsModal = ({ visible, onHide, onSelected }: Props) => {
  const intl = useIntl();
  const [TTList, setTTList] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formInstance] = Form.useForm();

  const getIdList = () => {
    setLoading(true);
    api
      .getItemListByProductId({ productId: formInstance.getFieldsValue().itemId, isEffective: 0 })
      .then((res) => {
        const { code, result } = res;
        if (code === 200 && result) {
          setLoading(false);
          setTTList(result);
        } else {
          setTTList([]);
          message.warning(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //   数据表头
  const columns: any = [
    // 图片
    {
      title: intl.formatMessage({ id: "common.image" }),
      dataIndex: "image",
      key: "image",
      width: "100px",
      align: "center",
      render: (value: string | undefined, item: any, index: any) => {
        return value ? <Image width={100} src={value} /> : "/";
      }
    },

    // 名称/ID
    {
      title: intl.formatMessage({ id: "waterfall.nameOrID" }),
      align: "center",
      key: "productName",
      width: 500,
      render: (text: any, record: { productId: number, productName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; skuId: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; campaignId: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, index: any) => {
        return (
          <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
            {/* 名称 */}
            <p>{record.productName}</p>
            {/* 商品id  */}
            <p>
              {" "}
              {intl.formatMessage({ id: "common.itemId" })}: {record.productId}
            </p>
            {/* tap id */}
            <p>
              {intl.formatMessage({ id: "waterfall.TapId" })}: {record.campaignId}
            </p>
          </div>
        );
      }
    },

    // 价格
    {
      title: intl.formatMessage({ id: "common.price" }),
      key: "minPrice",
      align: "center",
      width: 150,
      render: (text: any, record: { maxPrice: any; minPrice: any; }, index: any) => {
        return (
          <div>
            {record.maxPrice === record.minPrice
              ? formatPrice(record.minPrice)
              : `${formatPrice(record.minPrice)}~${formatPrice(record.maxPrice)}`}
          </div>
        );
      }
    },
    // 佣金率
    {
      title: intl.formatMessage({ id: "common.commissionRate" }),
      dataIndex: "creatorCommissionPercentStr",
      key: "creatorCommissionPercentStr",
      align: "center",
      width: 150
    },
    //   佣金
    {
      title: intl.formatMessage({ id: "common.commission" }),
      align: "center",
      key: "maxEarn",
      width: 150,
      render: (text: any, record: { minEarn: any; maxEarn: any; }, index: any) => {
        return (
          <div>
            {record.minEarn === record.maxEarn
              ? formatPrice(record.maxEarn)
              : `${formatPrice(record.minEarn)}~${formatPrice(record.maxEarn)}`}
          </div>
        );
      }
    },
    //   类目
    {
      title: intl.formatMessage({ id: "waterfall.category" }),
      align: "center",
      key: "categoryNameEn",
      width: 150,
      render: (text: any, record: { categoryName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; categoryNameEn: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, index: any) => {
        const locale = localStorage.getItem("umi_locale");
        return <div>{locale === "th-TH" ? record.categoryName : record.categoryNameEn}</div>;
      }
    }
  ];
  const rowSelection: any = {
    selectedRowKeys,
    onChange: (selectedKeys: React.SetStateAction<never[]>) => {
      setSelectedRowKeys(selectedKeys);
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: "waterfall.addShop" })}
      width={800}
      open={visible}
      destroyOnClose
      okText={intl.formatMessage({ id: "button.Add" })}
      okButtonProps={{
        disabled: selectedRowKeys.length === 0
      }}
      onOk={() => {
        setTTList([]);
        formInstance.resetFields();
        onHide?.();

        onSelected?.(selectedRowKeys);
      }}
      onCancel={() => {
        formInstance.resetFields();
        setTTList([]);
        onHide?.();
      }}>
      <div>
        <div className={`${styles["searchHeader"]}`}>
          <Form
            form={formInstance}
            name='basic'
            layout='inline'
            labelCol={{ span: 6 }}
            labelAlign='left'
            wrapperCol={{ span: 12 }}
            onFieldsChange={(values) => {
              setItemId(values[0].value);
            }}
          >
            {/* 瀑布流ID */}
            <Form.Item label={intl.formatMessage({ id: "common.itemId" })} name='itemId'>
              <NumberInput widthStyle={300} allowClear />
            </Form.Item>
          </Form>

          <Button
            type='primary'
            className={`${styles["searchHeader_btn"]}`}
            disabled={!itemId}
            onClick={() => {
              getIdList();
            }}>
            {" "}
            {intl.formatMessage({ id: "button.Search" })}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={TTList}
          rowKey={"id"}
          className={`${styles["tableModal"]}`}
          rowSelection={rowSelection}
          pagination={false}
          loading={loading}
          scroll={{
            x: 1000,
            y: 500
          }}
        />
      </div>
    </Modal>
  );
};
