export default {
  Sample_Application: "สินค้าตัวอย่าง",
  TAP_Sales_Report: "รายงานยอดขาย",
  Creator_Analysis: "ข้อมูลครีเอเตอร์",
  Product_Analysis: "ข้อมูลสินค้า",
  Seller_Analysis: "ข้อมูลร้านค้า",
  Creator_Name_: "ชื่อครีเอเตอร์",
  Please_enter: "กรอกข้อมูล",
  Order_Creation_Date_: "วันที่ส่งคำขอ",
  Export_Report: "ดาวน์โหลดรีพอต",
  Data_last_updated_on__mm___dd____yy____hh_mm___BKK_time_zone_:
    "ข้อมูลอัปเดตล่าสุดเมื่อ dd mm HH:MM, เวลา yy น.",
  Creator_with_Sales: "ยอดขายครีเอเตอร์",
  Authorized_Creator: "ครีเอเจอร์ที่ได้รับอนุญาต",
  Unauthorized_Creator: "ครีเอเจอร์ที่ไม่ได้รับอนุญาต",
  GMV: "GMV",
  Completed_GMV: `ยอดขายสินค้า 
(สมบูรณ์)`,
  Cancelled_GMV: `ยอดขายสินค้า 
(ยกเลิก)`,
  Progressing_GMV: `ยอดขายสินค้า
(กำลังดำเนินการ)`,
  Completed_Sales: "ยอดขายสินค้า",
  Live_Streaming___Completed_GMV: `ยอดขายไลฟ์สด 
(สมบูรณ์)`,
  Video___Completed_GMV: `ยอดขายจากวิดิโอ 
(สมบูรณ์)`,
  Showcase___Completed_GMV: `ยอดขายจากโชว์เคส 
(สมบูรณ์)`,
  Product_Information: "ข้อมูลสินค้า",
  Product_ID_: "รหัสสินค้า",
  Product_Price: "ราคาสินค้า",
  Seller: "ร้านค้า",
  Sales_Volume: "ยอดขาย",
  Store_Code_: "รหัสร้านค้า",
  Seller_Name_: "ชื่อร้านค้า",
  Seller_Information: "ข้อมูลร้านค้า",
  Actions: "การดำเนินการ",
  View_Sales_Products: "ดูสินค้าที่ขาย",
  Sales_Products_of_Store_Named_xxxxx: "สินค้าที่ขายของร้านค้า #",
  saleByCreator: "ยอดขายของ #",
  orderNum: "จำนวนการสั่งซื้อ",
  "订单日期": "วันที่สั่งซื้อ",
  "带货达人": "ครีเอเตอร์",
  "所属商家": "ข้อมูลร้านค้า",
  "投流状态": "สถานะการยิงโฆษณา",
  "未投流": "ยังไม่ยิงโฆษณา",
  "已投流": "ยิงโฆษณาแล้ว",

};
