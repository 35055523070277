export default {
  bannerOver: "Nội dung vượt quá giới hạn ký tự ",
  originBannerPosition: "vị trí",
  bannerPosition: "Vị trí banner",
  topBanner: "Banner đầu trang",
  middleBanner: "Banner giữa",
  middleBannerFix: "Banner giữa cố định",
  middleLongBanner: "Banner giữa dài",
  DailyRecommendation: "Đề xuất hôm nay",
  lazadaLandingPage: "Ảnh Banner Liên kết Lazada",
  bannerTime: "Thời gian banner",
  bannerSort: "Sắp xếp",
  displayLocation: "Vị trí phụ",
  RotationBanner: "Banner trượt",
  FixedUpperLeft: "Cố định trên cùng bên trái",
  FixedUpperRight: "Cố định trên cùng bên phải",
  LazadaLandingPage: "Trang Liên kết Lazada",

  originName: "Tên trang chủ",
  Name: "Tên banner trang chủ",
  ActivityStatus: "Trạng thái hoạt động",
  JumpType: "Loại",
  All: "Tất cả",
  Item: "Liên kết sản phẩm",
  Activity: "Liên kết TB",
  AlreadyShelves: "Hoạt động",
  ToShelved: "chờ hoạt động",
  RemovedShelves: "Ngưng hoạt động",
  Image: "Hình ảnh",
  ImgTip:
    "Kích thước khuyến nghị: 702*202 Pixels, kích thước giới hạn là 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg và gif. Chỉ có thể tải lên một lần. ",
  ImgTipBanner:
    "Kích thước khuyến nghị 600*300 Pixels, kích thước giới hạn là 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg và gif. Chỉ có thể tải lên một lần. ",
  Sort: "Sắp xếp",
  ItemId: "Mã ID sản phẩm",
  JumpUrl: "Liên kết TB",
  SortTip:
    "Thứ tự sẽ được sắp xếp theo đơn vị số, số ít số chữ số sẽ được hiển thị ở trên cùng, bạn có thể điền số từ 1~6",
  MiddleLongSortTips:
    "Thứ tự sẽ được sắp xếp theo đơn vị số, số ít số chữ số sẽ được hiển thị ở trên cùng, bạn có thể điền số từ 1~2",
  TopBannerSortTip:
    "Thứ tự sẽ được sắp xếp theo đơn vị số, số ít số chữ số sẽ được hiển thị ở trên cùng, bạn có thể điền số từ 1~12",
  MiddleBannerSortTips:
    "Thứ tự sẽ được sắp xếp theo đơn vị số, số ít số chữ số sẽ được hiển thị ở trên cùng, bạn có thể điền số từ 1~4",
  onTheShelfTips:
    " Số lượng banner được kích hoạt trong khoảng thời gian đã đạt đến giới hạn tối đa, và không thể thêm nữa.",
  Uploaded: "Đã tải lên",
  Deleted: "Đã xóa",
  Address: "Địa chỉ",
  originBannerTime: "Thời gian",
  originImage: "Hình ảnh",
  originSort: "Sắp xếp",
  originItemId: "PDP",
  pageLink: "Liên kết trang",
  h5Page: "Trang H5",
  h5Link: "Liên kết H5",
  noValidTapId: "Không có TAP ID hợp lệ",
  success: "Thành công",
  UrlOrID: "Trang chuyển hướng/ ID Sản phẩm",
  AreYouSureToDeleteThisCampaignPage:
    "Liên kết chiến dịch sẽ không còn hiệu lực sau khi xóa. Bạn có chắc chắn muốn xóa trang chiến dịch này không?",
  ConfirmDeleteBanner: "Xác nhận xóa banner?",
  InAppPage: "Trang ứng dụng",
  InAppPageLink: "Liên kết trang ứng dụng",

  addBanner: "Thêm Banner Trang chủ",
  editBanner: "Chỉnh sửa Banner Trang chủ",
  文本宣传: "Quảng cáo văn bản",
  图片宣传: "Quảng cáo hình ảnh",
  建议尺寸702x220:
    "Kích thước khuyến nghị: 702*220 pixels, giới hạn kích thước 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg, gif. Chỉ có thể tải lên một hình ảnh mỗi lần.",
  "建议尺寸：宽度固定702像素，长度不限，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。":
    "Kích thước đề nghị: Chiều rộng cố định 702 pixel, chiều dài không giới hạn, giới hạn kích thước 2MB; chỉ hỗ trợ các định dạng png, jpg, jpeg, gif. Chỉ có thể tải lên một hình ảnh mỗi lần."
};
