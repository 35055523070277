export default {
  Cancel: "ยกเลิกการแก้ไข",
  Save: "บันทึก",
  ConfirmCloseTitle: "แน่ใจหรือไม่ที่ต้องการออกจากหน้านี้",
  ConfirmCloseDesc: "หลังจากปิดตารางนี้จะไม่ถูกบันทึก",
  addUserTitle: "Import User",
  addSampleTitle: "Import Sample Request",
  addPersonTitle: "Import Shipping Information",
  addSupplyTitle1: "นำเข้า ",
  addSupplyTitle2: " ร้านค้าของซัพพลายเออร์",
  InsertRow: "เพิ่มแถว",
  TipNotEmpty: "No valid content to upload",
  可搜索区域或邮编: "คุณสามารถค้นหาตามพื้นที่หรือรหัสไปรษณีย์ได้ค่ะ",
  待发货: "รอการจัดส่ง",
  履约跟进: "กำลังดำเนินการ",
  已发货: "จัดส่งแล้ว",
  "10条样品申请等待您的审核与发货":
    '<span style="color: red;">คำขอสินค้าตัวอย่าง{num}ชิ้น</span>กำลังรอคุณตรวจสอบและจัดส่ง！',
  "X条带货视频可投流打造单品爆款，投流后可以增长您店铺的单品销量哦！":
    '<span style="color: red;">{num} คลิปวิดีโอ </span>ที่สามารถทำการยิงโฆษณา และทำเป็นสินค้ายอดนิยมได้ ซึ่งหลักจากยิงโฆษณาแล้ว มันจะสามารถเพิ่มยอดขายให้กับร้านค้าของคุณได้อย่างมาก!',
  "如因快递错发、丢包、破损需要补发的样品申请，商家可以在此增补订单":
    'ถ้าหากมีคำร้องขอสินค้าตัวอย่างเนื่องจาก<span style="color: red;">ขนส่งสินค้าผิดพลาด, สินค้าสูญหาย </span>หรือสินค้าเสียหาย ทางร้านค้าสามารถเพิ่มคำสั่งซื้อได้ที่นี่',
  "当前列表内容编辑未提交，若不保存所有编辑的内容将失效，是否保存编辑内容？":
    "การแก้ไขเนื้อหาในรายการปัจจุบันยังไม่ได้ส่ง หากไม่บันทึก การแก้ไขทั้งหมดจะหายไป คุณต้องการบันทึกเนื้อหาที่แก้ไขหรือไม่?",

  ["add_sheet.Error"]: "Error",

  ["add_user.tt用户名"]: "ชื่อผู้ใช้ TikTok",
  ["add_user.跟进人"]: "ผู้ดูแล",
  ["add_user.联系电话"]: "เบอร์ติดต่อ",
  ["add_user.lineId"]: "LINE ID",
  ["add_sample.达人名称"]: "ชื่อครีเอเตอร์",
  ["add_sample.申请日期"]: "วันที่ส่งคำขอ",
  ["add_sample.item id"]: "ไอดีสินค้า",
  ["add_sample.规格"]: "ข้อกำหนด",
  ["add_sample.数量"]: "จำนวน",
  ["add_sample.商品链接"]: "ลิงก์สินค้า",
  ["add_sample.橱销截图"]: "ภาพหน้าจอที่แสดงยอดขาย",
  ["add_sample.30天成交件数"]: "จำนวนชิ้นที่ซื้อขายใน 30 วัน",
  ["add_sample.近一个月gmv"]: "GMV ต่อเดือน",
  ["add_sample.收件人姓名"]: "ชื่อผู้รับ",
  ["add_sample.收件人电话"]: "เบอร์ติดต่อผู้รับ",
  ["add_sample.收件地址"]: "ที่อยู่ผู้รับ",
  ["add_sample.地区"]: "เขต",
  ["add_sample.详细地址"]: "ที่อยู่",
  ["add_sample.可履约方式"]: "วิธีการปฏิบัติ",
  ["add_sample.视频"]: "วิดีโอ",
  ["add_sample.直播"]: "ไลฟ์สด",
  ["add_sample.视频+直播"]: "วิดีโอและไลฟ์สตรีมมิ่ง",

  ["add_person.订单号"]: "หมายเลขการสั่งซื้อ",
  ["add_person.主播名"]: "ชื่อผู้ใช้ TikTok",
  ["add_person.商品类目"]: "หมวดหมู่สินค้า",
  ["add_person.商品名称"]: "ชื่อผลิตภัณฑ์",
  ["add_person.商品图"]: "รูปภาพสินค้า",
  ["add_person.item id"]: "ไอดีสินค้า",
  ["add_person.价格"]: "ราคา",
  ["add_person.店铺名称"]: "ชื่อร้านค้า",
  ["add_person.商品链接"]: "ลิงก์สินค้า",
  ["add_person.规格"]: "ข้อกำหนด",
  ["add_person.数量"]: "จำนวน",
  ["add_person.video/live"]: "วิดีโอ/ไลฟ์",
  ["add_person.收件人姓名"]: "ชื่อผู้รับ",
  ["add_person.收件人电话"]: "เบอร์ติดต่อผู้รับ",
  ["add_person.收件地址"]: "ที่อยู่ผู้รับ",
  ["add_person.橱销截图"]: "ภาพหน้าจอที่แสดงยอดขาย",
  ["add_person.近一个月gmv"]: "GMV ต่อเดือน",
  ["add_person.follow"]: "ผู้ติดตาม",
  ["add_person.物流单号"]: "หมายเลขพัสดุ",
  ["add_person.视频链接"]: "ลิงก์วิดีโอ",
  ["add_person.直播时间"]: "เวลาไลฟ์สตรีม",
  ["add_person.同意/拒绝"]: "ตอบรับ/ปฏิเสธ",
  ["add_person.同意"]: "ตอบรับ",
  ["add_person.拒绝"]: "ปฏิเสธ",
  ["add_person.MCN"]: "MCN",
  ["add_person.跟进人"]: "ผู้ดูแล",
  ["add_person.投流视频ID"]: "รหัสโปรโมทวิดีโอ",
  ["add_person.履约出单数"]: "จำนวนคำสั่งซื้อ",
  ["add_person.履约GMV"]: "GMV",
  ["add_person.投流状态"]: "สถานะโปรโมชั่นวิดีโอ",
  ["add_person.订单来源"]: "ที่มาของคำสั่งสินค้า",
  ["add_person.样品申请信息"]: "ข้อมูลคำขอตัวอย่าง",
  ["add_person.商家样品审核跟进表"]: "ติดตามการตรวจสอบตัวอย่างของผู้ขาย",
  ["add_person.商家投流跟进表"]: "ตารางติดตามโปรโมชั่นวิดีโอผู้ขาย",
  ["add_person.申请时间"]: "เวลาที่ยื่นคำร้อง",
  ["add_person.备注"]: "หมายเหตุ",
  ["add_person.拒绝原因"]: "เหตุผลการยกเลิก",
  ["add_person.收件地址省"]: "จังหวัด",
  ["add_person.收件地址市"]: "อำเภอ/เขต",
  ["add_person.收件地址区"]: "ตำบล",
  ["add_person.邮编"]: "รหัสไปรษณีย์",
  ["add_person.Content ID"]: "หมายเลขคอนเทนต์",
  ["add_person.当月视频销量"]: "ยอดขายจากคลิปวิดีโอในเดือนนี้",
  ["add_person.上周视频销量"]: "ยอดขายจากคลิปวิดีโอในสัปดาห์ที่แล้ว",
  ["add_person.上月视频销量"]: "ยอดขายจากคลิปวิดีโอในเดือนที่แล้ว",
  ["add_person.Ads Code"]: "รหัสโฆษณา",
  ["add_person.增补物流单号"]: "เพิ่มหมายเลขติดตามพัสดุ",

  ["add_supply.店铺id"]: "รหัสร้านค้า"
};
