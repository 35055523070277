export default {
  All: "ทั้งหมด",
  CouponName: "ชื่อคูปอง",
  PlaceInputName: "กรุณากรอกชื่อคูปอง",
  CouponCode: "Voucher ID",
  PlaceInputCode: "กรุณากรอก Voucher ID",
  Status: "สถานะ",
  NotStarted: "ยังไม่เริ่ม",
  Ongoing: "กำลังดำเนินการ",
  Closed: "สิ้นสุดแล้ว",
  CouponFee: "ค่าธรรมเนียมคูปอง",
  TermsOfUse: "เงื่อนไขการใช้",
  Man: "ขั้นต่ำ",
  Use1: "สามารถใช้เงินบาทได้",
  Time: "ระยะเวลาที่ใช้ได้",
  CreateTime: "วันที่สร้างคูปอง",
  Stocks: "จำนวนคูปอง",
  Limits: "จำกัดจำนวนต่อคน",
  SendType: "วิธีการส่งคูปอง",
  NoLimit: "ไม่จำกัด",
  Limit: "จำกัด",
  Times: "ครั้ง",
  SystemRelease: "ระบบส่งคูปองอัตโนมัติ",
  CollectionRequired: "ผู้ใช้เก็บเอง",
  ViewProducts: "ดูสินค้า",
  EditCoupon: "แก้ไขคูปอง",
  AddCoupon: "เพิ่มคูปอง",
  AddCouponSucc: "เพิ่มคูปองสำเร็จ",
  AddCouponHandle: "เพิ่มคูปองสำเร็จ กรุณาเลือกสินค้า",
  Set: "ตั้งค่าคูปอง",
  SeleteItem: "เลือกสินค้า",
  FullReduction: "ขั้นต่ำ",
  TotalFee: "จำนวนเงินลดเต็ม",
  CouponLessFee: "จำนวนเงินส่วนลด",
  CouponType: "ประเภทคูปอง",
  AllMoney: "ยอดรวม",
  AfterCoupon: "ใช้การได้หลังจากวันที่เก็บ",
  AfterSend: "发放当日起",
  UseDays: "วัน",
  ItemSet: "การตั้งค่าสินค้า",
  userLimit: "จำกัดต่อคน",
  AllItem: "สินค้าทั้งหมด",
  DesignatedItem: "สินค้าที่กำหนด",
  SeleteTime: "กรุณาเลือกช่วงเวลาที่ใช้ได้",
  valideReject: "ตรวจสอบข้อมูลไม่สำเร็จ",
  RequireSave: "ไม่สามารถแก้ไขสินค้าได้หากบันทึก ต้องการดำเนินการต่อหรือไม่?",
  NotSeleteItem: "ต้องเลือกสินค้า",
  MinZero: "กรอกจำนวนมากกว่า 0",
  CouponFeeVal: "ขั้นต่ำต้องมากกว่ามูลค่าคูปอง",
  InputInt: "กรุณาใส่เลขจำนวนเต็มบวก",
  TotalFeeVal: "จำนวนส่วนลดต้องน้องกว่าขั้นต่ำที่กำหนด",
  NotSelete: "ยังไม่ได้เลือก",
  ConfirmDeleteSelete: "ยืนยันการลบสินค้าที่เลือกหรือไม่?",
  AllPeople: "ทุกคน",
  oldPeople: "ผู้ใช้งานเก่า",
  onePeople: "ผู้ใช้งานที่กำหนด ",
  newPeople: "ผู้ใช้งานใหม่",
  itemInfo: "ข้อมูลสินค้า",
  ItemPrice: "ข้อมูลสินค้า",
  Inventory: "สต็อก",
  notNameEmpty: "จำเป็นต้องกรอกชื่อ",
  notTotalFeeEmpty: "จำเป็นต้องกรอกส่วนลดทั้งหมด",
  notCouponLessFeeEmpty: "จำเป็นต้องกรอกมูลค่าคูปอง",
  notStocksEmpty: "จำเป็นต้องกรอกมูลค่าคูปอง",
  notDateEmpty: "จำเป็นต้องกรอกระยะเวลาที่มีผล",
  EditTip: "Defocus/Enter เพื่อทริกเกอร์การแก้ไข ประเภทเนื้อหาเป็นตัวเลข ต้องไม่เว้นว่าง",
  MinFee: "ค่าเงินขั้นต่ำ",
  MaxFee: "นิกายสูงสุด",
  FeeError: "ค่าที่กรอกในเกณฑ์การค้นหาไม่ถูกต้อง",
  Fee: "นิกาย",
  UserPickup: "ลูกค้าเก็บเอง",
  ID: "รหัสคูปอง",
  PlaceInputID: "กรุณากรอก ID คูปอง",
  TotalNum: "จำนวนคูปอง",
  LimitNum: "คงเหลือ",
  SendRate: "อัตราการเก็บคูปอง",
  UseNum: "จำนวนที่ใช้งาน",
  UseRate: "อัตราการใช้คูปอง",
  CouponFee2: "ค่าคูปอง",
  OrderFee: "จำนวนการบริจาคคูปอง",
  MemberName: "ชื่อบัญชี",
  MemberMobile: "เบอร์โทรศัพท์",
  OrderNo: "หมายเลขคำสั่งซื้อ",
  UseStatus: "สถานะ",
  UseTime: "ช่วงเวลาที่ใช้ได้",
  NotUse: "ยังไม่ได้ใช้",
  Used: "ใช้แล้ว",
  Expired: "หมดอายุแล้ว",
  Send: "ส่ง",
  ConfirmSend: "ยืนยันการส่งหรือไม่?",
  RequireLimit: "กรุณากรอกจำนวนคูปองที่ต้องกาจำกัดต่อคน",
  LimitMin: "การจำกัดจำนวนคูปองต่อคนต้องมากกว่า 0",
  RequireStocks: "กรุณากรอกจำนวนที่ต้องการส่ง",
  StocksMin: "จำนวนที่ต้องการส่งต้องมากกว่า 0",
  COUPON_FOR_SHOP:
      "เจ้าของร้านเชิญผู้ใช้โดยตรงและผู้ใช้ที่ได้รับเชิญโดยตรงทำการสั่งซื้อครั้งแรกบนแพลตฟอร์มให้เสร็จสมบูรณ์ เจ้าของร้านจะได้รับคูปอง",
  COUPON_FOR_MEMBER_LOGIN: "ผู้ใช้ใหม่สามารถรับคูปองสำหรับการสั่งซื้อครั้งแรกได้",
  COUPON_FOR_REGISTER: "ผู้ใช้ใหม่ลงทะเบียนสำเร็จเพื่อรับคูปอง",
  GetTime: "กรุณาเลือกระยะเวลาเก็บ",
  SeleteGetTime: "กรุณาเลือกระยะเวลาเก็บ",
  InputDays: "กรุณากรอกวันที่สามารถใช้ได้",
  InputUserId: "กรุณากรอก user ID",
  InputUserIds: "กรุณากรอก user ID และหากมีจำนวนมากกรุณาใช้ \",\" ระหว่าง user ID",
  InputSellerId: "กรุณากรอก seller ID",
  InputSellerIds: "กรุณากรอก seller ID และหากมีจำนวนมากกรุณาใช้ \",\" ระหว่าง seller ID",
  NameTip: "กรุณากรอกชื่อคูปองที่ง่ายต่อการจำ เช่น ลด 10 บาท ขั้นต่ำ 100",
  StockTip: "กรุณากรอกจำนวนคูปองที่ต้องการสร้างมิฉะนั้นจะไม่จำกัดจำนวนคูปอง",
  GetTimeTip: "ระยะเวลาที่สามารถเก็บคูปองได้",
  UseTimeTip: "ระยะเวลาที่สามารถใช้คูปองได้",
  LimitTip: "จำกัดคูปองต่อยูสเซอร์",
  RequireName: "กรุณากรอกชื่อคูปอง",
  RequireTotal: "กรุณากรอกจำนวนลด",
  RequireCouponFee: "กรุณากรอกขั้นต่ำ",
  UserLevel: "ตามระดับ Youpikker",
  UserId: "ตาม user ID",
  RequireUserLevel: "กรุณาเลือกระดับ Youpikker",
  RequireUserId: "กรุณาเพิ่ม user ID",
  RequireSellerId: "กรุณาเพิ่ม seller ID",
  RequireGategory: "กรุณาเพิ่มหมวดหมู่",
  TimeCheck: "ระยะเวลาการเก็บต้องสิ้นสุดก่อนระยะเวลาใช้การ",
  Promote: "โปรโมชั่น",
  ConfirmPromote: "แน่ใจหรือไม่ว่าต้องการโปรโมทคูปอง?",
  SendLimits: "จำนวนที่ส่งให้ต่อยูสเซอร์",
  RequireImportSellerId: "กรุณาอัปโหลดไฟล์ user ID",
  DownloadModel: "ดาวน์โหลดเวอร์ชั่น",
  ShippingCoupon: "ส่วนลดค่าจัดส่ง",
  NoDoor: "ไม่มีขั้นต่ำ",
  NoDoorTip: "ไม่มีขั้นต่ำ"
};
