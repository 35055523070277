export default {
  settle: "Đăng ký",
  settleIn: "Đăng ký cửa hàng Youpik",
  accountType: "Loại Tài khoản",
  personalAccount: "Cá nhân",
  personalAccountTip:
    "Để trở thành người bán cá nhân, bạn cần phải đủ 18 tuổi và cung cấp thẻ căn cước hoặc thẻ dân dụ.",
  enterpriseAccount: "Doanh nghiệp",
  enterpriseAccountTip:
    "Vui lòng đảm bảo rằng giấy phép kinh doanh của bạn có hiệu lực để tham gia Youpik.",
  sellerType: "Loại Người bán",
  requireSellerType: "Vui lòng chọn loại người bán",
  CrossBorderSeller: "Người bán vượt biên",
  OrdinarySeller: "Người bán địa phương",
  BrandSeller: "Người bán thương hiệu",
  ShopLocation: "Địa điểm cửa hàng",
  StoreName: "Tên Cửa hàng",
  requireShopLocation: "Vui lòng chọn",
  haveReferralCode: "Tôi có Mã giới thiệu",
  referralCodeOptional: "Mã giới thiệu (Tùy chọn)",
  ConfirmationInformation: "Vui lòng đồng ý với các điều khoản và điều kiện",
  AccountSettle: "Người bán đã đăng ký",
  FillStorageInfo: "Vui lòng điền thông tin Kho hàng",
  FillIdAndBank: "Vui lòng điền thông tin Thẻ căn cước và Tài khoản ngân hàng",
  Password: "Mật khẩu",
  ConfirmPassword: "Xác nhận Mật khẩu",
  Email: "Địa chỉ Email",
  MobileCode: "Mã SMS",
  SlideToVerify: "Trượt để xác nhận",
  PrevStup: "Bước Trước",
  NextStep: "Bước Tiếp Theo",
  AddressBook: "Sổ Địa Chỉ",
  CloneStorageAddress: "Giống như địa chỉ kho hàng",
  Address: "Địa chỉ",
  CountryRegion: "Quốc gia/Khu vực",
  Province: "Tỉnh/Thành phố",
  District: "Quận/Huyện",
  PostalCode: "Mã bưu chính",
  exportAllTip: "Thao tác thành công, vui lòng kiểm tra bản ghi xuất"
};
