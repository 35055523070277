import http from "./instance";

export default {
  // 获取瀑布流列表
  getWaterfallByPage: (params: any) =>
    http.get<any>("/api-uchoice/waterfall/getWaterfallByPage", params),
  // 导入商品
  importProduct: (data: any) => http.postJSON<any>("/api-uchoice/waterfall/importProduct", data),
  // OPS-根据商品Id获取商品信息
  getItemListByProductId: (params: any) =>
    http.get<any>("/api-uchoice/tt/item/getItemListByProductId", params),
  // 导出模版
  exportProduct: (params: any) =>
    http.get<any>("/api-uchoice/waterfall/exportProduct", params, {}, "blob"),
  // 获取瀑布流详情
  getWaterfallInfo: (params: any) =>
    http.get<any>("/api-uchoice/waterfall/getWaterfallInfo", params),
  // 添加或编辑瀑布流
  saveOrUpdateWaterfall: (params: any) =>
    http.postJSON<any>("/api-uchoice/waterfall/saveOrUpdateWaterfall", params),
  // 删除瀑布流
  deleteWaterfallById: (params: any) =>
    http.get<any>("/api-uchoice/waterfall/deleteWaterfallById", params)
};
