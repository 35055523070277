export default {
  通知中心: "การแจ้งเตือน",
  新增: "เพิ่ม",
  搜索: "ค้นหา",
  序号: "เลขประจำ",
  标题: "ชื่อ",
  时间范围: "ช่วงเวลา",
  是否强提醒: "เปิดการแจ้งเตือน",
  发布时间: "เวลาแจ้งเตือน",
  操作: "ดำเนินการ",
  编辑: "แก้ไข",
  删除: "ลบ",
  是: "ใช่",
  否: "ไม่",
  删除后:
    "หลังจากลบแล้ว ฝั่งผู้ใช้จะไม่เห็นบันทึกการแจ้งเตือน และไม่สามารถแก้ไขได้ คุณแน่ใจหรือไม่ว่าต้องการลบ",
  确定: "แน่ใจ",
  取消: "ยกเลิก",
  内容: "เนื้อหา",
  通知: "แจ้ง",
  详情: "รายละเอียด",
  忽略: "เพิกเฉย",
  状态: "สถานะ",
  已读: "อ่านแล้ว",
  未读: "ยังไม่ได้อ่าน",
  返回: "ตอบกลับ"
};
