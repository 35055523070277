export default {
  thailand: "Thailand",
  vietnam: "Vietnam",
  logOut: "Log out",
  homePage: "Homepage",
  logIn: "Log In",
  username: "Username",
  password: "Password",
  fPwd: "Forgot password?",
  nation: "Nation"
};
