import http from "./instance";

export interface GetShortLinkParams {
  url: string;
  type?: number;
}

export const getShortLink = async (data: GetShortLinkParams): Promise<IResponseProps<string>> => {
  return http.post<GetShortLinkParams>("/api-base/link/getLongLink", { ...data, type: 6 });
};
