export default {
  // 卖家结算
  confirmStatus: "คุณแน่ใจหรือไม่ว่าจะเปลี่ยนสถานะเป็น \"ชำระเงินแล้ว\"",
  WalletBalance: "ยอดคงเหลือ",
  orderBill: "ยอดคำสั่งซื้อ",
  rewardBill: "ยอดเงินรางวัล",
  tax: "ภาษี",
  CompleteTime: "เวลาดำเนินการ",
  WithdrawalApplication: "การถอนเงิน",
  AmountReceived: "จำนวนเงินที่ได้รับ",
  WithdrawalNumber: "เลขอ้างอิง",
  WithdrawalAmounts: "จำนวนเงิน",
  PaymentSuccessful: "ชำระเงินสำเร็จ",
  CanceledPayment: "ยกเลิกการชำระเงิน",
  "Total Cashback (L0,1)": "ยืนยันยอดเงินคืน for L0,L1/ ยืนยันค่าคอมมิชชั่น for L2,L3",
  "Unpaid": "รายการไม่สำเร็จ",
  WithdrawalTime: "เวลาถอนเงิน",
  AmountBeforeTax: "จำนวนเงินก่อนหักภาษี",
  // 下载翻译发票
  downloadInvoice: "ดาวน์โหลดใบกำกับภาษี",
  batchDownloadInvoice: "ดาวน์โหลดใบกำกับภาษีจำนวนมาก",
  selectBillTime: "Please choose the statement period！",
  CompressedPackage: "PDF zip",
  // 注册活动奖励翻译
  RewardType: "ประเภทเงินรางวัล",
  UpgradingReward: "เงินรางวัลเลื่อนระดับ",
  RegisteringReward: "เงินรางวัลลงทะเบียน",
  // 店主结算翻译
  shopId: "รหัส Youpikker",
  shopInviteCode: "รหัสคำเชิญ Youpikker",
  upgradeTime: "อัปเกรดเวลา",
  upgradeLevel: "อัปเกรดเลเวล",
  preTaxCommission: "ค่าคอมมิชชั่นก่อนหักภาษี",
  afterTaxCommission: "ค่าคอมมิชชั่นหลังหักภาษี",
  orderAmount: "ยอดรวมคำสั่งซื้อ",
  upgradeId: "อัปเกรดเลขที่คำสั่งซื้อ",
  upgradeAmount: "อัปเกรดยอดคำสั่งซื้อ",
  downloadOrderBill: "ดาวน์โหลดคำสั่งซื้อ",
  downloadUpgareBill: "ดาวน์โหลดคำสั่งซื้อที่อัปเกรด",

  // 卖家结算
  // 翻译
  LocalSellers: "ร้านค้าในประเทศ",
  payoutReason: "เหตุผลที่การคืนเงินล้มเลว",
  InPayment: "การชำระเงิน",
  BillStatusPrompt: "ใบเรียกเก็บเงินต่อไปนี้อยู่ในสถานะการชำระเงินและไม่สามารถเปลี่ยนแปลงได้",
  Processing: "อยู่ในระหว่างดำเนินการ",
  allTransfer: "ยอดโอนทั้งหมด",
  strip: "ใบ",
  ManualPayment: "ชำระเงินด้วยตัวเอง",
  ksherPayout: "ksher payout",
  Reprint: "ชำระอีกครั้ง",
  noDBS: "บิลผู้ขายที่ไม่ใช่ DBS",
  DBS: "บิลผู้ขาย DBS",
  PaymentFailed: "ชำระเงินไม่สำเร็จ",
  transfer: "โอน",
  reTransfer: "โอนใหม่อีกครั้ง",
  mode: "วิธีชำระเงิน",
  BatchTransfer: "โอนเงินหลายจำนวน",
  transferType: "โปรดเลือกวิธีชำระเงิน",
  thisPage: "หน้านี้",
  ServiceCharge: "ค่าธรรมเนียม",
  TotalTransfer: "ยอดโอนทั้งหมด",
  ConfirmTransfer: "ยืนยันการชำระ",
  pleaseSelect: "โปรดเลือก",
  BillAmount: "จำนวนที่ต้องชำระ",
  transferAmount: "จำนวนยอดเงินโอน",
  historicalArrears: "ประวัติรายจ่ายที่ค้างชำระ",
  cumulativeAmount: "ยอดสะสม",
  notMeetingThePaymentConditions: "การชำระเงินไม่ตรงตามเงื่อนไข",

  //
  PaymentPreview: "ภาพรวมการชำระเงิน",
  AlreadyPaymentAmount: "จำนวนเงินที่จ่าย",
  NotPaymentAmount: "จำนวนเงินที่ยังไม่ได้ชำระ",
  All: "ทั้งหมด",
  SettlementStatus: "สถานะ",
  BillingId: "รหัสใบเรียกเก็บเงิน",
  BillingCycle: "รอบบิล",
  SellerType: "ประเภทผู้ค้า",
  SellerId: "บิล ID",
  SellerName: "ชื่อร้าน",
  StatementCycle: "รอบงบ",
  SettlementAmount: "จำนวนบิลปัจจุบัน",
  AdvanceAmountOfPreviousPeriod: "จำนวนเงินขั้นสูงในช่วงก่อนหน้านี้",
  PaymentAccountName: "ชื่อบัญชีการชำระเงิน",
  PaymentAccountNumber: "บัญชีการชำระเงิน",
  PaymentBank: "ธนาคารชำระเงิน",
  BillingDetail: "รายละเอียดบิล",
  ChagneStatus: "เปลี่ยนสถานะ",
  LocalPersonalSeller: "ผู้ขายแต่ละรายในท้องถิ่น",
  LocalEnterpriseSeller: "ผู้ขายธุรกิจในท้องถิ่น",
  CrossBorderPersonalSeller: "ผู้ขายข้ามพรมแดน",
  CrossBorderEnterpriseSeller: "ผู้ขายองค์กรข้ามพรมแดน",
  CrossBorderMainlandSeller: "ผู้ประกอบการและพ่อค้าข้ามพรมแดนของจีนแผ่นดินใหญ่",
  CrossBorderHongKongSeleller: "ผู้ค้าองค์กรข้ามพรมแดนจีนฮ่องกง",
  SellerSettlement: "การตั้งถิ่นฐานของผู้ค้า",
  AlreadySettlementTip: "คุณแน่ใจหรือไม่ว่าจะเปลี่ยนสถานะการชำระบัญชีเป็นชำระแล้ว?",
  NotSettlementTip: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนสถานะการชำระบัญชีเป็นไม่เรียบร้อย?",
  LastWeek: "อาทิตย์ที่แล้ว",
  LastOneMonth: "เดือนที่แล้ว",
  LastThreeMarch: "สามเดือนล่าสุด",
  ToBePayment: "ที่รอดำเนินการชำระเงิน",
  AlreadyPeyment: "จ่าย",
  // 卖家账单详情
  OrderNo: "หมายเลขคำสั่งซื้อ",
  ItemId: "Item ID",
  ItemSkuId: "Sku ID",
  OrderDate: "วันสั่ง",
  WholesalePrice: "ราคาขายปลีก",
  ItemNum: "ราคาขายปลีก",
  Freight: "การส่งสินค้า",
  Status: "สถานะสินค้า",
  PlatformDiscount: "ข้อเสนอแพลตฟอร์ม",
  SellerDiscount: "ข้อเสนอของผู้ค้า",
  ActualAmountPaid: "จำนวนเงินที่ชำระจริง",
  PaymentProcessingFee: "จ่ายค่าธรรมเนียมการดำเนินการ",
  PlatformCommission: "ค่าคอมมิชชั่นแพลตฟอร์ม",
  PromotionCommission: "ค่าคอมมิชชั่นยูพิคเกอร์",
  OrderDetail: "ค่าคอมมิชชั่นเจ้าของร้าน",
  SellerBillingDetail: "รายละเอียดการสั่งซื้อ",
  CompensationAmountTips: "เนื่องจากปัญหาของระบบ จำนวนเงินที่ชำระไม่ครบถ้วน จะถูกชำระในงวดปัจจุบัน",
  AmountNotDeductedTips: "เงินเรียกคืนจากการชำระเงินส่วนเกินให้แก่ร้านค้า",
  openingBalanceTips: "ผลรวมของรายละเอียดทั้งหมดในบิลปัจจุบัน (ยกเว้นจำนวนที่ยังไม่ได้ชำระในอดีต) คำสั่งซื้อ COD",
  dbsOpeningBalanceTips:
      "ผลรวมของรายละเอียดทั้งหมดในบิลปัจจุบัน (ยกเว้นจำนวนที่ยังไม่ได้ชำระในอดีต) คำสั่งซื้อ COD, ราคาขายสินค้า, ค่าขนส่งของผู้ซื้อ, คูปองร้านค้า, การชำระค่าธรรมเนียมการจัดการโดยไม่ต้องชำระบิล คุณสามารถดูสิ่งนี้ได้ในตารางรายละเอียดที่ส่งออก ของข้อมูล ",
  closingBalanceTips: "จำนวนเงินที่โดดเด่นในช่วงปัจจุบัน",
  salesNO: "",
  overpaymentDeduction: "ยอดค้างชำระจากงวดก่อน",
  SubsidizedPlatform: "ค่าคอมมิชชั่นสมทบจากแพลตฟอร์ม",
  sellerVoucherDiscount: "ผลรวมของคูปองร้าน",
  ActivitySubsidy: "เงินสมทบกิจกรรม",
  CompensationAmount: "จำนวนเงินชดเชยที่ผ่านมา",
  AmountNotDeducted: "จำนวนเงินจ่ายขาด/เกิน ",
  openingBalance: "ความสมดุลเริ่มต้น",
  YoupikFee: "ยูพิค",
  // 店主结算
  ShopkeeperId: "รายละเอียดบิลผู้ขาย",
  ShopkeeperName: "รหัสเจ้าของ",
  ShopkeeperLevel: "ชื่อเจ้าของ",
  LastCycleDeductionCommisson: "ยอดค้างชำระจากงวดก่อน",
  ShopkeeperSettlement: "การหักค่าคอมมิชชั่นจากงวดก่อนหน้า",
  SLIReward: "SLI Reward",
  CampaignRewards: "Campaign Rewards",
  PastCompensationAmount: "Past compensation amount",
  ReverseYoupik: "Reverse Youpik Marketing Commission",
  BankCode: "รหัสธนาคาร",
  ExportTip: "นิคมเจ้าของร้าน?",
  NotSupportFile: "ไม่รองรับไฟล์",
  ImportTip: "ต้องการอัปโหลดไฟล์ใช่หรือไม่?",
  // 店主账单详情
  ItemAmount: "ยอดรวมสินค้า",
  ItemActualAmountPaid: "จำนวนเงินที่ชำระตามจริงา",
  commissionRate: "% ค่าคอมมิชชั่น",
  PreTaxCommissionAmount: "ยอดคอมมิชชั่นก่อนหักภาษี ",
  NextTaxCommissionAmout: "ยอดคอมมิชชั่นหลังหักภาษี",
  ShopkeeperSubCommission: "ค่าคอมมิชชั่นที่ผู้ขายจัดการ",
  Detail: "รายละเอียด",
  OrdinaryItem: "สินค้าทั่วไป",
  GifItem: "สินค้า Youpik Choice",
  ypcPlus: "YPK+ Choice Products",
  lazadaGood: "Lazada",
  SubCommissionAmount: "จำนวนค่าคอมมิชชั่นย่อย",
  ShopkeeperBillingDetail: "รายละเอียดการเรียกเก็บเงินของเจ้าของร้าน ",
  AlreadySettlement: "ชำระค่าใช้จ่ายเรียบร้อยแล้ว",
  NotSettlement: "ยังไม่ได้ชำระค่าใช้จ่าย",
  ItemType: "ประเภทสินค้า",
  crossBorderSeller: "ร้านค้าต่างประเทศ",
  localSeller: "ร้านค้าในประเทศ",
  billOverView: "ภาพรวมบิล",
  transactionOverview: "ภาพรวมการขาย",
  dbsSeller: "DBS Sellers",
  paymentTime: "เวลาที่ชำระเงิน",
  orderId: "หมายเลขคำสั่งซื้อ",
  ietTip: "ผลรวมของค่าใช้จ่ายทั้งหมดจำนวนเงินที่แท้จริงของการตั้งถิ่นฐานจะขึ้นอยู่กับรายละเอียดของบิล",
  incomeTip: "ผลรวมของค่าใช้จ่ายบวกทั้งหมดที่เกิดขึ้นจริงจะขึ้นอยู่กับรายละเอียดของบิล",
  expenditureTip: "ผลรวมของค่าใช้จ่ายทั้งหมดที่เป็นลบปริมาณที่แท้จริงของการตั้งถิ่นฐานจะขึ้นอยู่กับรายละเอียดของบิล",
  totalAmount: "จำนวนทั้งหมด",
  income: "รายรับ",
  expenditure: "ค่าใช้จ่าย",
  transactionDate: "วันที่ทำการขาย",
  feeName: "ชื่อ",
  transactionNum: "หมายเลขการชำระเงิน",
  amount: "จำนวนเงิน",
  accountingPeriod: "ระยะเวลาบิล",
  expenseType: "ประเภทค่าใช้จ่าย",
  expenseName: "ชื่อ",
  expenseAmount: "จำนวนค่าใช้จ่าย",
  salesAmount: "มูลค่าการขาย",
  // openingBalance: 'ดุลบัญชีเริ่มต้น',
  total: "รวม",
  returnRefundAmount: "จำนวนเงินจากการคืนสินค้าหรือการขอคืนเงิน",
  returnRefundshopp: "มูลค่าสินค้าส่งคืน",
  returnRefundBug: "ค่าขนส่งสินค้าส่งคืน",
  actSubsidyRefund: "คืนเงินสมทบกิจกรรม",
  actSubsidyComRefund: "คืนเงินสมทบค่าคอมมิชชั่นแพลตฟอร์ม",
  returnRefundSure: "การคืนเงินค่าขนส่งที่เกิดขึ้นจริง",
  PlatformShopp: "คืนเงินคูปองส่วนลดร้านค้า",
  platformcommission: "การคืนเงินค่าคอมมิชชั่นแพลตฟอร์ม",
  shopkeepercommission: "การคืนเงินค่าคอมมิชชั่นยูพิคเกอร์",
  compensatecommission: "คืนเงินคูปองแพลตฟอร์มชดเชย",
  paymentAmount: "จำนวนการชำระเงิน",
  closingBalance: "ยอดปิด",
  download: "ดาวน์โหลด",
  bankAddress: "ที่อยู่เจ้าของบัญชีธนาคาร",
  serviceCharge: "ค่าธรรมเนียม",
  handlingRate: "อัตราค่าธรรมเนียม",
  shopDiscount: "จำนวนลดจากคูปองผู้ขาย",
  actualFreight: "ผู้ขายชำระค่าขนส่ง (รวมภาษี 7%)",
  ActualPaymentAmount: "จำนวนเงินจริง",
  date: "วันที่",
  username: "ชื่อผู้ใช้",
  reportName: "ชื่อเอกสาร",
  status: "สถานะ",
  history: "ประวัติ",
  itemPriceCredit: "มูลค่าคำสั่งซื้อทั้งหมด",
  ShopCoupon: "คูปองส่วนลดร้านค้า",
  CompensatedPlatform: "คูปองแพลตฟอร์มชดเชย",
  Compensatedfreight: "คูปองค่าขนส่งแพลตฟอร์มชดเชย",
  CompensatedPlatformrefund: "คืนเงินคูปองแพลตฟอร์มชดเชย",
  Compensatedfreightrefund: "คืนเงินคูปองค่าขนส่งแพลตฟอร์มชดเชย",
  shippingFeePaidbyCustomer: "ผู้ซื้อจ่ายค่าขนส่ง",
  commission: "ค่าคอมมิชชั่นแพลตฟอร์ม",
  youpikMarketingCommission: "ค่าคอมมิชชั่นยูพิคเกอร์",
  PaymentAmount: "จำนวนเงินปัจจุบัน",
  paymentFee: "ค่าธรรมเนียมการจัดการ",
  financeSettlement: "สถานะการชำระเงินคือผลรวมของจำนวนเงินในบิลปัจจุบันที่ชำระแล้ว",
  financeNoSettlement: "สถานะของการตั้งถิ่นฐานคือผลรวมของจำนวนเงินของบิลที่โดดเด่นในปัจจุบัน",
  ShowCurrentBill: "แสดงบิลปัจจุบัน",
  // lazada
  lazadaOrders: "จำนวนการสั่งซื้อจากLazada ",
  totalCommission: "ค่าคอมมิชชั่นทั้งหมด",
  ordersOfLastMonth: "จำนวนออเดอร์เดือนก่อน",
  commissionLastMonth: "ค่าคอมมิชชั่นเดือนก่อน",
  thisMonthOrders: "จำนวนออเดอร์เดือนนี้",
  thisMonthCommission: "ค่าคอมมิชชั่นเดือนนี้",
  reconciliationCycle: "กระทบยอดบัญชี",
  collection: "ยอดสะสม",
  collectionRecord: "บันทึกใบเสร็จรับเงิน",
  received: "เก็บเงิน",
  unReceived: "ไม่ได้รับเงิน",
  partialReceived: "ยอดชำระบางส่วน",
  inputCollectionAmount: "กรุณาระบุจำนวนเงิน",
  itemSource: "แหล่งสินค้า",
  lazadaPromoteUnion: "เครือ Lazada",
  commissionAmount: "จำนวนค่านายหน้า"
};
