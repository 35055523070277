import Cookies from "js-cookie";

import {
  OPS_TH_TOKEN_COOKIE_KEY,
  OPS_TOKEN_COOKIE_KEY,
  OPS_VI_TOKEN_COOKIE_KEY
} from "@/constants";
import { nationStorage } from "./storage";

const createCookieItem = (key: string) => ({
  set(token: string, config: Cookies.CookieAttributes = { expires: 7 }) {
    Cookies.set(key, token, config);
  },
  get() {
    return Cookies.get(key);
  },
  remove() {
    Cookies.remove(key);
  }
});

export const uchoiceToken_old = createCookieItem(OPS_TOKEN_COOKIE_KEY);

// export const uchoiceToken = {
//   set(token: string) {
//     localStorage.setItem(OPS_TOKEN_COOKIE_KEY, token);
//   },
//   get() {
//     return localStorage.getItem(OPS_TOKEN_COOKIE_KEY);
//   },
//   remove() {
//     localStorage.setItem(OPS_TOKEN_COOKIE_KEY, "");
//   }
// };

export const uchoiceToken_th = {
  set(token: string) {
    localStorage.setItem(OPS_TH_TOKEN_COOKIE_KEY, token);
  },
  get() {
    return localStorage.getItem(OPS_TH_TOKEN_COOKIE_KEY);
  },
  remove() {
    localStorage.setItem(OPS_TH_TOKEN_COOKIE_KEY, "");
  }
};
export const uchoiceToken_vi = {
  set(token: string) {
    localStorage.setItem(OPS_VI_TOKEN_COOKIE_KEY, token);
  },
  get() {
    return localStorage.getItem(OPS_VI_TOKEN_COOKIE_KEY);
  },
  remove() {
    localStorage.setItem(OPS_VI_TOKEN_COOKIE_KEY, "");
  }
};
export const uchoiceToken = {
  set(token: string) {
    if (nationStorage.get() == "TH") {
      uchoiceToken_th.set(token);
    } else {
      uchoiceToken_vi.set(token);
    }
  },
  get() {
    if (nationStorage.get() == "TH") {
      return localStorage.getItem(OPS_TH_TOKEN_COOKIE_KEY);
    }
    return localStorage.getItem(OPS_VI_TOKEN_COOKIE_KEY);
  },
  remove() {
    if (nationStorage.get() == "TH") {
      return localStorage.setItem(OPS_TH_TOKEN_COOKIE_KEY, "");
    }
    return localStorage.setItem(OPS_VI_TOKEN_COOKIE_KEY, "");
  }
};
