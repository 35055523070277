export default {
  supplierName: "Tên Nhà Cung Cấp",
  supplierId: "Mã Nhà Cung Cấp",
  numberOfStores: "Số Lượng Cửa Hàng",
  storeName: "Tên Cửa Hàng",
  addTime: "Thời Gian Thêm",
  storeNum: "Mã Cửa Hàng",
  unprocessedSampleRequest: "Yêu Cầu Mẫu Chưa Xử Lý",
  shipLink: "Liên Kết Giao Hàng",
  deleteLinkTips:
    "Sau khi xóa, liên kết trở nên không hợp lệ và thông tin nhà cung cấp không thể khôi phục được. Xác nhận xóa?",
  deleteShopTips:
    "Sau khi xóa, nhà cung cấp sẽ không thể gửi mẫu đến cửa hàng này nữa. Bạn có chắc chắn muốn xóa không?",
  move: "Di Chuyển",
  operationRecords: "Hồ Sơ Hoạt Động",
  cooperativeProductQuantity: "Số Lượng Sản Phẩm Hợp Tác",
  activityProductQuantity: "Số Lượng Sản Phẩm Trong Chiến Dịch",
  canAdd: "Thêm",
  added: "Đã Thêm",
  belongToApp: "Đã Liên Kết với Nhà Cung Cấp Khác",
  addSuccess: "Thêm thành công",
  noticeTips: "Lưu Ý: Chỉ có thể thêm những nhà cung cấp bạn đã hợp tác",
  moveId: "Vui lòng chọn Mã Nhà Cung Cấp mà bạn muốn di chuyển tới",
  supplier: "Nhà Cung Cấp",
  inputShopNameOrNum: "Vui lòng nhập tên cửa hàng hoặc mã cửa hàng để tìm kiếm",
  inputSupplierId: "Tên nhà cung cấp không tồn tại.",
  shelfStatus: "Trạng Thái Nhà Cung Cấp",
  immediateProcessing: "Liên Kết Ngay Bây Giờ",
  lastSampleDate: "Thời Gian Yêu Cầu Mẫu Cuối Cùng",
  sample3day: "Chờ Phê Duyệt (3 ngày qua)",
  merchantContactInformation: "Thông tin liên lạc",
  noBindPer: "x cửa hàng đang chờ liên kết với nhà cung cấp!",
  willBindNew: "Sau khi tạo, xxx sẽ được liên kết với nhà cung cấp này",
  waitBindShop: "Cửa Hàng Chờ Liên Kết",
  pendingMerchantReviewNum: "Số Lượng Đang Chờ Phê Duyệt",
  newSupplyChain: "Nhà Cung Cấp Mới",
  bindSupplyChain: "Liên Kết Nhà Cung Cấp",
  replacingSupplyChain: "Thay Đổi Nhà Cung Cấp",
  cooperationTime: "Thời Gian Hợp Tác"
};
