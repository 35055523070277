export default {
  反馈方向: "Loại phản hồi",
  问题反馈: "Phản hồi",
  功能建议: "Đề xuất tính năng",
  反馈标题: "Tiêu đề phản hồi",
  "一句话概括您的反馈…": "Tóm tắt phản hồi của bạn trong một câu…",
  内容超出字符数量上限: "Nội dung vượt quá giới hạn ký tự",
  请输入反馈标题: "Vui lòng nhập tiêu đề phản hồi",
  上传附件: "Tải lên Đính kèm",
  点击或将文件拖拽到这里上传: "Nhấp chuột hoặc kéo và thả tệp vào đây để tải lên",
  "支持扩展名: .jpg .png .mp4": "Hỗ trợ định dạng: .jpg .png .mp4",
  "图片最多上传10张，视频仅支持1个": "Tải lên tối đa 10 hình ảnh, chỉ hỗ trợ 1 video",
  所有文件大小不超过20MB: "Chỉ được tải lên 1 video, kích thước tất cả tệp không vượt quá 20MB",
  请上传至少1个附件: "Vui lòng tải lên ít nhất 1 tệp đính kèm",
  "不可添加，最多上传10张图片": "Không thể thêm, tải lên tối đa 10 hình ảnh",
  "不可添加，图片和视频不可同时上传":
    "Không thể thêm, không thể tải lên hình ảnh và video cùng một lúc",
  "不可添加，最多上传1个视频": "Không thể thêm, chỉ tải lên tối đa 1 video",
  "不可添加，仅支持 .jpg .png .mp4格式添加":
    "Không thể thêm, chỉ hỗ trợ thêm định dạng .jpg .png .mp4",
  "不可添加，文件超出大小，最大不超过20MB":
    "Không thể thêm, tệp vượt quá kích thước, tối đa không vượt quá 20MB",
  反馈描述: "Mô tả phản hồi",
  "系统问题或功能建议，描述越清楚能让开发者更快定位问题哦~":
    "Vấn đề hệ thống hoặc đề xuất tính năng, mô tả càng rõ ràng, nhà phát triển sẽ nhanh chóng xác định vấn đề~~",
  "联系电话/LINE": "Số điện thoại/LINE",
  "请留下您的手机号或LINE ID吧~": "Vui lòng để lại số điện thoại di động hoặc LINE ID của bạn~~",
  提交并继续反馈: "Gửi và tiếp tục phản hồi",
  待受理: "Đang chờ xử lý",
  处理中: "Đang xử lý",
  已完结: "Hoàn thành",
  已接收: "Đã nhận",
  新回复: "phản hồi mới",
  您还没提交任何反馈: "Bạn chưa gửi bất kỳ phản hồi nào.",
  我们乐意能听到您的反馈与建议: "Chúng tôi rất vui được nghe phản hồi và đề xuất của bạn.",
  "您的反馈建议是我们前进的动力…": "Phản hồi của bạn là động lực để chúng tôi tiến lên...",
  联系方式: "Liên hệ",
  附件: "Tệp đính kèm",
  跟进一下: "Theo dõi",
  反馈进度表: "Tiến trình phản hồi",
  等待管理员接收: "Chờ Admin nhận",
  等待反馈问题处理: "Chờ phản hồi từ Admin",
  等待反馈问题完结: "Chờ phản hồi hoàn thành",
  等待管理员接收后处理反馈: "Chờ Admin nhận trước khi xử lý phản hồi",
  反馈跟进区: "Bình luận phản hồi",
  "写下你的评论…": "Để lại bình luận của bạn ở đây...",
  添加图片: "Thêm hình ảnh",
  Ulive管理员: "Quản trị viên Ulive",
  我: "tôi",
  刚刚: "vừa mới",
  "你确定要删除该评论吗？": "Bạn có chắc muốn xóa bình luận này không?",
  "未填写内容。": "Không có nội dung ở đây.",
  反馈时间: "Thời gian gửi phản hồi",
  处理状态: "Trạng thái xử lý",
  "没有更多评论啦~": "Đây là đáy rồi~",
  提交反馈: "Gửi phản hồi",
  详情: "Chi tiết",
  Ulive团队: "Nhóm Quản trị viên Ulive"
};
