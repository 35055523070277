export default {
  openInflowReward: "เปิดรางวัลกระแส",
  lastModifyTime: "เวลาแก้ไขล่าสุด",
  setUpPersonner: "แก้ไขโดย",
  inflowSet: "ตั้งค่า",
  numberOfProOrder: "คำสั่งซื้อ ≥",
  GMV: "GMV≥",
  NumberOfVideoViews: "จำนวนรับชม ≥",
  inflowAmount: "จำนวนรางวัล",
  saveModify: "บันทึก",
  abandonModify: "ละทิ้ง",
  confirmGiveUpTitle: "ยืนยันที่จะยกเลิกการเปลี่ยนแปลง",
  confirmGiveUpTips: "หลังจากยกเลิกเนื้อหาที่แก้ไขจะไม่ถูกบันทึก",
  confirmSaveTitle: "ยืนยันการเปลี่ยนแปลง",
  confirmSaveTips: "หลังจากบันทึกแล้ว ระบบจะเชื่อมเนื้อหาที่แก้ไขเพื่อชมคำแนะนำการรับรางวัล",
  continueTip: "คุณต้องการดำเนินการต่อหรือไม่",
  continue: "ดำเนินการต่อ",
  inflowed: "โปรโมท"
};
