export default {
  Product_Ranking_List: "Danh sách xếp hạng sản phẩm",
  List_ID_: "ID Danh sách:",
  List_Name_: "Tên danh sách:",
  On___Off: "Bật / Tắt",
  Please_Select_Here: "Vui lòng chọn ở đây",
  New_List: "Danh sách mới",
  List_ID: "ID Danh sách",
  List_Name: "Tên danh sách",
  Banner_image: "Ảnh banner",
  Sub_list: "Danh sách phụ",
  Status: "Trạng thái",
  Sort: "Sắp xếp",
  Action: "Thao tác",
  Off: "Tắt",
  On: "Bật",
  Preview: "Xem trước",
  Edit: "Chỉnh sửa",
  Please_try_to_edit_it_after_you_turn_the_list_off_:
    "Vui lòng thử chỉnh sửa sau khi tắt danh sách.",
  Confirm_to_turn_it_off: "Xác nhận tắt",
  Are_you_sure_that_you_want_to_turn_the_list_off_: "Bạn có chắc chắn muốn tắt danh sách không?",
  Cancel: "Hủy",
  Confirm: "Xác nhận",
  Turn_on_the_list: "Bật danh sách",
  If_you_choose_to_change_the_active_banners__the_original_banner_will_be_invalid_:
    "Nếu bạn chọn thay đổi các banner hoạt động, banner ban đầu sẽ không còn hiệu lực.",
  Available: "Có sẵn",
  Select: "Chọn",
  Change_confirmation: "Xác nhận thay đổi",
  Are_you_sure_that_you_change_the_original_list_which_named__xxx__instead__It_will_be_turned_off_after_you_changed_it_:
    "Bạn có chắc chắn muốn thay đổi danh sách gốc có tên # không? Sau khi bạn thay đổi nó sẽ bị tắt.",
  English_Version: "Phiên bản tiếng Anh",
  Thai_Version: "Phiên bản tiếng Thái",
  Vi_Version: "Phiên bản Việt Nam",
  New___Edit_Ranking_List: "Danh sách xếp hạng mới / chỉnh sửa",
  List_Name__English__: "Tên danh sách (Tiếng Anh):",
  List_Name__Thai__: "Tên danh sách (Tiếng Thái):",
  List_Name__VN__: "Tên danh sách(Tiếng Việt)",
  Maximum_10_characters: "Tối đa 10 ký tự",
  Required_to_fill_in_: "Bắt buộc phải điền.",
  Homepage_Banner_: "Banner trang chủ:",
  Self_designed_Banner: "Banner tự thiết kế",
  No: "Không",
  Yes: "Có",
  Ranking_List_Banner_: "Banner danh sách xếp hạng:",
  Main_Color_: "Màu chính:",
  Sub_list_1_Name__English__: "Tên danh sách phụ # (Tiếng Anh):",
  Sub_list_1_Name__Thai__: "Tên danh sách phụ # (Tiếng Thái):",
  Product_List_ID_: "ID Danh sách sản phẩm:",
  Numbers_only: "Chỉ chứa số",
  Product_List_ID_does_not_exist: "ID Danh sách sản phẩm không tồn tại",
  You_can_find_Product_List_ID_at_Operational_Management_Product_List_Management_:
    "Bạn có thể tìm ID Danh sách sản phẩm tại Quản lý Hoạt động-Quản lý Danh sách sản phẩm.",
  New_sub_list__4_sub_list_can_be_added_: "Danh sách phụ mới (# danh sách phụ có thể được thêm)",
  Image_suggestion__375_200_px_and_2M_as_maximum_size:
    "Gợi ý hình ảnh: 375*200 px và dung lượng tối đa là 2M.",
  Delete_List: "Xóa danh sách",
  Delete_confirmation: "Xác nhận xóa",
  Are_you_sure_that_you_want_to_delete_the_list_: "Bạn có chắc chắn muốn xóa danh sách?",
  Save_and_Preview: "Lưu và Xem trước",
  Save: "Lưu",
  Delete_failed: "Xóa thất bại",
  Failed_reason__This_list_is_on__Please_try_it_again_after_you_turn_it_off_:
    "Lý do thất bại: Danh sách này đang bật. Vui lòng thử lại sau khi tắt nó.",
  Save_succeed_: "Lưu thành công.",
  Delete_succeed_: "Xóa thành công.",
  Image_upload_failed: "Tải lên hình ảnh thất bại",
  Failed_reason__it_exceeds_maximum_size_as_2M_:
    "Lý do thất bại: Vượt quá dung lượng tối đa là 2M.",
  Save_failed: "Lưu th",

  Edit_Again: "Chỉnh sửa lại",
  Complete: "Hoàn thành",
  Delete: "Xóa",
  榜单简介英文: "Giới thiệu danh sách (Tiếng Anh):",
  榜单简介泰语: "Giới thiệu danh sách (Tiếng Thái):",
  榜单简介越南: " Danh sách giới thiệu(Tiếng Việt):",
  榜单简介: "Giới thiệu danh sách",
  sampleAmountTips:
    "Giá trị mẫu bằng đơn giá SKU * số lượng đăng ký. Nếu không điền thì được coi là vô hạn, không có giới hạn về số tiền.",
  sampleFulfillmentTips:
    "Giá trị mẫu bằng số lượng yêu cầu mẫu thực hiện / số lượng đã gửi. Nếu không điền thì được coi là 0%, không có giới hạn tỷ lệ thực hiện."
};
