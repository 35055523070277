import { history, useModel, useIntl } from "umi";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ProBreadcrumb } from "@ant-design/pro-components";
import { Space } from "antd";

import routes from "../../../../config/routes";

import styles from "./index.less";

const findAntiComponentPathName = (routes: IRouteProps[]): string[] => {
  const antiCompoentRoutes = routes.filter((route) => !route.component).map((route) => route.path);
  return antiCompoentRoutes;
};

const aniteComponentPaths = findAntiComponentPathName(routes);

export default function HeaderContent() {
  const intl = useIntl();
  const { initialState, setInitialState } = useModel("@@initialState");

  return (
    <Space className={styles["app-header-content"]}>
      {/* <span
        className={styles["app-header-collapse"]}
        onClick={() =>
          setInitialState({
            settings: {
              ...initialState?.settings,
              collapsed: !initialState?.settings.collapsed
            }
          })
        }
      >
        {initialState?.settings.collapsed ? (
          <MenuUnfoldOutlined className={styles["app-header-collapse-icon"]} />
        ) : (
          <MenuFoldOutlined className={styles["app-header-collapse-icon"]} />
        )}
      </span> */}
      {/* <ProBreadcrumb
        separator='/'
        className={styles["app-header-breadcrumb"]}
        itemRender={(route) => {
          const { breadcrumbName, path } = route;
          return !aniteComponentPaths.includes(path) ? (
            <span
              className={`${styles["app-header-breadcrumb-item"]}`}
              onClick={() => history.push(path)}
            >
              {breadcrumbName}
            </span>
          ) : (
            <span style={{ color: "#ffffff" }}>{breadcrumbName}</span>
          );
        }}
      /> */}
    </Space>
  );
}
