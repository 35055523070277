export default {
  openInflowReward: "Active Video Promotion Reward",
  lastModifyTime: "Last Modified Time",
  setUpPersonner: "Modifed By",
  inflowSet: "Video Promote Settings",
  numberOfProOrder: "Orders ≥",
  GMV: "GMV≥",
  NumberOfVideoViews: "Video Views ≥",
  inflowAmount: "Reward Amount",
  saveModify: "Save",
  abandonModify: "Discard",
  confirmGiveUpTitle: "Confirm Discarding Changes",
  confirmGiveUpTips: "After discarding, the changes will not take effect.",
  confirmSaveTitle: "Confirm Saving Changes",
  confirmSaveTips:
    "After saving, APP will synchronize the modified content to display video promote reward instructions.",
  continueTip: "Do you want to continue?",
  continue: "Continue",
  inflowed: "promoted"
};
