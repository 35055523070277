import { ULIVE_ADMIN_ENV_LOCALE_KEY } from "@/constants";

function createStorageObject<T>(key: string, options = {}) {
  return {
    get(): T | null {
      const str = localStorage.getItem(key);
      return str ? (JSON.parse(str) as T) : null;
    },
    clear() {
      localStorage.removeItem(key);
    },
    set(value: T) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    ...options
  };
}

export const nationStorage = {
  set(name: string) {
    localStorage.setItem("nation", name);
  },
  get() {
    return localStorage.getItem("nation");
  },
  remove() {
    localStorage.setItem("nation", "");
  }
};

export const userInfoVnStorage = {
  set(userInfo: string) {
    localStorage.setItem("userInfo_vn", userInfo);
  },
  get() {
    return localStorage.getItem("userInfo_vn");
  },
  remove() {
    localStorage.setItem("userInfo_vn", "");
  }
};
export const userInfoThStorage = {
  set(userInfo: string) {
    localStorage.setItem("userInfo_th", userInfo);
  },
  get() {
    return localStorage.getItem("userInfo_th");
  },
  remove() {
    localStorage.setItem("userInfo_th", "");
  }
};
export const userInfoStorage = {
  set(userInfo: string) {
    if (nationStorage.get() == "TH") {
      userInfoThStorage.set(userInfo);
    } else {
      userInfoVnStorage.set(userInfo);
    }
  },
  get() {
    if (nationStorage.get() == "TH") {
      return userInfoThStorage.get();
    }
    return userInfoVnStorage.get();
  },
  remove() {
    if (nationStorage.get() == "TH") {
      return userInfoThStorage.remove();
    }
    return userInfoVnStorage.remove();
  }
};
export const isTHRegion = () => {
  return nationStorage.get() === "TH";
};
export const isVIRegion = () => {
  return nationStorage.get() === "VI";
};
export const facebookPageTokenStorage = createStorageObject<string>("app_facebook_page_token");

export const apiEnvStorage = createStorageObject<TEnvEnmu>(ULIVE_ADMIN_ENV_LOCALE_KEY);
