import { useEffect, useState } from "react";
import type {
  TabsProps
} from "antd";
import {
  message
} from "antd";
import {
  Button,
  Space,
  Modal,
  Spin,
  Tabs
} from "antd";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useIntl } from "umi";
import { getShareLinkNew } from "@/utils/getShareLink";
import { copy } from "@/utils/copy";
import { generateCollectionUrl } from "../common";
import styles from "./index.less";
import { Inner } from "./inner";
import userManage from "@/api/userManage";
import { excelParse } from "@/utils/common";
import { formatTime } from "@/utils/format";
import { ShareModal } from "../components/ShareModal";


export type TabKey = "valid" | "invalid" | "pending"
export interface CurrentRecord {
  record: any,
  tabKey?: TabKey
}

interface Props {
  handleBack: () => void
  current: CurrentRecord
}

export default ({ handleBack, current }: Props) => {
  const intl = useIntl();

  const [shareProduct, setShareProduct] = useState<any>();
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);

  const [tabKey, setTabKey] = useState<TabKey>(current.tabKey || "valid");
  const [tabTitles, setTabTitles] = useState<{
      [K in TabKey]: string;
    }>({
      "valid": intl.formatMessage({ id: "collection.有效商品" }),
      "invalid": intl.formatMessage({ id: "collection.失效商品" }),
      "pending": intl.formatMessage({ id: "collection.待处理商品" })
    });

  const [modal, contextHolder] = Modal.useModal();
  const [exportLoading, setExportLoading] = useState(false);

  const [shareModalVisible, setShareModalVisible] = useState(false);

  const handleExport = async ()=>{
    modal.confirm({
      title: intl.formatMessage({ id: "collection.导出商品集" }),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: "collection.仅导出当前有效商品列表，你还要继续吗？" }
            )
          }}
        />
      ),
      okText: intl.formatMessage({ id: "button.Confirm" }),
      cancelText: intl.formatMessage({ id: "button.Cancel" }),
      onOk() {
        setExportLoading(true);
        userManage
          .exportItemSetProduct({
            itemSetId: current.record.id,
          })
          .then((res: any) => {

            if ((res as Blob).size === 0) {
              return;
            }
            excelParse({
              data: res
            }, undefined, `${current.record.itemSetName}_${formatTime(Date.now(), "YYYYMMDDHHmmss")}.xls`);

            message.success(intl.formatMessage({ id: "tip.ActionSuccess" }));
          })
          .catch((err: any) => {
            console.log(err);
          })
          .finally(() => {
            setExportLoading(false);
          });
      }
    });
  };

  const handleGenerateShareLink = async () => {
    setGenerateLoading(true);
    try {
      const { itemSetName, description } = current.record || {};
      const { image } = shareProduct || {};
      const linkRes = await getShareLinkNew({
        title: itemSetName || "",
        content: description || "",
        imageUrl: image,
        url: generateCollectionUrl(current.record.id || "")
      });
      const { code } = linkRes || {};
      if (code === 200) {
        copy(linkRes.result, false);
        Modal.success({
          title: (
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: "ttSample.您已成功复制xxx商品集分享链接" },
                  { setName: current.record.itemSetName || "" }
                )
              }}
            />
          ),
          content: intl.formatMessage({ id: "ttSample.您可以将链接粘贴至任何对话框。" }),
          okText: intl.formatMessage({
            id: "button.Confirm"
          })
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGenerateLoading(false);
    }
  };

  const onUpdateTabTitles = async () => {
    const { code, result } = await userManage.requestItemSetInfo({ itemSetId: current.record.id });
    if (code === 200) {
      const { validItemCount, invalidItemCount, pendingItemCount } = result;

      setTabTitles({
        "valid": `${intl.formatMessage({ id: "collection.有效商品" })}（${validItemCount}）`,
        "invalid": `${intl.formatMessage({ id: "collection.失效商品" })}（${invalidItemCount}）`,
        "pending": `${intl.formatMessage({ id: "collection.待处理商品" })}（${pendingItemCount}）`
      });

      if (validItemCount === 0) {
        setShareProduct(false);
      }
    }
  };

  useEffect(() => {
    onUpdateTabTitles();
  }, []);

  const tabItems: TabsProps["items"] = [
    {
      key: "valid",
      label: tabTitles.valid,
      children: <Inner current={current.record} tabKey='valid' focusTabKey={tabKey} onUpdateTabTitles={onUpdateTabTitles} onShareProductReady={(product) => setShareProduct(product)} />

    },
    {
      key: "invalid",
      label: tabTitles.invalid,
      children: <Inner current={current.record} tabKey='invalid' focusTabKey={tabKey} onUpdateTabTitles={onUpdateTabTitles} />
    },
    {
      key: "pending",
      label: tabTitles.pending,
      children: <Inner current={current.record} tabKey='pending' focusTabKey={tabKey} onUpdateTabTitles={onUpdateTabTitles} />
    }
  ];

  return (
    <Spin spinning={false}>
      <div className={styles.table_container}>
        <div className={styles.title_container}>
          <div className={styles.left_container}>
            <div className={styles.back_container}>
              <LeftOutlined style={{ fontSize: 12, color: "#5245E5" }} />
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault();
                  if (handleBack) {
                    handleBack();
                  }
                }}
              >
                {intl.formatMessage({ id: "message.返回" })}
              </a>
            </div>
            <div className={styles.title}>{current.record.itemSetName || ""}</div>
          </div>
          <Space>
            <Button
              key='add_collection'
              onClick={() => {
                setShareModalVisible(true);
              }}
              type='primary'
              disabled={!shareProduct}>
              {intl.formatMessage({ id: "ttSample.分享商品集" })}
            </Button>
            <Button
              loading={exportLoading}
              key='add_collection'
              onClick={() => {
                handleExport();
              }}
              type='primary'
              disabled={!shareProduct}>
              {intl.formatMessage({ id: "collection.导出商品集" })}
            </Button>
          </Space>
        </div>
        <div className={styles.table_container}>
          <div className={styles.title_container}>
            <div className={styles.title}>{intl.formatMessage({ id: "ttSample.商品列表" })}</div>
          </div>
        </div>
        <div style={{ backgroundColor: "#fff" }}>
          <div className={styles.operate_wrap_container}>
            <div className={styles.table_title_container}>
              <Tabs
                activeKey={tabKey}
                items={tabItems}
                // @ts-ignore
                onChange={(key: TabKey) => {
                  setTabKey(key);
                  onUpdateTabTitles();
                }}
              />
              <div />
            </div>
          </div>
        </div>
      </div>
      <ShareModal
        visible={shareModalVisible}
        handleCancel={() => setShareModalVisible(false)}
        record={current.record}
      />
      {contextHolder}
    </Spin>
  );
};
