import { getH5BaseUrl } from "@/utils/getH5BaseUrl";
import { RegionParam } from "@/utils/regionParam";

export const handlePostData = (params: any) => {
  const { current, pageSize, itemSetName, creator, id } = params;
  const convertValues: any = {};
  convertValues.pageNo = current;
  convertValues.pageSize = pageSize;
  if (itemSetName) {
    convertValues["itemSetName$lk"] = itemSetName;
  }
  if (id) {
    convertValues["id"] = id;
  }
  if (creator) {
    convertValues.creator = creator;
  }
  return convertValues;
};

export const generateCollectionUrl = (id: any) => {
  return `${getH5BaseUrl()}/pages/tiktok/selection?id=${id}${RegionParam("&")}`;
};
