export default {
  TAP销售类目: "TAP销售类目",
  全平台销售类目: "全平台销售类目",
  手动添加: "手动添加",
  APP注册: "APP注册",
  达人公海: "达人公海",
  跟进记录: "跟进记录",
  是否意愿申样: "是否意愿申样",
  每条视频报价: "每条视频报价",
  达人来源: "达人来源",
  建联渠道: "建联渠道",
  请至少输入五个字符: "请至少输入五个字符",
  minPriceLessThanMaxPrice: "最低金额必须小于等于最高金额",
  TT昵称: "TT昵称",
  TT唯一名: "TT唯一名",
  MCN: "MCN",
  视频平均播放: "视频平均播放",
  粉丝范围: "粉丝范围",
  月销GMV: "月销GMV",
  月销视频GMV: "月销视频GMV",
  月销直播GMV: "月销直播GMV",
  联系方式: "联系方式",
  销售类目Top3: "销售类目(top3)",
  销售类目: "销售类目",
  联系LINE: "联系LINE",
  联系WhatsAPP: "联系Whats APP",
  联系邮箱: "联系邮箱",
  公海: "公海",
  建联: "建联",
  已建联: "已建联",
  建联成功Tips: "建联成功！可{{btn}}",
  前往跟进达人查看: "前往【跟进达人】查看",
  建联失败Tips: "建联失败！当前达人已被建联，请刷新列表",
  建联失败: "跟进记录小于五个字符/请选择联系渠道",
  联系渠道: "联系渠道",
  暂不明确: "暂不明确",
  建联达人: "建联达人",
  报价金额: "报价金额",
  "30GMV": "30-Day GMV",
  ///
  contactInformationOfInfluencers: "达人联系方式",
  UserId: "用户ID",
  Label: "标签",
  RegisterTime: "注册时间",
  ScreenshotData: "橱窗销售数据",
  followUp: "跟进人",
  lineId: "Line ID",
  Edit_Label: "编辑标签",
  UserList: "用户列表",
  sureModifyFans: "确定修改粉丝数？",
  followUpPerson: "是否修改跟进人？",
  bindGoods: "合作商品",
  productImage: "商品图片",
  productName: "商品名称",
  productId: "商品id",
  anchorSales: "主播销量",
  activityTime: "活动时间",
  activityId: "活动id",
  activityStatus: "活动状态",
  onSaleStatus: "上架状态",
  offlineUser: "线下用户",

  All: "全部",
  toRelease: "待发布",
  beginInAMinute: "即将开始",
  Ongoing: "进行中",
  Closed: "已结束",

  invalid: "已失效",
  video: "视频",
  UsingModules: "使用模块",
  TotalLikes: "总点赞数",
  NumberOfVideos: "视频数",
  TotalVisits: "总访问数",
  NumberOfFans: "粉丝数",
  ViewsToVideo: "浏览量-视频",
  ViewsLive: "浏览量-直播",
  AverageLikeVideo: "平均点赞-视频",
  AverageLikeLive: "平均点赞-直播",
  GPMVideo: "GPM-视频",
  GPMLive: "GPM-直播",
  uchoiceUser: "uchoice用户",
  ttUser: "tt用户",
  identity: "身份",
  uchoicettUser: "uchoice-tt用户",
  Whetherttauthorized: "是否tt授权",
  ttUserName: "tt用户名",
  ttId: "tt id",
  // 翻译
  sku: "规格",
  itemLink: "商品链接",
  applyTime: "申请日期",
  salesScreenshot: "橱销截图",
  系统更新: "由系统更新",
  系统获取: "系统获取",
  已审核: "已审核",
  上传橱窗证明: "手动上传橱窗证明",
  GMV数据: "成交件数数据",
  无需上传: "无需上传",
  JanuaryGMV: "近一个月gmv",
  RecipientName: "收件人姓名",
  RecipientTel: "收件人电话",
  RecipientAddress: "收件人地址",
  ttAuthorizationTime: "tt授权时间",
  ttNumberOfFans: "tt粉丝数",
  GMPToVideoLive: "GPM(视频/直播)",
  ViewsToVideoLive: "浏览量（视频/直播）",
  Viewingtimes: "观看次数（视频/直播）",
  AverageLikeLiveVideo: "平均点赞（视频/直播）",
  Videolist: "视频列表",
  TotalNumberOfVideos: "总视频数",
  FieldAccess: "场均访问",
  AverageLikesPerGame: "场均点赞数",
  AverageNumberOfComments: "场均评论数",
  AverageNumberOfForwardingInTheField: "场均转发数",
  AverageShare: "场均分享数",
  videoid: "视频id",
  ReleaseTime: "发布时间",
  duration: "时长(s)",
  NumberOfLikes: "点赞数",
  NumberOfComments: "评论数",
  NumberOfVisits: "访问数",
  NumberOfShares: "分享数",
  NumberOfForwards: "转发数",
  WithLinkOrNot: "是否带链接",
  yes: "是",
  no: "否",

  userTitle: "跟进达人",
  userID: "用户ID",
  userPhone: "手机号",
  registerUserPhone: "注册手机号",
  telephone: "联系电话",
  EntryTime: "入驻时间",
  RegistraTime: "注册时间",
  LastOnlineTime: "上次在线时间",
  integralSet: "积分配置",
  confirm: "确定提交审核",
  uliveTitle: "Ulive用户账号管理",
  shopName: "店名",
  facebook: "Facebook主页名称",
  BindTime: "绑定时间",

  OpenImportLink: "打开导入链接",
  目标用户: "目标用户",
  目标用户无商品: "目标用户-无商品",
  目标用户有商品: "目标用户-有商品",
  editTelephone: "是否修改联系电话?",

  修改TT用户名: "修改TT用户名",
  正在修改xxx的TT用户名: "正在修改xxx的TT用户名",
  新TT用户名: "新TT用户名",
  确认修改TT用户名: "确认修改TT用户名",
  "原用户名xxx将改为xxx,你还要继续吗?": "原用户名xxx将改为xxx,你还要继续吗?",
  修改的TT用户名重复: "修改的TT用户名重复",
  xxx已存在如确认修改用户及样品订单数据将会合并你还要继续吗:
    "xxx已存在，如确认修改，用户及样品订单数据将会合并，你还要继续吗？",
  新增用户: "新增用户",
  新增线下已建联的用户: "新增线下已建联的用户",
  请输入电话号码: "请输入电话号码",
  或者: "或者",
  "请输入LINE ID": "请输入LINE ID",
  标签: "标签",
  必填: "必填",
  "若不填LINE ID必填": "若不填，LINE ID必填",
  若不填电话必填: "若不填，电话必填",
  手机或line必填一个: "手机或line必填一个",
  Reauthorize: "重新授权",
  类目: "类目",
  橱窗销售截图: "橱窗销售截图",
  橱窗月销售GMV: "橱窗月销售GMV",

  达人链接管理: "达人链接管理",
  链接管理: "链接管理",
  合作链接: "合作链接",
  被替换商品: "被替换商品",
  下架商品: "下架商品",
  活动过期链接: "活动过期链接",
  合作过商品总数: "合作过商品总数",
  操作: "操作",
  查看详情: "查看详情",
  链接状态: "链接状态",
  合作中: "合作中",
  商品下架: "商品下架",
  被替换: "被替换",
  活动过期: "活动过期",
  同商品合作次数: "同商品合作次数",
  是否授权: "是否授权",
  已授权: "已授权",
  未授权: "未授权",
  活动过期商品: "活动过期商品",
  达人佣金率: "达人佣金率",
  达人佣金: "达人佣金",
  链接: "链接",
  当前状态: "当前状态",

  视频: "视频",
  直播: "直播"
};
