export default {
  total: "总共",

  importShop: "导入商品",
  exportTemplate: "导出模板",
  image: "图片",
  name: "名称",
  price: "价格",
  commission: "佣金",
  commissionRate: "佣金率",
  status: "状态",
  sort: "排序",
  upSelf: "上架",
  downSelf: "下架",
  itemId: "商品ID",
  Sort: "排序",
  pleaseInput: "请输入",
  pleaseSelect: "请选择",
  expand: "展开",
  collapse: "收起",
  Reject: "已拒绝",
  Submitted: "已提交",
  Passed: "已通过",
  Delivered: "已发货",
  Completed: "已完成",
  TT商品: "TikTok商品",
  支持扩展名: "支持扩展名"
};
