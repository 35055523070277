export default {
  Agree: "Đồng ý",
  Reject: "Từ chối",
  Action: "Thao tác",
  Search: "Tìm kiếm",
  Reset: "Đặt lại",
  Add: "Thêm",
  NewAdd: "Thêm mới",
  Export: "Xuất",
  BulkExport: "Xuất hàng loạt",
  Import: "Nhập",
  bulkImport: "Nhập hàng loạt",
  Delete: "Xóa",
  BulkDelete: "Xóa hàng loạt",
  Edit: "Chỉnh sửa",
  copy: "Sao chép",
  TakeDown: "Ngưng hoạt động",
  Uploaded: "Hoạt động",
  More: "Thêm",
  Cancel: "Hủy",
  Finish: "Hoàn thành",
  Submit: "Gửi",
  Confirm: "Xác nhận",
  AllSelete: "Chọn tất cả",
  ShowMore: "Xem thêm",
  ShowLess: "Ẩn bớt",
  SeeDetail: "Xem chi tiết",
  Audit: "Phê duyệt",
  Audit2: "Kiểm tra",
  Detail: "Chi tiết",
  Refuse: "Từ chối",
  Adopt: "Chấp nhận",
  Save: "Lưu",
  Tip: "Mẹo",
  uploadFile: "Tải lên tập tin",
  clearAll: "Xóa hết",
  select: "Chọn",
  refresh: "Làm mới",
  获取分享链接: "Nhận Liên kết Chia sẻ"
};
