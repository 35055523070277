import { message } from "antd";
import { getIntl } from "umi";

// 复制
export const copy = (text: any, toast = true) => {
  const intl = getIntl();
  // 创建input标签
  const input: any = document.createElement("input");
  try {
    // 将input的值设置为需要复制的内容
    input.value = text;
    // 添加input标签
    document.body.appendChild(input);
    // 选中input标签
    input.select();
    // 执行复制
    document.execCommand("copy");
    if (toast) {
      message.success(intl.formatMessage({ id: "tip.ContentCopiedClipboard" }));
    }
  } catch (error) {
    console.log("copy error", error);
  } finally {
    // 移除input标签
    document.body.removeChild(input);
  }
};
