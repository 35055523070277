export default {
  originBannerPosition: "位置",
  bannerPosition: "Banner位置",
  topBanner: "Top banner",
  middleBanner: "Middle banner(轮播)",
  middleBannerFix: "Middle banner(固定)",
  middleLongBanner: "middle long banner",
  DailyRecommendation: "今日推荐",
  lazadaLandingPage: "Lazada落地页",
  bannerTime: "Banner时间",
  bannerSort: "Banner排序",
  displayLocation: "展示位置",
  RotationBanner: "轮播banner",
  FixedUpperLeft: "固定右上",
  FixedUpperRight: "固定右下",
  LazadaLandingPage: "lazada落地页",
  // /
  originName: "名称",
  Name: "Banner名称",
  ActivityStatus: "活动状态",
  JumpType: "跳转类型",
  All: "全部",
  Item: "商品",
  Activity: "活动链接",
  AlreadyShelves: "已上架",
  ToShelved: "待上架",
  RemovedShelves: "已下架",
  Image: "Banner图片",
  ImgTip: "建议尺寸：702*202 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。",
  ImgTip220: "建议尺寸：702*220 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。",
  ImgTipBanner:
    "建议尺寸：600*300 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。",
  Sort: "资源位置",
  ItemId: "商品ID/SKU_ID",
  JumpUrl: "活动链接",
  SortTip: "控制展示位置，数字越小展示位置越靠前，可填入1~6",
  MiddleLongSortTips: "控制展示位置，数字越小展示位置越靠前，可填入1~2",
  TopBannerSortTip: "控制展示位置，数字越小展示位置越靠前，可填入1~12",
  MiddleBannerSortTips: "控制展示位置，数字越小展示位置越靠前，可填入1~4",
  onTheShelfTips: "该时间段内激活的banner已达上限，无法添加更多了~",
  Uploaded: "上架",
  Deleted: "下架",
  Address: "地址",
  originBannerTime: "活动时间",
  originImage: "图片",
  originSort: "排序",
  originItemId: "商品ID",
  pageLink: "页面链接",
  h5Page: "H5页面",
  h5Link: "H5链接",
  noValidTapId: "无有效活动ID",
  success: "成功",
  UrlOrID: "跳转地址/商品ID",
  AreYouSureToDeleteThisCampaignPage: "删除后活动链接将失效，确认删除该活动页面？",
  ConfirmDeleteBanner: "确认下架",
  InAppPage: "APP内页面",
  InAppPageLink: "APP内页面链接",

  addBanner: "新增轮播图",
  editBanner: "修改轮播图",
  文本宣传: "文本宣传",
  图片宣传: "图片宣传",
  建议尺寸702x220:
    "建议尺寸：702*220 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。",
  "建议尺寸：宽度固定702像素，长度不限，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。":
    "建议尺寸：宽度固定702像素，长度不限，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。"
};
