export default {
  Product_Ranking_List: "การจัดอันดับสินค้า",
  List_ID_: "List ID:",
  List_Name_: "ชือรายการ:",
  On___Off: "เปิด / ปิด",
  Please_Select_Here: "กรุณาเลือก",
  New_List: "รายการสินค้าใหม่",
  List_ID: "List ID",
  List_Name: "ชือรายการ",
  Banner_image: "Banner",
  Sub_list: "รายการย่อย",
  Status: "สถานะ",
  Sort: "ลำดับ",
  Action: "ดำเนินการ",
  Off: "ปิด",
  On: "เปิด",
  Preview: "ดูตัวอย่าง",
  Edit: "แก้ไข",
  On: "เปิด",
  Off: "ปิด",
  Please_try_to_edit_it_after_you_turn_the_list_off_:
    "โปรดยกเลิกรายการสินค้าและแก้ไขอีกครั้งภายหลัง",
  Confirm_to_turn_it_off: "ยืนยันการยกเลิกรายการสินค้า",
  Are_you_sure_that_you_want_to_turn_the_list_off_: "คุณต้องการยกเลิกรายการสินค้าใช่หรือไม่",
  Cancel: "ยกเลิก",
  Confirm: "ยืนยัน",
  Turn_on_the_list: "เปิดใช้รายการสินค้า ",
  If_you_choose_to_change_the_active_banners__the_original_banner_will_be_invalid_:
    "หากคุณเปลี่ยน Banner Banner เก่าจะถูกยกเลิกทันที",
  Available: "ว่าง",
  Select: "เลือก",
  Cancel: "ยกเลิก",
  Confirm: "ยืนยัน",
  Change_confirmation: "ยืนยันที่จะเปลี่ยนหรือไม่",
  Are_you_sure_that_you_change_the_original_list_which_named__xxx__instead__It_will_be_turned_off_after_you_changed_it_:
    "แน่ใจหรือว่าว่าต้องการเปลี่ยน # หลังจากเปลี่ยนแล้วสินค้าจะถูกนำออกจากรายการสินค้า ",
  English_Version: "ภาษาอังกฤษ",
  Thai_Version: "ภาษาไทย",
  Vi_Version: "เวอร์ชันเวียดนาม",
  New___Edit_Ranking_List: "เพิ่ม / แก้ไข อันดับสินค้า",
  List_Name__English__: "ชื่อรายการ (ภาษาอังกฤษ)",
  List_Name__Thai__: "ชื่อรายการ (ภาษาไทย)",
  List_Name__VN__: "ชื่อรายการ (ภาษาเวียดนาม)",
  Maximum_10_characters: "สูงสุด 10 ตัวอักษร",
  Required_to_fill_in_: "กรุณากรอกชื่อ",
  Homepage_Banner_: "แบนเนอร์หน้าหลัก：",
  Self_designed_Banner: "Banner:",
  No: "ไม่",
  Yes: "ใช่",
  Ranking_List_Banner_: "ลำดับ Banner:",
  Main_Color_: "สี:",
  Sub_list: "รายการย่อย",
  Sub_list_1_Name__English__: "ชื่อรายการย่อย # (ภาษาอังกฤษ):",
  Sub_list_1_Name__Thai__: "ชื่อรายการย่อย # (ภาษาไทย):",
  Product_List_ID_: "ID รายการสินค้า: ",
  Numbers_only: "ตัวเลขเท่านั้น",
  Product_List_ID_does_not_exist: "ไม่มีรายการสินค้านี้",
  You_can_find_Product_List_ID_at_Operational_Management_Product_List_Management_:
    'สามารถค้นหาหมายเลขรายการสินค้าได้ที่ "การจัดการการดำเนินงาน" หลังจากนั้นไปที่ "รายการสินค้า" ',
  New_sub_list__4_sub_list_can_be_added_: "รายการใหม่ (สามารถเพิ่ม # รายการใหม่)",
  Image_suggestion__375_200_px_and_2M_as_maximum_size: "ขนาดรูปภาพ: 375*200 px และไม่เกิน 2M ",
  Delete_List: "ลบรายการ ",
  Delete_confirmation: "ยืนยันการลบรายการ",
  Are_you_sure_that_you_want_to_delete_the_list_: "ยืนยันที่จะลบรายการหรือไม่",
  Cancel: "ยกเลิก",
  Confirm: "ยืนยัน",
  Save_and_Preview: "บันทึกและดูตัวอย่าง",
  Save: "บันทึก",
  Delete_failed: "ลบไม่สำเร็จ",
  Failed_reason__This_list_is_on__Please_try_it_again_after_you_turn_it_off_:
    "ไม่สำเร็จ: กรุณาปิดรายการสินค้าและลองใหม่อีกครั้ง",
  Save_succeed_: "บันทึกสำเร็จ",
  Delete_succeed_: "ลบสำเร็จ",
  Image_upload_failed: "การอัปโหลดรูปภาพล้มเหลว",
  Failed_reason__it_exceeds_maximum_size_as_2M_: "ไม่สำเร็จ: ขนาดใหญ่เกินกำหนด (ต้องไม่เกิน 2M) ",
  Save_failed: "การบันทึกล้มเหลว",
  Failed_reason__This_list_is_on__Please_try_it_again_after_you_turn_it_off_:
    "ไม่สำเร็จ: กรุณาปิดรายการสินค้าและลองใหม่อีกครั้ง",
  Edit_Again: "แก้ไขอีกครั้ง",
  Complete: "สำเร็จ",
  Delete: "ลบ",
  榜单简介英文: "บทนำของรายการ (ภาษาอังกฤษ)：",
  榜单简介泰语: "บทนำของรายการ (ภาษาอังกฤษ)：",
  榜单简介越南: "คำอธิบายรายการ (ภาษาเวียดนาม)：",
  榜单简介: "บทนำของรายการ",
  sampleAmountTips:
    "จำนวนเงินตัวอย่างเป็นราคาต่อหน่วยของ SKU * จำนวนที่ขอ หากไม่กรอกจะถือว่าไม่จำกัด ไม่มีข้อจำกัดทางการเงิน",
  sampleFulfillmentTips:
    "จำนวนเงินตัวอย่างเป็นจำนวนตัวอย่างที่ขอ / จำนวนที่ส่งออก หากไม่กรอกจะถือเป็น 0% และไม่มีข้อจำกัดอัตราการปฏิบัติตาม"
};
