import { langPrefix } from "./format";

type IModuleProps = Record<string, Record<string, string>>;

export function getFileInFolder(modulesFiles: IMWebpackContextStatic) {
  const modules = modulesFiles.keys().reduce((module: IModuleProps, modulePath: string) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = modulesFiles(modulePath);
    module[moduleName] = value.default;
    return module;
  }, {});
  return modules;
}

export function getFileInFolderInVite(modulesFiles: any) {
  const modules: any = Object.keys(modulesFiles).reduce((modules: any, modulePath: any) => {
    const moduleName = modulePath.replace(/(.*\/)*([^.]+).*/gi, "$2");
    const value = modulesFiles[modulePath];
    modules[moduleName] = value.default;
    return modules;
  }, {});
  return modules;
}

export function flatModuleObject(
  modulesFiles: IMWebpackContextStatic | any,
  isVite: boolean = true
): Record<string, string> {
  const modules = isVite ? getFileInFolderInVite(modulesFiles) : getFileInFolder(modulesFiles);
  return Object.keys(modules).reduce(
    (a, b) => ({
      ...a,
      ...langPrefix(modules[b], b)
    }),
    {}
  );
}
