export default {
  All: "Tất cả",
  CouponName: "Tên Voucher",
  PlaceInputName: "Yêu cầu nhập Tên Voucher",
  CouponCode: "Mã Voucher",
  PlaceInputCode: "Yêu cầu nhập Mã Voucher",
  Status: "Trạng thái",
  NotStarted: "Chưa Bắt Đầu",
  Ongoing: "Đang Diễn Ra",
  Closed: "Đã Đóng",
  CouponFee: "Mệnh giá Voucher",
  TermsOfUse: "Điều Kiện Sử Dụng",
  Man: "Giá Trị Tối Thiểu",
  Use1: "Bảt tiền có sẵn",
  Time: "Khoảng Thời Gian Tồn Tại",
  CreateTime: "Ngày Tạo",
  Stocks: "Số Lượng Voucher",
  Limits: "Giới Hạn Số Lượng",
  SendType: "Phương Thức Gửi",
  NoLimit: "Không Giới Hạn",
  Limit: "Giới Hạn",
  Times: "Lần",
  SystemRelease: "Hệ thống phát Voucher tự động",
  CollectionRequired: "Có thể thu thập",
  ViewProducts: "Xem Sản Phẩm",
  EditCoupon: "Sửa Voucher",
  AddCoupon: "Thêm Voucher",
  AddCouponSucc: "Thêm Voucher Thành Công",
  AddCouponHandle: "Thêm Voucher Thành Công, vui lòng chọn sản phẩm",
  Set: "Cài Đặt Voucher",
  SeleteItem: "Chọn Sản Phẩm",
  FullReduction: "Giá Trị Tối Thiểu",
  TotalFee: "Số Tiền Giảm Giá",
  CouponLessFee: "Số Tiền Giảm",
  CouponType: "Loại Voucher",
  AllMoney: "Tổng",
  AfterCoupon: "Sau Khi Sử Dụng Voucher",
  AfterSend: "Từ ngày gửi",
  UseDays: "ngày",
  ItemSet: "Cài Đặt Sản Phẩm",
  userLimit: "Giới Hạn Số Lượng",
  AllItem: "Tất Cả Sản Phẩm",
  DesignatedItem: "Sản Phẩm Đã Chọn",
  SeleteTime: "Vui lòng chọn thời gian hiệu lực",
  valideReject: "Dữ liệu không hợp lệ",
  RequireSave:
    "Sau khi gửi không thể thay đổi sản phẩm cụ thể thành Voucher, bạn có chắc chắn muốn gửi không?",
  NotSeleteItem: "Cần Chọn Sản Phẩm",
  MinZero: "Nên nhập số lớn hơn 0",
  CouponFeeVal: "Giá trị tiêu chuẩn phải lớn hơn số tiền Voucher",
  InputInt: "Vui lòng nhập số nguyên",
  TotalFeeVal: "Số tiền Voucher phải nhỏ hơn giá trị tiêu chuẩn",
  NotSelete: "Chưa Chọn",
  ConfirmDeleteSelete: "Bạn có chắc chắn muốn xóa sản phẩm không?",
  AllPeople: "Tất Cả Mọi Người",
  oldPeople: "Người Cũ",
  onePeople: "Người Đã Chọn",
  newPeople: "Người Mới",
  itemInfo: "Thông Tin Chi Tiết Sản Phẩm",
  ItemPrice: "Giá",
  Inventory: "Hàng Tồn",
  notNameEmpty: "Yêu Cầu Tên",
  notTotalFeeEmpty: "Yêu Cầu Giảm Giá",
  notCouponLessFeeEmpty: "Yêu Cầu Số Tiền Voucher",
  notStocksEmpty: "Yêu Cầu Hàng Tồn",
  notDateEmpty: "Yêu Cầu Khoảng Thời Gian Tồn Tại",
  EditTip: "Nhấn Enter để kích hoạt chỉnh sửa, kiểu dữ liệu là số, không thể trống",
  MinFee: "Số Tiền Tối Thiểu",
  MaxFee: "Số Tiền Tối Đa",
  FeeError: "Số tiền nhập trong tiêu chí tìm kiếm không đúng",
  Fee: "Số Tiền",
  UserPickup: "Có Thể Thu Thập",
  ID: "Mã Voucher",
  PlaceInputID: "Vui lòng nhập mã Voucher",
  TotalNum: "Tổng số lượng đã phát hành",
  LimitNum: "Số lượng còn lại",
  SendRate: "Tỷ lệ thu thập",
  UseNum: "Số lượng đổi Voucher",
  UseRate: "Tỷ lệ sử dụng",
  CouponFee2: "Mệnh giá Voucher",
  OrderFee: "Số tiền góp của Voucher",
  MemberName: "Tên Thành Viên",
  MemberMobile: "Số Điện Thoại",
  OrderNo: "Số Đơn Hàng",
  UseStatus: "Trạng Thái Sử Dụng",
  UseTime: "Khoảng Thời Gian Sử Dụng",
  NotUse: "Chưa Sử Dụng",
  Used: "Đã Sử Dụng",
  Expired: "Hết Hạn",
  Send: "Gửi",
  ConfirmSend: "Xác nhận Gửi?",
  RequireLimit: "Vui lòng nhập giới hạn cho mỗi người",
  LimitMin: "Số lượng tối đa mỗi người phải lớn hơn không",
  RequireStocks: "Vui lòng nhập số lượng phát hành",
  StocksMin: "Số lượng gửi phải lớn hơn không",
  COUPON_FOR_SHOP: "Youpikker sẽ nhận Voucher nếu người bạn mời thành công đặt hàng",
  COUPON_FOR_MEMBER_LOGIN: "Người dùng mới đặt hàng thành công sẽ nhận Voucher",
  COUPON_FOR_REGISTER: "Người dùng mới đăng ký thành công sẽ nhận Voucher",
  GetTime: "Khoảng Thời Gian Thu Thập",
  SeleteGetTime: "Vui lòng chọn ngày thu thập",
  InputDays: "Vui lòng nhập ngày hiệu lực",
  InputUserId: "Vui lòng nhập mã người dùng",
  InputUserIds: 'Vui lòng nhập mã người dùng, vui lòng sử dụng dấu "," để phân tách mã người dùng',
  InputSellerId: "Vui lòng nhập mã người bán",
  InputSellerIds:
    '"Vui lòng nhập mã người bán, vui lòng sử dụng dấu "," để phân tách mã người bán"',
  NameTip: "Vui lòng nhập tên voucher chất lượng (ví dụ: Giảm giá 5 THB với đơn hàng từ 100)",
  StockTip: "Vui lòng nhập số lượng voucher bạn muốn phát hành, nếu không sẽ không giới hạn",
  GetTimeTip: "Thời gian mà người dùng có thể thu thập voucher",
  UseTimeTip: "Thời gian mà người dùng có thể đổi voucher",
  LimitTip: "Giới hạn voucher cho mỗi khách hàng",
  RequireName: "Vui lòng nhập tên voucher",
  RequireTotal: "Vui lòng nhập số tiền giảm giá",
  RequireCouponFee: "Vui lòng nhập giá trị tiêu chuẩn",
  UserLevel: "Theo cấp bậc của Youpikker",
  UserId: "Theo mã người dùng",
  RequireUserLevel: "Vui lòng chọn cấp bậc Youpikker",
  RequireUserId: "Vui lòng thêm mã người dùng",
  RequireSellerId: "Vui lòng thêm mã người bán",
  RequireGategory: "Vui lòng thêm danh mục",
  TimeCheck: "Thời gian thu thập phải trước khi kết thúc thời gian đổi",
  Promote: "Khuyến Mãi",
  ConfirmPromote: "Bạn có chắc chắn muốn khuyến mãi voucher không?",
  SendLimits: "Số lượng gửi mỗi người",
  RequireImportSellerId: "Vui lòng tải lên tệp ID người bán",
  DownloadModel: "Tải Mẫu",
  ShippingCoupon: "Giảm Phí Vận Chuyển",
  NoDoor: "Không Tối Thiểu",
  NoDoorTip: "0 có nghĩa là không có điều kiện"
};
