import type { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import request from "./axios";

// "application/x-www-form-urlencoded;charset=UTF-8"
// "application/json"
class Http {
  static instance: Http | null = null;
  static getInstance() {
    if (Http.instance) {
      return Http.instance;
    }
    Http.instance = new Http();
    return Http.instance;
  }

  async createRequest<P = any>(config: AxiosRequestConfig): Promise<P> {
    const resonse: AxiosResponse<P> = await request(config);
    return resonse.data;
  }

  get<T = any, P = any>(
    url: string,
    params?: T,
    headers?: Record<string, any>,
    responseType?: any
  ): Promise<P> {
    return this.createRequest<P>({
      method: "GET",
      url,
      params: {
        ...params,
        appName: "uchoice"
      },
      headers,
      responseType: responseType
    });
  }

  post<T = any, P = any>(url: string, data?: T, headers?: Record<string, any>): Promise<P> {
    return this.createRequest<P>({
      method: "POST",
      url,
      data: qs.stringify({ ...data, appName: "uchoice" }),
      headers
    });
  }

  postJSON<T = any, P = any>(url: string, data?: T): Promise<P> {
    return this.createRequest<P>({
      method: "POST",
      url,
      data: Object.prototype.toString.call(data) === "[object Object]" ? { ...data, appName: "uchoice" } : data,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  upload<T = any, P = any>(url: string, formData?: T): Promise<P> {
    return this.createRequest<P>({
      method: "POST",
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  exportGet<T = any, P = any>(url: string, params?: T, headers?: any): Promise<P> {
    return this.createRequest<P>({
      method: "GET",
      url,
      responseType: "blob",
      params: {
        ...params,
        appName: "uchoice"
      },
      headers
    });
  }
}

export default Http.getInstance();
