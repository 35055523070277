const bag_icon =
  "https://youpik-test.oss-ap-southeast-1.aliyuncs.com/system/20230915/db801ffc712c4d718a693d68dd779664.png";
const bag_icon_active =
  "https://youpik-test.oss-ap-southeast-1.aliyuncs.com/system/20230915/e2ed8cdc2ab243828a8ce7e12e6bae04.png";

const supplierManageRoute: IRouteProps[] = [
  {
    path: "/supplierManagement",
    name: "供应链管理",
    component: "./supplierManagement",
    meta: { icon: bag_icon, activeIcon: bag_icon_active }
  },
  {
    path: "/supplierManagement/add",
    name: "新增",
    component: "./supplierManagement/supplierAdd",
    hideInMenu: true
  },
  {
    path: "/supplierManagement/edit/:supplierId",
    name: "编辑",
    component: "./supplierManagement/supplierAdd",
    hideInMenu: true
  },
  {
    path: "/supplierManagement/shop/:supplierid&:name",
    name: "店铺",
    component: "./supplierManagement/shop",
    hideInMenu: true
  },
  // 待绑定店铺
  {
    path: "/supplierManagement/shop/pendingStore",
    name: "待绑定店铺",
    component: "./supplierManagement/shop/pendingStore",
    hideInMenu: true
  }
];

export default supplierManageRoute;
