export default {
  // lazada活动页跳转中间页配置
  Lazada页面: "Lazada页面",
  跳转至: "跳转至",
  // 活动页面配置
  search: "搜索",
  Reset: "重置",
  Add: "添加",
  All: "全部",
  NoStart: "未开始",
  Ongoing: "进行中",
  Closed: "已结束",
  ID: "ID",
  Status: "状态",
  URL: "链接",
  Actions: "操作",
  ViewDetail: "查看详情",
  delete: "删除",
  Edit: "编辑",
  PageTableName: "页面名称",
  PageTableTime: "活动时间",
  // 动态页面配置
  Basic: "基本信息设置",
  CampaignTime: "活动时间：",
  PageName: "页面名称：",
  BasicTips: "建议尺寸：图片宽度750像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。",
  BasicBg: "页面背景颜色：",
  BasicDefaultBg: "默认背景色：#f5f5f5",
  Voucher: "优惠券设置",
  ActiveProduct: "活动商品设置",
  navigation: "是否显示楼层导航:",
  recommended: "建议楼层大于等于4个时使用",
  floor: "楼层",
  iconDefault: "icon默认：",
  iconSelected: "icon选中：",
  iconNameEn: "icon名称（En）：",
  iconNameThai: "icon名称（Thai）：",
  iconTip: "建议尺寸：图片宽度90*90像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。",
  Suggested: "建议尺寸：图片宽度750*150像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。",
  ActivityID: "活动ID:",
  ProductList: "商品列表样式",
  Style1: "样式 1",
  Style2: "样式 2 ",
  Style3: "样式 3 ",
  Style4: "样式 4 ",
  floor2: "楼层 2:",
  Deletefloor: "删除楼层",
  Addfloor: "+ 新增楼层",
  save: "保存",
  cancel: "取消",
  PageTitle: "活动标题:",
  delTip: "删除后活动链接将失效，确认删除该活动页面？",
  savePreview: "保存并预览",
  added: "已添加",
  idError: "id不正确",
  more10: "最多10个楼层",
  inputActId: "请输入活动id",
  inputfloorImg: "请上传楼层图",
  inputIcon: "请上传默认icon",
  inputIcons: "请上传选中icon",
  inputFloorEn: "请输入楼层英文名",
  inputFloorTh: "请输入楼层泰文名",
  confirmCanelSave: "请确认取消保存",
  someShip: "部分包邮",
  allShip: "包邮",
  noShip: "不包邮",
  sleWaterful: "请选择瀑布流",
  活动商品类型: "活动商品类型",
  Youpik商品活动: "Youpik商品活动",
  Lazada商品活动: "Lazada商品活动",
  Youpik商品: "Youpik商品",
  Lazada商品: "Lazada商品",
  楼层名称: "楼层名称",
  Lazada瀑布流: "Lazada瀑布流",
  // youpik改版
  Lazada瀑布流ID: "Lazada瀑布流ID",
  savewaterfullFailed: "保存失败: 瀑布流ID不存在",
  waterfullNo: "瀑布流ID不存在",
  主会场展示排数提示: "可填入大于等于0的整数，当填入999时表示在主会场展示楼层的所有商品",

  //
  主会场展示排数: "主会场展示排数",
  切换商品类型: "切换商品类型",
  "切换类型将不保存原类型下的设置内容，你还要继续吗？":
    "切换类型将不保存原类型下的设置内容，你还要继续吗？",
  "分会场Head banner": "分会场Head banner",
  主会场楼层图片: "主会场楼层图片",
  "建议尺寸：图片宽度750像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。":
    "建议尺寸：图片宽度750像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。",
  inputfloorImg2: "请上传分会场Head banner",
  请输入楼层名称: "请输入楼层名称",
  mainLineNum: "可填入1~5的整数。"
};
