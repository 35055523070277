export default {
  // ops_tiktokTT
  merchantSubsidies: "商家补贴",
  pdpDescription: "pdp说明",
  // fb商品
  generate: "生成",
  // lazada商品优化
  LazadaTwotyDaySales: "过去2周销量",
  LazadaHotSellingTrends: "lazada热销趋势",
  AllianceSales: "联盟销量",
  customSort: "自定义排序",
  addConditions: "增加条件",
  ascendingOrder: "升序",
  descendingOrder: "降序",
  priority: "优先级",
  sortField: "排序字段",
  order: "顺序",
  moreFourCondition: "最多只能添加4个条件",
  // 商品管理-lazada
  LazadaThirtyDaySales: "lazada30天销量",
  updateSituation: "更新情况",
  updated: "已更新",
  notUpdated: "未更新",
  Lazada下载导入: "link",
  // lazada转化youpik链接
  WhoAdded: "添加人",
  Youpik: "平台",
  Users: "用户",
  // tiktok快速需求
  duplicateCommodity: "重复商品",
  repeat: "Reapted",
  notRepeated: "Not reapted",
  // tiktok翻译
  haveToBeSynchronizedTips: "个新活动待同步，请确认分类",
  CommissionRateRange: "佣金率范围",
  CreatorCommissionRate: "创作者佣金率",
  CreatorCommission: "创作者佣金",
  classification: "分类",
  sellerStatus: "商家状态",
  synchronization: "同步",
  toRelease: "待发布",
  beginInAMinute: "即将开始",

  //
  productId: "商品ID",
  YoupikShop: "Youpik商品",
  LazadaShop: "Lazada商品",
  lowToheight: "价格从低到高",
  heightTolow: "价格从高到低",
  saleHeightTolow: "销量从高到低",
  sale: "销量",
  // 排行榜翻译
  actualSale: "实际销量",
  lazadaCategore: "lazada类目",
  //

  CommissionHeightTolow: "佣金从高到低",
  commissionRateHeightTolow: "佣金率从高到低",
  addtips: "个商品",
  noAddShop: "无法添加：部分商品无法添加，其余商品均添加成功",
  // addFailed: '添加失败，请检查网络设置',
  failedID: "失败商品ID",
  failedReason: "失败原因：商品已下架",
  //
  // 翻译
  pleasePutLink: "请输入链接",

  //
  PackageWeight: "包裹重量",
  PackageInfoTitle: "交货和保修",
  PackageSize: "包装尺寸",
  SpecialPrice: "特价",
  SpecialPriceTime: "特价时间",
  AboutDetailItem: "商品详情相关",
  Color: "颜色",
  Price: "价格",
  AllItem: "全部商品",
  OnlineItems: "在线商品",
  InReview: "审核中",
  ShelvesItem: "下架商品",
  PlatformShelvesItem: "平台下架商品",
  Category: "类目",
  ItemId: "商品ID",
  ItemName: "商品名称",
  SellerSKU: "卖家SKU",
  StoreSKU: "店铺SKU",
  ItemImage: "商品图片",
  ItemInfo: "商品信息",
  ItemPrice: "商品价格",
  ItemStatus: "商品状态",
  StoreId: "店铺ID",
  CreateTime: "建立时间",
  Operation: "操作",
  AlreadyStore: "已上架",
  handleShlfTips: "上架超过配置数量限制",
  NotInStore: "已下架",
  SoldOut: "已售完",
  Update: "更新",
  Inventory: "库存",
  Item: "商品",
  PlaceSeleteDeleteItem: "请先选择要删除的商品",
  ItemProperties: "商品属性",
  ItemTitle: "商品标题",
  TitlePlacehold: "例如：尼康Coolpix A300 数码相机",
  ItemEnTitle: "商品英文名",
  EnTitlePlacehold: "请输入商品英文名",
  CategoryPlacehold: "点击按钮搜索类目",
  SeleteCategory: "选择类目",
  Seletion: "您当前的选择是",
  Brand: "品牌",
  HomeImgUrl: "商品主图",
  ItemContent: "商品详描",
  ItemContentEn: "商品详描英文",
  ItemShortDesc: "商品简介",
  ItemShortDescEn: "商品简介英文",
  GetItems: "获取物品",
  GetItemsEn: "获取物品(英)",
  ItemMainVariant: "商品主规格",
  MainVariantTip: "请填写主规格选项名称，例如红、黄",
  MainVariantSelete: "主规格选项",
  AddSelete: "新增选项",
  DeleteSelete: "删除选项",
  ItemSecondVariant: "次属性名称（自定义）",
  // ItemSecondAttribute: "次属性名称（自定义）",
  SecondVariantTip: "请填写次属性种类，例如尺寸",
  ClickUpload: "点击上传",
  UploadTip: "第一张图为封面，建议仅上传一张图片。最多8张图片，尺寸在500×500到2000×2000px之间。",
  AuditTipInfo: "确定提交该审核结果吗?",
  RejectTipInfo: "确定驳回该申请吗?",
  RejectTipReason: "驳回必须填写大于等于六个字符的审批意见",
  StockNum: "库存数量",
  Variants: "变体",
  GifItem: "礼包商品",
  PlatformActiveSelect: "平台上下架选择",
  AllPlatformActive: "全部(平台上下架)",
  PlatformRejectActive: "平台下架",
  PlatformAgreeActive: "平台上架",
  BusinessActiveSelect: "商家上下架选择",
  AllBusinessActive: "全部(商家上下架)",
  BusinessRejectActive: "商家下架",
  BusinessAgreeActive: "商家上架",
  IsSellOut: "是否售罄",
  AllSellOut: "全部(是否售罄)",
  SellOut: "售罄",
  NoSellOut: "未售罄",
  ActiveItemTip: "确定要上架该商品吗?",
  DisabledItemTip: "确定要下架该商品吗?",
  AcitveGifItemTip: "确认要设置该商品为礼包商品吗?",
  DisabledGifItemTip: "确认要取消该礼包商品吗?",
  SellPrice: "售价",
  PostAuditTimeRange: "提交审核时间范围",
  AuditedTimeRange: "审核完成时间范围",
  SeellerShopSku: "店铺/商品SKU",
  AuditStatu: "审核状态",
  PostTime: "提交时间",
  AuditTime: "审核时间",
  AuditId: "审批记录ID",
  SellerLoginName: "商家登陆名",
  SellerId: "商家ID",
  AuditOpinion: "审批意见",
  ToBeReviewed: "待审核",
  MachineAuditAgree: "机审通过",
  MachineAuditReject: "机审驳回",
  PeopleAuditAgree: "人工审批通过",
  PeopleAuditReject: "人工审批驳回",
  BeOverdueAudit: "已过期审核",
  AutomaticGeneration: "自动生成",
  AuditAction: "审核操作",
  Long: "长",
  Width: "宽",
  Height: "高",
  PlaceInputAuditOpinion: "请输入审核意见",
  Nothing: "无",
  // lazada
  weight: "权重",
  homePageSelection: "首页精选",
  itemWeight: "商品权重",
  setItemWeightTitle: "请输入商品权重，数字越小权重越高",
  销量: "销量",
  更新时间: "更新时间",
  uChoice链接: "uChoice链接",
  TikTok链接: "TikTok链接",
  "30天销量": "30天销量",
  TikTok总销量: "TikTok总销量"
};
