export default {
  Campaign_Page_Management: "การตั้งค่าหน้าแคมเปญ",
  Title_Name: "ชื่อ",
  _Campaign_Type: "ประเภทแคมเปญ",
  Campaign_Time: "เวลา",
  Add_New: "เพิ่ม",
  Thai: "ไทย",
  Vnvi: "ภาษาเวียดนาม",
  English: "อังกฤษ",
  Banner_Image: "แบนเนอร์",
  Configuration_Page_Link: "ลิงก์",
  Delete: "ลบ",
  Confirm_Deletion: "ลบ",
  Are_you_sure_you_want_to_delete_this_campaign_page_: "คุณแน่ใจหรือไม่ว่าต้องการลบแคมเปญนี้",
  This_link_is_already_active_on_the_homepage__Please_turn_it_off_before_editing_or_deleting_:
    "แคมเปญนี้เริ่มไปแล้ว กรุณาปิดแคมเปญก่อนแก้ไขหรือลบ",
  Recommended_size__Width_750_pixels__size_limit_2MB__It_supports_only_png__jpg__jpeg__gif_formats__Only_one_image_can_be_uploaded_at_a_time_:
    "ขนาดที่แนะนำ: ความกว้างของรูปภาพคือ 750 พิกเซล และขนาดจำกัดที่ 2MB รองรับเฉพาะรูปแบบ png, jpg, jpeg และ gif เท่านั้น และอัปโหลดได้แค่รูปเดียว",
  _Background_Color_: "สีพื้นหลัง:",
  Default_background_color___f5f5f5: "สีพื้นหลัง: #f5f5f5",
  Switch_Campaign_Type: "เปลี่ยนประเภทแคมเปญ",
  Switching_the_type_will_not_save_the_settings_of_the_original_type__Do_you_want_to_continue_:
    "หากเปลี่ยนประเภทแคมเปญ ระบบจะไม่บันทึกประเภทแคมเปญเดิม  คุณต้องการดำเนินการต่อหรือไม่",
  Cancel: "ยกเลิก",
  Confirm: "ยืนยัน",
  Product_List_Configuration: "การตั้งค่ารายการสินค้า",
  _Product_List_ID_: "List ID:",
  Product_list_ID_can_be_found_in_the_Operation_Management___Product_List_:
    "สามารถค้นหารายการสินค้านี้ในหน้ารายการสินค้า",
  Save: "บันทึก",
  Save_and_Preview: "บันทึกและดูตัวอย่าง",
  After_cancellation__the_content_you_edited_will_not_be_saved__Do_you_want_to_continue_:
    "หลังจากยกเลิก ข้อมูลที่คุณแก้ไขจะไม่ถูกบันทึก คุณต้องการดำเนินการต่อหรือไม่",
  Product_List: "รายการสินค้า",
  Campaign_Image: "รูปภาพ",
  Title: "หัวข้อ",
  _Title__Thai__: "ชื่อ (ไทย):",
  _Title__English__: "ชื่อ (อังกฤษ):",
  _Title__VN__: "หัวข้อ (ภาษาเวียดนาม)：",
  Basic_Information_Setting: "ตั้งค่าหน้าแคมเปญ",
  New_Edit_Campaign_Page: "หน้าแคมเปญใหม่/แก้ไข"
};
