export default {
  反馈方向: "Feedback type",
  问题反馈: "Feedback",
  功能建议: "Feature advice",
  反馈标题: "Feedback title",
  "一句话概括您的反馈…": "Summarize your feedback in one sentence…",
  内容超出字符数量上限: "Content exceeds character limit",
  请输入反馈标题: "Please enter a feedback title",
  上传附件: "Upload Attachment",
  点击或将文件拖拽到这里上传: "Click or drag and drop files here to upload",
  "支持扩展名: .jpg .png .mp4": "Support format: .jpg .png .mp4",
  "图片最多上传10张，视频仅支持1个": "upload a maximum of 10 images",
  所有文件大小不超过20MB: "only 1 video, and the size of all files should not exceed 20MB",
  请上传至少1个附件: "Please upload at least 1 attachment",
  "不可添加，最多上传10张图片": "Can not be added, upload up to 10 images",
  "不可添加，图片和视频不可同时上传":
    "Cannot be added, images and videos cannot be uploaded at the same time",
  "不可添加，最多上传1个视频": "Cannot be added, upload a maximum of 1 video",
  "不可添加，仅支持 .jpg .png .mp4格式添加":
    "Cannot be added, only supports .jpg .png .mp4 format to add",
  "不可添加，文件超出大小，最大不超过20MB":
    "Cannot be added, the file exceeds the size, the maximum does not exceed 20MB",
  反馈描述: "Feedback description",
  "系统问题或功能建议，描述越清楚能让开发者更快定位问题哦~":
    "System problems or feature advice, the clearer the description, the faster Admin can target the problem~~",
  "联系电话/LINE": "Contact number/LINE",
  "请留下您的手机号或LINE ID吧~": "Please leave your mobile phone number or LINE ID~~",
  提交并继续反馈: "Submit and continue feedback",
  待受理: "Pending",
  处理中: "Processing",
  已完结: "Done",
  已接收: "Accepted",
  新回复: "new reply",
  您还没提交任何反馈: "You have not submitted any feedback.",
  我们乐意能听到您的反馈与建议: "We would love to hear your feedback and suggestions.",
  "您的反馈建议是我们前进的动力…": "Your feedback is the driving force for us to move forward...",
  联系方式: "Contact",
  附件: "Attachment",
  跟进一下: "Follow up",
  反馈进度表: "Feedback progress",
  等待管理员接收: "Wait for Admin to receive",
  等待反馈问题处理: "Waiting for feedback from Admin",
  等待反馈问题完结: "Waiting for feedback to be completed",
  等待管理员接收后处理反馈: "Wait for Admin to receive",
  反馈跟进区: "Feedback comments",
  "写下你的评论…": "Leave your comments here...",
  添加图片: "Add pictures",
  Ulive管理员: "Ulive Admin",
  我: "me",
  刚刚: "just now",
  "你确定要删除该评论吗？": "Are you sure you want to delete this comment?",
  "未填写内容。": "No content here.",
  反馈时间: "Submit time",
  处理状态: "Status",
  "没有更多评论啦~": "This is the bottomline~",
  提交反馈: "Submit",
  详情: "Details",
  Ulive团队: "Ulive Admin"
};
