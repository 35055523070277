export default {
  supplierTime: "Supplier Checking Time",
  deliveryTime: "Shipping time",
  completeTime: "Complete Time",
  closeTime: "Closure Time",
  履约申请时间: "Fulfillment Request Time",
  履约拒绝时间: "Fulfillment Rejection Time",
  履约通过时间: "Fulfillment Approval Time",
  mcn: "mcn",

  NameOfTalent: "Creator’s name",
  numOfTalent: "Creator‘s account",
  TAPID: "TAP ID ",
  tradingStatus: "Status",
  ScreenshotSales: "The sales screenshots of tiktok showcase ",
  TAPActivityInformation: "TAP Info",
  lastPage: "Previous page",
  nextPage: "Next page",
  CourierCompany: "Courier Company",
  GoToFlashOfficialWebsite: "Go to the logistics offical website",
  flashMessage: "Logistics ",
  ScreenshotData: "Showcase sales screenshots",
  followUp: "Follower",
  lineId: "Line ID",
  track: "Track",
  orderStatusWillChang: 'The order status will be changed to "To be shipped".',
  ship: "Delivery",
  screenDataNo:
    "We apologize that your showcase sales data does not meet the merchant‘s requirements. Please select other products or purchase samples directly.",
  // sampleReject: '非常抱歉，因商家缺货，您的样品申请被拒绝。',
  // sampleRequestFailed: '非常抱歉，因物流原因，您的样品申请失败。',
  // otherReasons: '其他原因填写',
  InitialConditionSetting: "Initial condition setting",
  AverageVideoPlayback: "Video",
  videoPlayback: "Video",
  accountCanOnlyApply: "A KOL-TT account can only apply for a maximum of",
  oneSamples: "samples at the same time;",
  afterTheApplication:
    "after the application is successful, if the sales volume of the sample order applied by the expert in the TAP background is greater than",
  sampleApplication: "one sample application quota will be restored",
  ttStoreName: "TT shop name",
  sureModifyFans: "Make sure to revise the number of followers",
  sureModifyVideo: "Are you sure you want to revise the average video views?",
  Submitted: "submitted",
  followUpPerson: "Confirm to modify the follow-up person",
  goToLogisticsOfficialWebsite: "Go to the logistics offical website",

  YoupikCategore: "Youpik Category",
  lazadaName: "Lazada categore Name",
  youPikName: "Youpik categore Name",
  buyingAttr: "Properties at the time of purchase",
  commonItem: "Normal Products",
  giftItem: "Youpik Choice Products",
  activityItem: "event merchandise",
  RefundID: "Refund ID",
  RefundMethod: "Refund Method",
  BankCardRefund: "Bank Card Refund",
  KsherRefund: "Ksher Refund",
  remark: "Remark",
  RefundTips:
    "Confirm to Pass，it means already refunded to user and order status will change to Refunded.",
  salesAmount: "Sales Amount",
  All: "All",
  Unpaid: "Unpaid",
  Wrapping: "To Pack",
  Shipping: "To Ship",
  PenddingShip: "Pending Pickup",
  InTransit: "Shipping",
  ProperDelivery: "Delivered",
  BuyerId: "Customer ID",
  OrderTimeScope: "Order time range",
  OrderNum: "Order Number",
  OrderType: "Order Type",
  GeneralOrders: "Normal",
  VoucherOrders: "Order Voucher",
  PreOrders: "Pre-order",
  ToRtsPkg: "RTS parcel (pending)",
  AlreadyRtsPkg: "RTS parcel (complete)",
  CreatePkg: "Create Package",
  BuyerName: "Customer Name",
  Package: "Package",
  TrackingOrderNum: "Tracking Number",
  ShippingMethod: "Delivery Option",
  RetailPrice: "Retail Price",
  Status: "Status",
  TotalPrice: "Total",
  ToDelivered: "To be delivered",
  ToPending: "Pending",
  ShippingIncluded: "Shipping Fee Included",
  CreationTime: "Creation Time",
  DeliveryPrintFaceSheet: "Delivery& Label Printing",
  PrintingSurfaceSingle: "Print label",
  ToRecreateParcel: "Re-Create package",
  CheckLogistics: "Check Logistics",
  CancelOrder: "Cancel Order",
  PackageNumber: "Package Number",
  CancelItem: "Cancel Item",
  CancelReason: "Cancel Reason",
  To: "Sent to",
  Product: "Product",
  Img: "Image",
  PaymentMethod: "Payment Method",
  ItemTotalAmount: "Total Amount",
  ItemTotalNum: "Item Total Number",
  BuyerInfo: "Buyer's information",
  BuyersInfo: "Customer Information",
  invitationCoce: "Invitation Code",
  userID: "user id",
  Customer: "Customer",
  TelephoneNumber: "Mobile Number",
  TaxCode: "Tax ID",
  Subtotal: "Subtotal",
  TotalPlatformOffers: "Platform Discount Total",
  TotalSellerDiscount: "Seller Discount Total",
  TotalShippingDiscount: "Total Shipping Fee Discount",
  Cumulative: "Grand Total",
  TransactionInfo: "Transaction Information",
  RefundOfReturnCommission: "Refund commissions amount of returned order",
  PaymentForGoods: "Item Price Credit",
  PaymentOfServiceCharge: "Payment Fee",
  ReturnPayment: "Return/Refund",
  LogistisFreight: "Shipping Fee",
  Commission: "Commission",
  BillingAddress: "Billing Address",
  DeliveryAddress: "Delivery Address",
  DeliverAfterSellerCancle: "Seller cancel after shipped product",
  LogisticsDistributionFailCancle: "Cancellation of logistics delivery failed",
  LogisticsDistributionReturnCancle: "Cancellation of logistics delivery",
  LogisticsDistributionTimeOutCancle: "Cancellation by logistics delivery exceed delivery time",
  LogisticsSolicitationCancle: "Pickup cancelled",
  SellerDeliverTimeOutCancle: "Cancellation by seller exceed delivery time",
  PlatformCancle: "Cancel by platform",
  SellerCancle: "Cancel by seller",
  UserCancle: "Cancel by user",
  PayTimeOut: "Payment Timeout",
  ToBepay: "To be paid",
  ToBePacked: "To be packed",
  ToBeDeliver: "To be delivered",
  Delivered: "Shipping",
  SignedIn: "Delivered",
  OrderComplete: "Order Complete",
  transactionCancle: "Cancel Transaction",
  title: "Order List",
  Date: "Date",
  TransactionType: "Transaction Type",
  CostAmount: "Expense",
  Number: "Number",
  AccountToBill: "Account statement",
  SellerLoginName: "Seller login name",
  ItemId: "Product ID",
  SellerSku: "Seller SKU",
  SKUNum: "SKU Number",
  ModeOfPerformance: "Performance method", // 履约方式
  Refund: "Refund",
  Promotion: "Promotion",
  Coupon: "Voucher",
  Freight: "Delivery Fee",
  DetailedHistory: "History in detailed",
  SKUCoding: "SKUs ID",
  Collected: "Picked up",
  InTransit2: "In Transit",
  InDelivery: "Shipping",
  Stranded: "Stranded",
  DifficultPartsInHanlde: "Handling in process",
  ReturnedItems: "Returned Items",
  AbnormalClosing: "Abnormal close",
  Rescinded: "Revoke",
  OrderDetail: "Order Detail",
  ItemInfo: "Product detail",
  platDiscountSubsidy: "Platform Voucher",
  platShippingSubsidy: "Free Shipping Voucher",
  sellerDiscountSybsidy: "Seller Voucher",
  amountActuallPaid: "Actual amount paid",
  storeName: "Seller shop name", // 卖家店铺名称
  toBeDelivered: "To be delivered",
  packageId: "Package ID",
  number: "Quantity",
  ProperDeliveryFailed: "Deliver Failed",
  Type1010: "Cancellation from merchant after delivered goods",
  Type1009:
    "Cancellation from logistic failure eg, couldn't scan barcode, courier didn't pick up the parcel etc.",
  Type1008:
    "Cancellation from logistic return eg, customers buy an item, but requested return goods",
  Type1007:
    "Cancellation from logistic delivery overdue, eg, logistic has to send goods within 2 days, but it has been 7 days and the goods hasn't reach the destination.",
  Type1006:
    "Cancellation from logistic late pickup eg, no logistic couriers comes to pick up the parcel in the specific time",
  Type1005:
    "Cancellation from merchant delivery overdue eg, parcel has to be delivered to consumers within 7 days, but cancellation because of late delivery",
  Type1004: "Platform cancellation",
  Type1003: "Merchant cancellation",
  Type1002: "Customer cancellation",
  Type1001: "Cancellation from payment overtime",
  Type1000: "Pending payment",
  Type2001: "To Pack",
  Type2002: "To Ship",
  Type3000: "Shipping",
  Type4000: "Delivered",
  Type5000: "Completed",
  OrderCancel: "Cancel transaction",
  ShopName: "Shop Name",
  Remark: "refund notes",
  CSRemark: "CS notes",
  Select: "Please select",
  responseOption1: "No Stock",
  responseOption2: "Price is wrong",
  responseOption3: "Another reason",
  CancelPkg: "Cancel Package",
  CancelPkgSuccess: "Package has been successfully cancelled",
  CancelResponsiblePart: "Cancelled From",
  cancelResponsiblePart1: "User",
  cancelResponsiblePart2: "Seller",
  cancelResponsiblePart3: "Platform",
  cancelResponsiblePart4: "Logistics responsibility",
  cancelReasonTip: "Please select reason",
  cancelFialedReason:
    "The reason for the failure of the appeal will be delivered to the user, please fill in according to the actual situation",
  cancelFialedOtherReason:
    "The reason for the failure of the appeal will be delivered to the user, please fill in according to the actual situation",
  cancelResponsiblePartTip: "Please select who cancel the order",
  ConfirmCancelOrder: "Are you sure to cancel order？",
  cancelOrderSuccess: "Successfully cancelled order",
  ConfirmCancelItem: "Are you sure to cancel product？",
  ConfirmCancelPkg: "Are you sure to cancel parcel？",
  CancelTime: "Canceled on",
  CancelUser: "Cancellation party",
  Pending: "Pending",
  Completed: "Completed",
  Reject: "Reject",
  RefundAmount: "Refund Amount",
  RefundAmountStats: "Payment Status",
  Response: "Response",
  ApplyTime: "Request Created Time",
  SellerType: "Seller Type",
  SendTo: "Sent to",
  ItemID: "Product ID",
  LogisticsType: "Logistic type",
  Close: "Non DBS",
  HandDbs: "Manual DBS",
  AutoDbs: "Automatic DBS",
  LogStatu: "Logistics status",
  LogType: "Logistics mode",
  FlashExpress: "Flash Express",
  SeaStar: "SeaStar",
  Zambo: "LAZABAO",
  CenterGroup: "Center Group",
  TS: "TS",
  Acommerce: "Acommerce",
  Interpid: "Interpid",
  DBSbyhand: "DBS by Hand",
  reason_app_1: "Change payment method",
  reason_app_2: "Delivery time is too long",
  reason_app_3: "Duplicate order",
  reason_app_4: "Change of Delivery Address",
  reason_app_5: "Change of mind",
  reason_app_6: "Decided on another product",
  reason_app_7: "Shipping cost too high",
  reason_app_8: "Found better price elsewhere",
  SellerName: "Seller Name",
  MakingMoney: "Payment in process",
  Type_1001: "Payment not completed in time",
  Type_1002: "Customer cancellation",
  Type_1003: "Merchant cancellation",
  Type_1004: "Platform cancellation",
  Type_1005: "Seller do not deliver in time",
  Type_1006: "Courier do not pick up in time",
  Type_1007: "Courier do not deliver in time",
  Type_1008:
    "Cancellation from logistic return eg, customers buy an item, but requested return goods",
  Type_1009:
    "Cancellation from logistic failure eg, couldn't scan barcode, courier didn't pick up the parcel etc.",
  Type_1010: "Cancellation from merchant after delivered goods",
  Type_2000: "Refund on its way",
  Type_2001: "Refunded from Order Cancellation",
  Type_4000: "Refund Complete",
  Type_1012: "Timeout not shipped canceled",
  reason6: "Timeout not shipped",
  User: "User",
  Seller: "Seller",
  Ops: "OPS",
  Todo: "Pending ",
  CancelGetRespons: "Reason of cancel pick up",
  flashReasponse9: "Shipping address information does not suit with format",
  flashReasponse80: "Seller cancels task",
  flashReasponse83: "Cannot contact seller",
  flashReasponse85: "Seller's phone number is empty",
  flashReasponse90: "The package is not ready to be collected",
  flashReasponse91: "The package does not meet the transportation standard",
  flashReasponse95: "Delayed to pick up due to shortage of vehicle/manpower",
  flashReasponse98: "Wrong pick up address",
  flashReasponse99: "Package does not meet the collection conditions: oversized",
  flashReasponse100: "Package does not meet the collection conditions: contraband",
  flashReasponse105: "Damaged external package",
  ToRts: "Waiting to RTS",
  Rtsed: "Already RTS",
  Cancel: "Cancelled",
  LossDamage: "Loss and damage",
  HasProduct: "Returned",
  Undone: "Shipment cancelled",
  SelectOrderTime: "Please select order time range",
  Countdown: "Countdown",
  Down: "The SKU Stock will be cleared, please replenish the inventory in time；",
  ActualFreight: "Shipping Fee (Paid By Seller)（VAT）",
  handlingCharges: "Payment of handling charges (VAT)",
  freightRefund: "Buyer paid freight refund (VAT)",
  ActualRefund: "Reverse Shipping Fee (Paid By Seller)（VAT）",
  // 交易信息需求
  CommoditySales: "Commodity sales price",
  MerchantCoupon: "Merchant coupon",
  freight: "freight",
  freightBuy: "Buyer pays freight (VAT)",
  freightTrue: "Actual freight (VAT)",
  CampaignSubsidy: "Campaign Subsidy",
  CampaignCommissionCompensation: "Commission% Subsidy",
  YoupikFee: "Youpik Fee",
  PlatformCommission: "Youpik Platform Commission (Including 7% VAT)",
  OwnerCommission: "Youpik Marketing Commission (Including 7% VAT)",
  Paymenthandling: "Payment Fee（VAT）",
  ReturnRefund: "Return refund amount",
  ReverseCampaignSubsidy: "Reverse Campaign Subsidy",
  ReverseCommissionCompensation: "Reverse Commission Subsidy",
  RefundSales: "Item Price refund",
  MerchantCoupons: "Merchant coupon refund",
  PlatformCommissionrefund: "Platform Commission (VAT) refund",
  OwnerCommissionrefund: "Owner‘s Commission (VAT) refund",
  showDetail: "More income details",
  hideDetail: "Collapse",
  // Down: 'The SKU Stock will be cleared, please replenish the inventory in time；
  refundStatus: "refund status",
  userSubmit: "User submitted",
  opsRefuse: "OPS outright reject",
  userCancelsApplicationSuccessfully: "The user cancels the application successfully",
  refundSuccess: "Refund successfully",
  refundRejectQC: "Refund refused (QC failed)",
  waitingForRetrun: "Awaiting return shipment",
  duringTheReturn: "In return, the merchant is waiting to receive the goods",
  waitingForBankCard: "bank card pending",
  waitingForBankCardRetrun: "Awaiting credit card refund",
  // lazada
  unpaid: "Unpaid",
  paid: "Paid",
  successfulInvestment: "Delivered",
  refund: "Refund",
  unitPrice: "unit price",
  totalPriceOfGoods: "total price",
  viewCommission: "View commission",
  orderTimeRange: "Order time range",
  // ...
  stateCirculation: "Status flow",
  submitOrder: "SUBMIT",
  buyerPayment: "Paid by customers",
  paymentAmount: "Payment amount",
  cancelPackageCreation: "Cancel package",
  sellerPackaging: "Packed",
  sellerShipment: "Shipped",
  logisticsOrderNo: "Tracking #",
  buyerRefund: "Buyer initiates the refund request",
  refundOrderNo: "Refund order #",
  sellerCancelOrder: "Canceled by seller",
  cancelForLogistics: "Canceled due to shipment",
  sellerCancelsRefund: "Buyer canceled refund request",
  packageNotMactchOrderNo: "Package item and tracking number is not consistent.",
  addressExceptionCode: "error: Invalid address",
  addressNotLockerStation: "There is no Locker station at this address.",
  lockernoUse: "Locker cannot be used.",
  noOpenLocker: "Can't open Locker",
  noPackage: "no package",
  packageCollectionFailed: "Package collection failed to be processed",
  logisticsRefundCancel:
    "Cancellation of logistics and delivery returns (additional 50% for logistics)",
  buyerInitiatedReturnRefund: "Buyer initiates return&refund",
  platformRefuseRefund: "Platform refuses to refund",
  submitRefundApply: "Submit a return request",
  buyerReturned: "Buyer returned",
  refundUnderReview: "Cancelled, refund under review",
  refundPendingPayment: "Refund pending payment",
  payPoints: "Paid by Youpik coins",
  logisticsProvider: "Logistics Partner",
  deliveryFailed: "Delivery Failed",
  deliveryFailed_1: "Delivery failed: return is on the way.",
  deliveryFailed_2: "Delivery failed: return to warehouse",
  rejectAndReturn: "Rejected",
  userReject: "Customer rejected",
  lostPackage: "Package Lost",
  abnormalLogistics: "Logistics exception",
  abnormalLogisticsConcat:
    "The logistics is abnormal. If you have any questions, please contact the logistics company.",
  "预估佣金（佣金率）": "Est. commission（commission rate）",
  实际佣金: "Actual commission",
  店主预估佣金: "Youpikker Est. commission",
  店主实际佣金: "Youpikker Actual commission",
  妥投时间: "Delivery time",
  退款时间: "Return time",
  申述记录: "Appeal Record",
  查看报告: "Report",
  "申述-待审核": "Appeal - Pending",
  "申述-失败": "Appeal - Rejected",
  "申述-成功": "Appeal - Approved",
  审核人: "Reviewer",
  审核意见: "Review Comments",
  审核时间: "Review time",
  审核: "Review",
  "审核通过后该订单状态将修改为已完成，会进行店主佣金结算。是否确认继续?":
    'The status of the order will be changed to be "Completed" after the review is approved and the Youpikker commission will be settled. Do you confirm to continue?',
  请选择原因: "Please select a reason",
  "申诉失败原因会返回给用户，请根据实际情况填写":
    "The reason for the failure of the appeal will be delivered to the user, please fill in according to the actual situation",
  其他原因说明: "Other reasons",
  Lazada订单申诉失败原因: "Lazada order appeal reject reason",
  概览: "Overview",
  仅显示已完成订单: "Show completed orders only",
  退款率: "Refund Rate",
  异常订单率: "Rejected rate",
  刷单: "Rejected-Completed orders",
  刷单率: "Rejected-Completed rate",
  申诉订单: "Appeal orders",
  申诉率: "Appeal rate",
  买家: "buyer",
  全部订单: "all orders",
  异常订单: "Rejected orders",
  订单金额: "order amount",
  预估佣金: "Estimated commission",
  账号状态: "Account status",
  申诉记录: "Appeal Record",
  申请订单: "Apply for an order",
  凭证: "Proofs",
  联系人: "contact",
  联系方式: "contact details",
  订单状态: "Order Status",
  成功: "Approved",
  失败: "Rejected",
  不通过: "Fail",
  待审核: "Pending",
  订单数: "number of order",
  服务单号: "service order number",
  注销: "log out",
  禁用: "disabled",
  正常: "normal",
  删除: "delete",
  税后: "After-tax",
  不含官方账号佣金: "Excluding official account commission",
  数量: "Quantity",
  增补物流单号: "Supplement Logistics ",
  商家补发: "Seller Resend",
  已增补发货: "Supplement Logistics Sent",
  增补发货: "Resend Supplement Logistics",
  "Content ID": "Content ID",
  视频链接: "Video Link",
  视频销量: "Video Sales",
  视频GMV: "Video GMV",
  视频订单数: "Video Orders",
  "Ads Code": "Ads Code",
  未投流: "Not Promoted",
  已投流: "Promoted",
  未关联样品订单: "Not Associated with Sample Orders",
  订单关联状态: "Order Association Status",
  提交时间: "Submission Time",
  选择时间范围: "Select a time range"
};
