import { getShortLink } from "@/api/base";
import { getUliveAdminBaseUrl } from "./getBaseUrl";

export interface ShareLinkData {
  url: string;
  title: string;
  imageUrl: string;
}

export interface ShareLinkDataNew {
  url: string;
  title: string;
  imageUrl: string;
  content: string;
}

export const getShareLink = async ({ url, title, imageUrl }: ShareLinkData) => {
  const longUrl = `${getUliveAdminBaseUrl()}api-base/link/getShareHtml?type=6&url=${encodeURIComponent(
    url
  )}&content=${encodeURIComponent(title)}&imageUrl=${encodeURIComponent(imageUrl)}`;
  return getShortLink({ url: longUrl });
};

export const getShareLinkNew = async ({ url, title, content, imageUrl }: ShareLinkDataNew) => {
  const longUrl = `${getUliveAdminBaseUrl()}api-base/link/getShareHtml?type=6&url=${encodeURIComponent(
    url
  )}&title=${encodeURIComponent(title)}&content=${encodeURIComponent(
    content
  )}&imageUrl=${encodeURIComponent(imageUrl)}`;
  return getShortLink({ url: longUrl });
};
