export default {
  达人表现: "{name}'s Creator Performance",
  我的达人分析: "My Creator Analysis",
  日期区间: "Date Range",
  PIC: "PIC",
  "GMV（预估）": "GMV (Estimated)",
  "TAP佣金收入（预估）": "TAP Commission Income (Estimated)",
  "GMV（完成）": "GMV (Actual)",
  "TAP佣金收入（完成）": "TAP Commission Income (Actual)",
  PIC榜单: "PIC Ranking",
  "All PIC达人表现": "All PIC Creator Performance",
  PIC达人分析: "PIC Creator Analysis"
};
