export default {
  TAP销售类目: "หมวดหมู่การขาย TAP",
  全平台销售类目: "หมวดหมู่การขายทุกแพลตฟอร์ม",
  手动添加: "ทะเบียน",
  APP注册: "WhatsApp",
  达人公海: "ตลาดครีเอเตอร์",
  跟进记录: "บันทึกการติดตาม",
  是否意愿申样: "ยินดีสมัครขอสินค้าตัวอย่าง",
  每条视频报价: "ใบเสนอราคา",
  达人来源: "ที่มาของครีเอเตอร์",
  建联渠道: "ช่องทางการติดต่อ",
  请至少输入五个字符: "กรุณาใส่อักขระอย่างน้อยห้าตัวเพื่อพูดคุยกับครีเอเตอร์",
  minPriceLessThanMaxPrice: "จำนวนขั้นต่ำต้องน้อยกว่าหรือเท่ากับจำนวนสูงสุด!",
  TT昵称: "ชื่อ Tiktok",
  TT唯一名: "ชื่อเฉพาะ Tiktok",
  MCN: "MCN",
  视频平均播放: "ยอดวิวโดยเฉลี่ย",
  粉丝范围: "ขอบเขตของแฟนคลับ",
  月销GMV: "GMV 30 วัน",
  月销视频GMV: "ยอดขายวิดีโอต่อเดือน",
  月销直播GMV: "ยอดขายถ่ายทอดสดต่อเดือน",
  销售类目Top3: "หมวดหมู่ขายดีที่สุด 3 อันดับแรก",
  销售类目: "หมวดหมู่การขาย",
  联系LINE: "ติดต่อ LINE",
  联系WhatsAPP: "ติดต่อ WhatsApp",
  联系邮箱: "อีเมล",
  公海: "ตลาดครีเอเตอร์",
  建联: "ติดตาม",
  已建联: "已建联",
  建联成功Tips: "เชื่อมต่อสำเร็จ! คุณสามารถ{{btn}}",
  前往跟进达人查看: "ตรวจสอบได้ที่ [ติดตามครีเอเตอร์]",
  建联失败Tips: "การเชื่อมต่อล้มเหลว! ครีเอเตอร์ถูกเชื่อมต่อแล้ว กรุณารีเฟรชรายการ",
  建联失败: "การเชื่อมต่อล้มเหลว! ครีเอเตอร์ถูกเชื่อมต่อแล้ว กรุณารีเฟรชรายการ",
  联系渠道: "ช่องทางการติดต่อ",
  暂不明确: "ยังไม่แน่นอน",
  建联达人: "สร้างครีเอเตอร์ใหม่",
  报价金额: "จำนวน",
  "30GMV": "GMV 30 วัน",
  UserId: "ID ผู้ใช้งาน",
  Label: "แท็ก",
  RegisterTime: "เวลาลงทะเบียน",
  ScreenshotData: "ภาพหน้าจอข้อมูลการขายใน Showcase",
  followUp: "ผู้ดูแล",
  lineId: "Line ID",
  Edit_Label: "แก้ไข",
  UserList: "รายชื่อผู้ใช้",
  sureModifyFans: "กรุณาตรวจสอบการแก้ไขจำนวนผู้ติดตาม",
  followUpPerson: "คุณยืนยันที่จะแก้ไขผู้ดูแลหรือไม่",
  bindGoods: "สินค้าที่ได้รับความร่วมมือ",
  productImage: "ภาพสินค้า",
  productName: "ชื่อสินค้า",
  productId: "ชื่อสินค้า",
  anchorSales: "ยอดขายของครีเอเตอร์",
  activityTime: "เวลากิจกรรม",
  activityId: "รหัสกิจกรรม",
  activityStatus: "สถานะใช้งาน",
  onSaleStatus: "อยู่ในขบวนการขาย",
  offlineUser: "ผู้ใช้ออฟไลน์",

  All: "ทั้งหมด",
  toRelease: "รอดำเนินการ",
  beginInAMinute: "กำลังเริ่ม",
  Ongoing: "กำลังประมวลผล",
  Closed: "เกิน",

  invalid: "หมดอายุ",
  video: "วิดีโอ TikTok",
  UsingModules: "สินค้า",
  TotalLikes: "ถูกใจทั้งหมด",
  NumberOfVideos: "วิดีโอ",
  TotalVisits: "รับชมทั้งหมด",
  NumberOfFans: "ผู้ติดตาม",
  ViewsToVideo: "รับชมวิดีโอ",
  ViewsLive: "รับชมไลฟ์",
  AverageLikeVideo: "ยอดไลก์เฉลี่ยของวิดีโอ",
  AverageLikeLive: "ยอดไลก์เฉลี่ยของไลฟ์",
  GPMVideo: "GPM - วิดีโอ",
  GPMLive: "GPM - ไลฟ์",
  uchoiceUser: "ผู้ใช้ Uchoice",
  ttUser: "ผู้ใช้ TikTok",
  identity: "ตัวตน",
  uchoicettUser: "ผู้ใช้ Uchoice-TikTok",
  Whetherttauthorized: "บัญชี TikTok ได้รับอนุญาตแล้วใช่หรือไม่",
  ttUserName: "ชื่อผู้ใช้ TikTok",
  ttId: "TikTok ID",
  sku: "ข้อกำหนด",
  itemLink: "ลิงก์สินค้า",
  applyTime: "วันที่ส่งคำขอ",
  salesScreenshot: "ภาพหน้าจอที่แสดงยอดขาย",
  系统更新: "อัปเดตโดยระบบ",
  系统获取: "ระบบได้รับ",
  上传橱窗证明: "อัปโหลดหลักฐานการจัดแสดงด้วยตนเอง",
  GMV数据: "ข้อมูลจำนวนการทำธุรกรรม",
  无需上传: "ไม่จำเป็นต้องอัปโหลด",
  已审核: "ตรวจสอบแล้ว",
  JanuaryGMV: "GMV ต่อเดือน",
  RecipientName: "ชื่อผู้รับ",
  RecipientTel: "เบอร์ติดต่อผู้รับ",
  RecipientAddress: "ที่อยู่ผู้รับ",
  ttAuthorizationTime: "เวลาที่ได้รับอนุญาต",
  ttNumberOfFans: "ผู้ติดตามใน TikTok",
  GMPToVideoLive: "GPM (วิดีโอ/ไลฟ์)",
  ViewsToVideoLive: "วิดีโอ PV/ไลฟ์ UV",
  Viewingtimes: "观看次数（视频/直播）",
  AverageLikeLiveVideo: "ยอดไลก์เฉลี่ย (วิดีโอ/ไลฟ์)",
  Videolist: "รายการวิดีโอ",
  TotalNumberOfVideos: "วิดีโอ",
  FieldAccess: "ยอดรับชมวิดีโอ",
  AverageLikesPerGame: "ยอดไลก์วิดีโอ",
  AverageNumberOfComments: "ยอดคอมเมนต์วิดีโอ",
  AverageNumberOfForwardingInTheField: "ยอดแชร์วิดีโอ",
  AverageShare: "ยอดแชร์วิดีโอ",
  videoid: "Video ID",
  ReleaseTime: "เวลาที่โพสต์",
  duration: "เวลา(s)",
  NumberOfLikes: "ถูกใจ",
  NumberOfComments: "คอมเมนต์",
  NumberOfVisits: "รับชม",
  NumberOfShares: "แชร์",
  NumberOfForwards: "แชร์",
  WithLinkOrNot: "วิดีโอสินค้าใช่หรือไม่",
  yes: "ใช่",
  no: "ไม่ใช่",

  userTitle: "ติดตามครีเอเตอร์",
  userID: "ไอดีผู้ใช้",
  userPhone: "หมายเลขโทรศัพท์มือถือ",
  registerUserPhone: "เบอร์โทรศัพท์",
  telephone: "เบอร์ติดต่อ",
  EntryTime: "เวลาที่เข้าร่วม",
  RegistraTime: "เวลาลงทะเบียน",
  integralSet: "การกำหนดค่าเหรียญ",
  confirm: "ยืนยันส่งให้ตรวจสอบ",
  uliveTitle: "จัดการบัญชีผู้ใช้ Ulive",
  shopName: "ชื่อร้านค้า",
  LastOnlineTime: "ระยะเวลาออนไลน์ครั้งที่แล้ว",
  facebook: "ชื่อเพจเฟสบุ๊ค",
  BindTime: "เวลาเชื่อมต่อ",

  OpenImportLink: "เปิดลิงก์",
  目标用户: "กลุ่มเป้าหมาย",
  目标用户无商品: "ทาเกตผู้ใช้โดยไม่มีผลิตภัณฑ์",
  目标用户有商品: "ทาเกตผู้ใช้กับผลิตภัณฑ์",
  editTelephone: "คุณต้องการเปลี่ยนเบอร์ติดต่อหรือไม่",

  修改TT用户名: "แก้ไขชื่อ TikTok",
  正在修改xxx的TT用户名: "กำลังแก้ไขชื่อ TikTok สำหรับ xxx",
  新TT用户名: "ชื่อ TikTok ใหม่",
  确认修改TT用户名: "ยืนยันการเปลี่ยนชื่อ  TikTok",
  "原用户名xxx将改为xxx,你还要继续吗?":
    "ชื่อ TikTok เดิมคือ xxx จะถูกเปลี่ยนเป็น xxx คุณต้องการดำเนินการต่อหรือไม่",
  修改的TT用户名重复: "ชื่อ TikTok นี้มีการใช้งานแล้ว",
  xxx已存在如确认修改用户及样品订单数据将会合并你还要继续吗:
    "xxx มีอยู่แล้ว หากการแก้ไขได้รับการยืนยัน ข้อมูลผู้ใช้และคำขอสินค้าตัวอย่างจะรวมเข้าด้วยกัน คุณต้องการดำเนินการต่อหรือไม่",
  新增用户: "เพิ่มผู้ใช้ใหม่",
  新增线下已建联的用户: "เพิ่มผู้ใช้แบบออฟไลน์ใหม่",
  联系方式: "ข้อมูลติดต่อ:",
  请输入电话号码: "โปรดป้อนหมายเลขโทรศัพท์",
  或者: "หรือ",
  "请输入LINE ID": "โปรดป้อน LINE ID",
  标签: "หมวดหมู่",
  必填: "(ต้องระบุ)",
  "若不填LINE ID必填": "หากไม่ระบุ จะต้องระบุ LINE ID",
  若不填电话必填: "หากไม่ระบุ จะต้องระบุหมายเลขโทรศัพท์",
  手机或line必填一个: "โปรดป้อนหมายเลขโทรศัพท์ หรือ LINE ID",
  Reauthorize: "ให้สิทธิ์อีกครั้ง",
  类目: "หมวดหมู่",
  橱窗销售截图: "ภาพหน้าจอที่แสดงยอดขาย",
  橱窗月销售GMV: "GMV ต่อเดือน",

  达人链接管理: "การตั้งค่าลิงก์สินค้าของครีเอเตอร์",
  链接管理: "ตั้งค่าลิงก์",
  合作链接: "ลิงก์แคมเปญ",
  被替换商品: "เปลี่ยนลิงก์สินค้า",
  下架商品: "สินค้าไม่เข้าร่วมแคมเปญ",
  活动过期链接: "ลิงก์สินค้าหมดอายุ",
  合作过商品总数: "จำนวนของสินค้าที่เข้าร่วมแคมเปญ",
  操作: "กำลังดำเนินการ",
  查看详情: "ดูรายละเอียด",
  链接状态: "สถานะลิงก์",
  合作中: "แคมเปญกำลังดำเนินอยู่",
  商品下架: "สินค้าหมด",
  被替换: "แทนที่",
  活动过期: "สิ้นสุดแคมเปญ",
  同商品合作次数: "จำนวนสินค้าที่ซ้ำกัน",
  是否授权: "ไม่ว่าจะได้รับอนุญาต",
  已授权: "ได้รับอนุญาตแล้ว",
  未授权: "ไม่ได้รับอนุญาต",
  活动过期商品: "สินค้าที่เกินกำหนดกิจกรรม",
  达人佣金率: "อัตราค่าคอมมิชชั่นของครีเอเตอร์",
  达人佣金: "ค่าคอมมิชชั่นของครีเอเตอร์",
  链接: "ลิงก์",
  当前状态: "สถานะปัจจุบัน",

  视频: "วิดีโอ",
  直播: "ไลฟ์สด"
};
