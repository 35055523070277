export default {
  通知方式: "Notification Method",
  APP推送: "APP Push",
  标题: "Title",
  消息内容: "Message Content",
  打开跳转至: "Open to Jump to",
  橱窗检测: "Showcase Inspection",
  数据榜单首页: "Data Leaderboard Homepage",
  AI话题首页: "AI Topic Homepage",
  AI定制: "AI Customization",
  待发送: "Pending Send",
  已发送: "Sent",
  发送失败: "Send Failed",
  推送时间: "Push Time",
  接收人: "Recipient",
  所有用户: "All Users",
  创建人: "Creator",
  查看自定义推送: "View Custom Push",
  编辑自定义推送: "Edit Custom Push",
  创建自定义推送: "Create Custom Push",
  全部用户: "All Users",
  指定用户: "Specified Users",
  定时发送: "Scheduled Send",
  即刻发送: "Immediate Send",
  内容分类: "Content Category",
  通知: "Notification",
  推荐: "Recommendation",
  频次: "Frequency",
  仅一次: "Only Once",
  每n天一次: "Once Every {day} Days",
  通知名称: "Notification Name",
  触发节点: "Trigger Node",
  待审核转为待发货: "Pending Review to Pending Shipment",
  待发货转为已发货: "Pending Shipment to Shipped",
  待发货转为待履约: "Pending Shipment to Pending Fulfillment",
  橱窗账号登录状态异常: "Showcase Account Login Status Abnormal",
  存在橱窗高佣待替换商品: "High Commission Items Waiting for Replacement in Showcase",
  没有橱窗登录过的用户: "Users who have not logged into the showcase before",
  没有开启自动替换高佣的用户: "Users who have not activate automatic button",
  未点击过加入推广计划按钮的用户: "Users who have not clicked the Join Promotion Plan button",
  "榜单推荐-U选爆品": "Leaderboard Recommendation - U Select Popular Items",
  "榜单推荐-达人带货榜": "Leaderboard Recommendation - Influencer Merchandise",
  "榜单推荐-U选高佣": "Leaderboard Recommendation - U Select High Commission",
  "榜单推荐-AI热点主题榜": "Leaderboard Recommendation - AI Hot Topic",
  "榜单推荐-持续好货榜": "Leaderboard Recommendation - Continuous Good Items",
  "榜单推荐-达人涨粉榜": "Leaderboard Recommendation - Influencer Follower Growth",
  "榜单推荐-日销商品榜": "Leaderboard Recommendation - Daily Sales Items",
  "榜单推荐-运营主推tap": "Leaderboard Recommendation - Main Operation Tap",
  所有用户包括访客: "All Users Including Visitors",
  申样用户: "Sample Application Users",
  橱窗授权用户: "Showcase Authorized Users",
  已登录过橱窗的用户: "Users who have logged into the showcase",
  默认跳转: "Default Jump",
  指定H5页面: "Specified H5 Page",
  APP内页面: "In-App Page",
  单商品: "Single Item",
  待发货订单列表: "Pending Shipment Order List",
  待履约订单列表: "Pending Fulfillment Order List",
  橱窗检测账号列表: "Showcase Inspection Account List",
  高佣可替换商品列表: "High Commission Replaceable Item List",
  APP指定商品集落地页: "APP Specified Item Set Landing Page",
  APP数据持续好货榜: "APP Data Continuous Good Items Leaderboard",
  编辑内容: "Edit Content",
  确认关闭推送消息: "Confirm Close Push Message",
  确认修改推送内容: "Confirm Modify Push Content",
  "确认后内容将生效，你还要继续吗？":
    "<span style='color: red'>After confirmation, the content will take effect,</span> do you want to continue?",
  正在编辑推送内容: "Editing Push Content",
  推送标题: "Push Title",
  请输入至少五个字符: "Please Enter at Least Five Characters",
  "确认后将不再推送此条消息，你还要继续吗？":
    "<span style='color: red'>After confirmation, this message will no longer be pushed,</span> do you want to continue?",
  关闭推送: "Close Push",
  开启推送: "Enable Push",
  "TAP榜单-免费样品": "uChoice Rank - Free Sample Ranking",
  "TAP榜单-收藏夹": "uChoice Rank -Wishlist Ranking",
  "TAP榜单-添加橱窗": "uChoice Rank -TikTok Showcase Ranking",
  "TAP榜单- 30天畅销榜单": "uChoice Rank -Best Sellers Ranking"
};
