export default {
  数据概览: "Data Overview",
  跟进表现: "Follow-up Performance",
  按无新增挂链达人数: "Creators with No New Links for Over 1 Month",
  按无成交达人数: "Creators with No Sales for Over 2 Months",
  跟进达人总数: "Total Creators",
  超1个月无新增挂链达人数: "Creators with No New Links for Over 1 Month",
  超2个月无成交达人数: "Creators with No Sales for Over 2 Months",
  达人: "Creator",
  已合作商品数: "Number of Products Collaborated",
  已出单数: "Number of Orders",
  历史GMV: "Historical GMV",
  最后一次挂链时间: "Last Link Added Date",
  最后一次出单时间: "	Last Order Date",
  超1个月未新增挂链达人: "Creators with No New Links for Over 1 Month"
};
