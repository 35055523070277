import { userInfoStorage } from "./utils/storage";

/**
 * 34495407390742 公用的管理员账号youpik
 * 47017749708844 柳斌账号
 * tapMaster 标记为tap达人的账号
 * tapMasterOrSuperAdmin 标记为tap达人的账号或者超级管理员
 */
export default (initialState: IInitialStateProps) => {
  // 在这里按照初始化数据定义项目中的权限，统一管理
  // 参考文档 https://next.umijs.org/docs/max/access
  console.log("initialState", initialState);
  const {
    currentUser: { id, type, roleId }
  } = initialState;
  return {
    canOporation: id === "34495407390742" || id === "47017749708844", // 超级管理员的账号或者柳斌的账号
    creatorExportPermissions:
      id === "57816306155548" ||
      id === "47017749708844" ||
      id === "34495407390742" ||
      id === "100000000000" ||
      id === "65666967732236" ||
      id === "121268152369192" ||
      id === "196469831499821",
    isSuperAdmin: type === "superAdmin",
    isLiubin: id === "47017749708844", // liubin的账号
    tapMaster: roleId === 1 || roleId === 3, // roleId 1达人运营 2商家运营 3达人运营和商家运营
    tapMasterOrSuperAdmin: roleId === 1 || roleId === 3 || type === "superAdmin"
  };
};
