import { useEffect, useRef, useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getLocale, setLocale, useIntl } from "umi";
import HeaderDropdown from "@/components/Layout/HeaderDropdown";
import th_icon from "@/assets/images/login/th_icon.png";
import vn_icon from "@/assets/images/login/vn_icon.png";
import styles from "./index.less";
import { uchoiceToken_th, uchoiceToken_vi } from "@/utils/auth";
import { nationStorage } from "@/utils/storage";

const getNationText = (key: string, intl: any) => {
  switch (key) {
    case "TH":
      return (
        <div>
          <img src={th_icon} className={styles.nation_icon} />
          {intl.formatMessage({ id: "login.thailand" })}
        </div>
      );
    case "VI":
      return (
        <div>
          <img src={vn_icon} className={styles.nation_icon} />
          {intl.formatMessage({ id: "login.vietnam" })}
        </div>
      );
    default:
      return (
        <div>
          <img src={th_icon} className={styles.nation_icon} />
          {intl.formatMessage({ id: "login.thailand" })}
        </div>
      );
  }
};
const lingualArr = ["TH", "VI"];

export interface IEnvProps {
  wrapClassName?: string;
  handleSelectedEnation: any;
}

const MultiLingual: React.FC<IEnvProps> = ({ wrapClassName, handleSelectedEnation }) => {
  const intl = useIntl();
  const nation = useRef(nationStorage.get() || "");
  const changeLang = (lang: string) => {
    // const lang = getLocale();
    if (lang === "TH") {
      nation.current = "TH";
      // setLocale("th-TH", false);
    } else if (lang === "VI") {
      nation.current = "VI";
      // setLocale("vi-VN", false);
    }
  };

  useEffect(() => {
    // changeLang();
  }, []);
  const handleClickItem: MenuProps["onClick"] = (it) => {
    const { key } = it;
    if ((key == "TH" && !uchoiceToken_th.get()) || (key == "VI" && !uchoiceToken_vi.get())) {
      changeLang(key);
      nationStorage.set(key);
      location.href = `${location.origin}/login?redirect=${encodeURIComponent(location.href)}`;
    } else {
      handleSelectedEnation(key);
    }
  };

  const menuHeaderDropdown = (
    <Menu onClick={handleClickItem} selectedKeys={[nation.current]}>
      {lingualArr.map((it) => (
        <Menu.Item key={it}>{getNationText(it, intl)}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown} className={wrapClassName}>
      <span className={styles.action}>
        <span className={`${styles.env_desc} `}>{getNationText(nation.current, intl)}</span>
        <DownOutlined style={{ marginLeft: "5px", fontSize: "12px" }} />
      </span>
    </HeaderDropdown>
  );
};

export default MultiLingual;
