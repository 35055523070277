export default {
  waterfallList: "瀑布流列表",
  waterfallId: "瀑布流ID",
  waterfallName: "瀑布流名称",
  shopUpNum: "商品上架数量",
  addWaterfall: "新增瀑布流",
  addEditWaterfall: "新增/编辑瀑布流",
  addShop: "新增商品",
  waterFullShop: "瀑布流商品",
  total: "共计",
  numShop: "个商品",
  nameOrID: "名称/ID",
  category: "类目",
  remove: "移除",
  confirmDelete: "确认删除",
  confirmDeleteTips: "您确认要移出该商品吗？",
  cannotBeAdded: "不可添加",
  cannotBeAddedReason: "失败原因：已达瀑布流上限50个商品",
  addSuccessItem: "已成功添加",
  removeItemSuccess: "移除商品成功",
  sumbitSuccess: "提交成功",
  otherItemNoImport: "部分商品未导入",
  successNum: "成功数量",
  one: "个",
  failNum: "失败数量",
  failSeason: "失败原因",
  moreAddItem: "瀑布流商品最多可添加50个商品",
  TapId: "TAP ID",
  toBeReleased: "待发布",
  beginInAminute: "即将开始",
  inProgress: "进行中",
  Ended: "已结束",
  noDelete: "不可删除",
  waterfallSet: "该瀑布流已配置排行榜ID为",
  ItemList: "的商品列表",
  waterfallNot: "瀑布流名称不能为空"
};
