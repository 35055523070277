import { nationStorage } from "./storage";

export function RegionParam(param = "&&") {
  return nationStorage.get() == "TH" ? `${param}region=TH` : `${param}region=VN`;
}

export function RegionValue() {
  return nationStorage.get() == "TH" ? "TH" : "VN";
}

export default {
  RegionParam,
  RegionValue
};
