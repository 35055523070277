export default {
  有效商品: "Valid Products",
  失效商品: "Invalid Products",
  待处理商品: "Pending Products",
  批量上架: "Bulk Activate",
  确认商品上架至商品集: "Confirm activating the product to the list",
  "商品上架后商品集用户可浏览/复制链接/申样该商品，你还要继续吗？":
    '<span style="color: red">After the products are activated, creators can browse, copy links, and apply for samples.</span> Do you want to continue?',
  上架: "Active",
  "count个商品均已上架！": "All {count} products are activated!",
  "successCount个商品已上架，failedCount个商品已失效，请前往失效商品查看！":
    "{successCount} products are activated, and {failedCount} are failed. Please check in the invalid product list!",
  商品失效原因: "Invalid Reason",
  修改商品集: "Edit Product List",
  导出商品集: "Export",
  "仅导出当前有效商品列表，你还要继续吗？": "Export only the current list of valid products.",
  "同步中…大概需要3分钟": "Synchronizing... It may take about 3 minutes",
  "同步失败，请联系技术人员": "Synchronization failed, please contact technical support",
  同步完成: "Synchronization complete",
  无需同步: "No synchronization needed",
  编辑商品集: "Edit Product Set",
  "编辑专题+列表页面": "Edit Theme + List Page",
  仅编辑专题页面: "Edit Theme Page Only",
  "list主题（泰文）": "List Theme {index} (Thai)",
  "list主题（越南）": "List of Topics (Vietnam)",
  商品集同步状态: "Synchronization Status",
  专题页面预览: "Theme Page Preview",
  列表页面预览: "List Page Preview",
  样式一: "Style 1",
  样式二: "Style 2",
  Earn: "Earn",
  高佣活动结束: "Campaign Ending on {date}",
  已售: "{value} Sold",
  预览: "View",
  "Get uChoice Link": "Get Link",
  活动已结束: "Ends",
  商家已下架: "Seller Offline",
  平台已下架: "Platform Offline",
  "Sort by": "Sort by",
  平台上架: "Platform Status",
  上架1: "Active",
  下架1: "Inactive",
  全部1: "All",
  新品筛选: "New Products",
  过去x天: "Past {day} Days"
};
