export default {
  达人表现: "ประสิทธิภาพของ Creator ของ {name}",
  我的达人分析: "การวิเคราะห์ Creator ของฉัน",
  日期区间: "ช่วงวันที่",
  PIC: "PIC",
  "GMV（预估）": "GMV (โดยประมาณ)",
  "TAP佣金收入（预估）": "Tรายได้ค่าคอมTAP (โดยประมาณ)",
  "GMV（完成）": "GMV (จริง)",
  "TAP佣金收入（完成）": "รายได้ค่าคอม TAP (จริง)",
  PIC榜单: "อันดับ PIC",
  "All PIC达人表现": "ประสิทธิภาพของ Creator ทั้งหมดใน PIC",
  PIC达人分析: "การวิเคราะห์ Creator ใน PIC"
};
