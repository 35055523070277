export default {
  All: "All",
  CouponName: "Voucher Name",
  PlaceInputName: "Voucher Name Required",
  CouponCode: "Voucher ID",
  PlaceInputCode: "Voucher ID Required",
  Status: "Status",
  NotStarted: "Not Started",
  Ongoing: "Ongoing",
  Closed: "Closed",
  CouponFee: "Coupon denomination",
  TermsOfUse: "Terms Of Use",
  Man: "Minimum Spend",
  Use1: "Thai baht available",
  Time: "Available Period",
  CreateTime: "Create Date",
  Stocks: "Voucher Number",
  Limits: "Limit per user",
  SendType: "Send Method",
  NoLimit: "No Limit",
  Limit: "Limit",
  Times: "Times",
  SystemRelease: "Voucher system automatically send voucher",
  CollectionRequired: "Collectible ",
  ViewProducts: "View Products",
  EditCoupon: "Edit Voucher",
  AddCoupon: "Add Voucher",
  AddCouponSucc: "Successfully Add Voucher",
  AddCouponHandle: "Successfully Add Voucher, please select product",
  Set: "Voucher Settings",
  SeleteItem: "Select Item",
  FullReduction: "Minimum Spend",
  TotalFee: "Deduction amount",
  CouponLessFee: "Discount amount",
  CouponType: "Voucher Type",
  AllMoney: "Total",
  AfterCoupon: "Available from collection date",
  AfterSend: "发放当日起",
  UseDays: "days", // 需修改结构
  ItemSet: "Item Setting",
  userLimit: "Limit per user",
  AllItem: "All Items",
  DesignatedItem: "Selected Item",
  SeleteTime: "Please select effective time",
  valideReject: "Data Validation Failed",
  RequireSave: "Cannot change specific product to voucher after submitting, are you sure to submit?",
  NotSeleteItem: "Required Selected Item",
  MinZero: "Should insert number more than 0",
  CouponFeeVal: "Minimum spend need to be more than voucher amount",
  InputInt: "Please enter integer",
  TotalFeeVal: "Voucher amount need to be less than minimum spend",
  NotSelete: "Unselect",
  ConfirmDeleteSelete: "Are you sure to delete product?",
  AllPeople: "Everyone",
  oldPeople: "Old Users",
  onePeople: "Selected Users",
  newPeople: "New Users",
  itemInfo: "Product Details",
  ItemPrice: "Price",
  Inventory: "Stock",
  notNameEmpty: "Required Name",
  notTotalFeeEmpty: "Required Discount",
  notCouponLessFeeEmpty: "Required Voucher Amount",
  notStocksEmpty: "Required Stock",
  notDateEmpty: "Required Available Period",
  EditTip: "Defocus/Enter to trigger the modification, the content type is number, cannot be empty",
  MinFee: "Minimum amount",
  MaxFee: "Maximum amount",
  FeeError: "The amount entered in the search criteria is wrong",
  Fee: "Amount",
  UserPickup: "Collectible",
  ID: "Voucher ID",
  PlaceInputID: "Please enter voucher ID",
  TotalNum: "Total number of issued",
  LimitNum: "The remaining amount",
  SendRate: "Collection Rate",
  UseNum: "Redemption Amount",
  UseRate: "Redemption Rate",
  CouponFee2: "Coupon fee",
  OrderFee: "Coupon contribution amount",
  MemberName: "Username",
  MemberMobile: "Mobile Number",
  OrderNo: "Order Number",
  UseStatus: "Status",
  UseTime: "Useable Period",
  NotUse: "Unused",
  Used: "Used",
  Expired: "Expired",
  Send: "Send",
  ConfirmSend: "Sending Confirmation?",
  RequireLimit: "Please enter the limit per person",
  LimitMin: "The maximum number per person should be greater than zero",
  RequireStocks: "Please enter issued quantity",
  StocksMin: "Sending amount should greater than zero",
  COUPON_FOR_SHOP: "Youpikker will get voucher if the friend thier invite successfully placed order",
  COUPON_FOR_MEMBER_LOGIN: "New user who successfully placed order will get voucher",
  COUPON_FOR_REGISTER: "New user who successfully sign up will get voucher",
  GetTime: "Collection period",
  SeleteGetTime: "Please select collection date",
  InputDays: "Please enter effective date",
  InputUserId: "Please enter user ID",
  InputUserIds: "Please enter user ID, please use \"\",\"\" to seperate user ID",
  InputSellerId: "Please enter seller ID",
  InputSellerIds: "\"Please enter seller ID, please use \"\",\"\" to seperate seller ID\"",
  NameTip: "Please fill in quality voucher name (e.g. Discount 5 THB with 100 min spend)",
  StockTip: "Please fill in the number of vouchers you want to issue, if not it will be unlimited",
  GetTimeTip: "The period of time that users can collect vouchers",
  UseTimeTip: "The period of time that users can redeem vouchers",
  LimitTip: "Voucher Limit per Customer",
  RequireName: "Please fill in voucher name",
  RequireTotal: "Please fill in discount amout",
  RequireCouponFee: "Please fill in minimum spend",
  UserLevel: "By Youpikker level",
  UserId: "By user ID",
  RequireUserLevel: "Please select Youpikker level",
  RequireUserId: "Please add user ID",
  RequireSellerId: "Please add seller ID",
  RequireGategory: "Please add category",
  TimeCheck: "Collection period should be before redemption period ends",
  Promote: "Promotion",
  ConfirmPromote: "Are you sure to promote voucher?",
  SendLimits: "Number of sending per user",
  RequireImportSellerId: "Please upload user ID file",
  DownloadModel: "Download Version",
  ShippingCoupon: "Shipping Fee Discount",
  NoDoor: "No Minimum",
  NoDoorTip: "0 means no conditions"
};
