export default {
  StartTime: "开始日期",
  Tip: "提示",
  EndTime: "结束日期",
  ConfirmDelete: "确认删除?",
  DeleteSuccess: "删除成功",
  Canceled: "已取消！",
  ActionSuccess: "操作成功",
  ActionError: "操作失败",
  ConfirmDeletes: "请确认批量删除",
  ContentCopiedClipboard: "内容已复制到剪切板！",
  SorryCopyFailed: "抱歉，复制失败！",
  TemporarilyNoData: "暂无数据",
  uploadRejected: "上传失败，请重试!",
  isDeleteImg: "是否删除该图片?",
  goBeyondFilNum: "超出文件限制!",
  imgBigLimit: "图片不能超过20M!",
  当前限制选择x个图片: "当前限制选择{limit}个图片",
  ConfirmCancel: "确认取消吗？",
  refresh: "刷新"
};
