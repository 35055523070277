export default {
  // 翻译
  pendingClassificationBtn: "x products pending classification",
  //
  categoryLabels: "Category Label",
  wiseManPerStatus: "Creator Fulfill Information",
  totalNumSampleAppOrders: "Total Sample Orders",
  shippedOrderNum: "Shipped",
  pendingPerformanceNum: "Pending Fulfill",
  performanceNum: "Fulfill Rate",
  mannerOfPerformance: "Content Type",
  area: "District",
  detailedAddress: "Address",
  pleaseDatailAdd: "Enter street name, house name and room number.",
  clickLogOffWeb: "Click to view the logistics official website",
  ttSample: "Setting for U&T sample request",
  All: "All",
  Shipping: "To Ship",
  ToSellerReviewed: "Pending Review by Merchant",
  sellerAgree: "Merchant Approved",
  agreeToSellerAgree: 'After approval, it will enter into "Pending Merchant Approval" status',
  sellerAgreeToShipping: 'After approval, it will enter into "Pending Shipment" status',
  videoLink: "Video Link",
  Live: "Live Stream",
  video: "Video",
  liveId: "Live ID",
  liveLink: "live stream link",
  maxInput999Num: "You can only enter a maximum of 999",
  // 履约
  FulfillRecords: "Fulfill records",
  inputPositiveInteger: "Only positive integers can be entered",
  uploadedTheVideoPromotionID: "The sample order has uploaded the video promotion ID:",
  VideoIdExists: "Video ID already exists",
  liveTimeTips: "LIVE Time Required",
  modifyTheNumberOfProducts: "Revise the number of samples",
  inputNewSample: "Input the number of new samples",
  LiveAndVideo: "Video & Livestream",
  PerformanceToBereviewed: "Fulfillments to be confirmed",
  PerformancePass: "Fullfillment checking approved",
  RefusalOfPerformance: "Fulfill rejection",
  PerformanceStatus: "Fulfill State",
  PerformanceMethod: "Fulfill Ways",
  MerchantReviewRejected: "Merchant rejected",
  AutomatedReviewApproved: "Automated Review Approved",
  ShipmentRejected: "Shipment Rejected",

  Rejected: "Rejected",
  appUp: "App uploaded",
  orderBack: "Order feedback",
  PICConfirm: "PIC Confirmed ",
  视频回溯: "Video Playback",
  直播回溯: "Live Playback",
  直播视频回溯: "Live & Video Playback",
  promised: "Fulfilled",
  noPromise: "Unfulfilled",
  promiseedReject: "The fulfillment of this sample order has rejected before",
  videoid: "Promote Video Code (Optional)",
  videosId: "Promote Video Code",
  upLoadVideo: "Please upload your VIDEO Code",
  confirmReject: "Confirm Rejection of Fulfillment",
  promiseFinish: "Agree to Proceed to Completed",
  importApply: "Import Sample Application",
  importShip: "Import Shipping Information",
  repeatApply: "Repeat Application",
  ToBeReviewed: "To Be Reviewed",
  inflowCloseSuccess: "Close Streaming Success",
  Delivered: "Shipping",
  closeed: "Closed",
  OrderNum: "Order number",
  NameOfTalent: "Creator’s name",
  StoreName: "Shop Name",
  ItemId: "Product ID",
  ItemName: "Product Name",
  TAPID: "TAP ID ",
  ApplyTime: "Request Created Time",
  lookRecord: "History",
  Item: "Product",
  unitPrice: "unit price",
  Commission: "Commission",
  tradingStatus: "Status",
  ScreenshotSales: "The sales screenshots of tiktok showcase",
  TAPActivityInformation: "ข้อมูล TAP",
  lastPage: "Previous page",
  nextPage: "Next page",
  NumberOfFans: "Followers",
  AverageVideoPlayback: "Video",
  CheckLogistics: "Check Logistics",
  CourierCompany: "Courier Company",
  GoToFlashOfficialWebsite: "Go to the logistics offical website",
  goToLogisticsOfficialWebsite: "Go to the logistics offical website",
  flashMessage: "Logistics ",
  AwbNum: "Tracking number",
  ship: "Delivery",
  orderStatusWillChang: 'The order status will be changed to "To be shipped".',
  Select: "select",
  请选择原因: "Please select a reason",
  "申诉失败原因会返回给用户，请根据实际情况填写":
    "The reason for the failure of the appeal will be delivered to the user, please fill in according to the actual situation",
  其他原因说明: "Other reasons",
  Lazada订单申诉失败原因: "Lazada order appeal reject reason",
  otherReasons: "other reasons",
  actTip: 'Succeed, please go to "Import History" to view',
  toSelect: "Please select",
  useExcel: "Import Excel file",
  selectFile: "Select file",
  downloadModel: "Download template",
  importModel: "Import template",
  importHistory: "Import history",
  exportHistory: "Export history",
  UserName: "User Name",
  time: "Time",
  fileName: "File name",
  Status: "Status",
  Completed: "Completed",
  Failure: "Failure",
  download: "Download",
  refresh: "Refresh",
  palceInputAllContent: "Please fill in details!",
  receiver: "Receiver",
  "uChoice&TT Sample Application Creator Threshold Setting":
    "uChoice&TT Sample Application Creator Threshold Setting",

  申请渠道: "Request Channel",
  线上: "Online",
  线下: "Offline",
  截图时间段: "Sales Time Period",
  "30天成交件数": "30-day sales volume",
  成交件数档位: "Transaction Volume Tie",
  寄样信息: "Shipping Info.",
  新增订单: "New Order",
  修改订单: "Edit Order",
  订单号: "order number #",

  商品分类: "Product Category",
  创建商品集链接: "Create Product Set",
  添加至已有商品集: "Add to Existing Product Set",
  请选择商品集: "Please select a Product Set",
  "商品集名称（泰文）": "Product Set Name (Thai):",
  "推荐理由（泰文）": "Recommendation Text (Thai)",
  "商品集名称（越南）": "Product list name (Vietnamese):",
  "推荐理由（越南）": "Recommendation Text (Vietnamese)",
  "近30天热销商品，下个爆款等你来选！批量免费样品吧等你来拿~":
    "uChoice เลือกสินค้าขายดี เพิ่มสินค้าคอมมิชชั่นสูง ดาวน์โหลดแอปเพื่อดูเพิ่มเติม!",
  创建商品集: "Create Product Set",
  搜索商品集ID或名称: "Search for Product Set ID or Name",
  创建集合: "Create Set",
  集合名称: "Set Name",
  创建人: "PIC",
  商品集ID: "Product Set ID",
  名称: "Name",
  简介: "Recommendation Text",
  有效商品数: "Number of Valid Products",
  集合链接: "Set Link",
  分享: "Share",
  管理商品: "Manage",
  分享商品集: "Share Product Set",
  序号: "No.",
  请输入商品ID或商品名称: "Please enter the Product ID or Name",
  批量移除: "Bulk Remove",
  添加商品: "Add Products",
  商品列表: "Product List",
  全选本页: "Select All",
  佣金范围: "Commission Range",
  最低库存量: "Min. Stock",
  "近2周销售增长%": "Sales Growth% (2 wks)",
  最低总销量: "Min. Total Sales",
  近30天最低销量: "30 Days Min. Sale",
  确认要生成商品集链接: "Confirm generating a Product Set link",
  继续: "Continue",
  确认要添加商品至集合: "Confirm adding products to the set",
  "正在添加1个商品至【商品集名称】，你还要继续吗？":
    'Adding <span style="color: red">{num}</span> product to <span style="color: red">【{name}】</span>. Do you want to continue?',
  添加成功: "Added successfully",
  您可以前往商品集列表管理您的集合商品: "You can manage your product sets in the Product Set list",
  去管理: "Manage Set",
  关闭: "Close",
  已成功创建商品集链接: "Product Set link successfully created",
  复制链接: "Copy Link",
  "正在创建的商品集共包含10个有效商品，你还要继续吗？":
    'The product set being created contains a total of <span style="color: red">{num} valid products</span>. Do you want to continue?',
  "您可以将链接粘贴至任何对话框。": "You can paste the link into any chat box.",
  您已成功复制xxx商品集分享链接:
    'You have successfully copied <span style="color: red">{setName}</span> Product Set sharing link',
  确认删除商品集: "Confirm deleting the Product Set",
  "删除成功！": "Deletion successful!",
  "您正在删除【商品集合名称】，删除后用户端分享链接将失效，你还要继续吗？":
    'You are deleting <span style="color: red">【{setName}】</span>, and the user\'s sharing link will be invalid after deletion. Do you want to continue?',
  Sort: "Sort",
  移除: "Remove",
  "25/100 个商品已添加至商品集，已失效商品（活动结束、商家下架、库存不足、平台下架）系统将会自动移除。":
    '<span style="color: #5245E5">{num}</span>/100 products have been added to the Product Set. Invalid products (ended campaign, seller inactive, out of stock, platform removed) will be automatically removed.',
  确认移除商品: "Confirm removing products",
  "您正在移除2个商品，你还要继续吗？":
    'You are removing <span style="color: red">{num} products</span>. Do you want to continue?',
  "已选择 20/100 个商品": 'Selected <span style="color: #7f6ff2">{num}</span>/100 Products',
  "添加失败，商品集最多可添加100个商品，剩余可添加1个商品。":
    'Adding failed, maximum of 100 products allowed, <span style="color: red">{num}</span> more can be added.',
  明星活动: "Celebrity Campaigns",
  同步: "Synchronize",
  更新: "Update",
  普通活动: "Normal Campaigns",
  普通活动列表: "List of Normal Campaigns",
  明星活动列表: "List of Celebrity Campaigns",
  更新中: "Updating",
  个活动待同步至普通活动列表: " campaigns pending synchronization to the normal campaigns list",
  个活动待同步至明星活动列表: " campaigns pending synchronization to the celebrity campaigns list",
  请填写tap佣金率: "Please fill in the TAP commission rate",
  同步中: "Syncing",
  同步失败: "Sync Failed",
  再次发起: "Retry",
  "固定佣金，不可调整": "Commission is already set, it cannot be revised now",
  等级数量: "Number of Levels",
  "限制类型：GMV": "Limit Type: GMV",
  "限制类型：成交数量": "Limit Type: Trading Volume",
  级: "Level {{level}}",
  样品数量: "Sample Quantity",
  及以上: "and above",
  确认销量范围: "Confirm Sales Range",
  不能大于30: "No more than 30",
  "最低金额无法高于/等于最高金额":
    "The minimum amount cannot be higher than/equal to the maximum amount",
  机审条件配置: "Automated Review Condition Configuration",
  样品金额: "Sample Amount≤",
  并且: "and",
  达人账号履约率: "Influencer Account Fulfillment Rate≥",
  操作人: "Operator",
  时间: "Time",
  文件名: "File Name",
  成功商品数: "Successful Products",
  失败商品数: "Failed Products",
  导入状态: "Import Status",
  导入中: "Importing",
  完成: "Completed",
  失败: "Failed",
  导入商品: "Import Products",
  导入记录: "Import Records",
  下载模板: "Download Template",
  导入商品记录: "Import Product Records",
  "已导入100个商品！": "Successfully imported {success} products!",
  "已导入99个商品，1个商品导入失败，请下载导入记录查看原因！":
    "Imported {success} products, {fail} import failed. Please download the import record to check the reason!",
  商品未挂橱窗: "Product not in showcase",
  已挂橱窗: "Add shocase",
  未挂橱窗: "Not add showcase",
  operationalReviewApproved: "Operational review approved",
  merchantpasses: "Merchant Passes"
};
