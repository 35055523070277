export default {
  waterfallList: "รายการสินค้า",
  waterfallId: "List ID",
  waterfallName: "ชื่อ",
  shopUpNum: "จำนวนสินค้า",
  addWaterfall: "เพิ่มรายการสินค้า",
  addEditWaterfall: "เพิ่ม / แก้ไขรายการสินค้า",
  addShop: "เพิ่มสินค้า",
  waterFullShop: "สินค้า",
  total: "ทั้งหมด",
  numShop: "รายการ",
  nameOrID: "ชื่อ / ID / หมายเลข",
  category: "ประเภท",
  remove: "ลบ",
  confirmDelete: "ยืนยันที่จะลบหรือไม่",
  confirmDeleteTips: "คุณแน่ใจว่าจะลบสินค้านี้ออกจากรายการ?",
  cannotBeAdded: "เพิ่มสินค้าไม่สำเร็จ",
  cannotBeAddedReason: "ไม่สำเร็จ: สามารถเพิ่มสินค้าได้สูงสุด 50 รายการ",
  addSuccessItem: "เพิ่มสินค้า รายการสำเร็จ",
  removeItemSuccess: "ลบสินค้าออกเรียบร้อย",
  sumbitSuccess: "เพิ่มสินค้าสำเร็จ",
  otherItemNoImport: "เพิ่มสินค้าบางรายการไม่สำเร็จ",
  successNum: "เพิ่มสินค้า",
  one: "รายการสำเร็จ",
  failNum: "ไม่สำเร็จ",
  failSeason: "ไม่สำเร็จ",
  moreAddItem: "สามารถเพิ่มสินค้าได้สูงสุด 50 รายการ",
  TapId: "TAP ID",
  toBeReleased: "待发布",
  beginInAminute: "即将开始",
  inProgress: "กำลังประมวลผล",
  Ended: "สิ้นสุดแล้ว",
  noDelete: "ลบไม่สำเร็จ",
  waterfallSet: "เนื่องจากสินค้าถูกจัดอันดับอยู่ที่ ID",
  ItemList: " ",
  waterfallNot: "ชื่อ กรุณากรอกชื่อ"
};
