export default {
  waterfallList: "Danh sách sản phẩm",
  waterfallId: "ID danh sách",
  waterfallName: "Tên danh sách",
  shopUpNum: "Số lượng mặt hàng có sẵn",
  addWaterfall: "Danh sách mới",
  addEditWaterfall: "Danh sách Sản phẩm mới / Sửa đổi",
  addShop: "Thêm sản phẩm",
  waterFullShop: "Mục danh sách",
  total: "Tổng",
  numShop: "mặt hàng",
  nameOrID: "Tên / ID / ID Hoạt động / ID",
  category: "Danh mục",
  remove: "Xóa bỏ",
  confirmDelete: "Xác nhận Xóa",
  confirmDeleteTips: "Bạn có chắc chắn muốn xóa mục từ danh sách không?",
  cannotBeAdded: "Thêm sản phẩm thất bại",
  cannotBeAddedReason: "Lý do thất bại: chỉ có thể thêm tối đa 50 mục.",
  addSuccessItem: "Thêm mục thành công",
  removeItemSuccess: "Xóa mục thành công",
  sumbitSuccess: "Gửi thành công",
  otherItemNoImport: "Một số mục thêm không thành công",
  successNum: "Thành công:",
  one: " mục",
  failNum: "Thất bại:",
  failSeason: "Lý do thất bại:",
  moreAddItem: "Chỉ có thể thêm tối đa 50 mục.",
  TapId: "TAP ID",
  toBeReleased: "Sắp phát hành",
  beginInAminute: "Sắp bắt đầu",
  inProgress: "Đang diễn ra",
  Ended: "Đã kết thúc",
  noDelete: "Xóa không thành công",
  waterfallSet: "danh sách sản phẩm này được cấu hình với danh sách xếp hạng có ID là",
  ItemList: "Danh sách mặt hàng",
  waterfallNot: "Tên danh sách là bắt buộc phải điền."
};
