export default {
  thailand: "nước Thái Lan",
  vietnam: "Việt Nam",
  logOut: "Đăng xuất",
  homePage: "Trang chủ",
  logIn: "Đăng nhập",
  username: "Tên đăng nhập",
  password: "Mật khẩu",
  fPwd: "Quên mật khẩu?",
  nation: "Quốc gia"
};
