export default {
  有效商品: "有效商品",
  失效商品: "失效商品",
  待处理商品: "待处理商品",
  批量上架: "批量上架",
  确认商品上架至商品集: "确认商品上架至商品集",
  "商品上架后商品集用户可浏览/复制链接/申样该商品，你还要继续吗？":
    '<span style="color: red">商品上架后商品集用户可浏览/复制链接/申样该商品</span>，你还要继续吗？',
  上架: "上架",
  "count个商品均已上架！": "{count}个商品均已上架！",
  "successCount个商品已上架，failedCount个商品已失效，请前往失效商品查看！":
    "{successCount}个商品已上架，{failedCount}个商品已失效，请前往失效商品查看！",
  商品失效原因: "Reasons for product failure",
  修改商品集: "Modification of product collection",
  导出商品集: "导出商品集",
  "仅导出当前有效商品列表，你还要继续吗？": "仅导出当前有效商品列表，你还要继续吗？",
  "同步中…大概需要3分钟": "同步中…大概需要3分钟",
  "同步失败，请联系技术人员": "同步失败，请联系技术人员",
  同步完成: "同步完成",
  无需同步: "无需同步",
  编辑商品集: "编辑商品集",
  "编辑专题+列表页面": "编辑专题+列表页面",
  仅编辑专题页面: "仅编辑专题页面",
  "list主题（泰文）": "*list主题{index}（泰文）",
  "list主题（越南）": "*list主题{index}（越南）",
  商品集同步状态: "商品集同步状态",
  专题页面预览: "专题页面预览",
  列表页面预览: "列表页面预览",
  样式一: "样式一",
  样式二: "样式二",
  Earn: "赚",
  高佣活动结束: "高佣将于{date}结束",
  已售: "{value} 已售",
  预览: "预览",
  "Get uChoice Link": "获得uChoice链接",
  活动已结束: "活动结束",
  商家已下架: "商家已下架",
  平台已下架: "平台下架",
  "Sort by": "排序",
  平台上架: "平台上架",
  上架1: "上架",
  下架1: "下架",
  全部1: "全部",
  新品筛选: "新品筛选",
  过去x天: "过去{day}天"
};
