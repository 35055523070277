import { useModel } from "umi";
import collapse1 from "../../../assets/images/icon_collapsed1.png";
import collapse2 from "../../../assets/images/icon_collapsed2.png";
import styles from "./index.less";

const MenuFooterRender = () => {
  const { initialState, setInitialState } = useModel("@@initialState");

  const toggleCollapse = () => {
    setInitialState({
      settings: {
        ...initialState?.settings,
        collapsed: !initialState?.settings.collapsed
      }
    });
  };

  return (
    <div className={styles.menu_footer_container}>
      {initialState?.settings.collapsed ? (
        <img src={collapse1} onClick={toggleCollapse} />
      ) : (
        <img src={collapse2} onClick={toggleCollapse} />
      )}
    </div>
  );
};

export default MenuFooterRender;
