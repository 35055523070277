export default {
  Lazada页面: "Lazada page",
  跳转至: "Jump to",
  // 活动页面配置
  search: "Search",
  Reset: "Reset",
  Add: "Add",
  All: "All",
  NoStart: "Not Start Yet",
  Ongoing: "Ongoing",
  Closed: "Closed",
  ID: "ID",
  Status: "Status",
  URL: "URL",
  Actions: "Actions",
  ViewDetail: "View Detail",
  delete: "Delete",
  Edit: "Edit",
  PageTableName: "Page Name",
  PageTableTime: "Campaign Time",
  // 动态页面配置
  Voucher: "Voucher settings",
  ActiveProduct: "Active product settings",
  navigation: "Whether to display floor navigation：",
  recommended: "It is recommended to use when there are 4 floors or more",
  floor: "floor",
  iconDefault: "icon default:",
  iconSelected: "icon  Selected：",
  iconNameEn: "icon Name（En）：",
  iconNameThai: "icon Name（Thai）：",
  Suggested:
    "Suggested size: The image width is 750*150 pixels, and the size is limited to 2MB; only png, jpg, jpeg, and gif formats are supported.",
  ActivityID: "Activity ID：",
  ProductList: "Product list style:",
  Style1: "Style 1",
  Style2: "Style 2 ",
  Style3: "Style 3 ",
  Style4: "Style 4 ",
  floor2: "floor 2：",
  Deletefloor: "Delete floor",
  Addfloor: "Add floor",
  Basic: "Basic Info",
  CampaignTime: "Campaign Time：",
  PageName: "Page Name：",
  BasicTips:
    "Suggested size: The image width is 750 pixels, and the size is limited to 2MB; only png, jpg, jpeg, and gif formats are supported.",
  BasicBg: "background color:",
  BasicDefaultBg: "Default background color: #f5f5f5",
  save: "Save",
  cancel: "Cancel",
  PageTitle: "Page Name:",
  delTip: "The link will be expired after deleting, please confirm to delete the campaign？",
  savePreview: "Save and preview",
  added: "Added successfully",
  idError: "Incorrect voucher ID.",
  more10: "Up to 10 floors",
  inputActId: "Please enter TB ID",
  inputfloorImg: "Please upload a floor banner",
  inputIcon: "Please upload the inactive icon",
  inputIcons: "Please upload the active icon",
  inputFloorEn: "Please enter the English name of the floor",
  inputFloorTh: "Please enter the floor name in Thai",
  confirmCanelSave: "Please confirm to cancel saving",
  someShip: "Free shipping excluding remote area",
  allShip: "Free shipping",
  noShip: "No free shipping",
  sleWaterful: "Please select a product list",
  活动商品类型: "Type of campaign product",
  Youpik商品活动: "Youpik product campaign",
  Lazada商品活动: "Lazada product campaign",
  Youpik商品: "Youpik Product",
  Lazada商品: "Lazada Product",
  楼层名称: "Floor name",
  Lazada瀑布流: "Lazada Product List",
  主会场展示排数: "Main campaign display rows:",
  切换商品类型: "Switch product campaign type",
  "切换类型将不保存原类型下的设置内容，你还要继续吗？":
    "Switching the type will not save the settings under the current type.",
  "分会场Head banner": "Head banner of the sub-event",
  主会场楼层图片: "banner",
  "建议尺寸：图片宽度750像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。":
    "Recommended size: Image width is 750 pixels, size is limited to 2MB; only png, jpg, jpeg, gif formats are supported.",
  inputfloorImg2: "Head banner of the sub-event, please upload a picture",
  请输入楼层名称: "Please enter the Floor name",
  mainLineNum: "An integer from 1 to 5 can be entered.",
  waterfullNo: "Waterfall Not Exist"
};
