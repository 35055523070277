export default {
  Require: "This field is required",
  requiredUploadpicture: "Please upload picture",
  pleaseEnterCorrectFormat: "Please use correct format",
  requireName: "Required Name",
  requireMobile: "Required Mobile Phone",
  requireSellerType: "Please Select Seller Type",
  requireStoreLocation: "Please Select Shop Location",
  requireAccountType: "Please Select Account Type",
  requireCheckRead: "Please agreed to the terms and conditions",
  requireCategory: "Please Select Category",
  requireItemId: "Please Enter Item ID",
  requireItemName: "Please Enter Item Name",
  requireSellerSKU: "Please Enter Seller SKU",
  requireSotreSKU: "Please Enter Shop SKU",
  requirePassword: "Required Password",
  noEmptyUsername: "Account cannot be empty", // 账号不能为空
  Select: "Please select",
  Please_input: "Please input",
  closeTip: "Leaving the current page information will not be saved",
  Tip: "Tip",
};
