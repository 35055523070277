export default {
  // 卖家结算
  confirmStatus: "Bạn có chắc chắn đánh dấu thanh toán đã thanh toán?",
  WalletBalance: "Số dư",
  orderBill: "Hóa đơn đơn hàng",
  rewardBill: "Hóa đơn khuyến khích",
  CompleteTime: "Thời gian",
  WithdrawalApplication: "Quản lý rút tiền",
  AmountReceived: "Số tiền nhận được",
  WithdrawalNumber: "ID tham chiếu",
  WithdrawalAmounts: "Số tiền",
  PaymentSuccessful: "Thanh toán thành công",
  CanceledPayment: "Thanh toán đã hủy",
  "Total Cashback (L0,1)": "Tiền hoàn lại đã xác nhận cho L0,L1/ Hoa hồng đã xác nhận cho L2,L3",
  Unpaid: "Đang chờ ",
  tax: "ภาษี",
  WithdrawalTime: "Thời gian rút tiền",
  AmountBeforeTax: "Số tiền trước thuế",
  // 下载翻译发票
  downloadInvoice: "Tải xuống hóa đơn thuế",
  batchDownloadInvoice: "Tải xuống hóa đơn thuế hàng loạt",
  selectBillTime: "Vui lòng chọn kỳ hóa đơn！",
  CompressedPackage: "PDF nén",
  // 注册活动奖励翻译
  RewardType: "Loại thưởng",
  UpgradingReward: "Thưởng nâng cấp",
  RegisteringReward: "Thưởng đăng ký",
  // 卖家结算
  // 店主结算翻译
  shopId: "ID Youpikker",
  shopInviteCode: "Mã mời Youpikker",
  upgradeTime: "Thời gian nâng cấp",
  upgradeLevel: "Cấp độ nâng cấp",
  preTaxCommission: "Hoa hồng trước thuế",
  afterTaxCommission: "Hoa hồng sau thuế",
  orderAmount: "Số tiền hóa đơn đơn hàng",
  upgradeId: "id hóa đơn thưởng",
  upgradeAmount: "Số tiền hóa đơn thưởng",
  downloadOrderBill: "Tải xuống hóa đơn đơn hàng",
  downloadUpgareBill: "Tải xuống hóa đơn nâng cấp",
  // 卖家结算
  // 翻译
  LocalSellers: "Người bán địa phương",
  payoutReason: "Lý do hoàn tiền thất bại",
  InPayment: "Đang thanh toán",
  BillStatusPrompt: "Các hóa đơn sau đây đang trong trạng thái thanh toán và không thể thay đổi",
  Processing: "Đang xử lý",
  allTransfer: "Chuyển khoản tổng",
  strip: "dải",
  ManualPayment: "Thanh toán thủ công",
  ksherPayout: "thanh toán ksher",
  Reprint: "trả lại",
  noDBS: "Hóa đơn người bán không phải DBS",
  DBS: "Hóa đơn người bán DBS",
  PaymentFailed: "Thanh toán thất bại",
  transfer: "chuyển khoản",
  reTransfer: "chuyển khoản lại",
  mode: "Phương thức thanh toán",
  BatchTransfer: "Chuyển khoản hàng loạt",
  transferType: "Vui lòng chọn loại chuyển khoản",
  thisPage: "trang này",
  ServiceCharge: "Phí",
  TotalTransfer: "Chuyển khoản tổng",
  ConfirmTransfer: "Xác nhận chuyển khoản",
  pleaseSelect: "vui lòng chọn",
  BillAmount: "Số tiền hóa đơn",
  transferAmount: "số tiền chuyển khoản",
  historicalArrears: "nợ xấu lịch sử",
  cumulativeAmount: "số tiền lũy kế",
  notMeetingThePaymentConditions: "không đáp ứng điều kiện thanh toán",

  //
  PaymentPreview: "Xem trước thanh toán",
  AlreadyPaymentAmount: "Số tiền đã thanh toán",
  NotPaymentAmount: "Số tiền chưa thanh toán",
  All: "Tất cả",
  SettlementStatus: "Trạng thái",
  BillingId: "ID hóa đơn",
  BillingCycle: "Kỳ hóa đơn",
  SellerType: "Loại người bán",
  SellerId: "ID người bán",
  SellerName: "Tên người bán",
  StatementCycle: "Kỳ hóa đơn",
  SettlementAmount: "Số tiền thanh toán hiện tại",
  AdvanceAmountOfPreviousPeriod: "Số tiền thanh toán trước",
  PaymentAccountName: "Tên tài khoản",
  PaymentAccountNumber: "Số tài khoản",
  PaymentBank: "Tên ngân hàng",
  BillingDetail: "Chi tiết hóa đơn",
  ChagneStatus: "Thay đổi trạng thái",
  LocalPersonalSeller: "Người bán cá nhân địa phương",
  LocalEnterpriseSeller: "Người bán doanh nghiệp địa phương",
  CrossBorderPersonalSeller: "Người bán cá nhân xuyên biên giới",
  CrossBorderEnterpriseSeller: "Công ty người bán xuyên biên giới",
  SellerSettlement: "Thanh toán người bán",
  AlreadySettlementTip: "Khăng khăng thay đổi trạng thái thanh toán thành đã thanh toán?",
  NotSettlementTip: "Khăng khăng thay đổi trạng thái thanh toán thành chưa thanh toán?",
  LastWeek: "Tuần trước",
  LastOneMonth: "Tháng trước",
  LastThreeMarch: "Ba tháng trước",
  ToBePayment: "Đang chờ thanh toán",
  AlreadyPeyment: "Đã thanh toán",
  // 卖家账单详情
  OrderNo: "Số đơn hàng",
  ItemId: "ID mặt hàng",
  ItemSkuId: "ID Sku mặt hàng",
  OrderDate: "Ngày đặt hàng",
  WholesalePrice: "Giá bán sỉ",
  ItemNum: "Số lượng",
  Freight: "Phí vận chuyển",
  PlatformDiscount: "Ưu đãi từ nền tảng", // 平台优惠
  SellerDiscount: "Ưu đãi từ người bán", // 商家优惠
  ActualAmountPaid: "Số tiền thanh toán thực tế", // 实际支付金额
  PaymentProcessingFee: "Phí xử lý thanh toán", // 支付手续费
  PlatformCommission: "Hoa hồng nền tảng", // 平台佣金
  PromotionCommission: "Hoa hồng tiếp thị", // 店主佣金
  OrderDetail: "chi tiết đơn hàng", // 订单详情
  SellerBillingDetail: "Chi tiết hóa đơn người bán", // 卖家账单详情
  YoupikFee: "Youpik",
  // 店主结算
  ShopkeeperId: "ID Youpikker", // 店主ID
  ShopkeeperName: "Tên Youpikker", // 店主名称
  ShopkeeperLevel: "Cấp độ Youpikker", // 店主等级
  LastCycleDeductionCommisson: "Hoa hồng khấu trừ trong kỳ trước", // 上周期扣除佣金
  SLIReward: "Thưởng SLI",
  Status: "Trạng thái",
  CampaignRewards: "Thưởng chiến dịch",
  PastCompensationAmount: "Số tiền bồi thường trước đây",
  ReverseYoupik: "Hoa hồng tiếp thị Youpik đảo ngược",
  ShopkeeperSettlement: "Thanh toán cho shopkeeper", // 店主结算
  BankCode: "Mã ngân hàng",
  ExportTip: "Bạn có chắc chắn muốn xuất file?",
  NotSupportFile: "File không được hỗ trợ",
  ImportTip: "Bạn có chắc chắn muốn nhập file?",
  // 店主账单详情
  ItemAmount: "Tổng số tiền mặt hàng",
  ItemActualAmountPaid: "Số tiền thực tế đã thanh toán",
  commissionRate: "Tỷ lệ hoa hồng",
  PreTaxCommissionAmount: "Số tiền hoa hồng trước thuế",
  NextTaxCommissionAmout: "Số tiền hoa hồng sau thuế tiếp theo",
  ShopkeeperSubCommission: "Chi tiết hoa hồng phụ",
  Detail: "Chi tiết",
  OrdinaryItem: "Sản phẩm bình thường",
  GifItem: "Sản phẩm lựa chọn của Youpik",
  ypcPlus: "Sản phẩm lựa chọn YPK+",
  lazadaGood: "Lazada",
  SubCommissionAmount: "Số tiền hoa hồng",
  ShopkeeperBillingDetail: "Chi tiết hóa đơn chủ cửa hàng",
  AlreadySettlement: "Đã thanh toán",
  NotSettlement: "Chưa thanh toán",
  ItemType: "Loại sản phẩm",
  crossBorderSeller: "Người bán xuyên biên giới",
  localSeller: "Người bán địa phương",
  billOverView: "Tổng quan hóa đơn",
  transactionOverview: "Tổng quan giao dịch",
  dbsSeller: "Người bán DBS",
  paymentTime: "Thời gian thanh toán",
  orderId: "ID đơn hàng",
  ietTip: "Tổng số chi phí, số tiền thanh toán thực tế sẽ dựa vào chi tiết hóa đơn",
  incomeTip:
    "Tổng số các khoản chi phí tích cực, số tiền thanh toán thực tế sẽ dựa vào chi tiết hóa đơn",
  expenditureTip:
    "Tổng số các khoản chi phí tiêu cực, số tiền thanh toán thực tế sẽ dựa vào chi tiết hóa đơn",
  totalAmount: "Tổng số tiền",
  income: "Thu nhập",
  expenditure: "Chi phí",
  transactionDate: "Ngày giao dịch",
  feeName: "Tên chi phí",
  transactionNum: "Số giao dịch",
  amount: "Số tiền",
  accountingPeriod: "Kỳ kế toán",
  expenseType: "Loại chi phí",
  expenseName: "Tên chi phí",
  expenseAmount: "Số tiền chi phí",
  salesAmount: "Số tiền bán hàng",
  openingBalance: "Số dư đầu kỳ",
  total: "Tổng",
  returnRefundAmount: "Số tiền hoàn trả/hủy",
  returnRefundshopp: "Hoàn tiền mặt hàng đảo ngược",
  returnRefundBug: "Hoàn phí vận chuyển đã thanh toán bởi khách hàng (bao gồm VAT)",
  actSubsidyRefund: "Hoàn tiền hỗ trợ chiến dịch",
  actSubsidyComRefund: "Hoàn tiền hỗ trợ hoa hồng",
  returnRefundSure: "Hoàn phí vận chuyển đã thanh toán bởi người bán (bao gồm VAT)",
  PlatformShopp: "Hoàn giảm giá phiếu của người bán",
  platformcommission: "Hoa hồng nền tảng Youpik đảo ngược (bao gồm VAT 7%)",
  shopkeepercommission: "Hoa hồng tiếp thị Youpik đảo ngược (bao gồm VAT 7%)",
  compensatecommission: "Hoàn phí nền tảng khuyến mại đã hoàn lại cho người bán",
  paymentAmount: "Số tiền thanh toán",
  closingBalance: "Số dư cuối kỳ",
  download: "Tải xuống",
  bankAddress: "Địa chỉ ngân hàng mở",
  serviceCharge: "Phí dịch vụ",
  handlingRate: "Phần trăm phí dịch vụ",
  shopDiscount: "Giảm giá phiếu của người bán",
  actualFreight: "Phí vận chuyển do người bán thanh toán (bao gồm VAT 7%)",
  ActualPaymentAmount: "Số tiền thanh toán thực tế",
  date: "Ngày",
  username: "Tên người dùng",
  reportName: "Tên bảng",
  status: "Trạng thái",
  history: "Lịch sử",
  itemPriceCredit: "Tín dụng giá mặt hàng",
  ShopCoupon: "Giảm giá phiếu của người bán",
  CompensatedPlatform: "Phí nền tảng khuyến mại đã hoàn lại cho người bán",
  Compensatedfreight: "Phí vận chuyển nền tảng khuyến mại đã hoàn lại cho người bán",
  CompensatedPlatformrefund: "Hoàn lại phiếu nền tảng khuyến mại",
  Compensatedfreightrefund: "Hoàn phí vận chuyển nền tảng khuyến mại đã hoàn lại cho người bán",
  shippingFeePaidbyCustomer: "Phí vận chuyển do khách hàng thanh toán (bao gồm VAT)",
  commission: "Hoa hồng nền tảng Youpik (bao gồm VAT 7%)",
  youpikMarketingCommission: "Hoa hồng tiếp thị Youpik (bao gồm VAT 7%)",
  PaymentAmount: "Số tiền hiện tại",
  paymentFee: "Phí thanh toán（VAT）",
  financeSettlement: "Trạng thái thanh toán là tổng số tiền hóa đơn hiện tại đã thanh toán",
  financeNoSettlement: "Trạng thái thanh toán là tổng số tiền hóa đơn hiện tại đang chờ thanh toán",
  ShowCurrentBill: "Hiển thị hóa đơn hiện tại",
  CompensationAmountTips:
    "Do sự cố hệ thống, số tiền thanh toán thiếu sẽ được bù trong kỳ hiện tại",
  AmountNotDeductedTips:
    "Chi phí chưa thanh toán và một số số tiền chưa thanh toán phát sinh từ việc đảo ngược kỳ trước",
  openingBalanceTips:
    "Tổng số chi tiết trong hóa đơn hiện tại (trừ số tiền chưa thanh toán trước đây) Đơn hàng COD",
  dbsOpeningBalanceTips:
    "Tổng số chi tiết trong hóa đơn hiện tại (trừ số tiền chưa thanh toán trước đây) Đơn hàng COD, giá bán sản phẩm, phí vận chuyển của người mua, phiếu giảm giá cửa hàng, thanh toán phí xử lý không có thanh toán; bạn có thể xem phần này trong bảng chi tiết đã xuất",
  closingBalanceTips: "số tiền chưa thanh toán kỳ trước + số tiền kỳ hiện tại",
  salesNO: "",
  CompensationAmount: " Số tiền thanh toán dưới",
  ActivitySubsidy: "Hỗ trợ chiến dịch",
  SubsidizedPlatform: "Hoa hồng bồi thường ",
  overpaymentDeduction: "Số tiền chưa thanh toán kỳ trước",
  AmountNotDeducted: "Số tiền thanh toán dư",

  // lazada
  lazadaOrders: "Tổng số đơn hàng lazada",
  totalCommission: "Tổng hoa hồng",
  ordersOfLastMonth: "Số đơn hàng tháng trước",
  commissionLastMonth: "Hoa hồng tháng trước",
  thisMonthOrders: "Số đơn hàng tháng này",
  thisMonthCommission: "Hoa hồng tháng này",
  reconciliationCycle: "chu kỳ đối chiếu",
  collection: "Bộ sưu tập",
  collectionRecord: "Hồ sơ thu",
  received: "Đã nhận",
  unReceived: "Chưa thu",
  partialReceived: "Thu một phần",
  inputCollectionAmount: "Vui lòng nhập số tiền thanh toán",
  itemSource: "nguồn hàng",
  lazadaPromoteUnion: "liên minh khuyến mãi lazada",
  commissionAmount: "Số tiền hoa hồng"
};
