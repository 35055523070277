export default {
  // 提现
  confirmStatus: "确认将状态变为已打款？",
  WalletBalance: "钱包余额",
  orderBill: "订单账单",
  rewardBill: "奖励账单",
  CompleteTime: "完成时间",
  WithdrawalApplication: "提现申请",
  AmountReceived: "到账金额",
  WithdrawalNumber: "提现编号",
  WithdrawalAmounts: "提现金额",
  PaymentSuccessful: "打款成功",
  CanceledPayment: "关闭打款",
  "Total Cashback (L0,1)": "已确认总收益",
  Unpaid: "未确认总收益",
  tax: "税",
  WithdrawalTime: "提现时间",
  AmountBeforeTax: "税前金额",
  // 下载翻译发票
  downloadInvoice: "下载发票",
  batchDownloadInvoice: "批量下载发票",
  selectBillTime: "请选择账单周期",
  CompressedPackage: "PDF zip",
  // 注册活动奖励翻译
  RewardType: "奖励类型",
  UpgradingReward: "升级奖励",
  RegisteringReward: "注册奖励",
  // 店主结算翻译
  shopId: "店主id",
  shopInviteCode: "店主邀请码",
  upgradeTime: "升级时间",
  upgradeLevel: "升级等级",
  preTaxCommission: "税前佣金",
  afterTaxCommission: "税后佣金",
  orderAmount: "订单账单金额",
  upgradeId: "奖励账单id",
  upgradeAmount: "奖励账单金额",
  downloadOrderBill: "下载订单账单",
  downloadUpgareBill: "下载升级账单",

  // 卖家结算
  // 翻译
  LocalSellers: "本地商家",
  payoutReason: "退款失败原因",
  InPayment: "打款中",
  BillStatusPrompt: "下列账单状态为打款中，无法变更状态",
  Processing: "处理中",
  allTransfer: "共转账",
  strip: "条",
  ManualPayment: "手动打款",
  ksherPayout: "ksher payout",
  Reprint: "重新打款",
  noDBS: "非DBS卖家账单",
  DBS: "DBS卖家账单",
  PaymentFailed: "打款失败",
  transfer: "转账",
  reTransfer: "重新转账",
  mode: "方式",
  BatchTransfer: "批量转账",
  transferType: "请选择转账类型",
  thisPage: "本页",
  ServiceCharge: "手续费",
  TotalTransfer: "共转账",
  ConfirmTransfer: "确定转账",
  pleaseSelect: "请选择",
  BillAmount: "账单金额",
  transferAmount: "转账金额",
  historicalArrears: "历史欠款",
  cumulativeAmount: "累计金额",
  notMeetingThePaymentConditions: "不满足打款条件",

  //

  PaymentPreview: "打款概览",
  AlreadyPaymentAmount: "已结算",
  NotPaymentAmount: "未结算",
  All: "全部",
  SettlementStatus: "结算状态",
  BillingId: "账单ID",
  BillingCycle: "账单周期",
  SellerType: "商家类型",
  SellerId: "商家ID",
  SellerName: "商家名称",
  StatementCycle: "对账单周期",
  SettlementAmount: "本期账单金额",
  PaymentAccountName: "打款账户名",
  PaymentAccountNumber: "打款账号",
  PaymentBank: "打款银行",
  BankCode: "Bank Code",
  BillingDetail: "账单详情",
  ChagneStatus: "变更状态",
  LocalPersonalSeller: "本地个人卖家",
  LocalEnterpriseSeller: "本地企业卖家",
  CrossBorderEnterpriseSeller: "跨境企业卖家",
  SellerSettlement: "商家结算",
  AlreadySettlementTip: "确定将结算状态变更为已结算?",
  NotSettlementTip: "确定将结算状态变更为未结算?",
  LastWeek: "最近一周",
  LastOneMonth: "最近一个月",
  LastThreeMarch: "最近三个月",
  ToBePayment: "待打款",
  AlreadyPeyment: "已打款",
  // 卖家账单详情
  OrderNo: "订单号",
  ItemId: "商品ID",
  ItemSkuId: "商品SKU_ID",
  OrderDate: "订单日期",
  WholesalePrice: "零售价格",
  ItemNum: "商品数量",
  Freight: "运费",
  Status: "商品状态",
  PlatformDiscount: "平台优惠",
  SellerDiscount: "商家优惠",
  ActualAmountPaid: "实际支付金额",
  PaymentProcessingFee: "支付手续费",
  PlatformCommission: "平台佣金",
  PromotionCommission: "店主佣金",
  OrderDetail: "订单详情",
  SellerBillingDetail: "卖家账单详情",
  YoupikFee: "Youpik",

  // 店主结算
  ShopkeeperId: "店主ID",
  ShopkeeperName: "店主名称",
  ShopkeeperLevel: "店主等级",
  LastCycleDeductionCommisson: "往期未结算金额",
  SLIReward: "SLI Reward",
  CampaignRewards: "Campaign Rewards",
  PastCompensationAmount: "Past compensation amount",
  ReverseYoupik: "Reverse Youpik Marketing Commission",
  ShopkeeperSettlement: "店主结算",
  ExportTip: "确定要导出该文件吗?",
  NotSupportFile: "不支持该文件类型",
  ImportTip: "确定要导入数据吗?",
  // 店主账单详情
  ItemAmount: "商品金额",
  ItemActualAmountPaid: "商品实付金额",
  commissionRate: "佣金率",
  PreTaxCommissionAmount: "税前佣金金额",
  NextTaxCommissionAmout: "税后佣金金额",
  ShopkeeperSubCommission: "店主分佣",
  Detail: "详情",
  OrdinaryItem: "普通商品",
  GifItem: "礼包商品",
  ypcPlus: "礼包PLUS商品",
  lazadaGood: "Lazada商品",
  SubCommissionAmount: "分佣金额",
  ShopkeeperBillingDetail: "店主账单详情",
  AlreadySettlement: "已结算",
  NotSettlement: "未结算",
  ItemType: "商品类型",
  crossBorderSeller: "跨境商家",
  localSeller: "本地商家",
  billOverView: "账单概览",
  transactionOverview: "交易概览",
  dbsSeller: "DBS商家",
  paymentTime: "打款时间",
  orderId: "订单ID",
  ietTip: "所有费用项金额之和,实际结算金额以账单详情为准",
  incomeTip: "所有正的费用项金额之和,实际结算金额以账单详情为准",
  expenditureTip: "所有负的费用项金额之和,实际结算金额以账单详情为准",
  totalAmount: "总金额",
  income: "收入",
  expenditure: "支出",
  transactionDate: "交易日期",
  feeName: "费用名称",
  transactionNum: "交易号",
  amount: "金额",
  accountingPeriod: "账期",
  expenseType: "费用类型",
  expenseName: "费用名称",
  expenseAmount: "费用金额",
  salesAmount: "销售金额",
  // openingBalance: '期初余额',
  total: "总计",
  returnRefundAmount: "退货退款金额",
  returnRefundshopp: "商品销售价退款",
  returnRefundBug: "买家下单支付的运费退款（VAT）",
  actSubsidyRefund: "活动补贴退款",
  actSubsidyComRefund: "补贴的平台佣金退款",
  returnRefundSure: "实际运费退款",
  PlatformShopp: "店铺优惠券退款",
  platformcommission: "平台佣金退款",
  shopkeepercommission: "店主佣金退款",
  compensatecommission: "补偿的平台优惠券退款",
  paymentAmount: "打款金额",
  closingBalance: "期终余额",
  download: "下载",
  bankAddress: "开户行地址",
  serviceCharge: "手续费",
  handlingRate: "手续费率",
  shopDiscount: "店铺优惠券金额",
  actualFreight: "实际运费",
  ActualPaymentAmount: "实际打款金额",
  date: "日期",
  username: "用户名",
  reportName: "报告名称",
  status: "状态",
  history: "历史记录",
  itemPriceCredit: "商品销售价",
  ShopCoupon: "店铺优惠劵",
  CompensatedPlatform: "补偿的平台优惠券",
  Compensatedfreight: "补偿的平台运费券",
  CompensatedPlatformrefund: "补偿的平台优惠券退款",
  Compensatedfreightrefund: "补偿的平台运费券退款",
  salesNO: "",
  overpaymentDeduction: "往期未结算金额",
  SubsidizedPlatform: "补贴的平台佣金",
  sellerVoucherDiscount: "店铺优惠券金额总和",
  CompensationAmountTips: "因系统问题，导致少打款的金额，在本期内补上",
  AmountNotDeductedTips: "往期逆向产生未结算的费用以及部分尚未结算的金额",
  openingBalanceTips: "本期账单内所有明细之和（往期未结算金额除外）",
  dbsOpeningBalanceTips:
      "本期账单内所有明细之和（往期未结算金额除外）COD订单，商品销售价，买家支付运费，店铺优惠券，支付手续费不进账单结算；您可以在导出的明细表格中查看这一部分数据    ",
  closingBalanceTips: "往期未结算金额+本期金额",
  ActivitySubsidy: "活动补贴",
  CompensationAmount: "往期补偿金额",
  AmountNotDeducted: "往期未扣金额",
  openingBalance: "期初余额",
  shippingFeePaidbyCustomer: "买家支付运费（VAT）",
  commission: "平台佣金(VAT)",
  youpikMarketingCommission: "店主佣金(VAT)",
  paymentFee: "支付手续费(VAT)",
  PaymentAmount: "本期金额",
  financeSettlement: "结算状态为已打款的本期账单金额之和",
  financeNoSettlement: "结算状态为待打款的本期账单金额之和",
  ShowCurrentBill: "展示本期账单",
  // lazada
  lazadaOrders: "Lazada订单数",
  totalCommission: "总佣金",
  ordersOfLastMonth: "上月订单数",
  commissionLastMonth: "上月佣金",
  thisMonthOrders: "本月订单数",
  thisMonthCommission: "本月佣金",
  reconciliationCycle: "对账周期",
  collection: "收款",
  collectionRecord: "收款记录",
  received: "已收款",
  unReceived: "未收款",
  partialReceived: "部分收款",
  inputCollectionAmount: "输入收款金额",
  itemSource: "商品来源",
  lazadaPromoteUnion: "lazada推广联盟",
  commissionAmount: "佣金金额"
};
