export default {
  openInflowReward: "Phần thưởng khuyến mãi video hoạt động",
  lastModifyTime: "Thời gian chỉnh sửa cuối cùng",
  setUpPersonner: "Người chỉnh sửa",
  inflowSet: "Cài đặt khuyến mãi video",
  numberOfProOrder: "Đơn hàng ≥",
  GMV: "GMV≥",
  NumberOfVideoViews: "Lượt xem video ≥",
  inflowAmount: "Số tiền thưởng",
  saveModify: "Lưu",
  abandonModify: "Hủy bỏ",
  confirmGiveUpTitle: "Xác nhận hủy bỏ thay đổi",
  confirmGiveUpTips: "Sau khi hủy bỏ, các thay đổi sẽ không có hiệu lực.",
  confirmSaveTitle: "Xác nhận lưu thay đổi",
  confirmSaveTips:
    "Sau khi lưu, APP sẽ đồng bộ nội dung đã chỉnh sửa để hiển thị hướng dẫn phần thưởng khuyến mãi video.",
  continueTip: "Bạn có muốn tiếp tục không?",
  continue: "Tiếp tục",
  inflowed: "đã khuyến mãi"
};
