export default {
  supplierName: "ชื่อซับพลายเออร์",
  supplierId: "รหัสซับพลายเออร์",
  numberOfStores: "จำนวนร้านค้า",
  storeName: "ชื่อร้านค้า",
  addTime: "เวลาที่เพิ่มเข้าไป",
  storeNum: "รหัสร้านค้า",
  unprocessedSampleRequest: "คำขอสินค้าตัวอย่างที่ยังไม่ได้รับการดำเนินการ",
  shipLink: "ลิงก์จัดส่งสินค้า",
  deleteLinkTips:
    "หลังจากลบแล้ว ลิงก์นี้จะไม่สามารถใช้งานและข้อมูซัพพลายเออร์จะหายไป คุณแน่ใจหรือไม่ว่าต้องการลบ",
  deleteShopTips:
    "หลังจากลบแล้ว ซับพลายเออร์จะไม่สามารถจัดส่งสินค้าไปยังร้านค้าได้ คุณแน่ใจที่จะลบแล้ว หรือไม่",
  move: "ย้าย",
  operationRecords: "ประวัติการดำเนินการ",
  cooperativeProductQuantity: "จำนวนสินค้าที่เข้าร่วม",
  activityProductQuantity: "จำนวนสินค้าในกิจกรรม",
  canAdd: "เพิ่ม",
  added: "เพิ่มแล้ว",
  belongToApp: "เป็นของซับพลายเออร์รายอื่น",
  addSuccess: "เพิ่มสำเร็จ",
  noticeTips: "หมายเหตุ: สามารถเพิ่มได้เพียงซับพลายเออร์ที่เข้าร่วมเท่านั้น",
  moveId: "กรุณาเลือกรหัสซับพลายเออร์ที่จะย้ายไป",
  supplier: "ซับพลายเออร์",
  inputShopNameOrNum: "กรุณาใส่ชื่อร้านค้า หรือรหัสร้านค้าที่จะทำการค้นหา",
  inputSupplierId: "ไม่มีซัพพลายเออร์นี้",
  shelfStatus: "สถานะร้านค้า",
  immediateProcessing: "เชื่อมต่อเดี๋ยวนี้",
  lastSampleDate: "เวลาการขอตัวอย่างครั้งล่าสุด",
  sample3day: "รอการอนุมัติ (ภายใน 3 วัน)",
  merchantContactInformation: "เวลาการขอตัวอย่างครั้งล่าสุด",
  noBindPer: "ร้าน x มีการขอสินค้าตัวอย่างแต่ยังไม่ได้เชื่อมกับซัพพลายเออร์,กรุณาดำเนินการทันที!",
  willBindNew: "หลังจากสร้าง, xxx จะเชื่อมต่อกับซัพพลายเออร์นี้",
  waitBindShop: "ร้านค้ารอการผูกมัด",
  pendingMerchantReviewNum: "รอการอนุมัติจากร้านค้า",
  newSupplyChain: "ซัพพลายเออร์ใหม่",
  bindSupplyChain: "เชื่อมต่อกับซัพพลายเออร์",
  replacingSupplyChain: "เปลี่ยนซัพพลายเออร์",
  cooperationTime: "เวลา"
};
