export default {
  supplierName: "Supplier Name",
  supplierId: "Supplier ID",
  numberOfStores: "Store Quantity",
  storeName: "Store Name",
  addTime: "Adding Time",
  storeNum: "Store ID",
  unprocessedSampleRequest: "Unprocessed Sample Applications",
  shipLink: "Shipping Link",
  deleteLinkTips:
    "After deletion, the link becomes invalid and the supplier information cannot be restored. Confirm to delete? ",
  deleteShopTips:
    "After deletion, the supplier cannot deliver samples to this store. Are you sure you want to delete?",
  move: "Move",
  operationRecords: "Operation Records",
  cooperativeProductQuantity: "Number of cooperation products",
  activityProductQuantity: "Number of products in campaign",
  canAdd: "Add",
  added: "Added",
  belongToApp: "Already bound to other suppliers",
  addSuccess: "Added successfully",
  noticeTips: "Note: Only suppliers you have cooperated with can be added",
  moveId: "Please select the Supplier ID that you want to move to",
  supplier: "Supplier",
  inputShopNameOrNum: "Please enter store name or store ID to search",
  inputSupplierId: "The supplier name does not exist.",
  shelfStatus: "Merchant status",
  immediateProcessing: "Bind Now",
  lastSampleDate: "Last Sample Request Time",
  sample3day: "Pending Approval (last 3d)",
  merchantContactInformation: "Contact Number",
  noBindPer: "x shop(s) are pending to bind with suppliers!",
  willBindNew: "After creation, xxx will bind with this supplier",
  waitBindShop: "Shop Pending Binding",
  pendingMerchantReviewNum: "Pending Approval",
  newSupplyChain: "New Supplier",
  bindSupplyChain: "Bind Supplier",
  replacingSupplyChain: "Change Supplier",
  cooperationTime: "Cooperate Time"
};
