export default {
  达人表现: "{name}'s达人表现",
  我的达人分析: "我的达人分析",
  日期区间: "日期区间",
  PIC: "PIC",
  "GMV（预估）": "GMV（预估）",
  "TAP佣金收入（预估）": "TAP佣金收入（预估）",
  "GMV（完成）": "GMV（完成）",
  "TAP佣金收入（完成）": "TAP佣金收入（完成）",
  PIC榜单: "PIC榜单",
  "All PIC达人表现": "All PIC达人表现",
  PIC达人分析: "PIC达人分析"
};
