import React, { memo, useEffect, useMemo, useState } from "react";
import { Spin, InputNumber } from "antd";
import { EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import userManage from "@/api/userManage";
import styles from "./index.less";

interface IEditInputProps {
  dataSource: any;
  setDataSource: any;
  current: any;
  index: number;
  reloadTable: any;
}

const EditSortInput: React.FC<IEditInputProps> = ({
  current,
  dataSource,
  setDataSource,
  index,
  reloadTable
}) => {
  const itemSortEdit = useMemo(() => dataSource[index].itemSortEdit || false, [dataSource, index]);
  const itemSort = useMemo(() => dataSource[index].itemSort, [dataSource, index]);
  const [tempItemSort, setTempItemSort] = useState<string>("");
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  useEffect(() => {
    setTempItemSort(itemSort);
    console.log("aaa", index, dataSource[index].productId, dataSource[index].itemSort, itemSort, { productId: dataSource[index].productId, itemSort: dataSource[index].itemSort });
  }, [itemSort]);

  useEffect(() => {
    console.log("aaa dataSource", dataSource);
  }, [dataSource]);

  const reloadData = () => {
    setDataSource(JSON.parse(JSON.stringify(dataSource)));
  };

  const handleInputEdit = () => {
    dataSource[index].itemSortEdit = true;
    reloadData();
  };

  const handleInputChange = (val: any) => {
    const value = val;
    setTempItemSort(value);
  };

  const handleInputConfirm = () => {
    setConfirmLoading(true);
    const { id } = dataSource[index];
    userManage
      .requestUpdateItemSetProductSort({
        itemId: id || "",
        itemSetId: current.id || "",
        itemSort: tempItemSort || 0
      })
      .then((res: any) => {
        const { code } = res;
        if (code === 200) {
          reloadTable();
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  const handleInputClose = () => {
    dataSource[index].itemSortEdit = false;
    reloadData();
  };

  return itemSortEdit ? (
    <Spin spinning={confirmLoading}>
      <div className={styles.edit_sort_input}>
        <InputNumber
          precision={0}
          min={0}
          max={9999}
          className={styles.input}
          value={tempItemSort}
          onChange={handleInputChange}
        />
        <CheckCircleOutlined className={styles.confirm} onClick={handleInputConfirm} />
        <CloseCircleOutlined className={styles.close} onClick={handleInputClose} />
      </div>
    </Spin>
  ) : (
    <div className={styles.edit_sort_input}>
      <div className={styles.label}>{itemSort}</div>
      <EditOutlined className={styles.edit} onClick={handleInputEdit} />
    </div>
  );
};

export default memo(EditSortInput);
