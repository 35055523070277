const sheetRoute: IRouteProps[] = [
  {
    path: "/sheet/:type",
    name: "uChoiceSheet",
    component: "./sheet",
    layout: false,
    hideInMenu: true
  }
];

export default sheetRoute;
