import { history, useModel } from "umi";
import { collectionReload } from "@/pages/collection";
import styles from "./index.less";

const CustomMenuItem = ({ item, defaultDom, menuProps }: any) => {
  const { initialState, setInitialState } = useModel("@@initialState");
  const { path, meta, pro_layout_parentKeys, routes } = item;
  const { matchMenuKeys } = menuProps;
  const active = matchMenuKeys[0] === path;

  return pro_layout_parentKeys.length === 0 && meta && meta.icon && meta.activeIcon ? (
    <div
      onClick={() => {
        if (!routes || routes.length === 0) {
          history.push(path);
        }
      }}
      className={styles.menu_item_container}>
      <img
        className={styles.icon}
        src={active ? meta.activeIcon : meta.icon}
        style={{ marginRight: initialState?.settings.collapsed ? 24 : 10 }}
      />
      {defaultDom}
    </div>
  ) : (
    <div
      onClick={() => {
        const { pathname } = window.location;
        if (
          pathname === "/product-manage/tt-collections" &&
          path === "/product-manage/tt-collections"
        ) {
          collectionReload();
        } else {
          history.push(path);
        }
      }}>
      {defaultDom}
    </div>
  );
};

export default CustomMenuItem;
