import type { ActionType, ProColumns, ProFormInstance } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Modal, Spin, message, Image, InputNumber, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useIntl } from "umi";
import { useEffect, useRef, useState } from "react";
import userManage from "@/api/userManage";
import { generateCollectionUrl, handlePostData } from "./common";
import type { CurrentRecord } from "./CollectionDetail";
import CollectionDetail from "./CollectionDetail";
import CreateCollectionModal from "../tt-products/components/CreateCollectionModal";
import { getShareLinkNew } from "@/utils/getShareLink";
import { copy } from "@/utils/copy";
import styles from "./index.less";
import { ExpandableText } from "@/components/ExpandableText";
import { ShareModal } from "./components/ShareModal";
import NumberInput from "@/components/NumberInput";

let collectionReloadFn: any = null;

export const collectionReload = () => {
  if (collectionReloadFn) {
    collectionReloadFn();
  }
};

export default () => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const intl = useIntl();
  const [modal, contextHolder] = Modal.useModal();

  const [showMode, setShowMode] = useState<"list" | "detail">("list");

  const [delLoading, setDelLoading] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);

  const [createCollectionModalVisible, setCreateCollectionModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<CurrentRecord>({
    record: {}
  });
  const currentRecordRef = useRef<any>({});
  const [shareModalVisible, setShareModalVisible] = useState(false);

  const handleDelCollection = (record: any) => {
    const { itemSetName } = record;
    modal.confirm({
      title: intl.formatMessage({ id: "ttSample.确认删除商品集" }),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "ttSample.您正在删除【商品集合名称】，删除后用户端分享链接将失效，你还要继续吗？"
              },
              { setName: itemSetName || "" }
            )
          }}
        />
      ),
      okText: intl.formatMessage({ id: "button.Confirm" }),
      cancelText: intl.formatMessage({ id: "button.Cancel" }),
      onOk() {
        setDelLoading(true);
        userManage
          .requestDeletedItemSet({
            itemSetId: record.id
          })
          .then((result: any) => {
            if (result.code === 200) {
              message.success(intl.formatMessage({ id: "ttSample.删除成功！" }));
              actionRef.current?.reload();
            }
          })
          .catch((err: any) => {
            console.log(err);
          })
          .finally(() => {
            setDelLoading(false);
          });
      }
    });
  };

  const columns: ProColumns<any>[] = [
    {
      title: intl.formatMessage({ id: "ttSample.商品集ID" }),
      dataIndex: "id",
      align: "center",
      width: 200,
      fieldProps: {
        placeholder: intl.formatMessage({ id: "common.pleaseInput" }),
        maxLength: 20
      },
      renderFormItem: (schema, config: any, form, action) => {
        return <NumberInput allowClear {...config.fieldProps} />;
      }
    },
    {
      title: intl.formatMessage({ id: "ttSample.名称" }),
      width: 200,
      dataIndex: "itemSetName",
      formItemProps: {
        label: intl.formatMessage({ id: "ttSample.集合名称" })
      },
      fieldProps: {
        placeholder: intl.formatMessage({ id: "common.pleaseInput" })
      },
      renderText(text, record, index, action) {
        return text ? <span style={{ whiteSpace: "pre-wrap" }}>{text}</span> : "-";
      }
    },
    {
      title: intl.formatMessage({ id: "ttSample.简介" }),
      width: 300,
      dataIndex: "description",
      hideInSearch: true,
      renderText(text, record, index, action) {
        return record.recommendType === 1 ? (
          <span style={{ whiteSpace: "pre-wrap" }}>{record.description}</span>
        ) : (
          <Image
            width={240}
            height={100}
            src={record.description}
            style={{ objectFit: "contain" }}
          />
        );
      }
    },
    {
      title: intl.formatMessage({ id: "tagManage.Create_Time" }),
      width: 140,
      dataIndex: "createTime",
      hideInSearch: true,
      renderText(text, record, index, action) {
        return text ? moment(text).format("YYYY/MM/DD HH:mm:ss") : "-";
      },
      align: "center"
    },
    {
      title: intl.formatMessage({ id: "ttSample.创建人" }),
      width: 140,
      dataIndex: "creator",
      align: "center",
      fieldProps: {
        placeholder: intl.formatMessage({ id: "common.pleaseInput" })
      }
    },
    {
      title: intl.formatMessage({ id: "ttSample.有效商品数" }),
      width: 140,
      dataIndex: "validItemCount",
      hideInSearch: true,
      align: "center",
      renderText(text, record, index, action) {
        return (
          <a
            style={{ padding: "10px" }}
            href=''
            onClick={(e: any) => {
              e.preventDefault();
              setShowMode("detail");
              setCurrentRecord({
                record
              });
            }}
          >
            {text}
          </a>
        );
      }
    },
    {
      title: intl.formatMessage({ id: "collection.失效商品" }),
      width: 140,
      dataIndex: "invalidItemCount",
      hideInSearch: true,
      align: "center",
      renderText(text, record, index, action) {
        return (
          <a
            style={{ padding: "10px" }}
            href=''
            onClick={(e: any) => {
              e.preventDefault();
              setShowMode("detail");
              setCurrentRecord({
                record,
                tabKey: "invalid"
              });
            }}
          >
            {text}
          </a>
        );
      }
    },
    {
      title: intl.formatMessage({ id: "collection.待处理商品" }),
      width: 140,
      dataIndex: "pendingItemCount",
      hideInSearch: true,
      align: "center",
      renderText(text, record, index, action) {
        return (
          <a
            style={{ padding: "10px" }}
            href=''
            onClick={(e: any) => {
              e.preventDefault();
              setShowMode("detail");
              setCurrentRecord({
                record,
                tabKey: "pending"
              });
            }}
          >
            {text}
          </a>
        );
      }
    },
    {
      title: intl.formatMessage({ id: "collection.商品集同步状态" }),
      hideInSearch: true,
      width: 200,
      render: (value, item, index) => {
        switch (item.tapStatus) {
          case 1:
            return (
              <span style={{ color: "purple" }}>
                {intl.formatMessage({ id: "collection.同步中…大概需要3分钟" })}
              </span>
            );
          case 2:
            return (
              <span style={{ color: "red" }}>
                {intl.formatMessage({ id: "collection.同步失败，请联系技术人员" })}
              </span>
            );
          case 3:
            return (
              <span style={{ color: "green" }}>
                {intl.formatMessage({ id: "collection.同步完成" })}
              </span>
            );
          case 4:
            return (
              <span style={{ color: "lightgray" }}>
                {intl.formatMessage({ id: "collection.无需同步" })}
              </span>
            );
          default:
            return "";
        }
      }
    },
    {
      title: intl.formatMessage({ id: "button.Action" }),
      valueType: "option",
      key: "option",
      fixed: "right",
      width: 214,
      align: "center",
      renderText: (text, record, index, action) => [
        record.validItemCount ? (
          <a
            key='share'
            onClick={(e) => {
              setCurrentRecord({
                record
              });
              setShareModalVisible(true);
            }}
          >
            {intl.formatMessage({ id: "ttSample.分享" })}
          </a>
        ) : (
          <span style={{ color: "#999" }}>{intl.formatMessage({ id: "ttSample.分享" })}</span>
        ),
        <a
          key='manage'
          onClick={(e) => {
            // e.preventDefault();
            // setShowMode("detail");
            setCurrentRecord({
              record
            });
            setCreateCollectionModalVisible(true);
          }}
        >
          {intl.formatMessage({ id: "button.Edit" })}
        </a>,
        <a
          key='manage'
          onClick={(e) => {
            e.preventDefault();
            setShowMode("detail");
            setCurrentRecord({
              record
            });
            currentRecordRef.current = record;
          }}
        >
          {intl.formatMessage({ id: "ttSample.管理商品" })}
        </a>,
        <a
          key='del'
          onClick={(e) => {
            e.preventDefault();
            handleDelCollection(record);
          }}
          style={{ color: "red" }}
        >
          {intl.formatMessage({ id: "button.Delete" })}
        </a>
      ]
    }
  ];

  const handleTableRequest = async (params: any = {}, sort: any, filter: any) => {
    console.log("params", params);
    const convertValues = handlePostData(params);
    const requestResult: any = await userManage.requestGetItemSetList(convertValues);
    if (requestResult.code === 200 && requestResult.result) {
      const { list, total } = requestResult.result || {};
      list.forEach((item: any) => {
        item.collectionUrl = generateCollectionUrl(item.id);
      });
      return {
        data: list || [],
        success: true,
        total: total || 0
      };
    }
    return {
      data: [],
      success: false,
      total: 0
    };
  };

  const handleCollectionBack = () => {
    setShowMode("list");
    actionRef.current?.reload();
  };

  const handleCollectionReload = () => {
    if (showMode === "list") {
      return;
    }
    setShowMode("list");
    // @ts-ignore
    actionRef.current?.reset();
  };

  useEffect(() => {
    collectionReloadFn = handleCollectionReload;
    return () => {
      collectionReloadFn = null;
    };
  }, [handleCollectionReload]);

  const handleCreateCollectionModalOk = () => {
    setCreateCollectionModalVisible(false);
    actionRef.current?.reload();
  };

  return (
    <>
      <div
        className={styles.table_container}
        style={{ visibility: showMode === "list" ? "visible" : "hidden" }}
      >
        <div className={styles.title_container}>
          <div className={styles.title}>{intl.formatMessage({ id: "menu.商品管理.商品集" })}</div>
          <Button
            key='add_collection'
            onClick={() => {
              setCurrentRecord({
                record: null
              });
              setCreateCollectionModalVisible(true);
            }}
            type='primary'
          >
            {intl.formatMessage({ id: "ttSample.创建集合" })}
          </Button>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#fff", visibility: showMode === "list" ? "visible" : "hidden" }}
        className={styles.pre_table_container}
      >
        <Spin spinning={delLoading || generateLoading}>
          <ProTable<any>
            scroll={{ x: 1600, y: showMode === "list" ? undefined : 0 }}
            columns={columns}
            actionRef={actionRef}
            formRef={formRef}
            request={handleTableRequest}
            editable={{
              type: "multiple"
            }}
            search={{
              labelWidth: "auto",
              searchText: intl.formatMessage({ id: "button.Search" }),
              resetText: intl.formatMessage({ id: "button.Reset" }),
              collapseRender: () => null,
              collapsed: false
            }}
            options={{
              reload: true,
              setting: false,
              density: false
            }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: 10,
              showSizeChanger: true,
              onChange: (page) => console.log(page),
              showTotal(total, range) {
                return `${intl.formatMessage({ id: "common.total" })} ${total}`;
              }
            }}
            dateFormatter='string'
          />
        </Spin>
      </div>
      {showMode === "detail" && (
        <div className={styles.collection_detail_container}>
          <CollectionDetail current={currentRecord} handleBack={handleCollectionBack} />
        </div>
      )}
      <CreateCollectionModal
        mode='no_products'
        modifyData={currentRecord.record}
        selectProductIds={[]}
        visible={createCollectionModalVisible}
        handleCancel={() => setCreateCollectionModalVisible(false)}
        handleOk={handleCreateCollectionModalOk}
      />
      {currentRecord.record && (
        <ShareModal
          visible={shareModalVisible}
          handleCancel={() => setShareModalVisible(false)}
          record={currentRecord.record}
        />
      )}

      {contextHolder}
    </>
  );
};
