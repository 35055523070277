export default {
  Actual_Sales: "Actual Sales",
  Export: "Export",
  English: "English ",
  Thai: "Thai",
  Vnvi: "Vietnamese",
  Please_filter_the_Youpik_category_and_Lazada_category_first:
    "Please filter the Youpik category and Lazada category first",
  Ranking_Setting: "Ranking Setting",
  Youpik_Category: "Youpik Category",
  Lazada_Category: "Lazada Category",
  Customized_Category: "Customized Category",
  Add: "Add",
  All: "All",
  Not_start: "Not start",
  Ongoing: "Ongoing",
  Closed: "Closed",
  Number_of_Products: "Number of Products",
  Create_Time: "Create Time",
  Valid_Time: "Valid Time",
  Ranking_Link: "Ranking Link",
  Status: "Status",
  Action: "Action",
  Edit: "Edit",
  Delete: "Delete",
  Rank: "Rank",
  Search: "Search",
  Reset: "Reset",
  Preview: "Preview",
  Total: "Total",
  Page: "20/ Page",
  Go_to___: "Go to...",
  Remark: "Remark",
  Save: "Save",
  Timed: "Timed",
  Open: "Open",
  Close: "Close",
  Rename: "Rename",
  Product_Image: "Product Image",
  Product_ID: "Product ID",
  Product_Name: "Product Name",
  Sale_Price: "Sale Price",
  Commission_Rate: "Commission Rate",
  PDP_Page: "PDP Page",
  All_best_seller_product_related_to_this_category_will_be_deleted__is_it_confirmed_:
    "All best seller product related to this category will be deleted, is it confirmed?",
  Confirm: "Confirm",
  Cancel: "Cancel",
  This_category_already_exists: "This category already exists",
  Please_input_product_ID: "Please input product ID",
  Select_All: "Select All",
  Add_in_batches: "Add in batches",
  Added: "Added",
  Ranking_Add: "Ranking Add",
  Please_input: "Please input",
  closeStatus: "After closing, it cannot be opened again. Are you sure?",
  inputCustomized_Category: "Please enter a customized category",
  goodIdExistence: "Failed to add, the product(s) has(have) appeared in other rankings",
  screenCategory: "Please filter the Youpik category and Lazada category first",
  最多添加20个: "Add up to 20 products, please choose properly"
};
