export default {
  waterfallList: "Product List ",
  waterfallId: "List ID",
  waterfallName: "List Name",
  shopUpNum: "Available Item(s)",
  addWaterfall: "New List",
  addEditWaterfall: "New / Edit Product List",
  addShop: "Add products",
  waterFullShop: "List Item",
  total: "Tota",
  numShop: "item(s)",
  nameOrID: "Name / ID / Activity ID/ID",
  category: "Category",
  remove: "Remove",
  confirmDelete: "Remove Confirmation",
  confirmDeleteTips: "Are you sure that you want to remove item from the list?",
  cannotBeAdded: "Adding products failed",
  cannotBeAddedReason: "Failed reason: maximum 50 items can be added only.",
  addSuccessItem: "Added item(s) succeed",
  removeItemSuccess: "Remove items succeed",
  sumbitSuccess: "Submit succeed",
  otherItemNoImport: "Some items added failed",
  successNum: "Succeed:",
  one: " item(s)",
  failNum: "Failed：",
  failSeason: "Failed reason：",
  moreAddItem: "maximum 50 items can be added only.",
  TapId: "TAP ID",
  toBeReleased: "待发布",
  beginInAminute: "即将开始",
  inProgress: "Ongoing",
  Ended: "Closed",
  noDelete: "Delete failed",
  waterfallSet: " this product list is configured with ranking list which ID is",
  ItemList: " ",
  waterfallNot: "List Name Required to fill in. "
};
