import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";
import { uploadFile } from "@/api/upload";

import styles from "./index.less";
import { Upload, message } from "antd";

interface IImageUploadProps {
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/gif" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/JPEG/PNG/GIF file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const ImageUpload: React.FC<IImageUploadProps> = ({ value, onChange, disabled }) => {
  const [loading, setLoading] = useState(false);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCustomRequest = (info) => {
    console.log(info);
    const formData = new FormData();
    formData.append("file", info.file as RcFile);
    setLoading(true);
    uploadFile(formData)
      .then((res) => {
        const { code, result } = res;
        if (code === 200) {
          if (onChange) {
            onChange(result);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.upload_image_container}>
      <Upload
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        disabled={disabled}
        beforeUpload={beforeUpload}
        customRequest={handleCustomRequest}
      >
        {value && !loading ? (
          <img
            src={value}
            alt='image'
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};

export default ImageUpload;
