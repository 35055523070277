export default {
  tagName: "Tag name",
  fitPlatform: "Platform",
  tagLevel: "Tag level",
  Level1: "Level 1",
  Level2: "Level 2",
  No: "Number",
  Target_Level1: "Belong to the level 1",
  Label_Members: "Number of people tagged",
  Create_Time: "Create time",
  Operate: "Action",
  Edit: "Edit",
  Delete: "Delete",
  Add: "Add",
  Add_Label: "Add tag",
  Label_Already_Exist: "The tag already exists",
  Cannot_Delete_By_User_Before: "There are",
  Cannot_Delete_By_User_End: "users under this tag, you cannot delete it.",
  Cannot_Delete_By_Label_Before: "There are",
  Cannot_Delete_By_Label_End: "level 2 tags under this level 1 tag, you cannot delete it.",
  Edit_Label: "Edit tag",
  All: "All",
  Label: "Tag",
  Input_label_Name: "Please enter a tag name",
  Select_Target_Level1: "Please select the corresponding level",
  Select_Label_Level: "Please select a label level",
  Select_Fit_Platform: "Please select the applicable platform",
  // 供应链
  供应链管理: "Supplier Management",
  供应链管理新增: "Supplier Management-Add",
  供应链管理编辑: "Supplier Management-Edit",
  基本信息标签: "Basic Information",
  荐品标签: "Product Recom",
  系统打标逻辑: "System Tagging Logic"
};
