export default {
  Active: "กิจกรรม",
  BaseSet: "การตั้งค่าข้อมูลพื้นฐาน",
  RuleSet: "การตั้งค่ากฎการลงทะเบียน",
  InfoSet: "การตั้งค่าข้อมูลการลงทะเบียน",
  PageSet: "การตั้งค่าหน้าแคมเปญ",
  // 补贴
  CampaignSubsidy: "เงินสมทบกิจกรรม",
  CommissionCompensationRote: "%เงินสมทบคอมมิชชั่น",
  BatchCampaignSubsidies: "ตั้งค่าจำนวนเงินสมทบกิจกรรม",
  BatchCommissionCompensation: "ตั้งค่าจำนวนเงินสมทบค่าคอมมิชชั่น",
  CampaignSubsidyAmountSettings: "การตั้งค่าจำนวนเงินสมทบกิจกรรม",
  CampaignSubsidyTip1:
      "1.เงินอุดหนุนกิจกรรมคือคำสั่งซื้อที่เกิดขึ้นในกิจกรรมนั้นๆ และยึดราคาตามกิจกรรมนั้น  โดยแพลตฟอร์มจะชดเชยให้ตามจำนวนเงินอุดหนุนที่ตั้งไว้ *   ทาง Youpik จ่ายเงินสมทบให้ผู้ค้าโดยอัตโนมัติ ตามจำนวนสินค้าที่ตั้งไว้",
  CampaignSubsidyTip2: "2.กำหนดจำนวนเงิน สำหรับค่าสมทบSKU  ",
  CampaignSubsidyTip3: "3.หลังจากกิจกรรมเริ่ม ไม่สามารถแก้ไขได้ ",
  SubsidyAmount: "จำนวนเงินสมทบ",
  CampaignSubsidyRule: "จำนวนเงินสมทบต้องมากกว่า 0 ทศนิยม 2 ตำแหน่ง",
  SetCampaignSubsidies: "ตั้งค่าเงินสมทบกิจกรรม",
  MoreThanPrice: "จำนวนเงินสมทบสินค้าเกินราคาขายเดิมของสินค้า คุณต้องการดำเนินการต่อหรือไม่？",
  CampaignSubsidySuccessfully: "เงินสมทบกิจกรรม ตั้งค่าสำเร็จ",
  continue: "ดำเนินการต่อ",
  CommissionCompensationRatioSetting: "ตั้งค่าอัตราส่วนเงินสมทบค่าคอมมิชชัน",
  CommissionCompensationTip1:
      "1.เงินสมทบค่าคอมมิชชั่น คือคำสั่งซื้อที่เกิดขึ้นในกิจกรรมนั้นๆ และยึดราคาตามกิจกรรมนั้น  โดยแพลตฟอร์มจะชดเชยให้ตามจำนวนเงินอุดหนุนที่ตั้งไว้ *ราคาในกิจกรรม* ทาง Youpik จ่ายเงินสมทบให้ผู้ค้าโดยอัตโนมัติ ตามจำนวนสินค้าที่ตั้งไว้",
  CommissionCompensationTip2: "2.กำหนดจำนวนเงิน สำหรับสมทบ SKU",
  CommissionCompensationTip3: "3.หลังจากกิจกรรมเริ่ม ไม่สามารถแก้ไขได้",
  CommissionCompensation: "เงินสมทบค่าคอมมิชชั่น",
  CommissionCompensationRule: "เงินสมทบต้องมากกว่า 0,ไม่เกินกว่า 100, ทศนิยม 2 ตำแหน่ง",
  CommissionCompensationSuccessfully: "ตั้งค่าเงินสมทบคอมมิชชั่น สำเร็จ",
  Set: "ตั้งค่า",
  Edit: "แก้ไขใหม่",
  CommissionSet: "เงื่อนไขการตั้งค่าคอมมิชชั่น ",
  CommissionRate: "อัตราค่าคอมมิชชันของสินค้า",
  SelectProduct: "เลือกสินค้า",
  CommodityRemoval: "คุณแน่ใจหรือไม่ว่าต้องการลบสินค้าออกจากกิจกรรมนี้?",
  NO: "หมายเลข ",
  auditOngoing: "นำสินค้าออกจากร้านค้า/นำสินค้าออกจากแพลตฟอร์ม",
  auditpass: "พร้อมขาย",
  Offshelf: "นำสินค้าออกจากแพลตฟอร์ม",
  sellerShelf: "นำสินค้าออกจากร้านค้า",
  shoppRate: "ระบุค่ามากกว่า 2 ",
  shoppMaxRate: "ระบุค่าไม่เกิน  100",
  Category: "หมวดหมู่สินค้า",
  InfoSetTip: "ข้อมูลการลงทะเบียนดูโดยผู้ขาย sc",
  ActiveTitle: "กิจกรรม",
  ActiveId: "รหัสกิจกรรม",
  ActiveStatu: "สถานะใช้งาน",
  ActivityType: "ประเภทของกิจกรรม",
  ActiveTime: "เวลากิจกรรม",
  CreateTime: "เวลาสร้าง",
  ApplyTime: "เวลาลงทะเบียน",
  All: "ทั้งหมด",
  ActiveSeller: "ธุรกิจที่ใช้งานอยู่ Active",
  ActiveGoods: "สินค้างานอีเว้นท์",
  NotLimit: "ไม่ จำกัด",
  SomeItem: "หมวดหมู่สินค้าที่กำหนด",
  NotStarted: "ยังไม่เริ่ม",
  Applying: "สมัครสมาชิก",
  Ongoing: "กำลังประมวลผล",
  Closed: "เกิน",
  AddActive: "เพิ่มกิจกรรม",
  Id: "ID",
  ActiveTimeName: "ชื่อช่วงเวลา",
  ActiveItemCount: "จำนวนสินค้า",
  IsOpen: "ไม่ว่าจะเปิดใช้งาน",
  OpenTip: "ไม่ว่าจะเป็นการเปิดงาน",
  CloseTip: "ว่าจะปิดงาน",
  InputItemid: "ป้อน ID ผลิตภัณฑ์เพื่อค้นหา",
  InputSkuid: "ป้อน SKU ID เพื่อค้นหา",
  ItemId: "รหัสสินค้า",
  Price: "ราคาขาย",
  Stock: "หุ้นที่มีอยู่",
  Limit: "รับจำนวนจำกัด",
  ActivePrice: "ราคากิจกรรม",
  ActiveStock: "สินค้าคงคลังกิจกรรม",
  SurplusStock: "สต็อกแคมเปญคงเหลือ",
  ActiveGoodsCount: "จำนวนสินค้าที่สามารถเข้าร่วมแคมเปญได้",
  Banner: "รูปภาพแบนเนอร์",
  BannerTip: "ขนาดที่แนะนำ: 350*210 พิกเซล จำกัดขนาด 2MB รองรับเฉพาะรูปแบบ png, jpg, jpeg, gif",
  ApplyTip: "หมายเหตุสำหรับการลงทะเบียน",
  BaseInfo: "ข้อมูลพื้นฐาน",
  ActiveGoodsSet: "การจัดการสินค้าเหตุการณ์ Event",
  ItemName: "ชื่อผลิตภัณฑ์",
  ShopName: "ชื่อร้าน",
  ItemCategory: "หมวดหมู่สินค้า",
  PendingReview: "อยู่ระหว่างการพิจารณา",
  Passed: "ผ่านไป",
  Failed: "ไม่ผ่าน",
  AddItem: "เพิ่มสินค้า",
  ReviewStatus: "สถานะการอนุมัติ",
  From: "แหล่งที่มา",
  AuditLogs: "บันทึกการตรวจสอบ",
  ConfirmPass: "ยืนยันแล้ว",
  AuditFaile: "ไม่ผ่านการตรวจสอบ",
  AuditPass: "สอบผ่าน",
  RequireReason: "กรุณากรอกเหตุผล",
  BatchPass: "ผ่านแบทช์",
  BatchFaile: "การปฏิเสธจำนวนมาก",
  EditItemInfo: "แก้ไขข้อมูลสินค้ากิจกรรม",
  PlaceChangeItem: "กรุณาเลือกสินค้าที่ต้องการรีวิว",
  Operator: "โอเปอเรเตอร์",
  OperationTime: "เวลาทำการ",
  AuditResult: "ผลการตรวจสอบ",
  FaileReason: "เหตุผลที่ปฏิเสธ",
  BatchSet: "การตั้งค่าแบทช์",
  ApplyAudit: "รอการตรวจสอบ",
  ActivityModel: "รูปแบบที่",
  ChangeModel: "เปลี่ยนรูปแบบ",
  ToUse: "ใช้งาน",
  PhoneView: "ดูตัวอย่าง",
  UploadTip: "ขนาด : กว้าง 750 พิกเซล ขนาดห้ามเกิน 2 MB สกุลไฟล์ png, jpg, jpeg and gif",
  ImageTip: "ข้อแนะนำ : กว้าง 327 px ยาว 120 px ขนาดไม่ควรเกิน 2 MB นามสกุลไฟล์ต้องเป็น png jpg jpeg และ gif",
  ShopStatus: "สถานะร้านค้า",
  AuditType: "สถานะแพลตฟอร์ม",
  IsGift: "Youpik Choice",
  Sort: "ลำดับ",
  Remove: "ลบ",
  BatchRemove: "ลบแบทช์",
  ConfirmRemove: "ต้องการลบสินค้าใช่หรือไม่?",
  ConfirmBatchRemove: "ต้องการลบสินค้าทั้งหมดที่เลือกใช่หรือไม่?",
  RequireBanner: "กรุณาอัปโหลดแบนเนอร์",
  ConfirmSave: "ต้องการบันทึกใช่หรือไม่?",
  ConfirmCancel: "ต้องการยกเลิกการแก้ไขใช่หรือไม่?",
  Preview: "ดูรายละเอียด",
  ActivityUrl: "Link ของแคมเปญ",
  Selecting: "กำลังเลือก",
  ActivityDiscounts: "ราคาลดของแคมเปญ",
  SomeSeller: "การกำหนดร้านค้า",
  RuleError: "กรอกข้อมูลไม่ถูกต้อง",
  SelectCategory: "ไม่ได้เลือกหมวดหมู่สินค้า",
  TimeError: "วันลงทะเบียนแคมเปญต้องสิ้นสุดก่อนวันแคมเปญเริ่ม",
  SkuStr: "ข้อกำหนดชนิดสินค้า",
  Discount: "ราคาลดของแคมเปญ",
  InputId: "กรุณากรอก seller ID และหากมีจำนวนมากกรุณาใช้ \",\" ระหว่าง seller ID",
  PlaceId: "กรุณากรอก seller ID",
  LabelImage: "แบนเนอร์แคมเปญ",
  ActivityCommission: "Campaign Commission",
  ActivityCommissionSet: "Campaign Commission Settings",
  ActivityCommissionRate: "% ค่าคอมมิชชั่น",
  ActivityCommissionTip: "หลังตั้งค่าแล้วค่าคอมมิชชั่นสินค้าจะเป็นไปตามที่กำหนดในช่วงเวลาแคมเปญ",
  ActivityCommissionRule:
      "คอมมิชชั่นแคมเปญ = % คอมมิชชั่น Youpikker + % คอมมิชชั่นแพลตฟอร์ม ; % คอมมิชชั่น Youpikker + % คอมมิชชั่นแพลตฟอร์ม ต้อง ≤100%",
  ExtensionCommissionRate: "% ค่าคอมแคมเปญ",
  PlatformCommissionRate: "% ค่าคอมแพลตฟอร์ม",
  ConfirmUpdeteCommission: "หลังจากบันทึกแล้วจะไม่สามารถแก้ไขค่าคอมมิชชั่นได้ แน่ใจหรือไม่ว่าต้องการบันทึก?",
  SeeItmeList: "ตรวจสอบสินค้าที่เกี่ยวข้อง",
  SkuId: "SKU ID",
  Statistics: "กำลังประมวลผล",
  ConfirmDeleteLog: "แน่ใจว่าจะลบหรือไม่?",
  ActivityBannerImage: "ภาพหน้าปก",
  ActivityBannerImageTip: "ขนาด : 200*200 พิกเซล ขนาดห้ามเกิน 2 MB สกุลไฟล์ png, jpg, jpeg and gif",
  downModel: "ดาวน์โหลดเวอร์ชั่น",
  UserId: "User ID",
  UserName: "ชื่อบัญชี",
  UserLevel: "ระดับผู้ใช้",
  superLevel: "ระดับสมาชิก",
  Pcode: "รหัสเชิญชวน",
  ActivityCompletionStatus: "สถานะแคมเปญ",
  ActivityRewardAmount: "รางวัล",
  isSettle: "สถานะชำระค่าใช้จ่าย",
  Settleed: "ชำระแล้ว ",
  NoSettle: "ยังไม่ได้ชำระ",
  SettlementTime: "เวลาที่ชำระเงิน",
  NoEnd: "ยังไม่สำเร็จ",
  End: "สำเร็จ",
  // 活动页面配置
  PageTitle: "ชื่อกิจกรรม",
  Coupon: "คูปอง ID:",
  NoCoupon: "คูปองที่ไม่ได้รับอนุญาตสำหรับเวลาที่คูปองจะไม่แสดงแต่ APP จะได้รับคูปอง",
  CouponAdd: "เพิ่ม",
  VoucherType: "ประเภทคูปอง",
  VoucherName: "ชื่อคูปอง",
  Denomination: "มูลค่าคูปอง",
  ConditionsUse: "เงื่อนไขการใช้งาน",
  VStatus: "สถานะ",
  CollectionTime: "เวลารวบรวม",
  usageTime: "เวลาใช้งาน",
  FreeShipping: "จัดส่งฟรี",
  saveYpcTip: "ยืนยันบันทึกแก้ไขข้อมูล",
  cancelYpcTip: "ถ้าออกจากหน้านี้ ข้อมูลจะไม่บันทึก",
  confirmItemInfo: "กรุณายืนยันข้อมูลสินค้า",
  placeselect: "กรุณาเลือก",
  all: "ทั้งหมด",
  select: "เลือก",
  lookRecord: "ดูประวัติ",
  actTip: "ดำเนินการสำเร็จ กรุณาไปที่  \"นำเข้าประวัติ\" เพื่อดู ",
  toSelect: "กรุณาเลือก ",
  useExcel: "นำเข้าจาก  Excel",
  selectFile: "กรุณาเลือกไฟล์",
  downloadModel: "ดาวน์โหลดแบบฟอร์ม",
  importModel: "นำเข้าแม่แบบ",
  tip1: "หมายเหตุการน้ำเข้าข้อมูล：",
  tip2: "1. โปรดดาวน์โหลดแบบฟอร์มและกรอกข้อมูลก่อนนำเข้า",
  tip3: "2. ขนาดไฟล์ต้องไม่เกิน 2M",
  tip4: "3. จำนวนสินค้านำเข้าครั้งละไม่เกิน 1,000 รายการ",
  timeisend: "สิ้นสุดระยะเวลาการลงทะเบียน",
  exportHistory: "นำเข้าจากประวัติ",
  createTime: "ตั้งค่าเวลา",
  fileName: "ชื่อไฟล์",
  status: "สถานะ",
  downloadFile: "ดาวน์โหลดไฟล์ต้นฉบับ",
  total: "ทั้งหมด",
  success: "สำเร็จ",
  filled: "ผิดพลาด",
  shopsku: "หมายเลข SKU",
  reason: "สาเหตุ",
  exportHistory2: "นำออกประวัติ",
  callme: "กรุณาติดต่อฉัน",
  closeTip2: "ยืนยันปิดกิจกรรม หลังจากปิดกิจกรรม จะไม่สามารถเปิดได้อีก",
  openTimeTip: "เวลาเริ่มกิจกรรมเร็วกว่าเวลาปัจจุบัน ไม่สามารถดำเนินการได้ โปรดแก้ไข",
  openLengthTip: "กิจกรรมดำเนินการแล้ว ไม่สมารถดำเนินการอีกได้",
  started: "กิจกรรมเริ่มแล้ว ไม่สามารถแก้ไขได้",
  timeTip: "เวลาเริ่มไม่สามารถเร็วกว่าเวลาปัจจุบัน",
  err20001: "ข้อมูล excel ว่างเปล่า",
  err20002: "เกิดข้อผิดพลาดที่หัวข้อเทมเพลต",
  err20003: "เกิดข้อผิดพลาดในการอ่านข้อมูลเทมเพลต"
};
