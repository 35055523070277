export default {
  通知中心: "Trung tâm thông báo",
  新增: "Thêm mới",
  搜索: "Tìm kiếm",
  序号: "Số thứ tự",
  标题: "Tiêu đề",
  时间范围: "Khoảng thời gian",
  是否强提醒: "Có cảnh báo mạnh không",
  发布时间: "Thời gian phát hành",
  操作: "Vận hành",
  编辑: "Chỉnh sửa",
  删除: "Xóa",
  是: "Có",
  否: "Không",
  删除后:
    "Sau khi xóa, phía người dùng không thể thấy bản ghi thông báo và không thể phục hồi. Bạn có chắc chắn muốn xóa không?",
  确定: "Có",
  取消: "Hủy",
  内容: "Nội dung",
  通知: "Thông báo",
  详情: "Chi tiết",
  忽略: "Bỏ qua",
  状态: "Trạng thái",
  已读: "Đã đọc",
  未读: "Chưa đọc",
  返回: "Quay lại"
};
