const performance_icon = "https://file.uchoice.pro/public/img/240722/1.png";
const performance_icon_active = "https://file.uchoice.pro/public/img/240722/3.png";

const performanceRoute: IRouteProps[] = [
  {
    path: "/performance",
    name: "绩效管理",
    meta: { icon: performance_icon, activeIcon: performance_icon_active },
    access: "canOporation",
    routes: [
      {
        path: "/performance/dashboard",
        name: "PIC绩效",
        component: "./performance/dashboard"
      },
      {
        path: "/performance/preview/:follower",
        name: "数据概览",
        component: "./performance/preview",
        hideInMenu: true
      }
    ]
  }
];

export default performanceRoute;
