export default {
  logOut: "退出登录",
  homePage: "首页",
  thailand: "泰国",
  vietnam: "越南",
  logIn: "登录",
  username: "账号",
  password: "密码",
  fPwd: "忘记密码?",
  nation: "国家"
};
