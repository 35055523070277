export default {
  Agree: "同意",
  Reject: "驳回",
  Action: "操作",
  Search: "搜索",
  Reset: "重置",
  Add: "添加",
  NewAdd: "新增",
  Export: "导出",
  BulkExport: "批量导出",
  Import: "导入",
  bulkImport: "批量导入",
  Delete: "删除",
  BulkDelete: "批量删除",
  Edit: "编辑",
  copy: "复制",
  TakeDown: "下架",
  Uploaded: "上架",
  More: "更多",
  Cancel: "取消",
  Finish: "完成",
  Submit: "提交",
  Confirm: "确定",
  AllSelete: "全选",
  ShowMore: "显示更多",
  ShowLess: "显示更少",
  SeeDetail: "查看详情",
  Audit: "审批",
  Audit2: "审核",
  Detail: "详情",
  Refuse: "拒绝",
  Adopt: "通过",
  Save: "保存",
  Tip: "提示",
  uploadFile: "上传文件",
  clearAll: "清空",
  select: "选择",
  refresh: "刷新",
  获取分享链接: "获取分享链接"
};
