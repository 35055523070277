export default {
  首页: "Homepage",
  绩效管理: "Performance Management",
  "绩效管理.PIC绩效": "PIC Performance",
  通知中心: "Notification Center",
  新增: "add",
  编辑: "edit",
  运营管理: "Operation Management",
  "运营管理.首页配置": "Homepage Setting",
  登录: "Login",
  "运营管理.添加": "Add",
  "运营管理.编辑": "Edit",
  "运营管理.详情": "Detail",
  用户管理: "User Management",
  "用户管理.uChoice用户": "Uchoice User Center",
  "用户管理.达人公海": "Creator Marketplace",
  "用户管理.跟进达人": "PIC Creator",
  "运营管理.瀑布流配置": "Product List ",
  样品申请: "Sample Application",
  "订单管理.sampleApplication": "Sample Application",
  "运营管理.排行榜配置": "Ranking List Management",
  "运营管理.排行榜新增": "New Ranking List",
  "运营管理.排行榜编辑": "Edit Ranking List",
  "运营管理.新人样品池配置": "New Comer Sample Pool Configuration",
  "运营管理.banner配置": "Homepage Banner Management",
  "运营管理.动态页配置": "Campaign Page Management",
  商品管理: "Product Management",
  "商品管理.TT商品": "Tiktok Products",
  "商品管理.普通活动": "Normal Campaigns",
  "商品管理.明星活动": "Celebrity Campaigns",
  "商品管理.商品集": "Product Set",
  "用户管理.标签管理": "Tag management",
  "用户管理.达人链接管理": "Creator's product link management",
  "用户管理.达人链接管理详情": "Creator's product link management",
  "运营管理.uChoice&TT样品申请门槛设置": "Sample Request Criteria",
  "运营管理.投流管理": "Video Promote Reward",
  销售报表: "TAP Sales Report",
  "销售报表.达人分析": "Creator Analysis",
  "销售报表.商品分析": "Product Analysis",
  "销售报表.商家分析": "Seller Analysis",
  "销售报表.视频履约分析": "Video Sales Analysis",
  // 供应链
  供应链管理: "Supplier Management",
  供应链管理新增: "Supplier Management-Add",
  供应链管理编辑: "Supplier Management-Edit",
  消息通知: "Message Notification",
  "消息通知.系统推送": "System Push",
  "消息通知.自定义推送": "Custom Push"
};
