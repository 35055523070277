export default {
  有效商品: "จำนวนสินค้าที่ดำเนินการแล้ว",
  失效商品: "จำนวนสินค้าที่ยังไม่ดำเนินการ",
  待处理商品: "จำนวนสินค้าที่รอการดำเนินการ",
  批量上架: "เพิ่มสินค้าจำนวนมาก",
  确认商品上架至商品集: "ยินยันการเพิ่มสินค้าลงในกลุ่มสินค้า",
  "商品上架后商品集用户可浏览/复制链接/申样该商品，你还要继续吗？":
    '<span style="color: red">หลังจากเพิ่มสินค้าแล้ว ครีเอเตอร์จะสามารถค้นหา, คัดลอกลิ้งก์ และขอสินค้าตัวอย่างได้</span> คุณต้องการดำเนินการต่อ หรือไม่?',
  上架: "เปิดใช้รายการสินค้า",
  "count个商品均已上架！": "มีสินค้า {count} รายการถูกเพิ่มแล้ว",
  "successCount个商品已上架，failedCount个商品已失效，请前往失效商品查看！":
    "มีสินค้า {successCount} รายการถูกเพิ่มแล้ว และสินค้า {failedCount} รายการไม่สามารถเพิ่มได้",
  商品失效原因: "เหตุผลที่ไม่ถูกต้อง",
  修改商品集: "แก้ไขกลุ่มสินค้า",
  导出商品集: "ส่งออกกลุ่มสินค้า",
  "仅导出当前有效商品列表，你还要继续吗？": "ส่งออกเฉพาะรายการสินค้าที่ถูกต้องปัจจุบันเท่านั้น",
  "同步中…大概需要3分钟": "กำลังเชื่อมต่อ...ใช้เวลาประมาณ 3 นาที",
  "同步失败，请联系技术人员": "การเชื่อมต่อล้มเหลว กรุณาติดต่อแอดมิน",
  同步完成: "เชื่อมต่อสำเร็จ",
  无需同步: "ไม่มีข้อมูลให้เชื่อมต่อ",
  编辑商品集: "แก้ไขกลุ่มสินค้า",
  "编辑专题+列表页面": "แก้ไขหน้าหลัก + หน้ารายการ",
  仅编辑专题页面: "แก้ไขเฉพาะหน้าหลัก",
  "list主题（泰文）": "รายการที่ {index} (ไทย)",
  "list主题（越南）": "รายชื่อหัวข้อ (เวียดนาม)",
  商品集同步状态: "สถานะการเชื่อมต่อกลุ่มสินค้า",
  专题页面预览: "ดูตัวอย่างหน้าหลัก",
  列表页面预览: "ดูตัวอย่างหน้ารายการ",
  样式一: "สไตล์ที่หนึ่ง",
  样式二: "สไตล์ที่สอง",
  Earn: "ได้รับ",
  高佣活动结束: "แคมเปญจะสิ้นสุดใน {date}",
  已售: "ขายแล้ว {value}",
  预览: "ดู",
  "Get uChoice Link": "รับลิงก์",
  活动已结束: "จบ",
  商家已下架: "ไม่จำหน่าย",
  平台已下架: "ปิดตัว",
  "Sort by": "เรียงตาม",
  平台上架: "สถานะแพลตฟอร์ม",
  上架1: "มีสินค้า",
  下架1: "ไม่มีสินค้า",
  全部1: "ทั้งหมด",
  新品筛选: "สินค้าใหม่",
  过去x天: "{day} วันที่ผ่านมา"
};
