export default {
  tagName: "Tên Thẻ",
  fitPlatform: "Nền Tảng",
  tagLevel: "Cấp Thẻ",
  Level1: "Cấp 1",
  Level2: "Cấp 2",
  No: "Số",
  Target_Level1: "Thuộc Cấp 1",
  Label_Members: "Số Người Được Gắn Thẻ",
  Create_Time: "Thời Gian Tạo",
  Operate: "Thao tác",
  Edit: "Sửa",
  Delete: "Xóa",
  Add: "Thêm",
  Add_Label: "Thêm Thẻ",
  Label_Already_Exist: "Thẻ đã tồn tại",
  Cannot_Delete_By_User_Before: "Có",
  Cannot_Delete_By_User_End: "người dùng dưới thẻ này, bạn không thể xóa nó.",
  Cannot_Delete_By_Label_Before: "Có",
  Cannot_Delete_By_Label_End: "thẻ cấp 2 dưới thẻ cấp 1 này, bạn không thể xóa nó.",
  Edit_Label: "Chỉnh Sửa Thẻ",
  All: "Tất Cả",
  Label: "Thẻ",
  Input_label_Name: "Vui lòng nhập tên thẻ",
  Select_Target_Level1: "Vui lòng chọn cấp tương ứng",
  Select_Label_Level: "Vui lòng chọn cấp thẻ",
  Select_Fit_Platform: "Vui lòng chọn nền tảng áp dụng",
  // 供应链
  供应链管理: "Quản Lý Nhà Cung Cấp",
  供应链管理新增: "Quản Lý Nhà Cung Cấp - Thêm Mới",
  供应链管理编辑: "Quản Lý Nhà Cung Cấp - Chỉnh Sửa",
  基本信息标签: "Thông Tin Cơ Bản",
  荐品标签: "Thẻ Sản Phẩm Đề Xuất",
  系统打标逻辑: "Logic Đánh Dấu Hệ Thống"
};
