import { SelectLang, getLocale } from "umi";
import { Space } from "antd";
import { setLocale, useIntl } from "umi";
import Env from "./Env";
import User from "./User";
import MultiLingual from "./multiLingual";
import { isProduction, isPre } from "@/utils/common";
import { nationStorage } from "@/utils/storage";
import { setLanguage } from "@/utils/setNationLang";

export default function RightContentRender() {
  const handleChangEnation = (key: any) => {
    nationStorage.set(key);
    // setLanguage(key);
    window.location.reload();
  };

  return (
    <Space>
      {!isProduction && !isPre && <Env />}
      <SelectLang />
      <MultiLingual handleSelectedEnation={handleChangEnation} />
      <User />
    </Space>
  );
}
