import { useMemo, useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useIntl } from "umi";
import HeaderDropdown from "@/components/Layout/HeaderDropdown";
import { nationStorage, userInfoStorage } from "@/utils/storage";
import styles from "./index.less";
import { useHistory } from "umi";
import { uchoiceToken, uchoiceToken_th, uchoiceToken_vi } from "@/utils/auth";

export interface IEnvProps {
  wrapClassName?: string;
}

const User: React.FC<IEnvProps> = ({ wrapClassName }) => {
  const history = useHistory();
  const intl = useIntl();
  const handleClickItem: MenuProps["onClick"] = (it) => {
    const { key } = it;
    console.log(key, "key");
    switch (key) {
      case "home":
        history.push({
          pathname: "/"
        });
        break;
      default:
        uchoiceToken.set("");
        location.href = `${location.origin}/login?redirect=${encodeURIComponent(location.href)}`;
        break;
    }
  };

  const menuHeaderDropdown = (
    <Menu onClick={handleClickItem}>
      <Menu.Item key={"home"}>{intl.formatMessage({ id: "login.homePage" })}</Menu.Item>
      <Menu.Item key={"logout"}>{intl.formatMessage({ id: "login.logOut" })}</Menu.Item>
    </Menu>
  );

  const user = useMemo(() => {
    const userInfo = JSON.parse(userInfoStorage.get() || "{}");
    return {
      roleName: userInfo.type,
      realName: userInfo.name
    };
  }, [localStorage]);

  return (
    <HeaderDropdown overlay={menuHeaderDropdown} className={wrapClassName}>
      <span className={styles.action}>
        <span className={`${styles.env_desc} `}>
          {user.roleName}: {user.realName}
        </span>
        <DownOutlined style={{ marginLeft: "5px", fontSize: "12px" }} />
      </span>
    </HeaderDropdown>
  );
};

export default User;
