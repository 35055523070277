export default {
  // 自定义跟随语言
  English: "英文",
  Thai: "泰文",
  Vnvi: "越南",
  Actual_Sales: "实际销量",
  Export: "导出",
  Please_filter_the_Youpik_category_and_Lazada_category_first: "请先筛选Youpik类目和Lazada类目",
  Ranking_Setting: "排行榜配置",
  Youpik_Category: "Youpik类目",
  Lazada_Category: "Lazada类目",
  Customized_Category: "自定义类目",
  Add: "新增",
  All: "所有",
  Not_start: "未开始",
  Ongoing: "进行中",
  Closed: "已结束",
  Number_of_Products: "商品数量",
  Create_Time: "创建时间",
  Valid_Time: "生效时间",
  Ranking_Link: "排行榜链接",
  Status: "状态",
  Action: "操作",
  Edit: "编辑",
  Delete: "删除",
  Rank: "排序",
  Search: "搜索",
  Reset: "重置",
  Preview: "预览",
  Total: "共100页",
  Page: "20条/页",
  Go_to___: "前往...页",
  Remark: "备注",
  Save: "保存",
  Timed: "定时",
  Open: "开",
  Close: "关",
  Rename: "重命名",
  Product_Image: "商品图片",
  Product_ID: "商品ID",
  Product_Name: "商品名称",
  Sale_Price: "售价",
  Commission_Rate: "佣金率",
  PDP_Page: "PDP页面",
  All_best_seller_product_related_to_this_category_will_be_deleted__is_it_confirmed_:
    "与该类目有关的排行榜都会被删除，是否确认？",
  Confirm: "确认",
  Cancel: "取消",
  This_category_already_exists: "该类目已存在",
  Please_input_product_ID: "请输入商品ID",
  Select_All: "全选",
  Add_in_batches: "批量添加",
  Added: "已添加",
  Ranking_Add: "排行榜 添加",
  Please_input: "请输入",
  closeStatus: "关闭后不可再次打开，是否确认",
  inputCustomized_Category: "请输入自定义类目",
  goodIdExistence: "添加失败。以上商品已出现在其他排行榜里",
  screenCategory: "请先筛选youpik类目和lazada类目",
  最多添加20个: "最多添加20个，请合理挑选"
};
