export default {
  Cancel: "Discard Editing",
  Save: "Save",
  ConfirmCloseTitle: "Are you sure to close?",
  ConfirmCloseDesc: "Table content cannot be restored after closing",
  addUserTitle: "Import User",
  addSampleTitle: "Import Sample Request",
  addPersonTitle: "Import Shipping Information",
  addSupplyTitle1: "Import ",
  addSupplyTitle2: " supplier’s shop",
  InsertRow: "Insert Row",
  TipNotEmpty: "No valid content to upload",
  可搜索区域或邮编: "You can search by area or postal code.",
  待发货: "To be shipped",
  履约跟进: "Sales Performance",
  已发货: "Shipped",
  "10条样品申请等待您的审核与发货":
    '<span style="color: red;">{num} sample applications </span>are waiting for your review and shipment!',
  "X条带货视频可投流打造单品爆款，投流后可以增长您店铺的单品销量哦！":
    '<span style="color: red;">{num} videos </span>with sales can run ads promotion to create hot products. After promoted, you can increase the sales of the products in your TikTok shop!',
  "如因快递错发、丢包、破损需要补发的样品申请，商家可以在此增补订单":
    'If there are sample requests for supplement due to<span style="color: red;"> misdelivery, loss, or damage</span>, the seller can supplement the logistics number here.',
  "当前列表内容编辑未提交，若不保存所有编辑的内容将失效，是否保存编辑内容？":
    "The current list content edits are not submitted. If not saved, all edited content will be lost. Do you want to save the edited content?",

  ["add_sheet.Error"]: "Error",

  ["add_user.tt用户名"]: "TikTok User Name",
  ["add_user.跟进人"]: "PIC",
  ["add_user.联系电话"]: "Contact Phone Number",
  ["add_user.lineId"]: "LINE ID",
  ["add_sample.达人名称"]: "Creator’s name",
  ["add_sample.申请日期"]: "Application Date",
  ["add_sample.item id"]: "Item ID",
  ["add_sample.规格"]: "Specifications",
  ["add_sample.数量"]: "Quantity",
  ["add_sample.商品链接"]: "Product Link",
  ["add_sample.橱销截图"]: "Showcase Screenshots",
  ["add_sample.30天成交件数"]: "30-Day Sales Volume",
  ["add_sample.近一个月gmv"]: "GMV in the Last Month",
  ["add_sample.收件人姓名"]: "Receiver's Name",
  ["add_sample.收件人电话"]: "Receiver's Phone Number",
  ["add_sample.收件地址"]: "Receiver's Address",
  ["add_sample.地区"]: "District",
  ["add_sample.详细地址"]: "Address",
  ["add_sample.可履约方式"]: "Content Type",
  ["add_sample.视频"]: "Video",
  ["add_sample.直播"]: "LIVE",
  ["add_sample.视频+直播"]: "Video & Livestream",

  ["add_person.订单号"]: "Order Number",
  ["add_person.主播名"]: "TikTok User Name",
  ["add_person.商品类目"]: "Category Name",
  ["add_person.商品名称"]: "Product Name",
  ["add_person.商品图"]: "Product Image",
  ["add_person.item id"]: "Item ID",
  ["add_person.价格"]: "Price",
  ["add_person.店铺名称"]: "Shop Name",
  ["add_person.商品链接"]: "Product Link",
  ["add_person.规格"]: "Specifications",
  ["add_person.数量"]: "Quantity",
  ["add_person.video/live"]: "Video/Live",
  ["add_person.收件人姓名"]: "Receiver's Name",
  ["add_person.收件人电话"]: "Receiver's Phone Number",
  ["add_person.收件地址"]: "Receiver's Address",
  ["add_person.橱销截图"]: "Showcase Screenshots",
  ["add_person.近一个月gmv"]: "GMV in the Last Month",
  ["add_person.follow"]: "Followers",
  ["add_person.物流单号"]: "Tracking #",
  ["add_person.视频链接"]: "Video Link",
  ["add_person.直播时间"]: "Livestream time",
  ["add_person.同意/拒绝"]: "Agree/Reject",
  ["add_person.同意"]: "Agree",
  ["add_person.拒绝"]: "Reject",
  ["add_person.MCN"]: "MCN",
  ["add_person.跟进人"]: "PIC",
  ["add_person.投流视频ID"]: "Promote Video Code",
  ["add_person.履约出单数"]: "Fulfillment Orders",
  ["add_person.履约GMV"]: "Fulfillment GMV",
  ["add_person.投流状态"]: "Video Promotion Status",
  ["add_person.订单来源"]: "Order Source",
  ["add_person.样品申请信息"]: "Sample Request Info",
  ["add_person.商家样品审核跟进表"]: "Merchant Sample Review Tracker",
  ["add_person.商家投流跟进表"]: "Merchant Video Promotion Follow-up Table",
  ["add_person.申请时间"]: "Request Date",
  ["add_person.备注"]: "Note",
  ["add_person.拒绝原因"]: "Reject Reason",
  ["add_person.收件地址省"]: "Province",
  ["add_person.收件地址市"]: "City",
  ["add_person.收件地址区"]: "District",
  ["add_person.邮编"]: "Postal Code",
  ["add_person.Content ID"]: "Content ID",
  ["add_person.当月视频销量"]: "Video Sales This Month",
  ["add_person.上周视频销量"]: "Video Sales Last Week",
  ["add_person.上月视频销量"]: "Video Sales Last Month",
  ["add_person.Ads Code"]: "Ads Code",
  ["add_person.增补物流单号"]: "Supplement Logistics Number",

  ["add_supply.店铺id"]: "Store ID"
};
