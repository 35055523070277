export default {
  supplierTime: "供应商审核时间",
  deliveryTime: "发货时间",
  completeTime: "完成时间",
  closeTime: "关闭时间",
  履约申请时间: "履约申请时间",
  履约拒绝时间: "履约拒绝时间",
  履约通过时间: "履约通过时间",
  mcn: "mcn",
  // uChoice&TT样品申请
  NameOfTalent: "达人名称",
  numOfTalent: "达人账号",
  TAPID: "TAP ID ",
  tradingStatus: "交易状态",
  ScreenshotSales: "厨窗销售截图",
  TAPActivityInformation: "TAP活动信息",
  lastPage: "上一页",
  nextPage: "下一页",
  CourierCompany: "快递公司",
  GoToFlashOfficialWebsite: "前往Flash官网查看",
  flashMessage: "物流信息",
  ScreenshotData: "橱窗销售数据",
  followUp: "跟进人",
  lineId: "Line ID",
  track: "轨迹",
  orderStatusWillChang: "订单状态将会变为待发货",
  ship: "发货",
  screenDataNo: "您的橱窗数据不满足商家预期",
  sampleReject: "非常抱歉，因商家缺货，您的样品申请被拒绝。",
  sampleRequestFailed: "非常抱歉，因物流原因，您的样品申请失败。",
  otherReasons: "其他原因填写",
  InitialConditionSetting: "初始条件设定",
  AverageVideoPlayback: "平均视频播放量",
  videoPlayback: "视频播放量",
  accountCanOnlyApply: "一个KOL-TT账号同时只能申请最多",
  oneSamples: "个样品",
  afterTheApplication: "申请成功后，TAP后台该达人申请的该样品订单销量大于",
  sampleApplication: "恢复一个样品申请名额",
  ttStoreName: "TT店铺名称",
  sureModifyFans: "确定修改粉丝数？",
  sureModifyVideo: "确定修改视频播放数？",
  Submitted: "已提交",
  followUpPerson: "是否修改跟进人？",
  goToLogisticsOfficialWebsite: "前往物流官网查询",

  // lazada落地页
  YoupikCategore: "Youpik类目",
  lazadaName: "Lazada分类名称",
  youPikName: "Youpik分类名称",
  //
  buyingAttr: "购买时属性",
  commonItem: "普通商品",
  giftItem: "礼包商品",
  activityItem: "活动商品",
  RefundID: "退款ID",
  RefundMethod: "退款方式",
  BankCardRefund: "银行卡退款",
  KsherRefund: "Ksher 退款",
  remark: "备注信息",
  RefundTips:
    "Confirm to Pass，it means already refunded to user and order status will change to Refunded.",

  All: "全部",
  Unpaid: "未支付",
  Wrapping: "打包",
  Shipping: "待发货",
  PenddingShip: "待揽件",
  InTransit: "在途",
  ProperDelivery: "妥投",
  BuyerId: "买家ID",
  orderType: "订单类型",
  OrderTimeScope: "订单时间范围",
  OrderNum: "订单号",
  OrderType: "订单类型",
  GeneralOrders: "普通订单",
  VoucherOrders: "券类订单",
  PreOrders: "预售订单",
  ToRtsPkg: "待RTS的包裹",
  AlreadyRtsPkg: "已RTS包裹",
  CreatePkg: "创建包裹",
  BuyerName: "买家姓名",
  Package: "包裹",
  TrackingOrderNum: "跟踪单号",
  ShippingMethod: "运输方式",
  RetailPrice: "零售价格",
  Status: "状态",
  TotalPrice: "总价",
  ToDelivered: "待配送",
  ToPending: "待揽件",
  ShippingIncluded: "包含运费",
  CreationTime: "创建时间",
  DeliveryPrintFaceSheet: "发货并打印面单",
  PrintingSurfaceSingle: "打印面单",
  ToRecreateParcel: "重新创建包裹",
  CheckLogistics: "查看物流",
  CancelOrder: "取消订单",
  PackageNumber: "打包数量",
  CancelItem: "取消商品",
  CancelReason: "取消原因",
  To: "发往",
  Product: "产品",
  Img: "图片",
  PaymentMethod: "支付方式",
  ItemTotalAmount: "商品总金额",
  ItemTotalNum: "商品总数量",
  BuyerInfo: "买家信息",
  BuyersInfo: "顾客信息",
  invitationCoce: "邀请码",
  userID: "user id",
  Customer: "客户",
  TelephoneNumber: "电话号码",
  TaxCode: "税码",
  Subtotal: "小计",
  TotalPlatformOffers: "平台优惠总计",
  TotalSellerDiscount: "卖家折扣总计",
  TotalShippingDiscount: "运费优惠总计",
  Cumulative: "累计",
  TransactionInfo: "交易信息",
  RefundOfReturnCommission: "退货佣金退款",
  PaymentForGoods: "货款",
  shippingActualFee: "实际运费（VAT)",
  PaymentOfServiceCharge: "支付手续费",
  ReturnPayment: "退货货款",
  LogistisFreight: "物流运费",
  Commission: "佣金",
  BillingAddress: "账单地址",
  DeliveryAddress: "送货地址",
  DeliverAfterSellerCancle: "发货后商家取消",
  LogisticsDistributionFailCancle: "物流配送失败取消",
  LogisticsDistributionReturnCancle: "物流配送退件取消",
  LogisticsDistributionTimeOutCancle: "物流配送超时取消",
  LogisticsSolicitationCancle: "物流揽件时取消",
  SellerDeliverTimeOutCancle: "商家发货超时取消",
  PlatformCancle: "平台取消",
  SellerCancle: "商家取消",
  UserCancle: "用户取消",
  PayTimeOut: "支付超时取消",
  ToBepay: "待付款",
  ToBePacked: "待打包",
  ToBeDeliver: "待配送",
  Delivered: "已发货",
  SignedIn: "已签收",
  OrderComplete: "订单完成",
  transactionCancle: "交易取消",
  title: "订单列表",
  Date: "日期",
  TransactionType: "交易类型",
  CostAmount: "费用项金额",
  Number: "编号",
  AccountToBill: "账户对账单",
  SellerLoginName: "卖家登陆名",
  ItemId: "商品ID",
  SellerSku: "卖家SKU",
  SKUNum: "SKU编号",
  ModeOfPerformance: "履约方式",
  Refund: "退款",
  Promotion: "促销",
  Coupon: "优惠券",
  Freight: "运费",
  DetailedHistory: "明细历史记录",
  SKUCoding: "sku编码",
  Collected: "已揽收",
  InTransit2: "运输中",
  InDelivery: "派送中",
  Stranded: "已滞留",
  DifficultPartsInHanlde: "疑难件处理中",
  ReturnedItems: "已退件",
  AbnormalClosing: "异常关闭",
  Rescinded: "已撤销",
  OrderDetail: "订单详情",
  ItemInfo: "商品信息",
  platDiscountSubsidy: "平台优惠补贴",
  platShippingSubsidy: "平台运费补贴",
  sellerDiscountSybsidy: "商家优惠券补贴",
  amountActuallPaid: "实付金额",
  storeName: "卖家店铺名称",
  toBeDelivered: "待发货",
  // TODO
  packageId: "包裹ID",
  number: "数量",
  ProperDeliveryFailed: "妥投失败",
  Type1010: "发货后商家取消",
  Type1009: "物流配送失败取消",
  Type1008: "物流配送退件取消",
  Type1007: "物流配送超时取消",
  Type1006: "物流揽件超时取消",
  Type1005: "商家发货超时取消",
  Type1004: "平台取消",
  Type1003: "商家取消",
  Type1002: "用户取消",
  Type1001: "支付超时取消",
  Type1000: "待付款",
  Type2001: "待打包",
  Type2002: "待配送",
  Type3000: "已发货",
  Type4000: "已签收",
  Type5000: "订单完成",
  // 翻译
  Type_1012: "超时未发货取消",
  //
  OrderCancel: "交易取消",
  ShopName: "店铺名称",
  Remark: "退款备注",
  CSRemark: "CS 备注",
  Select: "请选择",
  responseOption1: "无库存",
  responseOption2: "价格错误",
  responseOption3: "其他原因",
  CancelPkg: "取消包裹",
  CancelPkgSuccess: "取消包裹成功",
  CancelResponsiblePart: "取消责任方",
  cancelResponsiblePart1: "用户责任",
  cancelResponsiblePart2: "商家责任",
  cancelResponsiblePart3: "平台责任",
  cancelResponsiblePart4: "物流商责任",
  cancelReasonTip: "请选择原因",
  cancelFialedReason: "申诉失败原因会返回给用户，请根据实际情况填写",
  cancelFialedOtherReason: " 拒绝原因会返回给用户，请根据实际情况填写",
  cancelResponsiblePartTip: "请选择责任方",
  ConfirmCancelOrder: "确认取消订单",
  cancelOrderSuccess: "取消订单成功",
  ConfirmCancelItem: "确认取消商品",
  ConfirmCancelPkg: "确认取消包裹",
  CancelTime: "取消时间",
  CancelUser: "取消方",
  Pending: "待审核",
  Completed: "已完成",
  Reject: "已拒绝",
  RefundAmount: "退款金额",
  RefundAmountStats: "打款状态",
  Response: "原因",
  ApplyTime: "申请时间",
  SellerType: "商家类型",
  SendTo: "发往",
  ItemID: "商品ID",
  LogisticsType: "物流类型",
  Close: "非DBS",
  HandDbs: "手动BDS",
  AutoDbs: "自动DBS",
  LogStatu: "物流状态",
  LogType: "物流方式：",
  FlashExpress: "Flash Express",
  SeaStar: "SeaStar",
  Zambo: "来赞宝",
  CenterGroup: "Center Group",
  TS: "天识",
  Acommerce: "Acommerce",
  Interpid: "Interpid",
  DBSbyhand: "DBS by Hand",
  reason_app_1: "Change payment method",
  reason_app_2: "Delivery time is too long",
  reason_app_3: "Duplicate order",
  reason_app_4: "Change of Delivery Address",
  reason_app_5: "Change of mind",
  reason_app_6: "Decided on another product",
  reason_app_7: "Shipping cost too high",
  reason_app_8: "Found better price elsewhere",
  SellerName: "卖家名称",
  MakingMoney: "打款中",
  Type_1001: "支付超时取消",
  Type_1002: "用户取消",
  Type_1003: "商家取消",
  Type_1004: "平台取消",
  Type_1005: "商家发货超时取消",
  Type_1006: "物流揽件超时取消",
  Type_1007: "物流配送超时取消(人工核实)",
  Type_1008: "物流配送退件取消",
  Type_1009: "物流配送失败取消",
  Type_1010: "发货后商家取消 （物流费用不退）",
  Type_2000: "退款中",
  Type_2001: "退款完成",
  Type_4000: "退货完成",
  User: "用户",
  Seller: "商家",
  Ops: "OPS",
  Todo: "待处理",

  CancelGetRespons: "取消揽件原因",
  flashReasponse9: "发货地址信息不符合格式",
  flashReasponse80: "客户取消任务",
  flashReasponse83: "联系不上客户",
  flashReasponse85: "寄件人电话号码是空号",
  flashReasponse90: "包裹未准备好推迟揽收",
  flashReasponse91: "包裹包装不符合运输标准",
  flashReasponse95: "车辆/人力短缺推迟揽收",
  flashReasponse98: "地址错误",
  flashReasponse99: "包裹不符合揽收条件：超大件",
  flashReasponse100: "包裹不符合揽收条件：违禁品",
  flashReasponse105: "外包装破损",
  reason6: "超时未发货",
  ToRts: "待RTS",
  Rtsed: "已RTS",
  Cancel: "已取消",
  LossDamage: "丢失破损",
  HasProduct: "已退件",
  Undone: "已撤销发货",
  SelectOrderTime: "请选择订单时间范围",
  Countdown: "倒计时",
  Down: "The SKU Stock will be cleared, please replenish the inventory in time；",
  // 交易信息需求
  ActualFreight: "实际运费(VAT)",
  handlingCharges: "支付手续费(VAT)",
  salesAmount: "销售金额",
  CommoditySales: "商品销售价",
  MerchantCoupon: "商家优惠券",
  freight: "运费",
  freightBuy: "买家支付运费（VAT）",
  freightTrue: "实际运费（VAT）",
  CampaignSubsidy: "活动补贴",
  CampaignCommissionCompensation: "补贴的平台佣金",
  YoupikFee: "Youpik Fee",
  PlatformCommission: "平台佣金（VAT）",
  OwnerCommission: "店主佣金（VAT）",
  Paymenthandling: "支付手续费（VAT）",
  ReturnRefund: "退货退款金额",
  RefundSales: "商品销售价退款",
  freightRefund: "买家支付运费退款(VAT)",
  ReverseCampaignSubsidy: "活动补贴退款",
  ReverseCommissionCompensation: "补贴的平台佣金退款",
  ActualRefund: " 实际运费退款(VAT)",
  MerchantCoupons: "商家优惠券退款",
  PlatformCommissionrefund: "平台佣金（VAT）退款",
  OwnerCommissionrefund: "店主佣金（VAT）退款",
  showDetail: "查看收入详情",
  hideDetail: "收起收入详情",
  // Down: 'The SKU Stock will be cleared, please replenish the inventory in time；'
  refundStatus: "退款状态",
  userSubmit: "用户提交",
  opsRefuse: "OPS直接拒绝",
  userCancelsApplicationSuccessfully: "用户取消申请成功",
  refundSuccess: "退款成功",
  refundRejectQC: "退款拒绝(QC不通过)",
  waitingForRetrun: "等待寄回货物",
  duringTheReturn: "退货中，商家等待收货",
  waitingForBankCard: "待银行卡",
  waitingForBankCardRetrun: "待银行卡退款",
  // lazada
  unpaid: "未付款",
  paid: "已付款",
  successfulInvestment: "妥投成功",
  refund: "退款",
  unitPrice: "单价",
  totalPriceOfGoods: "商品总价",
  viewCommission: "查看分佣",
  orderTimeRange: "下单时间范围",
  // ....
  stateCirculation: "状态流转",
  submitOrder: "提交订单",
  buyerPayment: "买家付款",
  paymentAmount: "付款金额",
  cancelPackageCreation: "取消创建包裹",
  sellerPackaging: "卖家打包",
  sellerShipment: "卖家发货",
  logisticsOrderNo: "物流单号",
  buyerRefund: "买家发起退款",
  refundOrderNo: "退款单号",
  sellerCancelOrder: "卖家取消订单",
  cancelForLogistics: "物流原因取消",
  sellerCancelsRefund: "买家取消退款申请",
  packageNotMactchOrderNo: "包裹与实际运单号不符",
  addressExceptionCode: "地址不合法异常码",
  addressNotLockerStation: "该地址没有Locker station",
  lockernoUse: "Locker不能使用",
  noOpenLocker: "不能打开Locker",
  noPackage: "无包裹",
  packageCollectionFailed: "包裹揽收失败待处理",
  logisticsRefundCancel: "物流配送退件取消(物流额外收取50%)",
  buyerInitiatedReturnRefund: "买家发起退货退款",
  platformRefuseRefund: "平台拒绝退款",
  submitRefundApply: "提交退货申请",
  buyerReturned: "买家已退货",
  refundUnderReview: "取消，退款审核中",
  refundPendingPayment: "退款待打款",
  payPoints: "支付积分",
  logisticsProvider: "物流商",
  deliveryFailed: "派送失败",
  deliveryFailed_1: "派送失败，退件正在路上，请注意查收",
  deliveryFailed_2: "派送失败，退回仓库",
  rejectAndReturn: "拒收退回",
  userReject: "用户拒收",
  lostPackage: "包裹丢失",
  abnormalLogistics: "物流异常",
  abnormalLogisticsConcat: "物流异常，有问题请联系物流公司",
  "预估佣金（佣金率）": "预估佣金（佣金率）",
  实际佣金: "实际佣金",
  店主预估佣金: "店主预估佣金",
  店主实际佣金: "店主实际佣金",
  妥投时间: "妥投时间",
  退款时间: "退款时间",
  申述记录: "申述记录",
  查看报告: "查看报告",
  "申述-待审核": "申述-待审核",
  "申述-失败": "申述-失败",
  "申述-成功": "申述-成功",
  审核人: "审核人",
  审核意见: "审核意见",
  审核时间: "审核时间",
  审核: "审核",
  "审核通过后该订单状态将修改为已完成，会进行店主佣金结算。是否确认继续?":
    "审核通过后该订单状态将修改为已完成，会进行店主佣金结算。是否确认继续?",
  请选择原因: "请选择原因",
  "申诉失败原因会返回给用户，请根据实际情况填写": "申诉失败原因会返回给用户，请根据实际情况填写",
  其他原因说明: "其他原因说明",
  Lazada订单申诉失败原因: "Lazada订单申诉失败原因",
  概览: "概览",
  仅显示已完成订单: "仅显示已完成订单",
  退款率: "退款率",
  异常订单率: "异常订单率",
  刷单: "刷单",
  刷单率: "刷单率",
  申诉订单: "申诉订单",
  申诉率: "申诉率",
  买家: "买家",
  全部订单: "全部订单",
  异常订单: "异常订单",
  订单金额: "订单金额",
  预估佣金: "预估佣金",
  账号状态: "账号状态",
  申诉记录: "申诉记录",
  申请订单: "申请订单",
  凭证: "凭证",
  联系人: "联系人",
  联系方式: "联系方式",
  订单状态: "订单状态",
  成功: "成功",
  失败: "失败",
  不通过: "不通过",
  待审核: "待审核",
  订单数: "订单数",
  服务单号: "服务单号",
  注销: "注销",
  禁用: "禁用",
  正常: "正常",
  删除: "删除",
  税后: "税后",
  不含官方账号佣金: "不含官方账号佣金",
  数量: "数量",
  增补物流单号: "增补物流单号",
  商家补发: "商家补发",
  已增补发货: "已增补发货",
  增补发货: "增补发货",
  "Content ID": "Content ID",
  视频链接: "视频链接",
  视频销量: "视频销量",
  视频GMV: "视频GMV",
  视频订单数: "视频订单数",
  "Ads Code": "Ads Code",
  未投流: "未投流",
  已投流: "已投流",
  未关联样品订单: "未关联样品订单",
  订单关联状态: "订单关联状态",
  提交时间: "提交时间",
  选择时间范围: "选择时间范围"
};
