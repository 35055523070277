export default {
  merchantSubsidies: "ưu đãi của người bán",
  pdpDescription: "Mô tả PDP",
  generate: "Tạo",
  LazadaTwotyDaySales: "Doanh số Lazada (2 tuần cuối)",
  LazadaHotSellingTrends: "Xu hướng bán chạy Lazada",
  AllianceSales: "Doanh số liên kết",
  customSort: "Sắp xếp tùy chỉnh",
  addConditions: "Thêm mới",
  ascendingOrder: "Tăng dần",
  descendingOrder: "Giảm dần",
  priority: "Ưu tiên",
  sortField: "Lĩnh vực sắp xếp",
  order: "Sắp xếp",
  moreFourCondition: "Bạn có thể thêm tối đa 4 điều kiện.",
  LazadaThirtyDaySales: "Doanh số 30 ngày của Lazada",
  updateSituation: "Tình trạng cập nhật",
  updated: "Đã cập nhật",
  notUpdated: "Chưa cập nhật",
  WhoAdded: "Người đã thêm",
  Lazada下载导入: "liên kết",
  Youpik: "Youpik",
  Users: "Người dùng",
  // tiktok快速需求
  duplicateCommodity: "Mặt hàng trùng lặp",
  repeat: "Đã lặp",
  notRepeated: "Không lặp lại",
  haveToBeSynchronizedTips: "* sự kiện mới đang chờ được đồng bộ hóa, vui lòng xác nhận danh mục.",
  CommissionRateRange: "Phạm vi tỷ lệ hoa hồng",
  CreatorCommissionRate: "Tỷ lệ hoa hồng cho người sáng tạo",
  CreatorCommission: "Hoa hồng cho người sáng tạo",
  classification: "Danh mục",
  sellerStatus: "Người bán",
  synchronization: "Đồng bộ hóa",
  toRelease: "Chờ phát hành",
  beginInAMinute: "Sắp bắt đầu",

  productId: "Mã sản phẩm",
  YoupikShop: "Sản phẩm Youpik",
  LazadaShop: "Sản phẩm Lazada",
  lowToheight: "Giá từ thấp đến cao",
  heightTolow: "Giá từ cao xuống thấp",
  saleHeightTolow: "Doanh số từ cao xuống thấp",
  sale: "doanh số",
  actualSale: "Doanh số thực tế",
  lazadaCategore: "Danh mục Lazada",

  CommissionHeightTolow: "Hoa hồng từ cao xuống thấp",
  commissionRateHeightTolow: "Tỷ lệ hoa hồng từ cao xuống thấp",
  addtips: "mặt hàng đã được thêm",
  noAddShop:
    "Thêm một phần: Một số mặt hàng không thể được thêm vì các lý do sau, nhưng phần còn lại đã được thêm thành công",
  // addFailed: '添加失败，请检查网络设置',
  failedID: "Mã sản phẩm không thành công",
  failedReason: "Lý do: Sản phẩm đã ngừng hoạt động từ Youpik.",
  pleasePutLink: "Vui lòng nhập liên kết",
  PackageWeight: "Trọng lượng gói hàng",
  PackageInfoTitle: "Giao hàng & Bảo hành",
  PackageSize: "Kích thước gói hàng",
  SpecialPrice: "Giá đặc biệt",
  SpecialPriceTime: "Khuyến mãi giới hạn thời gian",
  AboutDetailItem: "Chi tiết sản phẩm",
  Color: "Màu sắc",
  Price: "Giá",
  AllItem: "Tất cả",
  OnlineItems: "Trực tuyến",
  InReview: "Đang chờ QC",
  ShelvesItem: "Không hoạt động",
  PlatformShelvesItem: "Tạm ngưng",
  Category: "Danh mục",
  ItemId: "Mã ID sản phẩm",
  ItemName: "Tên sản phẩm",
  SellerSKU: "SKU của người bán",
  StoreSKU: "SKU của cửa hàng",
  ItemImage: "Hình ảnh",
  ItemInfo: "Mã sản phẩm",
  ItemPrice: "Giá",
  ItemStatus: "Trạng thái",
  StoreId: "ID cửa hàng",
  CreateTime: "Đã tạo",
  Operation: "Thao tác",
  AlreadyStore: "Hoạt động",
  handleShlfTips: "Danh sách vượt quá giới hạn cấu hình",
  NotInStore: "Không hoạt động",
  SoldOut: "Hết hàng",
  Update: "Cập nhật",
  Inventory: "Kho",
  Item: "Sản phẩm",
  PlaceSeleteDeleteItem: "Vui lòng chọn sản phẩm bạn muốn xóa",
  ItemProperties: "Thuộc tính sản phẩm",
  ItemTitle: "Tên sản phẩm",
  TitlePlacehold: "Ví dụ: Máy ảnh kỹ thuật số Nikon Coolpix A300",
  ItemEnTitle: "Tên sản phẩm [EN]",
  EnTitlePlacehold: "Vui lòng nhập tên sản phẩm bằng tiếng Anh",
  CategoryPlacehold: "Nhấp vào nút để tìm kiếm danh mục",
  SeleteCategory: "Chọn danh mục",
  Seletion: "Chọn",
  Brand: "Thương hiệu",
  HomeImgUrl: "Hình ảnh chính",
  ItemContent: "Mô tả",
  ItemContentEn: "Mô tả chi tiết bằng tiếng Anh",
  ItemShortDesc: "Mô tả ngắn",
  ItemShortDescEn: "Mô tả ngắn [EN]",
  GetItems: "Nội dung sản phẩm",
  GetItemsEn: "Nội dung sản phẩm",
  ItemMainVariant: "Thông số chính của sản phẩm", // 商品主规格
  MainVariantTip: "Vui lòng điền vào tên biến thể, ví dụ như màu đỏ, vàng",
  MainVariantSelete: "Các tùy chọn biến thể chính",
  AddSelete: "Thêm lựa chọn",
  DeleteSelete: "Xóa lựa chọn",
  ItemSecondVariant: "Biến thể phụ (Tùy chỉnh)",
  // ItemSecondAttribute: "次属性名称（自定义）",
  SecondVariantTip: "Vui lòng điền vào loại biến thể phụ, ví dụ như kích thước",
  ClickUpload: "Nhấp để tải lên",
  UploadTip:
    "Ảnh đầu tiên là ảnh bìa, nên chỉ nên tải lên một ảnh. Tối đa 8 ảnh với kích thước từ 500×500 đến 2000×2000px.",

  AuditTipInfo: "Bạn có chắc chắn muốn gửi kết quả đã xác minh không?",
  RejectTipInfo: "Xác nhận thu hồi đơn đăng ký?",
  RejectTipReason: "Để thu hồi và được phê duyệt, vui lòng điền ít nhất 6 ký tự",
  StockNum: "Số lượng tồn kho",
  Variants: "Biến thể",
  GifItem: "Lựa chọn Youpik",
  PlatformActiveSelect: "Chọn",
  AllPlatformActive: "Tất cả nền tảng đều hoạt động",
  PlatformRejectActive: "Đình chỉ",
  PlatformAgreeActive: "Nền tảng hoạt động",
  BusinessActiveSelect: "Người bán chọn để hoạt động",
  AllBusinessActive: "Tất cả người bán hoạt động",
  BusinessRejectActive: "Người bán không hoạt động",
  BusinessAgreeActive: "Người bán hoạt động",
  IsSellOut: "Sản phẩm đã hết hàng chưa",
  AllSellOut: "Tất cả (có hết hàng không)",
  SellOut: "Hết hàng",
  NoSellOut: "Sản phẩm trực tuyến",
  ActiveItemTip: "Xác nhận để kích hoạt sản phẩm này?",
  DisabledItemTip: "Xác nhận để vô hiệu hóa sản phẩm này?",
  AcitveGifItemTip: "Vui lòng xác nhận để đặt sản phẩm là sản phẩm Youpikchoice?",
  DisabledGifItemTip: "Vui lòng xác nhận để vô hiệu hóa sản phẩm Youpikchoice?",
  SellPrice: "Giá bán",
  PostAuditTimeRange: "Thời gian gửi",
  AuditedTimeRange: "Thời gian hoàn tất phê duyệt ",
  SeellerShopSku: "SKU cửa hàng",
  AuditStatu: "Trạng thái phê duyệt",
  PostTime: "Thời gian gửi",
  AuditTime: "Thời gian xác minh",
  AuditId: "ID phê duyệt",
  SellerLoginName: "Tên đăng nhập người bán",
  SellerId: "ID người bán",
  AuditOpinion: "Bình luận được phê duyệt",
  ToBeReviewed: "Đang chờ xem xét",
  MachineAuditAgree: "Đồng ý",
  MachineAuditReject: "Từ chối bởi hệ thống",
  PeopleAuditAgree: "Đồng ý",
  PeopleAuditReject: "Từ chối",
  BeOverdueAudit: "Hết hạn xác minh",
  AutomaticGeneration: "Tự động tạo ra",
  AuditAction: "Thao tác được phê duyệt",
  Long: "Chiều dài",
  Width: "Chiều rộng",
  Height: "Chiều cao",
  PlaceInputAuditOpinion: "Vui lòng điền vào ý kiến xem xét",
  Nothing: "Không có gì",
  // lazada
  weight: "Sắp xếp",
  homePageSelection: "Nổi bật trang chủ",
  itemWeight: "Trọng lượng hàng hóa",
  setItemWeightTitle: "Vui lòng nhập trọng lượng của sản phẩm, số càng nhỏ thì vị trí càng cao",
  销量: "Doanh số",
  更新时间: "Thời gian cập nhật",
  默认: "Mặc định",
  uChoice链接: "Liên kết uChoice",
  TikTok链接: "Liên kết TikTok",
  "30天销量": "Doanh số 30 ngày",
  TikTok总销量: "Tổng doanh số TikTok"
};
