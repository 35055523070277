export default {
  通知中心: "Notification Center",
  新增: "add",
  搜索: "search",
  序号: "serial number",
  标题: "title",
  时间范围: "time range",
  是否强提醒: "Whether to remind strongly",
  发布时间: "release time",
  操作: "operate",
  编辑: "edit",
  删除: "delete",
  是: "Yes",
  否: "no",
  删除后:
    "After deletion, the user side cannot see the notification record, and it cannot be recovered. Are you sure you want to delete it?",
  确定: "Yes",
  取消: "Cancel",
  内容: "content",
  通知: "Notice",
  详情: "Details",
  忽略: "neglect",
  状态: "state",
  已读: "Have read",
  未读: "unread",
  返回: "return"
};
