export default {
  Require: "该字段为必填字段",
  pleaseEnterCorrectFormat: "请输入正确格式",
  requireName: "名称不能为空",
  requireMobile: "手机号不能为空",
  requireSellerType: "请选择卖家类型",
  requireStoreLocation: "请选择店铺位置",
  requireAccountType: "请选择账户类型",
  requireCheckRead: "请勾选已阅读",
  requireCategory: "请选择类目",
  requireItemId: "请输入商品ID",
  requireItemName: "请输入商品名称",
  requireSellerSKU: "请输入卖家SKU",
  requireSotreSKU: "请输入店铺SKU",
  requirePassword: "密码不能为空",
  requiredUploadpicture: "请上传图片",
  noEmptyUsername: "账号不能为空",
  Select: "请选择",
  Please_input: "请输入",
  closeTip: "离开当前页面信息将不会保存",
  Tip: "提示",
};
