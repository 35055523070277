export default {
  Reset: "重置",
  timeTips: "开始时间不能大于结束时间",
  name: "弹窗名称",
  ImgTip: "建议尺寸：588*720 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。",
  id: "弹窗id",
  status: "状态",
  ID: "ID",
  names: "名称",
  img: "图片",
  time: "时间",
  IDUrl: "商品/地址/链接",
  sort: "顺序",
  popupMode: "弹出方式",
  popUpImg: "弹窗图片",
  popUpTime: "弹窗时间",
  popUpMode: "弹窗方式",
  popUpSort: "弹窗顺序",
  OnceDay: "每天一次",
  Once: "1次",
  always: "一直"
};
