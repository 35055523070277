import type { DropdownProps } from "antd";
import { Dropdown } from "antd";
import classNames from "classnames";

import styles from "./index.less";

export interface IHeaderDropDownProps extends DropdownProps {
  overlayClassName?: string;
}

const HeaderDropdown: React.FC<IHeaderDropDownProps> = ({
  overlayClassName: cls,
  ...restProps
}) => <Dropdown overlayClassName={classNames(styles.container, cls)} {...restProps} />;

export default HeaderDropdown;
