export default {
  total: "Tổng",
  pleaseInput: "Vui lòng nhập",
  pleaseSelect: "Vui lòng chọn",
  expand: "Mở rộng",
  collapse: "Thu gọn",
  importShop: "Nhập",
  exportTemplate: "Xuất",
  image: "Hình ảnh",
  name: "Tên Danh sách",
  price: "Giá",
  commission: "Hoa hồng",
  commissionRate: "Tỷ lệ hoa hồng%",
  status: "Trạng thái",
  sort: "Sắp xếp",
  upSelf: "Kích hoạt",
  downSelf: "Vô hiệu hóa",
  itemId: "Mã ID sản phẩm",
  Sort: "Sắp xếp",
  Reject: "Từ chối",
  Submitted: "Đã gửi",
  Passed: "Đã thông qua",
  Delivered: "Đang giao hàng",
  Completed: "Đã hoàn thành",
  TT商品: "Sản phẩm TikTok"
};
