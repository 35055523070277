import dayjs from "dayjs";

import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { nationStorage } from "./storage";

export const dayjsPlus = dayjs.extend(weekday, localeData);

export function formatTime(
  time: number | undefined | null,
  format: string = "YYYY/MM/DD HH:mm:ss"
) {
  if (!time) {
    return "-";
  }
  return dayjs(time).format(format);
}

export function formatTimeYYMMDD(time: number) {
  return formatTime(time, "YYYY-MM-DD");
}

export function formatTimeDay(time: number) {
  return formatTime(time, "HH:mm:ss");
}
export function formatTimeDayYear(time: number) {
  return formatTime(time, "ssmmHH_DDMMYYYY");
}
export function langPrefix(obj: Record<string, string>, str: string) {
  const result: Record<string, string> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[str + "." + key] = obj[key];
    }
  }
  return result;
}

export function formatMoney(val = 0) {
  const num = Number(val).toFixed(2).toString();
  let result = num;
  const [zheng, decimals] = num.split(".");
  if (num.length > 5) {
    result = `${zheng.replace(/(\d)(?=(\d{3})+$)/g, "$1,")}.${decimals}`;
  }
  return result;
}
// 小数点
export function formatPrice(price: any) {
  let newPrice = price;
  if (typeof price === "string" && price.includes("฿")) {
    newPrice = price.replace(/[฿,]/g, "");
  } else if (typeof price === "string" && price.includes("₫")) {
    newPrice = price.replace(/[₫.]/g, "");
  }
  if (nationStorage.get() === "VI") {
    if (Number.isNaN(+newPrice) || newPrice === "") {
      return "0";
    }
    // 使用 Math.floor(price) 取整并转换为字符串
    return Math.floor(+newPrice).toString();
  }
  if (Number.isNaN(+newPrice) || newPrice === "") {
    return "0.00";
  }
  // 对价格保留两位小数并转换为字符串
  return parseFloat(newPrice).toFixed(2);
}

function formatNum(val = 0) {
  if (nationStorage.get() == "VI") {
    return `${(Math.floor(val) + "").replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`;
  }
  return `${val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}
export function formattHousandth(val = 0) {
  return `${(val + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}
export function formatPriceMillennials(price: any) {
  return formatNum(parseFloat(formatPrice(price)));
}

// 时间戳转换成那天的第一秒
export function timestampToTodayStart(timestamp: any) {
  const yyyymmdd = new Date(timestamp).toISOString().split("T")[0];
  const year = yyyymmdd.split("-")[0];
  const month = yyyymmdd.split("-")[1];
  const day = yyyymmdd.split("-")[2];
  return new Date(`${month}/${day}/${year} 00:00:00`).getTime();
}

// 时间戳转换成那天的最后一秒
export function timestampToTodayEnd(timestamp: any) {
  const yyyymmdd = new Date(timestamp).toISOString().split("T")[0];
  const year = yyyymmdd.split("-")[0];
  const month = yyyymmdd.split("-")[1];
  const day = yyyymmdd.split("-")[2];
  return new Date(`${month}/${day}/${year} 23:59:59`).getTime();
}
// 示例方法，没有实际意义
export function trim(str: string) {
  return str.trim();
}

// 移除非数字字符串
export function converToNumber(value: string) {
  let res = value.replace(/\D/g, "");
  if (res.startsWith("0")) {
    res = res.replace(/0+/g, "0");
  }
  return res;
}

export function currencyUnit(price: any) {
  if (nationStorage.get() == "TH") {
    return "฿" + price;
  }
  return price + "₫";
}

//字符串型数字转化为数字类型并去掉千分位
export function numberWithCommas(value: string) {
  console.log(value, "value");
  const numberWithoutCommas = parseFloat(value.replace(/,/g, ""));
  return numberWithoutCommas;
}
