export default {
  数据概览: "Tổng quan dữ liệu",
  跟进表现: "Theo dõi hiệu suất",
  按无新增挂链达人数: "Những nhà sáng tạo không có liên kết mới trong hơn 1 tháng",
  按无成交达人数: "Những nhà sáng tạo không có giao dịch trong hơn 2 tháng",
  跟进达人总数: "Tổng số người sáng tạo đã theo dõi",
  超1个月无新增挂链达人数: "Những nhà sáng tạo không có liên kết mới trong hơn 1 tháng",
  超2个月无成交达人数: "Những nhà sáng tạo không có giao dịch trong hơn 2 tháng",
  达人: "Nhà sáng tạo",
  已合作商品数: "Số lượng sản phẩm hợp tác",
  已出单数: "Số lượng đơn hàng",
  历史GMV: "Lịch sử GMV ",
  最后一次挂链时间: "Thời gian thêm liên kết lần cuối",
  最后一次出单时间: "	Thời gian đặt hàng lần cuối",
  超1个月未新增挂链达人: "Những nhà sáng tạo không có liên kết mới trong hơn 1 tháng"
};
