export default {
  Reset: "Đặt lại",
  name: "Tên",
  ImgTip:
    "Kích thước khuyến nghị: 588*720 Pixel, dung lượng tối đa 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg và gif. Chỉ có thể tải lên một tệp vào một thời điểm.",
  id: "ID",
  status: "Trạng thái",
  ID: "ID",
  names: "Tên",
  img: "Hình ảnh",
  time: "Thời gian",
  IDUrl: "Sản phẩm/URL/Liên kết",
  sort: "Sắp xếp",
  popupMode: "Loại",
  popUpImg: "Hình ảnh",
  popUpTime: "Thời gian",
  popUpMode: "Loại",
  popUpSort: "Thứ tự",
  OnceDay: "một lần mỗi ngày",
  Once: "chỉ một lần",
  always: "luôn luôn"
};
