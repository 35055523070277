export default {
  Campaign_Page_Management: "动态页配置",
  Title_Name: "标题名称",
  _Campaign_Type: "活动类型",
  Campaign_Time: "活动时间",
  Add_New: "新增",
  Thai: "泰文",
  English: "英文",
  Vnvi: "越南",
  Banner_Image: "Banner图",
  Configuration_Page_Link: "配置页链接",
  Delete: "删除",
  Confirm_Deletion: "确认删除",
  Are_you_sure_you_want_to_delete_this_campaign_page_: "您确认要删除该动态配置页吗？",
  This_link_is_already_active_on_the_homepage__Please_turn_it_off_before_editing_or_deleting_:
    "该链接已在首页上架，请下架后再操作编辑或删除。",
  Recommended_size__Width_750_pixels__size_limit_2MB__It_supports_only_png__jpg__jpeg__gif_formats__Only_one_image_can_be_uploaded_at_a_time_:
    "建议尺寸：宽度为750像素，大小限制为2MB；仅支持png，jpg，jpeg，gif格式。单次只能上传一张。",
  _Background_Color_: "背景颜色：",
  Default_background_color___f5f5f5: "默认背景色：#f5f5f5",
  Switch_Campaign_Type: "切换活动类型",
  Switching_the_type_will_not_save_the_settings_of_the_original_type__Do_you_want_to_continue_:
    "切换类型将不保存原类型下的设置内容，你还要继续吗？",
  Cancel: "取消",
  Confirm: "确定",
  Product_List_Configuration: "商品配置",
  _Product_List_ID_: "瀑布流ID：",
  Product_list_ID_can_be_found_in_the_Operation_Management___Product_List_:
    "瀑布流ID可在运营管理-瀑布流配置中找到。",
  Save: "保存",
  Save_and_Preview: "保存并预览",
  After_cancellation__the_content_you_edited_will_not_be_saved__Do_you_want_to_continue_:
    "取消后，您编辑的内容将不会保存，是否继续操作？",
  Product_List: "商品推荐",
  Campaign_Image: "活动图",
  Title: "标题",
  _Title__Thai__: "标题（泰文）：",
  _Title__English__: "标题（英文）：",
  _Title__VN__: "标题（越南语）：",
  Basic_Information_Setting: "基本信息设置",
  New_Edit_Campaign_Page: "新增/编辑动态页"
};
