export default {
  total: "Total",
  pleaseInput: "Please enter",
  pleaseSelect: "Please select",
  expand: "Expand",
  collapse: "Collapse",
  importShop: "Import",
  exportTemplate: "Export",
  image: "Item image",
  name: "List Name",
  price: "Price",
  commission: "Commission",
  commissionRate: "Commission Rate%",
  status: "Status",
  sort: "Sort",
  upSelf: "Active",
  downSelf: "Inactive",
  itemId: "Item ID ",
  Sort: "Sort",
  Reject: "Reject",
  Submitted: "submitted",
  Passed: "Passed",
  Delivered: "Shipping",
  Completed: "Completed",
  TT商品: "Tiktok Products",
  支持扩展名: "Support format"
};
