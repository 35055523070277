export default {
  新人样品池配置: "การจัดการสินค้าตัวอย่างสำหรับลูกค้าใหม่",
  保存: "บันทึก",
  "保存后瀑布流ID将生效，你还要继续吗？":
    '<span style="color: red;">ID รายการสินค้าจะมีผลหลังจากการบันทึก</span> คุณต้องการดำเนินการต่อไหม?',
  确认保存: "ยืนยันการบันทึก",
  "保存失败：瀑布流不存在！": "การบันทึกล้มเหลว: ไม่มี ID รายการสินค้า!",
  "操作成功！": "ดำเนินการสำเร็จ!",
  继续: "ดำเนินการต่อ",
  取消: "ยกเลิก"
};
