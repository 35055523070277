export default {
  merchantSubsidies: "เงินสบทบจากร้านค้า",
  pdpDescription: "คำอธิบายสินค้า",
  generate: "สร้างลิงก์",
  LazadaTwotyDaySales: "ยอดขายย้อนหลัง 2 สัปดาห์",
  LazadaHotSellingTrends: "สินค้าขายดี",
  AllianceSales: "ยอดขาย",
  customSort: "เรียงลำดับ",
  addConditions: "เพิ่ม",
  ascendingOrder: "น้อยไปมาก",
  descendingOrder: "มากไปน้อย",
  priority: "ลำดับ",
  sortField: "เงื่อนไข",
  order: "ลำดับ",
  moreFourCondition: "สามารถเพิ่มเงื่อนไขได้สูงสุด 4 รายการ",
  LazadaThirtyDaySales: "ยอดขายของ Lazada ภายใน 30 วัน",
  updateSituation: "อัปเดต",
  updated: "อัปเดตเรียบร้อย",
  notUpdated: "ยังไม่อัปเดต",
  WhoAdded: "ชื่อผู้ใช้",
  Lazada下载导入: "link",
  Youpik: "Youpik",
  Users: "ผู้ใช้",
  // tiktok快速需求
  duplicateCommodity: "สินค้าซ้ำ",
  repeat: "ทำซ้ำ",
  notRepeated: "ยังไม่ซ้ำ",
  haveToBeSynchronizedTips: "กิจกรรมใหม่กำลังรอการเชื่อมต่อ กรุณายืนยันหมวดหมู่",
  CommissionRateRange: "อัตราค่าคอมมิชชั่น",
  CreatorCommissionRate: "อัตราค่าคอมมิชชั่น",
  CreatorCommission: "ค่าคอมมิชชั่นสำหรับครีเอเตอร์",
  classification: "หมวดหมู่",
  sellerStatus: "ผู้ขาย",
  synchronization: "เชื่อมกิจกรรม",
  toRelease: "รอดำเนินการ",
  beginInAMinute: "กำลังเริ่ม",

  productId: "สินค้าYoupik",
  YoupikShop: "สินค้าYoupik",
  LazadaShop: "สินค้าลาซาด้า",
  lowToheight: "ราคาจากต่ำไปสูง",
  heightTolow: "ราคาจากมากไปน้อย",
  saleHeightTolow: "ยอดขายจากมากไปน้อย",
  sale: "ยอดขาย",
  actualSale: "ยอดขายจริง",
  lazadaCategore: "สินค้า Lazada",

  CommissionHeightTolow: "คอมมิชชั่นจากสูงไปต่ำ",
  commissionRateHeightTolow: "ค่าคอมมิชชั่นจากมากไปน้อย",
  addtips: "รายการแล้ว",
  noAddShop:
    "เพิ่มรายการสำเร็จ : มีบางรายการไม่สามารถเพิ่มได้เนื่องจากเหตุผลต่อไปนี้ แต่เพิ่มส่วนที่เหลือเรียบร้อยแล้ว",
  // addFailed: '添加失败，请检查网络设置',
  failedID: "รหัสสินค้า",
  failedReason: "เพิ่มล้มเหลว สาเหตุ สินค้าถูกปิดใช้งานจาก Youpik",
  pleasePutLink: "กรุณาใส่ลิงก์",
  PackageWeight: "น้ำหนักของบรรจุภัณฑ์ (รวมผลิตภัณฑ์) กก",
  PackageInfoTitle: "การจัดส่งและการรับประกัน",
  PackageSize: "แพคเกจขนาด (ซม.)",
  SpecialPrice: "ราคาพิเศษ",
  SpecialPriceTime: "ช่วงเวลาโปรโมชั่น",
  AboutDetailItem: "เกี่ยวกับรายละเอียดสินค้า",
  Color: "สี",
  Price: "ราคา",
  AllItem: "ทั้งหมด",
  OnlineItems: "ออนไลน์",
  InReview: "รอการตรวจสอบ",
  ShelvesItem: "สินค้าถูกปิดการใช้งาน",
  PlatformShelvesItem: "ระงับ",
  Category: "หมวดหมู่",
  ItemId: "ไอดีสินค้า",
  ItemName: "ชื่อสินค้า",
  SellerSKU: "รหัสเก็บสินค้า",
  StoreSKU: "รหัส SKU สินค้าของร้านค้า",
  ItemImage: "รูปภาพสินค้า",
  ItemInfo: "เนื้อหาสินค้า",
  ItemPrice: "ราคาสินค้า",
  ItemStatus: "สถานะสินค้า",
  StoreId: "ไอดีร้านค้า",
  CreateTime: "เวลาที่สร้าง",
  Operation: "การดำเนินการ",
  AlreadyStore: "เปิดใช้งานแล้ว",
  handleShlfTips: "ตั้งค่าสินค้าบนสล๊อตเกินจำกัด",
  NotInStore: "ปิดงานใช้งานแล้ว",
  SoldOut: "หมดสต็อก",
  Update: "อัปเดท",
  Inventory: "สต็อกสินค้า",
  Item: "สินค้า",
  PlaceSeleteDeleteItem: "กรุณาเลือกสินค้าที่ต้องการลบ",
  ItemProperties: "คุณสมบัติสินค้า",
  ItemTitle: "ชื่อผลิตภัณฑ์",
  TitlePlacehold: "เช่น กล้องดิจิตอล Nikon Coolpix A300",
  ItemEnTitle: "ชื่อสินค้าภาษาอังกฤษ",
  EnTitlePlacehold: "กรุณากรอกชื่อสินค้าภาษาอังกฤษ",
  CategoryPlacehold: "กรุณากรอกชื่อสินค้าภาษาอังกฤษ",
  SeleteCategory: "เลือกหมวดหมู่",
  Seletion: "ที่คุณเลือกอยู่ตอนนี้คือ",
  Brand: "ยี่ห้อ",
  HomeImgUrl: "รูปสินค้าหลัก",
  ItemContent: "คำอธิบาย",
  ItemContentEn: "รายละเอียดสินค้าภาษาอังกฤษแบบเต็ม",
  ItemShortDesc: "คำบรรยายสินค้าสั้นๆ",
  ItemShortDescEn: "คำบรรยายสินค้าสั้นๆ เป็นภาษาอังกฤษ",
  GetItems: "สินค้าภายในกล่อง",
  GetItemsEn: "สินค้าภายในกล่อง(ภาษาอังกฤษ)",
  ItemMainVariant: "ข้อมูลจำเพาะหลักของผลิตภัณฑ์",
  MainVariantTip: "กรุณากรอกชื่อตัวเลือกข้อมูลจำเพาะหลัก เช่น สีแดง สีเหลือง",
  MainVariantSelete: "ตัวเลือกข้อมูลจำเพาะหลัก",
  AddSelete: "เพิ่มตัวเลือก",
  DeleteSelete: "ลบตัวเลือก",
  ItemSecondVariant: "ชื่อข้อมูลจำเพาะย่อย(กำหนดเอง)",
  // ItemSecondAttribute: "次属性名称（自定义）",
  SecondVariantTip: "กรุณากรอกประเภทของข้อมูลจำเพาะย่อยเช่นขนาด",
  ClickUpload: "คลิกเพื่ออัปโหลด",
  UploadTip:
    "รูปแรกเป็นหน้าปก แนะนำให้อัปโหลดเพียงรูปเดียว อัปโหลดได้มากสุด 8 ภาพที่มีขนาดระหว่าง 500 × 500 ถึง 2000 × 2000px พิกเซล",
  AuditTipInfo: "ยืนยันว่าจะส่งผลการตรวจสอบใช่หรือไม่?",
  RejectTipInfo: "ยืนยันว่าต้องการปฏิเสธยื่นคำร้องใช่หรือไม่?",
  RejectTipReason:
    "กรณีปฏิเสธจำเป็นต้องกรอกความคิดเห็นการอนุมัติที่มีอักขระมากกว่าหรือเท่ากับหกตัวขึ้นไป",
  StockNum: "จำนวนสต็อก",
  Variants: "ตัวแปร",
  GifItem: "สินค้า Youpik Choice",
  PlatformActiveSelect: "การเลือกแพลตฟอร์มเปิด/ปิดการใช้งาน",
  AllPlatformActive: "ทั้งหมด(แพลตฟอร์มเปิด/ปิดการใช้งาน)",
  PlatformRejectActive: "ระงับ",
  PlatformAgreeActive: "แพลตฟอร์มเปิดใช้งาน",
  BusinessActiveSelect: "การเลือกร้านค้าเปิด/ปิดการใช้งาน",
  AllBusinessActive: "ทั้งหมด(ร้านค้าเปิด/ปิดการใช้งาน)",
  BusinessRejectActive: "ร้านค้าปิดการใช้งาน",
  BusinessAgreeActive: "ร้านค้าเปิดใช้งาน",
  IsSellOut: "ขายหมดแล้วใช่หรือไม่",
  AllSellOut: "ทั้งหมด(ขายหมดแล้วใช่หรือไม่",
  SellOut: "สินค้าหมด",
  NoSellOut: "ยังขายไม่หมด",
  ActiveItemTip: "ยืนยันว่าต้องการเปิดใช้งานสินค้าใช่หรือไม่??",
  DisabledItemTip: "ยืนยันว่าต้องการปิดใช้งานสินค้าใช่หรือไม่?",
  AcitveGifItemTip: "ยืนยันว่าต้องการตั้งค่าสินค้าชิ้นนี้ให้เป็นสินค้า Youpikchoice ใช่หรือไม่?",
  DisabledGifItemTip: "ยืนยันว่าต้องการยกเลิกสินค้า Youpikchoice นี้ใช่หรือไม่?",
  SellPrice: "ราคาขาย",
  PostAuditTimeRange: "ขอบเขตเวลาการส่งตรวจสอบ",
  AuditedTimeRange: "ขอบเขตเวลาการตรวจสอบเสร็จสมบูรณ์",
  SeellerShopSku: "Shop SKU",
  AuditStatu: "สถานะการตรวจสอบ",
  PostTime: "เวลาที่ส่ง",
  AuditTime: "เวลาตรวจอบ",
  AuditId: "รหัสบันทึกการอนุมัติ",
  SellerLoginName: "ชื่อเข้าสู่ระบบร้านค้า",
  SellerId: "รหัสร้านค้า",
  AuditOpinion: "ความคิดเห็นเกี่ยวกับการอนุมัติ",
  ToBeReviewed: "รอการตรวจสอบ",
  MachineAuditAgree: "อนุมัติโดยบอท",
  MachineAuditReject: "ปฏิเสธโดยบอท",
  PeopleAuditAgree: "อนุมัติโดยคน",
  PeopleAuditReject: "ปฏิเสธโดยคน",
  BeOverdueAudit: "การตรวจสอบหมดอายุแล้ว",
  AutomaticGeneration: "สร้างขึ้นโดยอัตโนมัติ",
  AuditAction: "ตรวจสอบการดำเนินการ ",
  Long: "ความยาว",
  Width: "ความกว้าง",
  Height: "ความสูง",
  PlaceInputAuditOpinion: "กรุณากรอกความคิดเห็นการตรวจสอบ",
  Nothing: "ไม่มี",
  // lazada
  weight: "น้ำหนัก",
  homePageSelection: "หน้าหลัก",
  itemWeight: "น้ำหนักสินค้า",
  setItemWeightTitle: "กรุณาตั้งน้ำหนักสินค้า เลขยิ่งน้อย น้ำหนักสินค้ายิ่งสูง",
  销量: "ยอดขาย",
  更新时间: "อัพเดทเวลา",
  uChoice链接: "ลิงก์ uChoice",
  TikTok链接: "ลิงก์ TikTok",
  "30天销量": "ยอดขาย 30 วัน",
  TikTok总销量: "ยอดขายทั้งหมดจาก TikTok"
};
