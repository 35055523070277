import { Spin } from "antd";
import styles from "./loading.less";

export default function Loading() {
  return (
    <div className={styles["loading"]}>
      <div className={styles["loading-content"]}>
        <Spin size='large' />
        <span className={styles["loading-text"]}>Loading ...</span>
      </div>
    </div>
  );
}
