import homeRoute from "./home";
import performanceRoute from "./performance";
import loginRoute from "./login";
import operationRoute from "./operation";
import userManageRoute from "./user-manage";
import ttProductsRoute from "./tt-products";
import orderManagementRoute from "./orderManagement";
import salesDataRoute from "./salesData";
import sheetRoute from "./sheet";
import supplierManageRoute from "./supplierManagement";
import pushRoute from "./push";

const routes: IRouteProps[] = [
  {
    path: "/",
    redirect: "/rootRedirect",
    hideInMenu: true
  },
  {
    path: "/rootRedirect",
    name: "rootRedirect",
    component: "./rootRedirect",
    hideInMenu: true
  },
  ...homeRoute,
  ...performanceRoute,
  ...operationRoute,
  ...userManageRoute,
  ...ttProductsRoute,
  ...loginRoute,
  ...orderManagementRoute,
  ...salesDataRoute,
  ...sheetRoute,
  ...supplierManageRoute,
  ...pushRoute,
  {
    layout: false,
    component: "./404"
  }
];

export default routes;
