import React from "react";
import { Input } from "antd";
import { isTHRegion } from "@/utils/storage";

const NumberInput = ({
  value,
  onChange,
  maxLength,
  showCount,
  onBlur,
  widthStyle,
  isWithPoint = false,
  ...others
}: any) => {
  const handleInputOnChange = (e: any) => {
    const changedValue = e.target.value;
    if (isWithPoint) {
      if (isTHRegion()) {
        //只包含数字、小数点，并且最多只能有两位小数、限制1-8整数
        onChange(changedValue.replace(/^(\d{1,8})(\.\d{0,2})?.*$/, "$1$2"));
      } else {
        onChange(changedValue.replace(/^\D*(\d{0,8}).*$/, "$1"));
      }
    } else {
      onChange(changedValue.replace(/\D/g, ""));
    }
  };

  return (
    <Input
      value={value}
      autoComplete='off'
      style={{ width: widthStyle ? widthStyle : 200 }}
      maxLength={maxLength}
      showCount={showCount}
      onChange={handleInputOnChange}
      onBlur={onBlur}
      {...others}
    />
  );
};

export default NumberInput;
