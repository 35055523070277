const order_icon =
  "https://file-th.ypkclient.com/system/20230915/3cb97a40f90d4e6db666d4928c5ccf2b.png";
const order_icon_active =
  "https://file-th.ypkclient.com/system/20230915/60a19cd2d09740f0883a35146f38a818.png";

const orderManagementRoute: IRouteProps[] = [
  {
    path: "/orderManagement",
    name: "样品申请",
    meta: { icon: order_icon, activeIcon: order_icon_active },
    component: "./orderManagement/sampleApp"
  },
  {
    path: "/orderManagement/sampleApp/detail/:id",
    name: "sampleApplicationDetail",
    component: "./orderManagement/detail",
    hideInMenu: true
  }
];

export default orderManagementRoute;
