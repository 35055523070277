export default {
  StartTime: "เวลาเริ่ม",
  Tip: "เคล็ดลับ",
  EndTime: "เวลาสิ้นสุด",
  ConfirmDelete: "ยืนยันการลบ",
  DeleteSuccess: "ลบแล้ว",
  Canceled: "ยกเลิกแล้ว",
  ActionSuccess: "ดำเนินการแล้ว",
  ActionError: "การดำเนินการล้มเหลว",
  ConfirmDeletes: "กรุณายืนยันว่าต้องการลบจำนวนมาก",
  ContentCopiedClipboard: "เนื้อหาถูกคัดลอกไปยังคลิปบอร์ดแล้ว！",
  SorryCopyFailed: "คัดลอกไม่สำเร็จ",
  TemporarilyNoData: "ไม่พบข้อมูล",
  uploadRejected: "อัปโหลดไม่สำเร็จ กรุณาลองอีกครั้ง!",
  isDeleteImg: "ต้องการลบภาพใช่หรือไม่?",
  goBeyondFilNum: "อัปโหลดไฟล์เกินขนาด!",
  imgBigLimit: "ขนาดรูปภาพห้ามเกิน 20M!",
  当前限制选择x个图片: "ปัจจุบันจำกัดให้เลือก {limit} ภาพ",
  refresh: "รีเฟรช"
};
