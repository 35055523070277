import { useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

import HeaderDropdown from "@/components/Layout/HeaderDropdown";

import { BASE_URLS } from "@/utils/getBaseUrl";
import { apiEnvStorage } from "@/utils/storage";

import styles from "./index.less";

const getEnvText = (key: TEnvEnmu) => {
  switch (key) {
    case "DEV":
      return "开发";
    case "STAGING":
      return "测试";
    case "PROD":
      return "正式";
    case "PRE":
      return "预发";
    default:
      return "正式";
  }
};

export interface IEnvProps {
  wrapClassName?: string;
}

const Env: React.FC<IEnvProps> = ({ wrapClassName }) => {
  const [env, setEnv] = useState(apiEnvStorage.get() || (process.env.API_ENV as TEnvEnmu));

  const handleClickItem: MenuProps["onClick"] = (it) => {
    const { key } = it;
    apiEnvStorage.set(key as TEnvEnmu);
    setEnv(key as TEnvEnmu);
    window.location.reload();
  };

  const menuHeaderDropdown = (
    <Menu onClick={handleClickItem} selectedKeys={[env]}>
      {Object.keys(BASE_URLS).map((it) => (
        <Menu.Item key={it as TEnvEnmu}>{getEnvText(it as TEnvEnmu)}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown} className={wrapClassName}>
      <span className={styles.action}>
        <span className={`${styles.env_desc} `}>{getEnvText(env)}</span>
        <DownOutlined style={{ marginLeft: "5px", fontSize: "12px" }} />
      </span>
    </HeaderDropdown>
  );
};

export default Env;
