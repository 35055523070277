export default {
  有效商品: "Sản phẩm hợp lệ",
  失效商品: "Sản phẩm không hợp lệ",
  待处理商品: "Sản phẩm đang chờ xử lý",
  批量上架: "Kích hoạt hàng loạt",
  确认商品上架至商品集: "Xác nhận kích hoạt sản phẩm vào danh sách",
  "商品上架后商品集用户可浏览/复制链接/申样该商品，你还要继续吗？":
    '<span style="color: red">Sau khi sản phẩm được kích hoạt, người tạo có thể duyệt, sao chép liên kết và đăng ký mẫu miễn phí.</span> Bạn có muốn tiếp tục không?',
  上架: "Hoạt động",
  "count个商品均已上架！": "Tất cả {count} sản phẩm đã được kích hoạt!",
  "successCount个商品已上架，failedCount个商品已失效，请前往失效商品查看！":
    "{successCount} sản phẩm đã được kích hoạt, {failedCount} sản phẩm không hợp lệ. Vui lòng kiểm tra trong danh sách sản phẩm không hợp lệ!",
  商品失效原因: "Lý do không hợp lệ của sản phẩm",
  修改商品集: "Chỉnh sửa Danh sách Sản phẩm",
  导出商品集: "Xuất danh sách",
  "仅导出当前有效商品列表，你还要继续吗？": "Chỉ xuất danh sách các sản phẩm hiện tại.",
  "同步中…大概需要3分钟": "Đang đồng bộ... Có thể mất khoảng 3 phút",
  "同步失败，请联系技术人员": "Đồng bộ thất bại, vui lòng liên hệ với bộ phận kỹ thuật",
  同步完成: "Đồng bộ hoàn thành",
  无需同步: "Không cần đồng bộ",
  编辑商品集: "Chỉnh sửa Danh sách Sản phẩm",
  "编辑专题+列表页面": "Chỉnh sửa Trang chủ + Trang danh sách",
  仅编辑专题页面: "Chỉnh sửa Trang chủ",
  "list主题（泰文）": "Chủ đề danh sách {index} (Tiếng Thái)",
  "list主题（越南）": "Danh sách chủ đề (Việt Nam)",
  商品集同步状态: "Trạng thái đồng bộ Danh sách Sản phẩm",
  专题页面预览: "Xem trước Trang chủ",
  列表页面预览: "Xem trước Trang danh sách",
  样式一: "Kiểu 1",
  样式二: "Kiểu 2",
  Earn: "Nhận",
  高佣活动结束: "Chiến dịch kết thúc vào ngày {date}",
  已售: "{value} Sold",
  预览: "Xem",
  "Get uChoice Link": "Lấy link",
  活动已结束: "Kết",
  商家已下架: "Ngừng kinh doanh",
  平台已下架: "Dừng hoạt động",
  "Sort by": "Sắp xếp theo",
  平台上架: "Trạng thái trên nền tảng",
  上架1: "Đăng bán",
  下架1: "Gỡ xuống",
  全部1: "Tất cả",
  新品筛选: "Lọc sản phẩm mới",
  过去x天: "{day} ngày qua:"
};
