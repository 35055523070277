const loginRoute: IRouteProps[] = [
  {
    path: "/login",
    name: "登录",
    component: "./login",
    layout: false,
    hideInMenu: true
  }
];

export default loginRoute;


