export default {
  TAP销售类目: "Danh mục bán hàng TAP",
  全平台销售类目: "Danh mục bán hàng toàn nền tảng",
  手动添加: "Thêm thủ công",
  APP注册: "Đăng ký APP",
  达人公海: "Biển công cộng của chuyên gia",
  跟进记录: "Ghi chép theo dõi",
  是否意愿申样: "Có muốn thử mẫu không",
  每条视频报价: "Báo giá mỗi video",
  达人来源: "Nguồn của chuyên gia",
  建联渠道: "Kênh kết nối",
  请至少输入五个字符: "Vui lòng nhập ít nhất năm ký tự",
  minPriceLessThanMaxPrice: "Số tiền tối thiểu phải nhỏ hơn hoặc bằng số tiền tối đa",
  TT昵称: "Biệt danh TT",
  TT唯一名: "Tên duy nhất TT",
  MCN: "MCN",
  视频平均播放: "Số lượt xem trung bình của video",
  粉丝范围: "Phạm vi người hâm mộ",
  月销GMV: "GMV bán hàng hàng tháng",
  月销视频GMV: "GMV video bán hàng hàng tháng",
  月销直播GMV: "GMV livestream bán hàng hàng tháng",
  销售类目Top3: "Danh mục bán hàng (top 3)",
  销售类目: "Danh mục bán hàng",
  联系LINE: "Liên hệ LINE",
  联系WhatsAPP: "Liên hệ WhatsApp",
  联系邮箱: "Liên hệ Email",
  公海: "Biển công cộng",
  建联: "Kết nối",
  已建联: "Đã kết nối",
  建联成功Tips: "Kết nối thành công! Có thể {{btn}}",
  前往跟进达人查看: "Đi đến 【Theo dõi chuyên gia】 để xem",
  建联失败Tips: "Kết nối thất bại! Chuyên gia hiện tại đã được kết nối, vui lòng làm mới danh sách",
  建联失败: "Ghi chép theo dõi ít hơn năm ký tự / Vui lòng chọn kênh liên hệ",
  联系渠道: "Kênh liên hệ",
  暂不明确: "Chưa rõ ràng",
  建联达人: "Tạo mới Creator",
  报价金额: "Số tiền báo giá",
  "30GMV": "30-Day GMV",
  UserId: "ID Người Dùng",
  Label: "kí hiệu",
  RegisterTime: "Thời Gian Đăng Ký",
  ScreenshotData: "Ảnh chụp màn hình sản phẩm",
  followUp: "PIC",
  lineId: "ID Line",
  Edit_Label: "Chỉnh Sửa Nhãn",
  UserList: "Danh Sách Người Dùng",
  sureModifyFans: "Chắc Chắn Sửa Số Lượng Người Theo Dõi",
  followUpPerson: "Xác Nhận Sửa Người Theo Dõi",
  bindGoods: "Sản Phẩm Hợp Tác",
  productImage: "Hình Ảnh Sản Phẩm",
  productName: "Tên Sản Phẩm",
  productId: "ID Sản Phẩm",
  anchorSales: "Doanh Số Người Tạo Nội Dung",
  activityTime: "Thời Gian Chiến Dịch",
  activityId: "ID Chiến Dịch",
  activityStatus: "Trạng Thái Chiến Dịch",
  onSaleStatus: "Trạng Thái Đang Bán",
  offlineUser: "Người Dùng Offline",

  All: "Tất cả",
  toRelease: "Chờ phát hành",
  beginInAMinute: "Sắp bắt đầu",
  Ongoing: "Đang diễn ra",
  Closed: "Đã đóng",

  invalid: "Hết hạn",
  video: "Video TikTok",
  UsingModules: "Sản phẩm đang sử dụng",
  TotalLikes: "Tổng lượt thích",
  NumberOfVideos: "Video",
  TotalVisits: "Tổng lượt xem",
  NumberOfFans: "Người theo dõi",
  ViewsToVideo: "Lượt xem video",
  ViewsLive: "Lượt xem trực tiếp",
  AverageLikeVideo: "Lượt thích trung bình của video",
  AverageLikeLive: "Lượt thích trung bình của trực tiếp",
  GPMVideo: "GPM của video",
  GPMLive: "GPM của trực tiếp",
  uchoiceUser: "Người dùng Uchoice",
  ttUser: "Người dùng TikTok",
  identity: "Định danh",
  uchoicettUser: "Người dùng uchoice-tt",
  Whetherttauthorized: "Có được ủy quyền Tiktok?",
  ttUserName: "Tên người dùng TikTok",
  ttId: "ID TikTok",
  sku: "Quy cách",
  itemLink: "Liên kết sản phẩm",
  applyTime: "Ngày đăng ký",
  salesScreenshot: "Ảnh chụp màn hình GMV",
  系统更新: "Được cập nhật bởi hệ thống",
  系统获取: "Hệ thống thu thập",
  上传橱窗证明: "Tải bằng chứng trưng bày lên thủ công",
  GMV数据: "  Dữ liệu Số Lượng Giao Dịch",
  无需上传: "Không cần tải lên",
  已审核: "Đã duyệt",
  JanuaryGMV: "GMV trong tháng qua",
  RecipientName: "Tên người nhận",
  RecipientTel: "Số điện thoại người nhận",
  RecipientAddress: "Địa chỉ người nhận",
  ttAuthorizationTime: "Thời gian ủy quyền",
  ttNumberOfFans: "Người theo dõi TikTok",
  GMPToVideoLive: "GPM (Video/Trực tiếp)",
  ViewsToVideoLive: "Lượt xem Video/Live",
  Viewingtimes: "Số lần xem (Video/Trực tiếp)",
  AverageLikeLiveVideo: "Lượt thích trung bình (Video/Trực tiếp)",
  Videolist: "Danh sách Video",
  TotalNumberOfVideos: "Số lượng Video",
  FieldAccess: "Lượt xem trung bình của video",
  AverageLikesPerGame: "Lượt thích trung bình của video",
  AverageNumberOfComments: "Số lượng bình luận trung bình của video",
  AverageNumberOfForwardingInTheField: "Số lượng chia sẻ trung bình của video",
  AverageShare: "Chia sẻ trung bình của video",
  videoid: "ID Video",
  ReleaseTime: "Thời gian đăng",
  duration: "Thời lượng(s)",
  NumberOfLikes: "Số lượt thích",
  NumberOfComments: "Số lượt bình luận",
  NumberOfVisits: "Số lượt xem",
  NumberOfShares: "Số lượt chia sẻ",
  NumberOfForwards: "Số lượt chia sẻ",
  WithLinkOrNot: "Video có sản phẩm không?",
  yes: "Có",
  no: "Không",

  userTitle: "Quản lý tài khoản người dùng Uchoice",
  userID: "ID người dùng",
  userPhone: "Số điện thoại",
  registerUserPhone: "Số điện thoại đăng ký",
  telephone: "Số điện thoại liên hệ",
  EntryTime: "Thời gian yêu cầu",
  RegistraTime: "Thời gian đăng ký",
  integralSet: "Cấu hình điểm thưởng",
  confirm: "Xác nhận gửi đề xuất để xem xét",
  uliveTitle: "Quản lý tài khoản người dùng Ulive",
  LastOnlineTime: "Thời gian hoạt động gần đây nhất",
  shopName: "Tên cửa hàng",
  facebook: "Tên trang Facebook",
  BindTime: "Thời gian kết nối",

  OpenImportLink: "Mở liên kết nhập",
  目标用户: "Người dùng mục tiêu",
  目标用户无商品: "Người dùng mục tiêu không có sản phẩm",
  目标用户有商品: "Người dùng mục tiêu có sản phẩm",
  editTelephone: "Bạn có muốn sửa số điện thoại liên hệ không?",

  修改TT用户名: "Sửa tên người dùng TikTok",
  正在修改xxx的TT用户名: "Đang sửa tên người dùng TikTok cho xxx",
  新TT用户名: "Tên người dùng TikTok mới",
  确认修改TT用户名: "Xác nhận thay đổi tên người dùng",
  "原用户名xxx将改为xxx,你还要继续吗?":
    "Tên người dùng gốc xxx sẽ được thay đổi thành xxx. Bạn có muốn tiếp tục không?",
  修改的TT用户名重复: "Tên người dùng TikTok đã được sửa đổi trùng lặp.",
  xxx已存在如确认修改用户及样品订单数据将会合并你还要继续吗:
    "xxx đã tồn tại. Nếu bạn xác nhận thay đổi, dữ liệu người dùng và đơn hàng mẫu sẽ được hợp nhất. Bạn có muốn tiếp tục không?",
  新增用户: "Thêm người dùng mới",
  新增线下已建联的用户: "Thêm người dùng mới đã kết nối offline",
  联系方式: "Thông tin liên hệ:",
  请输入电话号码: "Vui lòng nhập số điện thoại",
  或者: "hoặc",
  "请输入LINE ID": "Vui lòng nhập LINE ID",
  标签: "kí hiệu",
  必填: "Bắt buộc",
  "若不填LINE ID必填": "Nếu không điền, LINE ID là bắt buộc",
  若不填电话必填: "Nếu không điền, số điện thoại là bắt buộc",
  手机或line必填一个: "Vui lòng nhập số điện thoại hoặc LINE ID",
  Reauthorize: "cấp lại quyền",
  类目: "Danh mục",
  橱窗销售截图: "Ảnh chụp màn hình bán hàng",
  橱窗月销售GMV: "GMV trong tháng qua",

  达人链接管理: "quản lý liên kết sản phẩm của nhà sáng tạo",
  链接管理: "Quản lý liên kết",
  合作链接: "Liên kết sản phẩm hợp tác",
  被替换商品: "Liên kết sản phẩm đã được thay thế",
  下架商品: "Sản phẩm không hợp lệ",
  活动过期链接: "Liên kết sản phẩm đã hết hạn",
  合作过商品总数: "Tổng số sản phẩm đã hợp tác",
  操作: "Vận hành",
  查看详情: "Xem chi tiết",
  链接状态: "Trạng thái liên kết",
  合作中: "Đang hợp tác",
  商品下架: "Sản phẩm không hợp lệ",
  被替换: "Đã được thay thế",
  活动过期: "Chương trình đã hết hạn",
  同商品合作次数: "Số lần hợp tác cho cùng một sản phẩm",
  是否授权: "Đã được ủy quyền",
  已授权: "Đã ủy quyền",
  未授权: "Chưa được ủy quyền",
  活动过期商品: "Sản phẩm chương trình đã hết hạn",
  达人佣金率: "Tỷ lệ hoa hồng của người tạo",
  达人佣金: "Hoa hồng của người tạo",
  链接: "Liên kết",
  当前状态: "Trạng thái hiện tại",

  视频: "đoạn phim",
  直播: "phát trực tiếp"
};
