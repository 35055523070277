export default {
  supplierTime: "ตรวจสอบโดยผู้ขาย",
  deliveryTime: "เวลาจัดส่ง",
  completeTime: "เวลาเสร็จสิ้น",
  closeTime: "เวลาปิด",
  履约申请时间: "เวลาขอบรรลุการปฏิบัติ",
  履约拒绝时间: "เวลาปฏิเสธการปฏิบัติ",
  履约通过时间: "เวลาอนุมัติการปฏิบัติ",
  mcn: "mcn",
  NameOfTalent: "ชื่อครีเอเตอร์",
  numOfTalent: "บัญชี TikTok",
  TAPID: "TAP ID ",
  tradingStatus: "สถานะสินค้า",
  ScreenshotSales: "ภาพหน้าจอข้อมูลการขายใน Showcase",
  TAPActivityInformation: "ข้อมูล TAP",
  lastPage: "ก่อนหน้า",
  nextPage: "ถัดไป",
  CourierCompany: "จัดส่งโดย",
  GoToFlashOfficialWebsite: "หมายเลขติดตามพัสดุ",
  flashMessage: "การจัดส่ง",
  ScreenshotData: "ภาพหน้าจอข้อมูลการขายใน Showcase",
  followUp: "ผู้ดูแล",
  lineId: "Line ID",
  track: "ติดตามสถานะการจัดส่ง",
  orderStatusWillChang: "สถานะคำสั่งซื้อจะเปลี่ยนเป็นกำลังจัดส่ง",
  ship: "การจัดส่ง",
  screenDataNo:
    "สินค้าตัวอย่างไม่ได้รับการอนุมัติ เนื่องจากยอดขายไม่ผ่านเกณพ์พิจรณาจากร้านค้า กรุณาเลือกสินค้าใหม่อีกครั้งหรือกดซื้อตัวอย่างด้วยตัวเอง",
  // sampleReject: '非常抱歉，因商家缺货，您的样品申请被拒绝。',
  // sampleRequestFailed: '非常抱歉，因物流原因，您的样品申请失败。',
  // otherReasons: '其他原因填写',
  InitialConditionSetting: "การตั้งค่าเริ่มต้น",
  AverageVideoPlayback: "วิดีโอ",
  videoPlayback: "วิดีโอ",
  accountCanOnlyApply: "บัญชี KOL TikTok สามารถยื่นขอสินค้าตัวอย่างได้สูงสุด",
  oneSamples: " ชิ้น/บัญชี เท่านั้น ",
  afterTheApplication: "แต่หากยอดขายสินค้าที่ขอตัวอย่างไปมากกว่า",
  sampleApplication: "คำสั่งซื้อ คุณมีสิทธิได้รับการพิจารณาเพื่อรับสินค้าตัวอย่างเพิ่มอีก 1 รายการ",
  ttStoreName: "ชื่อ TikTok Shop ",
  sureModifyFans: "กรุณาตรวจสอบการแก้ไขจำนวนผู้ติดตาม",
  sureModifyVideo: "คุณแน่ใจหรือไม่ว่าต้องการแก้ไขจำนวนครั้งการดูวิดีโอ",
  Submitted: "ส่งคำขอเรียบร้อย",
  followUpPerson: "คุณยืนยันที่จะแก้ไขผู้ดูแลหรือไม่",
  goToLogisticsOfficialWebsite: "ไปที่เว็บไซต์ของบริษัทขนส่ง",

  YoupikCategore: " หมวดหมู่ Youpik",
  lazadaName: "Lazada categore Name",
  youPikName: "Youpik categore Name",

  buyingAttr: "ประเภทสินค้า",
  commonItem: "สินค้าทั่วไป",
  giftItem: "สินค้า Youpik Choice",
  activityItem: "สินค้าที่ร่วมกิจกรรม",

  RefundID: "รหัสเรียกค่าสินค้าคืน",
  RefundMethod: "ประเภทการเรียกค่าสินค้าคืน",
  BankCardRefund: "คืนค่าสินค้าผ่านบัตรเครดิต",
  KsherRefund: "คืนค่าสินค้าผ่าน Ksher",
  remark: "เงื่อนไข",
  RefundTips:
    "ยืนยันสถานะการคืนค่าสินค้าสำเร็จค่าสินค้าได้ถูกคืนแล้ว และสถานะปการสั่งซื้อจะเปลี่ยนเป็นได้รับค่าสินค้าคืนแล้ว",
  All: "ทั้งหมด",
  Unpaid: "รอการชำระเงิน",
  Wrapping: "สินค้าที่ต้องบรรจุ",
  Shipping: "กำลังจัดส่ง",
  PenddingShip: "รอรับสินค้า",
  InTransit: "จัดส่งแล้ว",
  ProperDelivery: "ส่งแล้ว",
  BuyerId: "รหัสลูกค้า",
  orderType: "ประเภทคำสั่งซื้อ",
  OrderTimeScope: "ขอบเขตเวลาคำสั่งซื้อ",
  OrderNum: "หมายเลขการสั่งซื้อ",
  OrderType: "ประเภทรายการสั่งซื้อ",
  GeneralOrders: "ธรรมดา",
  VoucherOrders: "คำสั่งซื้อคูปอง",
  PreOrders: "คำสั่งซื้อล่วงหน้า",
  ToRtsPkg: "พัสดุรอส่ง",
  AlreadyRtsPkg: "พัสดุที่พร้อมส่งแล้ว",
  CreatePkg: "สร้างแพ็คเกจ",
  BuyerName: "ชื่อผู้ซื้อ",
  Package: "บรรจุภัณฑ์",
  TrackingOrderNum: "หมายเลขติดตามพัสดุ",
  ShippingMethod: "ตัวเลือกการจัดส่ง",
  RetailPrice: "RetailPrice",
  Status: "สถานะ",
  TotalPrice: "รวม",
  ToDelivered: "รอจัดส่ง",
  ToPending: "รอดำเนินการ",
  ShippingIncluded: "รวมค่าจัดส่งแล้ว",
  CreationTime: "เวลาสร้าง",
  DeliveryPrintFaceSheet: "จัดส่งและพิมพ์ใบแปะหน้าพัสดุ",
  PrintingSurfaceSingle: "พิมพ์ใบแปะหน้าพัสดุ",
  ToRecreateParcel: "สร้างแพ็คเกจใหม่อีกครั้ง",
  CheckLogistics: "ตรวจสอบขนส่ง",
  CancelOrder: "ยกเลิกคำสั่งซื้อ",
  PackageNumber: "จำนวนบรรจุภัณฑ์",
  CancelItem: "รายการที่ถูกยกเลิก",
  CancelReason: "เหตุผลที่ยกเลิก",
  To: "ส่งไปยัง",
  Product: "สินค้า",
  Img: "รูปภาพ",
  PaymentMethod: "วิธีการชำระเงิน",
  ItemTotalAmount: "จำนวนเงินสินค้าทั้งหมด",
  ItemTotalNum: "จำนวนสินค้าทั้งหมด",
  BuyerInfo: "ข้อมูลผู้ซื้อ",
  BuyersInfo: "ข้อมูลผู้ซื้อ",
  invitationCoce: "รหัสคำเชิญ",
  userID: "user id",
  Customer: "ลูกค้า",
  TelephoneNumber: "หมายเลขโทรศัพท์",
  TaxCode: "เลขประจำตัวผู้เสียภาษี",
  Subtotal: "ยอดรวม",
  TotalPlatformOffers: "ส่วนลดจากแพลตฟอร์มทั้งหมด",
  TotalSellerDiscount: "ส่วนลดจากผู้ขายทั้งหมด",
  TotalShippingDiscount: "ส่วนลดค่าจัดส่งรวมทั้งหมด",
  Cumulative: "ยอดสะสม",
  TransactionInfo: "ข้อมูลการทำธุรกรรม",
  RefundOfReturnCommission: "การคืนเงินค่าคอมมิชชั่น",
  PaymentForGoods: "ยอดรวมค่าสินค้า",
  PaymentOfServiceCharge: "ค่าธรรมเนียมการจัดการ",
  ReturnPayment: "คืนสินค้า/คืนเงิน",
  LogistisFreight: "ค่าขนส่ง",
  Commission: "ค่าคอมมิชชั่น",
  BillingAddress: "ที่อยู่จัดส่งใบกำกับภาษี",
  DeliveryAddress: "ที่อยู่จัดส่ง",
  DeliverAfterSellerCancle: "ร้านค้ายกเลิกหลังจากส่งสินค้า",
  LogisticsDistributionFailCancle: "ยกเลิกการขนส่งล้มเหลว",
  LogisticsDistributionReturnCancle: "ยกเลิกจัดส่งคืนสินค้า",
  LogisticsDistributionTimeOutCancle: "ยกเลิกการขนส่งที่เกินเวลา",
  LogisticsSolicitationCancle: "ยกเลิกเมื่อขนส่งเข้ารับพัสุ",
  SellerDeliverTimeOutCancle: "ยกเลิกเนื่องจากร้านค้าไม่ส่งสินค้าตามเวลา",
  PlatformCancle: "แพลตฟอร์มยกเลิก",
  SellerCancle: "ร้านค้ายกเลิก",
  UserCancle: "ผู้ใช้งานยกเลิก",
  PayTimeOut: "เลยกำหนดเวลาชำระเงิน",
  ToBepay: "รอชำระเงิน",
  ToBePacked: "รอบรรจุ",
  ToBeDeliver: "รอจัดส่ง",
  Delivered: "จัดส่งแล้ว",
  SignedIn: "เซ็นรับพัสดุแล้ว",
  OrderComplete: "คำสั่งซื้อสำเร็จแล้ว",
  transactionCancle: "ยกเลิกการซื้อขาย",
  title: "รายการสั่งซื้อ",
  Date: "วันที่",
  TransactionType: "ประเภทการซื้อขาย",
  CostAmount: "จำนวนรายการค่าใช้จ่าย",
  Number: "หมายเลข",
  AccountToBill: "ใบแจ้งยอดบัญชี",
  SellerLoginName: "ชื่อเข้าสู่ระบบผู้ขาย",
  ItemId: "รหัสสินค้า",
  SellerSku: "Seller SKU",
  SKUNum: "หมายเลข SKU",
  ModeOfPerformance: "วิธีปฏิบัติตามสัญญา",
  Refund: "คืนเงิน",
  Promotion: "โปรโมชั่น",
  Coupon: "คูปอง",
  Freight: "ค่าขนส่ง",
  DetailedHistory: "รายละเอียดประวัติ",
  SKUCoding: "รหัส SKU",
  Collected: "เก็บแล้ว",
  InTransit2: "อยู่ระหว่างขนส่ง",
  InDelivery: "อยู่ระหว่างการจัดส่ง",
  Stranded: "หยุดเคลื่อนไหวแล้ว",
  DifficultPartsInHanlde: "กำลังดำเนินการความยากลำบาก",
  ReturnedItems: "คืนพัสดุแล้ว",
  AbnormalClosing: "การปิดที่ผิดปกติ",
  Rescinded: "เพิกถอนแล้ว",
  OrderDetail: "รายละเอียดคำสั่งซื้อ",
  ItemInfo: "ข้อมูลสินค้า",
  platDiscountSubsidy: "คูปองจาก Youpik",
  platShippingSubsidy: "คูปองส่วนลดค่าจัดส่ง",
  sellerDiscountSybsidy: "คูปองจากร้านค้า",
  amountActuallPaid: "ยอดใช้จ่ายตามจริง",
  storeName: "ชื่อร้านผู้ขาย",
  toBeDelivered: "ที่จะส่งมอบ",
  packageId: "แพคเกจ ID",
  number: "number",
  ProperDeliveryFailed: "จัดส่งล้มเหลว",
  Type1010: "ถูกยกเลิกจากทางร้านค้า หลังจากได้รับสินค้าแล้ว",
  Type1009: "ถูกยกเลิกเนื่องจากการขนส่งล้มเหลว เช่น ไม่สามารถแสกนบาร์โค้ด, ขนส่งไม่เข้ารับพัสดุ",
  Type1008: "ถูกยกเลิกเนื่องจากขนส่งทำการคืนสินค้า เช่น ลูกค้าสั่งซื้อแต่ต้องการคืน",
  Type1007:
    "ถูกยกเลิกเนื่องจากการขนส่งที่เกินเวลา เช่น ขนส่งทำการส่งสินค้าไปแล้ว 2 วัน แต่วันที่ 7 ยังไม่ถึงผู้รับ",
  Type1006: "ถูกยกเลิกเนื่องจากขนส่งเข้ารับพัสดุไม่ทันเวลาที่กำหนด",
  Type1005:
    "ถูกยกเลิกเนื่องจากร้านค้าส่งสินค้าช้ากว่าที่กำหนด เช่น สินค้าต้องถึงผู้รับภายใน 7 วัน ถ้าเกินเวลาจะทำให้โดนยกเลิก",
  Type1004: "ยกเลิกโดยแพลตฟอร์ม",
  Type1003: "ยกเลิกโดยร้านค้า",
  Type1002: "ยกเลิกโดยลูกค้า",
  Type1001: "ยกเลิกเนื่องจากหมดเวลาชำระเงิน",
  Type1000: "รอชำระเงิน",
  Type2001: "รอบรรจุ",
  Type2002: "รอจัดส่ง",
  Type3000: "กำลังจัดส่ง",
  Type4000: "เซ็นรับเรียบร้อยแล้ว",
  Type5000: "คำสั่งซื้อสำเร็จแล้ว",
  OrderCancel: "ยกเลิก",
  ShopName: "ชื่อร้านค้า",
  Remark: "refund notes",
  CSRemark: "CS notes",
  Select: "กรุณาเลือก",
  responseOption1: "ไม่มีสต็อก",
  responseOption2: "ราคาไม่ถูกต้อง",
  responseOption3: "เหตุผลอื่นๆ",
  CancelPkg: "ยกเลิกสินค้า",
  CancelPkgSuccess: "ยกเลิกสินค้าสำเร็จ",
  CancelResponsiblePart: "ยกเลิกจาก",
  cancelResponsiblePart1: "ผู้ใช้งาน",
  cancelResponsiblePart2: "ร้านค้า",
  cancelResponsiblePart3: "แพลตฟอร์ม",
  cancelResponsiblePart4: "ความรับผิดชอบของผู้ให้บริการขนส่ง",
  cancelReasonTip: "กรุณาเลือกสาเหตุ",
  cancelResponsiblePartTip: "กรุณาเลือกผู้ดำเนินการยกเลิก",
  cancelFialedReason: "เหตุผลจากการยื่นคำร้องไม่สำเร็จจะถูกส่งไปยังผู้ใช้ ",
  cancelFialedOtherReason: "เหตุผลจากการยื่นคำร้องไม่สำเร็จจะถูกส่งไปยังผู้ใช้ ",
  ConfirmCancelOrder: "แน่ใจหรือไม่ว่าต้องการยกเลิกคำสั่งซื้อ？",
  cancelOrderSuccess: "ยกเลิกคำสั่งซื้อสำเร็จ",
  ConfirmCancelItem: "แน่ใจหรือไม่ว่าต้องการยกเลิกสินค้า？",
  ConfirmCancelPkg: "แน่ใจหรือไม่ว่าต้องการยกเลิกพัสดุ？",
  CancelTime: "ส่งคำร้องขอยกเลิกเมื่อ",
  CancelUser: "รายการยกเลิก",
  Pending: "รอดำเนินการ",
  Completed: "สำเร็จแล้ว",
  Reject: "ปฏิเสธแล้ว",
  RefundAmount: "จำนวนเงินคืน",
  RefundAmountStats: "สถานะการจ่ายเงิน",
  Response: "เหตุผล",
  ApplyTime: "เวลาที่ยื่นคำร้อง",
  SellerType: "ประเภทผู้ขาย",
  SendTo: "ส่งไปยัง",
  ItemID: "รหัสสินค้า",
  LogisticsType: "ประเภทการขนส่ง",
  Close: "ไม่ใช่ DBS",
  HandDbs: "DBS แบบแมนวล",
  AutoDbs: "DBS อัตโนมัติ",
  LogStatu: "สถานะขนส่ง",
  LogType: "วิธีการขนส่ง:",
  FlashExpress: "Flash Express",
  SeaStar: "SeaStar",
  Zambo: "ลาซาเปา",
  CenterGroup: "Center Group",
  TS: "TS",
  Acommerce: "Acommerce",
  Interpid: "Interpid",
  DBSbyhand: "DBS by Hand",
  reason_app_1: "เปลี่ยนวิธีการชำระเงิน",
  reason_app_2: "ระยะเวลาในการจัดส่งนานเกินไป",
  reason_app_3: "คำสั่งซื้อซ้ำ",
  reason_app_4: "เปลี่ยนที่อยู่จัดส่ง",
  reason_app_5: "เปลี่ยนใจ",
  reason_app_6: "เลือกสินค้าอื่น",
  reason_app_7: "ค่าส่งแพงเกินไป",
  reason_app_8: "เจอสินค้าที่ถูกกว่าในแพลตฟอร์มอื่น",
  SellerName: "ชื่อร้าน",
  MakingMoney: "กำลังคืนเงิน",
  Type_1001: "ไม่ได้ชำระเงินในเวลาที่กำหนด",
  Type_1002: "ยกเลิกโดยลูกค้า",
  Type_1003: "ยกเลิกโดยร้านค้า",
  Type_1004: "ยกเลิกโดยแพลตฟอร์ม",
  Type_1005: "ผู้ขายไม่ได้ส่งสินค้าในเวลาที่กำหนด",
  Type_1006: "บริษัทจัดส่งไม่ได้รับสินค้าในเวลาที่กำหนด",
  Type_1007: "บริษัทจัดส่งไม่ได้ส่งสินค้าในเวลาที่กำหนด",
  Type_1008: "ถูกยกเลิกเนื่องจากขนส่งทำการคืนสินค้า เช่น ลูกค้าสั่งซื้อแต่ต้องการคืน",
  Type_1009: "ถูกยกเลิกเนื่องจากการขนส่งล้มเหลว เช่น ไม่สามารถแสกนบาร์โค้ด, ขนส่งไม่เข้ารับพัสดุ",
  Type_1010: "ถูกยกเลิกจากทางร้านค้า หลังจากได้รับสินค้าแล้ว",
  Type_2000: "ดำเนินการคืนเงิน",
  Type_2001: "ดำเนินการคืนเงิน",
  Type_4000: "คืนเงินสำเร็จ",
  Type_1012: "เกินระยะเวลากำหนด สินค้าถูกยกเลิก",
  reason6: "เกินระยะเวลากำหนดที่จะจัดส่ง ",
  User: "ลูกค้า",
  Seller: "ผู้ขาย",
  Ops: "OPS",
  Todo: "กำลังดำเนินการ",
  CancelGetRespons: "สาเหตุการยกเลิกการรับสินค้า",
  flashReasponse9: "ข้อมูลที่อยู่การจัดส่งไม่เหมาะสมกับฟอร์แมต",
  flashReasponse80: "ผู้ขายยกเลิกงาน",
  flashReasponse83: "ติดต่อผู้ขายไม่ได้",
  flashReasponse85: "ไม่พบเบอร์โทรศัพท์ของผู้ขาย",
  flashReasponse90: "พัสดุไม่พร้อมที่จะจัดส่ง",
  flashReasponse91: "แพคเกจไม่เหมาะสมกับมาตรฐานการขนส่ง",
  flashReasponse95: "เข้ารับพัสดุล่าช้าเนื่องจากรถขนส่ง/พนักงานไม่เพียงพอ",
  flashReasponse98: "ที่อยู่เข้ารับไม่ถูกต้อง",
  flashReasponse99: "แพคเกจไม่เหมาะสมกับเงื่อนไขการจัดส่ง: สินค้าเกินขนาด",
  flashReasponse100: "แพคเกจไม่เหมาะสมกับเงื่อนไขการจัดส่ง :สินค้าผิดกฎหมาย",
  flashReasponse105: "แพคเกจเสียหายภายนอก",
  ToRts: "รอทำ RTS",
  Rtsed: "ทำ RTS แล้ว",
  Cancel: "ยกเลิกแล้ว",
  LossDamage: "สูญเสีย และ เสียหาย",
  HasProduct: "ส่งคืนแล้ว",
  Undone: "ยกเลิกการขนส่ง",
  SelectOrderTime: "กรุณาเลือกช่วงเวลาการสั่งซื้อ",
  Countdown: "นับถอยหลัง",
  Down: "The SKU Stock will be cleared, please replenish the inventory in time；",
  ActualFreight: "ค่าขนส่งที่เกิดขึ้นจริง",
  handlingCharges: "ค่าธรรมเนียมการจัดการ",
  freightRefund: "ค่าขนส่งสินค้าส่งคืน",
  ActualRefund: "คืนค่าธรรมเนียมการจัดส่งตามจริง (VAT)",
  salesAmount: "ขาย",
  MerchantCoupon: "ราคาขายสินค้า",
  CommoditySales: "ราคาขายสินค้า",
  freight: "ค่าขนส่ง",
  freightBuy: "ผู้ซื้อจ่ายค่าขนส่ง",
  freightTrue: "ค่าขนส่งที่เกิดขึ้นจริง",
  CampaignSubsidy: "เงินสมทบกิจกรรม",
  CampaignCommissionCompensation: "%เงินสมทบคอมมิชชั่น",
  YoupikFee: "ยูพิค",
  PlatformCommission: "ค่าคอมมิชชั่นแพลตฟอร์ม",
  OwnerCommission: "ค่าคอมมิชชั่นยูพิคเกอร์",
  Paymenthandling: "จ่ายค่าธรรมเนียมหรือค่าธรรมเนียม）",
  ReturnRefund: "จำนวนเงินคืน",
  ReverseCampaignSubsidy: "คืนเงินสมทบกิจกรรม",
  ReverseCommissionCompensation: "คืนเงินสมทบค่าคอมมิชชั่นแพลตฟอร์ม",
  RefundSales: "มูลค่าสินค้าส่งคืน",
  MerchantCoupons: "คูปองการคืนเงิน",
  PlatformCommissionrefund: "ค่าคอมมิชชั่นแพลตฟอร์มการคืนเงิน",
  OwnerCommissionrefund: "การคืนเงินให้เจ้าของร้านค้า",
  showDetail: "รายละเอียดรายได้เพิ่มเติม",
  hideDetail: "พับ",
  // Down: 'The SKU Stock will be cleared, please replenish the inventory in time；'
  refundStatus: "สถานะการคืนเงิน",
  userSubmit: "ผู้ใช้ยืนยันเรียบร้อย",
  opsRefuse: "OPS ปฏิเสธการทำรายการ",
  userCancelsApplicationSuccessfully: "ลูกค้ายกเลิกการยื่นรื่องสำเร็จ",
  refundSuccess: "คืนเงินสำเร็จ",
  refundRejectQC: "ปฏิเสธการคืนเงิน (ไม่ผ่านการตรวจสอบ)",
  waitingForRetrun: "รอดำเนินการส่งคืนสินค้า",
  duringTheReturn: "อยู่ในระหว่างการคืนสินค้า ผู้ขายกำลังรอรับสินค้า",
  waitingForBankCard: "รอดำเนินการจากบัตรธนาคาร",
  waitingForBankCardRetrun: "รอการดำเนินการคืนเงินจากธนาคาร",
  // lazada
  unpaid: "ยังไม่ได้ชำระเงิน",
  paid: "ชำระเงินเรียบร้อย",
  successfulInvestment: "สั่งซื้อสำเร็จ",
  refund: "คืนเงิน",
  unitPrice: "ราคาสินค้า",
  totalPriceOfGoods: "ยอดรวม",
  viewCommission: "ตรวจสอบค่าคอมมิชชั่น",
  orderTimeRange: "ระยะเวลาในการสั่งซื้อ",
  // ...
  stateCirculation: "สถานะจัดส่งพัสดุ",
  submitOrder: "ส่งคำสั่งซื้อ",
  buyerPayment: "ลูกค้าชำระเงิน ",
  paymentAmount: "จำเงินที่ชำระ",
  cancelPackageCreation: "ยกเลิกพัสดุ",
  sellerPackaging: "ร้านค้าจัดเตรียมพัสดุ ",
  sellerShipment: "ร้านค้าส่งพัสดุ",
  logisticsOrderNo: "หมายเลขพัสดุ",
  buyerRefund: "ลูกค้าขอเงินคืน",
  refundOrderNo: "หมายเลขยกเลิกคำสั่งซื้อ",
  sellerCancelOrder: "ร้านค้ายกเลิกรายการสั่งซื้อ ",
  cancelForLogistics: "ยกเลิกพัสดุนื่องจากบริษัทขนส่ง",
  sellerCancelsRefund: "ลูกค้ายกเลิกการขอคืนเงิน",
  packageNotMactchOrderNo: "พัสดุไม่ตรงกับหมายเลขพัสดุ",
  addressExceptionCode: "ที่อยู่ไม่ถูกต้อง",
  addressNotLockerStation: "ที่อยู่ไม่มีที่ฝากพัสดุ",
  lockernoUse: "ที่ฝากพัสดุไม่สามารถใช้งานได้",
  noOpenLocker: "ไม่สามารถเปิด ที่ฝากพัสดุได้",
  noPackage: "ไม่มีพัสดุ",
  packageCollectionFailed: "การจัดการพัสดุล้มเหลว ",
  logisticsRefundCancel: "การยกเลิกการส่งกลับของบริษัทขนส่ง (ค่าใช้จ่ายเพิ่มเติม 50% )",
  buyerInitiatedReturnRefund: "ผู้ซื้อส่งคำขอการคืนเงิน",
  platformRefuseRefund: "ปฏิเสธการคืนเงิน",
  submitRefundApply: "ยืนยันคำขอยกเลิกสินค้า",
  buyerReturned: "ผู้ซื้อยกเลิกสินค้า",
  refundUnderReview: "ยกเลิกเรียบร้อย อยู่ในระหว่างพิจารณาคืนเงิน",
  refundPendingPayment: "อยู่ในระหว่างการคืนเงิน",
  payPoints: "ชำระด้วยคะแนน",
  logisticsProvider: "ผู้ให้บริการด้านขนส่ง",
  deliveryFailed: "จัดส่งไม่สำเร็จ",
  deliveryFailed_1: "จัดส่งไม่สำเร็จ อยู่ในระหว่างการคืนสินค้า กรุณาตรวจสอบ",
  deliveryFailed_2: "จัดส่งไม่สำเร็จ อยู่ในระหว่างส่งสินค้ากลับคลัง",
  rejectAndReturn: "ปฏิเสธการคืนสินค้า",
  userReject: "ลูกค้าปฏิเสธ",
  lostPackage: "พัสดุสูญหาย",
  abnormalLogistics: "ข้อยกเว้นด้านขนส่ง",
  abnormalLogisticsConcat: "การขนส่งมีความผิดปกติ หากมีข้อสงสัย โปรดติดต่อบริษัทขนส่ง",
  "预估佣金（佣金率）": "ทิศตะวันออก. ค่าคอมมิชชั่น (อัตราค่าคอมมิชชั่น)",
  实际佣金: "ค่าคอมมิชชั่นที่เกิดขึ้นจริง",
  店主预估佣金: "Youpikker ทิศตะวันออก. ค่าคอมมิชชั่น",
  店主实际佣金: "Youpikker ค่าคอมมิชชั่นที่เกิดขึ้นจริง",
  妥投时间: "เวลาจัดส่ง",
  退款时间: "เวลากลับ",
  申述记录: "บันทึกข้อร้องเรียน",
  查看报告: "รายงาน",
  "申述-待审核": "การร้องเรียน - อยู่ระหว่างดำเนินการ",
  "申述-失败": "การร้องเรียน - ความล้มเหลว",
  "申述-成功": "การร้องเรียน - ความสำเร็จ",
  审核人: "ผู้ตรวจสอบบัญชี",
  审核意见: "ตรวจสอบความคิดเห็น",
  审核时间: "เวลาตรวจสอบ",
  审核: "การตรวจสอบ",
  "审核通过后该订单状态将修改为已完成，会进行店主佣金结算。是否确认继续?":
    "สถานะของคำสั่งจะเปลี่ยนเป็นเสร็จสิ้นหลังจากการตรวจสอบได้รับการอนุมัติและค่าคอมมิชชั่น Youpikker จะถูกตัดสิน คุณยืนยันที่จะดำเนินการต่อหรือไม่",
  请选择原因: "กรุณาเลือกเหตุผล",
  "申诉失败原因会返回给用户，请根据实际情况填写":
    "เหตุผลของความล้มเหลวของการร้องเรียนจะถูกส่งคืนไปยังผู้ใช้ โปรดกรอกตามสถานการณ์จริง",
  其他原因说明: "เหตุผลอื่นๆ ที่ต้องอธิบาย",
  Lazada订单申诉失败原因: "เหตุผลความล้มเหลวในการร้องเรียนคำสั่งซื้อของ Lazada",
  概览: "ภาพรวม",
  仅显示已完成订单: "แสดงเฉพาะคำสั่งซื้อที่เสร็จสมบูรณ์",
  退款率: "อัตราการคืนเงิน",
  异常订单率: "อัตราการสั่งซื้อที่ถูกปฏิเสธ",
  刷单: "คำสั่งที่ถูกปฏิเสธ",
  刷单率: "อัตราคำสั่งซื้อที่ถูกปฏิเสธ",
  申诉订单: "คำสั่งร้องเรียน",
  申诉率: "อัตราการร้องเรียน",
  买家: "ผู้ซื้อ",
  全部订单: "ทุกออเดอร์",
  异常订单: "ระเบียบผิดปกติ",
  订单金额: "ยอดสั่งซื้อ",
  预估佣金: "ค่าคอมมิชชั่นโดยประมาณ",
  账号状态: "สถานะบัญชี",
  申诉记录: "บันทึกข้อข้องใจ",
  申请订单: "สั่งซื้อสินค้า",
  凭证: "ใบรับรอง",
  联系人: "ติดต่อ",
  联系方式: "รายละเอียดการติดต่อ",
  订单状态: "สถานะการสั่งซื้อ",
  成功: "ความสำเร็จ",
  失败: "ความล้มเหลว",
  不通过: "ล้มเหลว",
  待审核: "อยู่ระหว่างดำเนินการ",
  订单数: "จำนวนการสั่งซื้อ",
  服务单号: "เลขที่ใบสั่งบริการ",
  注销: "ออกจากระบบ",
  禁用: "พิการ",
  正常: "ปกติ",
  删除: "ลบ",
  税后: "หลังหักภาษี",
  不含官方账号佣金: "ไม่รวมค่าคอมมิชชั่นบัญชีทางการ",
  数量: "จำนวน",
  增补物流单号: "เพิ่มหมายเลขติดตามพัสดุ",
  商家补发: "ร้านค้าส่งสินค้าเพิ่มเติม",
  已增补发货: "ส่งสินค้าเพิ่มเติมแล้ว",
  增补发货: "ส่งสินค้าเพิ่มเติม",
  "Content ID": "หมายเลขคอนเทนต์",
  视频链接: "ลิงก์วิดีโอ",
  视频销量: "ยอดขายจากคลิปวิดีโอ",
  视频GMV: "ยอด GMV จากคลิปวิดีโอ",
  视频订单数: "จำนวนคำสั่งซื้อจากคลิปวิดีโอ",
  "Ads Code": "รหัสโฆษณา",
  未投流: "ยังไม่ยิงโฆษณา",
  已投流: "ยิงโฆษณาแล้ว",
  未关联样品订单: "ไม่ใช่รายการที่เข้าร่วมแคมเปญ",
  订单关联状态: "สถานะการเข้าร่วมแคมเปญ",
  提交时间: "เวลาจัดส่ง",
  选择时间范围: "เลือกช่วงเวลา"
};
