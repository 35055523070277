export default {
  首页: "Trang chủ",
  绩效管理: "Quản lý hiệu suất",
  "绩效管理.PIC绩效": "Hiệu suất PIC",
  通知中心: "Trung tâm thông báo",
  新增: "Thêm mới",
  编辑: "Chỉnh sửa",
  运营管理: "Quản lý hoạt động",
  "运营管理.首页配置": "Cài đặt trang chủ",
  登录: "Đăng nhập",
  "运营管理.添加": "Thêm",
  "运营管理.编辑": "Chỉnh sửa",
  "运营管理.详情": "Chi tiết",
  用户管理: "Quản lý người dùng",
  "用户管理.uChoice用户": "Trung tâm người dùng Uchoice",
  "用户管理.达人公海": "KOL công khai",
  "用户管理.跟进达人": "Theo dõi KOL",
  "运营管理.瀑布流配置": "Cấu hình danh sách sản phẩm",
  样品申请: "Đơn đăng ký mẫu",
  "订单管理.sampleApplication": "Đơn đăng ký mẫu",
  "运营管理.排行榜配置": "Quản lý bảng xếp hạng",
  "运营管理.排行榜新增": "Thêm mới bảng xếp hạng",
  "运营管理.排行榜编辑": "Chỉnh sửa bảng xếp hạng",
  "运营管理.新人样品池配置": "Cấu hình mẫu cho người mới",
  "运营管理.banner配置": "Quản lý banner trang chủ",
  "运营管理.动态页配置": "Quản lý trang chiến dịch",
  商品管理: "Quản lý sản phẩm",
  "商品管理.TT商品": "Sản phẩm Tiktok",
  "商品管理.普通活动": "Chiến dịch thông thường",
  "商品管理.明星活动": "Chiến dịch nổi bật",
  "商品管理.商品集": "Bộ sản phẩm",
  "用户管理.标签管理": "Quản lý nhãn",
  "用户管理.达人链接管理": "Quản lý liên kết sản phẩm của người nổi tiếng",
  "用户管理.达人链接管理详情": "Quản lý liên kết sản phẩm của người nổi tiếng",
  "运营管理.uChoice&TT样品申请门槛设置": "Tiêu chuẩn đăng ký mẫu thử",
  "运营管理.投流管理": "Thưởng quảng cáo video",
  销售报表: "Báo cáo doanh số TAP",
  "销售报表.达人分析": "Phân tích Nhà sáng tạo",
  "销售报表.商品分析": "Phân tích sản phẩm",
  "销售报表.商家分析": "Phân tích người bán",
  "销售报表.视频履约分析": "Phân tích doanh số video",
  // 供应链
  供应链管理: "Quản lý nhà cung cấp",
  供应链管理新增: "Thêm mới quản lý nhà cung cấp",
  供应链管理编辑: "Chỉnh sửa quản lý nhà cung cấp",
  消息通知: "Thông báo Tin nhắn",
  "消息通知.系统推送": "Đẩy Hệ thống",
  "消息通知.自定义推送": "Đẩy Tùy chỉnh"
};
