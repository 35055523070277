export default {
  Cancel: "放弃编辑",
  Save: "上传",
  ConfirmCloseTitle: "是否确定关闭？",
  ConfirmCloseDesc: "关闭后表格内容无法恢复",
  addUserTitle: "Import User",
  addSampleTitle: "Import Sample Request",
  addPersonTitle: "Import Shipping Information",
  addSupplyTitle1: "导入",
  addSupplyTitle2: "供应商的店铺",
  InsertRow: "添加行",
  TipNotEmpty: "无有效内容可上传",
  可搜索区域或邮编: "可搜索区域或邮编",
  待发货: "待发货",
  履约跟进: "履约跟进",
  已发货: "已发货",
  "10条样品申请等待您的审核与发货":
    '<span style="color: red;">{num}条</span>样品申请等待您的审核与发货！',
  "X条带货视频可投流打造单品爆款，投流后可以增长您店铺的单品销量哦！":
    '<span style="color: red;">{num}条</span>带货视频可投流打造单品爆款，投流后可以增长您店铺的单品销量哦！',
  "如因快递错发、丢包、破损需要补发的样品申请，商家可以在此增补订单":
    '如因快递<span style="color: red;">错发、丢包、破损需要补发</span>的样品申请，商家可以在此增补订单',
  "当前列表内容编辑未提交，若不保存所有编辑的内容将失效，是否保存编辑内容？":
    "当前列表内容编辑未提交，若不保存所有编辑的内容将失效，是否保存编辑内容？",

  ["add_sheet.Error"]: "Error",

  ["add_user.tt用户名"]: "tt用户名",
  ["add_user.跟进人"]: "跟进人",
  ["add_user.联系电话"]: "联系电话",
  ["add_user.lineId"]: "LINE ID",
  ["add_sample.达人名称"]: "达人名称",
  ["add_sample.申请日期"]: "申请日期",
  ["add_sample.item id"]: "商品ID",
  ["add_sample.规格"]: "规格",
  ["add_sample.数量"]: "数量",
  ["add_sample.商品链接"]: "商品链接",
  ["add_sample.橱销截图"]: "橱销截图",
  ["add_sample.30天成交件数"]: "30天成交件数",
  ["add_sample.近一个月gmv"]: "近一个月gmv",
  ["add_sample.收件人姓名"]: "收件人姓名",
  ["add_sample.收件人电话"]: "收件人电话",
  ["add_sample.收件地址"]: "收件地址",
  ["add_sample.地区"]: "地区",
  ["add_sample.详细地址"]: "详细地址",
  ["add_sample.可履约方式"]: "可履约方式",
  ["add_sample.视频"]: "视频",
  ["add_sample.直播"]: "直播",
  ["add_sample.视频+直播"]: "视频+直播",

  ["add_person.订单号"]: "订单号",
  ["add_person.主播名"]: "主播名",
  ["add_person.商品类目"]: "商品类目",
  ["add_person.商品名称"]: "商品名称",
  ["add_person.商品图"]: "商品图",
  ["add_person.item id"]: "商品ID",
  ["add_person.价格"]: "价格",
  ["add_person.店铺名称"]: "店铺名称",
  ["add_person.商品链接"]: "商品链接",
  ["add_person.规格"]: "规格",
  ["add_person.数量"]: "数量",
  ["add_person.video/live"]: "视频/直播",
  ["add_person.收件人姓名"]: "收件人姓名",
  ["add_person.收件人电话"]: "收件人电话",
  ["add_person.收件地址"]: "收件地址",
  ["add_person.橱销截图"]: "橱销截图",
  ["add_person.近一个月gmv"]: "近一个月gmv",
  ["add_person.follow"]: "粉丝数",
  ["add_person.物流单号"]: "物流单号",
  ["add_person.视频链接"]: "视频链接",
  ["add_person.直播时间"]: "直播时间",
  ["add_person.同意/拒绝"]: "同意/拒绝",
  ["add_person.同意"]: "同意",
  ["add_person.拒绝"]: "拒绝",
  ["add_person.MCN"]: "MCN",
  ["add_person.跟进人"]: "跟进人",
  ["add_person.投流视频ID"]: "投流视频ID",
  ["add_person.履约出单数"]: "履约出单数",
  ["add_person.履约GMV"]: "履约GMV",
  ["add_person.投流状态"]: "投流状态",
  ["add_person.订单来源"]: "订单来源",
  ["add_person.样品申请信息"]: "样品申请信息",
  ["add_person.商家样品审核跟进表"]: "商家样品审核跟进表",
  ["add_person.商家投流跟进表"]: "商家投流跟进表",
  ["add_person.申请时间"]: "申请时间",
  ["add_person.备注"]: "备注",
  ["add_person.拒绝原因"]: "拒绝原因",
  ["add_person.收件地址省"]: "收件地址（省）",
  ["add_person.收件地址市"]: "收件地址（市）",
  ["add_person.收件地址区"]: "收件地址（区）",
  ["add_person.邮编"]: "邮编",
  ["add_person.Content ID"]: "Content ID",
  ["add_person.当月视频销量"]: "当月视频销量",
  ["add_person.上周视频销量"]: "上周视频销量",
  ["add_person.上月视频销量"]: "上月视频销量",
  ["add_person.Ads Code"]: "Ads Code",
  ["add_person.增补物流单号"]: "增补物流单号",

  ["add_supply.店铺id"]: "店铺id"
};
