import React, { useRef } from "react";
import { ProTable } from "@ant-design/pro-components";
import type { ActionType, ProColumns, ProFormInstance } from "@ant-design/pro-components";
import { Modal } from "antd";
import { useIntl } from "umi";
import moment from "moment";
import sampleApi from "@/api/sampleapp";

interface IImportHistoryModalProps {
  open: boolean;
  current: any;
  handleOk: () => void;
  handleCancel: () => void;
}

export const handlePostData = (params: any) => {
  const { current, pageSize } = params;
  const convertValues: any = {};
  convertValues.pageNo = current;
  convertValues.pageSize = pageSize;
  convertValues.childrenType = 13;
  convertValues.type = 1;
  return convertValues;
};

function downloadFile(fileUrl: string, fileName: string) {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const ImportHistoryModal: React.FC<IImportHistoryModalProps> = (props) => {
  const { open, handleOk, handleCancel, current } = props;
  const actionRef = useRef<ActionType>();
  const formRef = useRef<ProFormInstance>();
  const intl = useIntl();

  const handleTableRequest = async (params: any = {}, sort: any, filter: any) => {
    console.log("params", params);
    const convertValues = handlePostData(params);
    convertValues.activityId = current.id || "";
    const requestResult: any = await sampleApi.requestExportingRecords(convertValues);
    if (requestResult.code === 200 && requestResult.result) {
      const { list, total } = requestResult.result || {};
      return {
        data: list || [],
        success: true,
        total: total || 0
      };
    }
    return {
      data: [],
      success: false,
      total: 0
    };
  };

  const columns: ProColumns<any>[] = [
    {
      title: intl.formatMessage({ id: "ttSample.操作人" }),
      dataIndex: "operator",
      hideInSearch: true,
      align: "center"
    },
    {
      title: intl.formatMessage({ id: "ttSample.时间" }),
      dataIndex: "createTime",
      hideInSearch: true,
      align: "center",
      renderText(text, record, index, action) {
        return text ? moment(text).format("YYYY/MM/DD HH:mm:ss") : "-";
      }
    },
    {
      title: intl.formatMessage({ id: "ttSample.文件名" }),
      dataIndex: "reportName",
      hideInSearch: true,
      align: "center",
    },
    {
      title: intl.formatMessage({ id: "ttSample.成功商品数" }),
      dataIndex: "successCount",
      hideInSearch: true,
      align: "center",
    },
    {
      title: intl.formatMessage({ id: "ttSample.失败商品数" }),
      dataIndex: "failCount",
      hideInSearch: true,
      align: "center",
    },
    {
      title: intl.formatMessage({ id: "ttSample.导入状态" }),
      dataIndex: "status",
      hideInSearch: true,
      align: "center",
      valueEnum: {
        0: {
          text: intl.formatMessage({ id: "ttSample.导入中" }),
          status: "Processing"
        },
        1: {
          text: intl.formatMessage({ id: "ttSample.完成" }),
          status: "Success"
        },
        2: {
          text: intl.formatMessage({ id: "ttSample.失败" }),
          status: "Error"
        },
      },
    },
    {
      title: intl.formatMessage({ id: "button.Action" }),
      valueType: "option",
      key: "option",
      fixed: "right",
      width: 96,
      align: "center",
      renderText: (text, record, index, action) => [
        <a
          key='download'
          onClick={(e) => {
            e.preventDefault();
            const { downloadLink, reportName } = record;
            if (downloadLink && reportName) {
              downloadFile(downloadLink, `${reportName}.xlsx`);
            }
          }}>
          {intl.formatMessage({ id: "ttSample.download" })}
        </a>
      ]
    }
  ];

  return <Modal title={intl.formatMessage({ id: "ttSample.导入商品记录" })} destroyOnClose open={open} onOk={handleOk} onCancel={handleCancel} width={1120} cancelText={intl.formatMessage({ id: "button.Cancel" })} okText={intl.formatMessage({ id: "button.Confirm" })}>
    <ProTable<any>
      scroll={{ y: 420 }}
      columns={columns}
      actionRef={actionRef}
      formRef={formRef}
      request={handleTableRequest}
      search={false}
      options={false}
      pagination={{
        showQuickJumper: true,
        defaultPageSize: 10,
        showSizeChanger: true,
        onChange: (page) => console.log(page),
        showTotal(total, range) {
          return `${intl.formatMessage({ id: "common.total" })} ${total}`;
        }
      }}
      dateFormatter='string'
    />
  </Modal>;
};

export default ImportHistoryModal;
