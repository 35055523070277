export default {
  // 翻译
  pendingClassificationBtn: "x sản phẩm đang chờ phân loại",
  //
  categoryLabels: "Nhãn Danh Mục",
  wiseManPerStatus: "Thông Tin Thực Hiện của Người Tạo",
  totalNumSampleAppOrders: "Tổng Số Đơn Đặt Hàng Mẫu",
  shippedOrderNum: "Đã Gửi",
  pendingPerformanceNum: "Chờ Thực Hiện",
  performanceNum: "Tỷ Lệ Thực Hiện",
  mannerOfPerformance: "Loại Nội Dung",
  area: "Tỉnh/Thành phố",
  detailedAddress: "Địa Chỉ Chi Tiết",
  pleaseDatailAdd: "Nhập tên đường, tên nhà và số phòng.",
  clickLogOffWeb: "Nhấp để xem trang web chính thức của logistic",
  ttSample: "Cài đặt cho yêu cầu mẫu U&T",
  All: "Tất cả",
  Shipping: "Đang Vận Chuyển",
  ToSellerReviewed: "Chờ Xem Xét từ Người Bán",
  sellerAgree: "Người Bán Đã Phê Duyệt",
  agreeToSellerAgree:
    'Sau khi phê duyệt, đơn hàng sẽ chuyển sang trạng thái "Chờ Phê Duyệt từ Người Bán"',
  sellerAgreeToShipping: 'Sau khi phê duyệt, đơn hàng sẽ chuyển sang trạng thái "Chờ Vận Chuyển"',
  videoLink: "Liên Kết Video",
  Live: "Phát sóng trực tiếp",
  video: "Video",
  liveId: "ID Trực Tiếp",
  liveLink: "Liên Kết Trực Tiếp",
  maxInput999Num: "Bạn chỉ có thể nhập tối đa 999",
  // 履约
  FulfillRecords: "Hồ Sơ Thực Hiện",
  inputPositiveInteger: "Chỉ có thể nhập số nguyên dương",
  uploadedTheVideoPromotionID: "Đơn hàng mẫu đã tải lên ID quảng cáo video:",
  VideoIdExists: "ID Video đã tồn tại",
  liveTimeTips: "Yêu cầu Thời Gian Trực Tiếp",
  modifyTheNumberOfProducts: "Sửa đổi số lượng mẫu",
  inputNewSample: "Nhập số lượng mẫu mới",
  LiveAndVideo: "video và phát sóng trực tiếp",
  PerformanceToBereviewed: "Các Đơn Thực Hiện Đang Đợi Xác Nhận",
  PerformancePass: "Xác Nhận Thực Hiện Đã Được Phê Duyệt",
  RefusalOfPerformance: "Từ Chối Thực Hiện",
  MerchantReviewRejected: "Người bán từ chối",
  AutomatedReviewApproved: "Được Duyệt Qua Xét Duyệt Tự Động",
  ShipmentRejected: "Từ Chối Giao Hàng",
  Rejected: "Từ Chối",
  PerformanceStatus: "Trạng Thái Thực Hiện",
  PerformanceMethod: "Phương Thức Thực Hiện",
  appUp: "Ứng Dụng đã tải lên",
  orderBack: "Phản Hồi Đơn Hàng",
  PICConfirm: "PIC Xác Nhận",
  视频回溯: "Phát Lại Video",
  直播回溯: "Phát Lại Trực Tiếp",
  直播视频回溯: "Phát Lại Video & Trực Tiếp",
  promised: "Đã Thực Hiện",
  noPromise: "Chưa Thực Hiện",
  promiseedReject: "Thực hiện đơn hàng mẫu này đã bị từ chối trước đó",
  videoid: "Mã Video Quảng Cáo (Tùy chọn)",
  videosId: "Mã Video Quảng Cáo",
  upLoadVideo: "Vui lòng tải lên Mã Video của bạn",
  confirmReject: "Xác Nhận Từ Chối Thực Hiện",
  promiseFinish: "Đồng Ý Tiến Hành Hoàn Thành",
  importApply: "Nhập Đơn Đăng Ký Mẫu",
  importShip: "Nhập Thông Tin Giao Hàng",
  repeatApply: "Lặp Lại Đơn Đăng Ký",
  ToBeReviewed: "Đang Chờ Xem Xét",
  inflowCloseSuccess: "Đóng Luồng Thành Công",
  Delivered: "Đã Giao",
  closeed: "Đã Đóng",
  OrderNum: "Số Đơn Hàng",
  NameOfTalent: "Tên Nhà sáng tạo",
  StoreName: "Tên Cửa Hàng",
  ItemId: "Mã Sản Phẩm",
  ItemName: "Tên Sản Phẩm",
  TAPID: "ID TAP",
  ApplyTime: "Thời Gian Tạo Đơn",
  lookRecord: "Lịch Sử",
  Item: "Sản Phẩm",
  unitPrice: "Đơn Giá",
  Commission: "Hoa Hồng",
  tradingStatus: "Trạng Thái",
  ScreenshotSales: "Ảnh chụp màn hình của tiktok showcase",
  TAPActivityInformation: "Thông Tin TAP",
  lastPage: "Trang Trước",
  nextPage: "Trang Sau",
  NumberOfFans: "Người Theo Dõi",
  AverageVideoPlayback: "Video",
  CheckLogistics: "Kiểm Tra Logistics",
  CourierCompany: "Công Ty Vận Chuyển",
  GoToFlashOfficialWebsite: "Đi đến trang web chính thức của logistics",
  goToLogisticsOfficialWebsite: "Đi đến trang web chính thức của logistics",
  flashMessage: "Logistics",
  AwbNum: "Số Theo Dõi",
  ship: "Giao Hàng",
  orderStatusWillChang: 'Trạng thái đơn hàng sẽ thay đổi thành "Chờ giao hàng".',
  Select: "chọn",
  请选择原因: "Vui lòng chọn một lý do",
  "申诉失败原因会返回给用户，请根据实际情况填写":
    "Lý do thất bại trong khi kháng cáo sẽ được chuyển đến người dùng, vui lòng điền thông tin theo tình hình thực tế",
  其他原因说明: "Giải thích Lý do Khác",
  Lazada订单申诉失败原因: "Lý do từ chối khiếu nại đơn hàng Lazada",
  otherReasons: "các lý do khác",
  actTip: 'Thành công, vui lòng đi đến "Lịch Sử Nhập" để xem',
  toSelect: "Vui lòng chọn",
  useExcel: "Nhập tệp Excel",
  selectFile: "Chọn tệp",
  downloadModel: "Tải xuống mẫu",
  importModel: "Nhập mẫu",
  importHistory: "Lịch sử nhập",
  exportHistory: "Lịch sử xuất",
  UserName: "Tên Người Dùng",
  time: "Thời Gian",
  fileName: "Tên Tệp",
  Status: "Trạng Thái",
  Completed: "Đã Hoàn Thành",
  Failure: "Thất Bại",
  download: "Tải Xuống",
  refresh: "Làm Mới",
  palceInputAllContent: "Vui lòng điền đầy đủ thông tin!",
  receiver: "Người Nhận",
  "uChoice&TT Sample Application Creator Threshold Setting":
    "Cài Đặt Ngưỡng Người Tạo Đơn Ứng Dụng Mẫu uChoice&TT",

  申请渠道: "Kênh Yêu Cầu",
  线上: "Trực Tuyến",
  线下: "Ngoại tuyến",
  截图时间段: "Thời Gian Bắt Đầu - Kết Thúc",
  "30天成交件数": "Doanh số bán hàng trong 30 ngày",
  成交件数档位: "Hạng Mức Số Lượng Giao Dịch",
  寄样信息: "Thông Tin Gửi Mẫu",
  新增订单: "Đơn Hàng Mới",
  修改订单: "Sửa Đơn Hàng",
  订单号: "Số Đơn Hàng #",

  商品分类: "Danh Mục Sản Phẩm",
  创建商品集链接: "Tạo Liên Kết Bộ Sản Phẩm",
  添加至已有商品集: "Thêm vào Bộ Sản Phẩm Đã Tồn Tại",
  请选择商品集: "Vui lòng chọn Bộ Sản Phẩm",
  "商品集名称（泰文）": "Tên Bộ Sản Phẩm (Tiếng Thái):",
  "推荐理由（泰文）": "Lời Giới Thiệu (Tiếng Thái)",
  "商品集名称（越南）": "Danh sách tên sản phẩm(Tiếng Việt)",
  "推荐理由（越南）": "Lí do đề xuất(Tiếng Việt)",
  "近30天热销商品，下个爆款等你来选！批量免费样品吧等你来拿~":
    "Sản phẩm bán chạy trong vòng 30 ngày gần đây, sẵn sàng để bạn lựa chọn! Hãy tải xuống ứng dụng và nhận mẫu miễn phí ngay bây giờ!",
  创建商品集: "Tạo Bộ Sản Phẩm",
  搜索商品集ID或名称: "Tìm kiếm ID hoặc Tên Bộ Sản Phẩm",
  创建集合: "tạo bộ sưu tập",
  集合名称: "tên bộ sưu tập",
  创建人: "Người Tạo",
  商品集ID: "ID Bộ Sản Phẩm",
  名称: "Tên",
  简介: "Lời Giới Thiệu",
  有效商品数: "Số Lượng Sản Phẩm Hợp Lệ",
  集合链接: "Liên Kết Tập Hợp",
  分享: "Chia Sẻ",
  管理商品: "Quản Lý",
  分享商品集: "Chia Sẻ Bộ Sản Phẩm",
  序号: "STT",
  请输入商品ID或商品名称: "Vui lòng nhập ID hoặc Tên Sản Phẩm",
  批量移除: "Xóa Hàng Loạt",
  添加商品: "Thêm Sản Phẩm",
  商品列表: "Danh Sách Sản Phẩm",
  全选本页: "Chọn Tất Cả",
  佣金范围: "Phạm Vi Hoa Hồng",
  最低库存量: "Số Lượng Tối Thiểu Trong Kho",
  "近2周销售增长%": "Tăng Trưởng Doanh Số (2 Tuần Gần Đây)",
  最低总销量: "Tổng Số Lượng Bán Tối Thiểu",
  近30天最低销量: "Số Lượng Bán Tối Thiểu Trong 30 Ngày Gần Đây",
  确认要生成商品集链接: "Xác Nhận Tạo Liên Kết Bộ Sản Phẩm",
  继续: "Tiếp Tục",
  确认要添加商品至集合: "Xác nhận thêm sản phẩm vào tập hợp",
  "正在添加1个商品至【商品集名称】，你还要继续吗？":
    'Thêm <span style="color: red">{num}</span> sản phẩm vào <span style="color: red">【{name}】</span>. Bạn có muốn tiếp tục không?',
  添加成功: "Thêm thành công",
  您可以前往商品集列表管理您的集合商品:
    "Bạn có thể quản lý sản phẩm tập hợp của mình trong danh sách Bộ Sản Phẩm",
  去管理: "Quản Lý",
  关闭: "Đóng",
  已成功创建商品集链接: "Liên kết Bộ Sản Phẩm được tạo thành công",
  复制链接: "Sao Chép Liên Kết",
  "正在创建的商品集共包含10个有效商品，你还要继续吗？":
    'Bộ sản phẩm đang được tạo bao gồm tổng cộng <span style="color: red">{num} sản phẩm hợp lệ</span>. Bạn có muốn tiếp tục không?',
  "您可以将链接粘贴至任何对话框。": "Bạn có thể dán liên kết vào bất kỳ hộp trò chuyện nào.",
  您已成功复制xxx商品集分享链接:
    'Bạn đã sao chép thành công liên kết chia sẻ Bộ Sản Phẩm <span style="color: red">{setName}</span>',
  确认删除商品集: "Xác nhận xóa Bộ Sản Phẩm",
  "删除成功！": "Xóa thành công!",
  "您正在删除【商品集合名称】，删除后用户端分享链接将失效，你还要继续吗？":
    'Bạn đang xóa <span style="color: red">【{setName}】</span>, và liên kết chia sẻ của người dùng sẽ không còn hiệu lực sau khi xóa. Bạn có muốn tiếp tục không?',
  Sort: "Sắp Xếp",
  移除: "Loại bỏ",
  "25/100 个商品已添加至商品集，已失效商品（活动结束、商家下架、库存不足、平台下架）系统将会自动移除。":
    'Đã thêm <span style="color: #5245E5">{num}</span>/100 sản phẩm vào Bộ Sản Phẩm. Sản phẩm không hợp lệ (kết thúc chiến dịch, ngừng hoạt động của người bán, hết hàng, bị loại bỏ bởi nền tảng) sẽ tự động bị loại bỏ.',
  确认移除商品: "Xác nhận loại bỏ sản phẩm",
  "您正在移除2个商品，你还要继续吗？":
    'Bạn đang loại bỏ <span style="color: red">{num} sản phẩm</span>. Bạn có muốn tiếp tục không?',
  "已选择 20/100 个商品": 'Đã chọn <span style="color: #7f6ff2">{num}</span>/100 Sản Phẩm',
  "添加失败，商品集最多可添加100个商品，剩余可添加1个商品。":
    'Thêm thất bại, tối đa chỉ cho phép 100 sản phẩm, còn <span style="color: red">{num}</span> sản phẩm có thể được thêm.',
  明星活动: "Chiến Dịch Nổi Bật",
  同步: "Đồng bộ",
  更新: "Cập Nhật",
  普通活动: "giá sản phẩm",
  普通活动列表: "Danh Sách Chiến Dịch Thường",
  明星活动列表: "Danh Sách Chiến Dịch Nổi Bật",
  更新中: "Đang Cập Nhật",
  个活动待同步至普通活动列表: " chiến dịch đang chờ đồng bộ hóa vào danh sách chiến dịch thường",
  个活动待同步至明星活动列表: " chiến dịch đang chờ đồng bộ hóa vào danh sách chiến dịch nổi bật",
  请填写tap佣金率: "Vui lòng điền tỷ lệ hoa hồng TAP",
  同步中: "Đang Đồng Bộ",
  同步失败: "Đồng Bộ Thất Bại",
  再次发起: "Thử Lại",
  "固定佣金，不可调整": "Hoa hồng đã được đặt cố định, không thể điều chỉnh ngay bây giờ",
  等级数量: "Số Lượng Cấp Độ",
  "限制类型：GMV": "Loại Hạn Chế: GMV",
  "限制类型：成交数量": "Loại hạn chế: Khối lượng giao dịch",
  机审条件配置: "Cấu Hình Điều Kiện Xét Duyệt Tự Động",
  样品金额: "Số Tiền Mẫu≤",
  并且: "và",
  达人账号履约率: "Tỷ Lệ Thực Hiện Tài Khoản Người Ảnh Hưởng≥",
  级: "Cấp {{level}}",
  样品数量: "Số Lượng Mẫu",
  及以上: "và hơn",
  确认销量范围: "Xác Nhận Phạm Vi Doanh Số",
  不能大于30: "Không được lớn hơn 30",
  "最低金额无法高于/等于最高金额": "Số tiền tối thiểu không thể cao hơn/bằng số tiền tối đa",
  操作人: "Người Vận Hành",
  时间: "Thời Gian",
  文件名: "Tên Tập Tin",
  成功商品数: "Số Sản Phẩm Thành Công",
  失败商品数: "Số Sản Phẩm Thất Bại",
  导入状态: "Trạng Thái Nhập",
  导入中: "Đang Nhập",
  完成: "Hoàn Thành",
  失败: "Thất Bại",
  导入商品: "Nhập Sản Phẩm",
  导入记录: "Nhập Lịch Sử",
  下载模板: "Tải Xuống Mẫu",
  导入商品记录: "Nhập Lịch Sử Sản Phẩm",
  "已导入100个商品！": "Đã nhập thành công {success} sản phẩm!",
  "已导入99个商品，1个商品导入失败，请下载导入记录查看原因！":
    "Đã nhập {success} sản phẩm, {fail} nhập thất bại. Vui lòng tải xuống lịch sử nhập để kiểm tra nguyên nhân!",
  operationalReviewApproved: "Đã phê duyệt kiểm duyệt vận hành",
  operationalReviewRejected: "Đã từ chối kiểm duyệt vận hành",
  merchantpasses: "Nhà bán hàng đã phê duyệt"
};
