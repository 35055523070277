export default {
  通知方式: "Phương thức Thông báo",
  APP推送: "Đẩy APP",
  标题: "Tiêu đề",
  消息内容: "Nội dung Tin nhắn",
  打开跳转至: "Mở để Chuyển đến",
  橱窗检测: "Kiểm tra Tủ trưng bày",
  数据榜单首页: "Trang chủ Bảng xếp hạng Dữ liệu",
  AI话题首页: "Trang chủ Chủ đề AI",
  AI定制: "Tùy chỉnh AI",
  待发送: "Chờ Gửi",
  已发送: "Đã Gửi",
  发送失败: "Gửi Thất bại",
  推送时间: "Thời gian Đẩy",
  接收人: "Người nhận",
  所有用户: "Tất cả Người dùng",
  创建人: "Người tạo",
  查看自定义推送: "Xem Đẩy Tùy chỉnh",
  编辑自定义推送: "Chỉnh sửa Đẩy Tùy chỉnh",
  创建自定义推送: "Tạo Đẩy Tùy chỉnh",
  全部用户: "Tất cả Người dùng",
  指定用户: "Người dùng Cụ thể",
  定时发送: "Gửi Định kỳ",
  即刻发送: "Gửi Ngay",
  内容分类: "Phân loại Nội dung",
  通知: "Thông báo",
  推荐: "Khuyến nghị",
  频次: "Tần suất",
  仅一次: "Chỉ một lần",
  每n天一次: "Một lần mỗi {day} ngày",
  通知名称: "Tên Thông báo",
  触发节点: "Nút Kích hoạt",
  待审核转为待发货: "Chờ duyệt chuyển thành chờ giao hàng",
  待发货转为已发货: "Chờ giao hàng chuyển thành đã giao hàng",
  待发货转为待履约: "Chờ giao hàng chuyển thành chờ thực hiện",
  橱窗账号登录状态异常: "Trạng thái đăng nhập tài khoản Tủ trưng bày không bình thường",
  存在橱窗高佣待替换商品: "Có hàng hóa hoa hồng cao chờ thay thế trong Tủ trưng bày",
  "榜单推荐-U选爆品": "Khuyến nghị Bảng xếp hạng - U chọn sản phẩm nổi bật",
  "榜单推荐-达人带货榜": "Khuyến nghị Bảng xếp hạng - Bảng hàng hóa của chuyên gia",
  "榜单推荐-U选高佣": "Khuyến nghị Bảng xếp hạng - U chọn hoa hồng cao",
  "榜单推荐-AI热点主题榜": "Khuyến nghị Bảng xếp hạng - Chủ đề nóng AI",
  "榜单推荐-持续好货榜": "Khuyến nghị Bảng xếp hạng - Bảng hàng hóa tốt liên tục",
  "榜单推荐-达人涨粉榜": "Khuyến nghị Bảng xếp hạng - Bảng tăng người theo dõi của chuyên gia",
  "榜单推荐-日销商品榜": "Khuyến nghị Bảng xếp hạng - Bảng hàng hóa bán hàng ngày",
  "榜单推荐-运营主推tap": "Khuyến nghị Bảng xếp hạng - Tap chính vận hành",
  所有用户包括访客: "Tất cả Người dùng Bao gồm Khách",
  申样用户: "Người dùng Đăng ký Mẫu",
  橱窗授权用户: "Người dùng Được ủy quyền Tủ trưng bày",
  默认跳转: "Chuyển hướng Mặc định",
  指定H5页面: "Trang H5 Cụ thể",
  APP内页面: "Trang trong APP",
  单商品: "Sản phẩm Đơn",
  待发货订单列表: "Danh sách Đơn hàng Chờ Giao hàng",
  待履约订单列表: "Danh sách Đơn hàng Chờ Thực hiện",
  橱窗检测账号列表: "Danh sách Tài khoản Kiểm tra Tủ trưng bày",
  高佣可替换商品列表: "Danh sách Sản phẩm Có thể Thay thế Hoa hồng Cao",
  APP指定商品集落地页: "Trang đích Bộ sản phẩm được chỉ định của APP",
  APP数据持续好货榜: "Bảng xếp hạng Hàng hóa Tốt Liên tục của APP",
  编辑内容: "Chỉnh sửa Nội dung",
  确认关闭推送消息: "Xác nhận Đóng Tin nhắn Đẩy",
  确认修改推送内容: "Xác nhận Sửa đổi Nội dung Đẩy",
  "确认后内容将生效，你还要继续吗？":
    "<span style='color: red'>Sau khi xác nhận, nội dung sẽ có hiệu lực,</span> bạn có muốn tiếp tục không?",
  正在编辑推送内容: "Đang Chỉnh sửa Nội dung Đẩy",
  推送标题: "Tiêu đề Đẩy",
  请输入至少五个字符: "Vui lòng Nhập ít nhất Năm ký tự",
  "确认后将不再推送此条消息，你还要继续吗？":
    "<span style='color: red'>Sau khi xác nhận, tin nhắn này sẽ không còn được đẩy nữa,</span> bạn có muốn tiếp tục không?",
  关闭推送: "Đóng Đẩy",
  开启推送: "Bật Đẩy"
};
