export default {
  Sample_Application: "Sample Application",
  TAP_Sales_Report: "TAP Sales Report",
  Creator_Analysis: "Creator Analysis",
  Product_Analysis: "Product Analysis",
  Seller_Analysis: "Seller Analysis",
  Creator_Name_: "Creator Name",
  Please_enter: "Please enter",
  Order_Creation_Date_: "Order Creation Date",
  Export_Report: "Export Report",
  Data_last_updated_on__mm___dd____yy____hh_mm___BKK_time_zone_:
    "Data last updated on mm dd, yy, HH:MM (BKK time zone)",
  Creator_with_Sales: "Creator with Sales",
  Authorized_Creator: "Authorized Creator",
  Unauthorized_Creator: "Unauthorized Creator",
  GMV: "GMV",
  Completed_GMV: "Completed GMV",
  Cancelled_GMV: "Cancelled GMV",
  Progressing_GMV: "Progressing GMV",
  Completed_Sales: "Completed Sales",
  Live_Streaming___Completed_GMV: "Live Streaming - Completed GMV",
  Video___Completed_GMV: "Video - Completed GMV",
  Showcase___Completed_GMV: "Showcase - Completed GMV",
  Product_Information: "Product Information",
  Product_ID_: "Product ID",
  Product_Price: "Product Price",
  Seller: "Seller",
  Sales_Volume: "Sales Volume",
  Store_Code_: "Store Code",
  Seller_Name_: "Seller Name",
  Seller_Information: "Seller Information",
  Actions: "Actions",
  View_Sales_Products: "View Sales Products",
  Sales_Products_of_Store_Named_xxxxx: "Sales Products of Store Named #",
  saleByCreator: "#'s sale product",
  orderNum: "Orders",
  "订单日期": "Order Date",
  "带货达人": "Creator",
  "所属商家": "Seller Info.",
  "投流状态": "Ads Status",
  "未投流": "Not Promoted",
  "已投流": "Promoted",

};
