export default {
  Product_Ranking_List: "排行榜列表",
  List_ID_: "榜单ID：",
  List_Name_: "榜单名称：",
  On___Off: "是否上架：",
  Please_Select_Here: "请选择",
  New_List: "新增榜单",
  List_ID: "榜单ID",
  List_Name: "名称",
  Banner_image: "配图",
  Sub_list: "是否分类",
  Status: "状态",
  Sort: "上架排序",
  Action: "操作",
  Off: "待上架",
  On: "已上架",
  Preview: "预览",
  Edit: "编辑",
  On: "上架",
  Off: "下架",
  Please_try_to_edit_it_after_you_turn_the_list_off_: "请先下架后才可操作编辑选项",
  Confirm_to_turn_it_off: "确认下架",
  Are_you_sure_that_you_want_to_turn_the_list_off_: "您确认在首页下架该排行榜吗？",
  Cancel: "取消",
  Confirm: "确定",
  Turn_on_the_list: "上架排行榜",
  If_you_choose_to_change_the_active_banners__the_original_banner_will_be_invalid_:
    "若选择已上架的位置则会替换原排行榜，原排行榜会下架",
  Available: "空",
  Select: "选择",
  Cancel: "取消",
  Confirm: "确定",
  Change_confirmation: "确认替换",
  Are_you_sure_that_you_change_the_original_list_which_named__xxx__instead__It_will_be_turned_off_after_you_changed_it_:
    "您确认替换原#，替换后原榜单将会被下架？",
  English_Version: "英文版",
  Thai_Version: "泰文版",
  Vi_Version: "越南版",
  New___Edit_Ranking_List: "新增/编辑排行榜",
  List_Name__English__: "榜单名称（英文）：",
  List_Name__Thai__: "榜单名称（泰文）：",
  List_Name__VN__: "榜单名称（越南）：",
  Maximum_10_characters: "最多输入10个字符",
  Required_to_fill_in_: "此为必填项",
  Homepage_Banner_: "首页配图：",
  Self_designed_Banner: "自定义banner图：",
  No: "否",
  Yes: "是",
  Ranking_List_Banner_: "榜单banner：",
  Main_Color_: "主题色系：",
  Sub_list: "是否商品分类：",
  Sub_list_1_Name__English__: "分类#名称（英文）：",
  Sub_list_1_Name__Thai__: "分类#名称（泰文）：",
  Product_List_ID_: "瀑布流ID：",
  Numbers_only: "仅可输入数字",
  Product_List_ID_does_not_exist: "瀑布流ID不存在",
  You_can_find_Product_List_ID_at_Operational_Management_Product_List_Management_:
    "瀑布流ID可在运营管理-瀑布流配置中找到",
  New_sub_list__4_sub_list_can_be_added_: "新增分类（还可新增#个分类）",
  Image_suggestion__375_200_px_and_2M_as_maximum_size: "图片建议：xxx X xxx px，大小不可超过2M",
  Delete_List: "删除榜单",
  Delete_confirmation: "确认删除",
  Are_you_sure_that_you_want_to_delete_the_list_: "您确认要删除该排行榜吗？",
  Cancel: "取消",
  Confirm: "确定",
  Save_and_Preview: "保存并预览",
  Save: "保存",
  Delete_failed: "不可删除",
  Failed_reason__This_list_is_on__Please_try_it_again_after_you_turn_it_off_:
    "失败原因：该排行榜状态为已上架，请先下架后再操作删除。",
  Save_succeed_: "保存成功",
  Delete_succeed_: "删除成功",
  Image_upload_failed: "图片上传失败",
  Failed_reason__it_exceeds_maximum_size_as_2M_: "失败原因：超出图片大小限制2M",
  Save_failed: "无法保存",
  Failed_reason__This_list_is_on__Please_try_it_again_after_you_turn_it_off_:
    "失败原因：该排行榜已上架",
  Edit_Again: "返回编辑",
  Complete: "完成并退出",
  Delete: "删除",
  榜单简介英文: "榜单简介 (英文)：",
  榜单简介泰语: "榜单简介 (泰语)：",
  榜单简介越南: "榜单简介 (越南)：",
  榜单简介: "榜单简介",
  sampleAmountTips: "样品金额为SKU单价*申请数量，若不填则视为无限大，无金额限制。",
  sampleFulfillmentTips: "样品金额为履约申样数/已发货数，若不填则视为0%，无履约率限制。"
};
