export default {
  Agree: "ยอมรับ",
  Reject: "ปฏิเสธ",
  Action: "ดำเนินการ",
  Search: "ค้นหา",
  Reset: "ตั้งค่าใหม่",
  Add: "เพิ่ม",
  NewAdd: "เพิ่ม",
  Export: "ส่งออก",
  BulkExport: "การส่งออกแบทช์",
  Import: "นำเข้า",
  bulkImport: "การนำเข้าแบทช์",
  Delete: "ลบ",
  BulkDelete: "ลบจำนวนมาก",
  Edit: "แก้ไข",
  copy: "คัดลอก",
  TakeDown: "ปิดการใช้งาน",
  Uploaded: "เปิดการใช้งาน",
  More: "เพิ่มเติม",
  Cancel: "ยกเลิก",
  Finish: "เสร็จสิ้น",
  Submit: "ส่ง",
  Confirm: "ยืนยัน",
  AllSelete: "เลือกทั้งหมด",
  ShowMore: "แสดงเพิ่มเติม",
  ShowLess: "แสดงน้อยลง",
  SeeDetail: "ดูรายละเอียด",
  Audit: "อนุมัติ",
  Audit2: "ตรวจสอบ",
  Detail: "รายละเอียด",
  Refuse: "ปฏิเสธ",
  Adopt: "ผ่าน",
  Save: "บันทึก",
  Tip: "เคล็ดลับ",
  uploadFile: "อัปโหลดไฟล์",
  clearAll: "ว่าง",
  select: "เลือก",
  refresh: "refresh",
  获取分享链接: "ให้ลิงก์แชร์"
};
