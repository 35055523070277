export default {
  Lazada页面: "Lazada page",
  跳转至: "Jump to",
  // 活动页面配置
  search: "ค้นหา",
  Reset: "ตั้งค่าใหม่",
  Add: "เพิ่ม",
  All: "ทั้งหมด",
  NoStart: "ยังไม่เริ่ม",
  Ongoing: "กำลังดำเนินการ",
  Closed: "เกิน",
  ID: "ID",
  Status: "สถานะ",
  URL: "URL",
  Actions: "การดำเนินการ",
  ViewDetail: "รายละเอียด",
  delete: "ลบ",
  Edit: "แก้ไข",
  PageTableName: "ชื่อเพจ",
  PageTableTime: "เวลากิจกรรม",
  // 动态页面配置
  Basic: "ข้อมูลพื้นฐาน",
  CampaignTime: "เวลากิจกรรม：",
  PageName: "ชื่อเพจ:",
  BasicTips:
    "ขนาดที่แนะนำ: ความกว้างของรูปภาพคือ 750 พิกเซล และขนาดจำกัดที่ 2MB รองรับเฉพาะรูปแบบ png, jpg, jpeg และ gif เท่านั้น",
  BasicBg: "สีพื้นหลัง：",
  BasicDefaultBg: "สีพื้นหลังเริ่มต้น：#f5f5f5",
  Voucher: "การตั้งค่าคูปอง",
  ActiveProduct: "การตั้งค่าผลิตภัณฑ์ที่ใช้งานอยู่",
  navigation: "ว่าจะแสดงการนำทางบนพื้นหรือไม่:",
  recommended: "แนะนำให้ใช้เมื่อมี 4 ชั้นขึ้นไป",
  floor: "พื้น",
  iconDefault: "icon ค่าเริ่มต้น：",
  iconSelected: "iocn เลือก：",
  iconNameEn: "icon ชื่อ（En）：",
  iconNameThai: "icon ชื่อ（Thai）：",
  Suggested:
    "ขนาดที่แนะนำ: ความกว้างของรูปภาพคือ 750*150 พิกเซล และขนาดจำกัดที่ 2MB รองรับเฉพาะรูปแบบ png, jpg, jpeg และ gif เท่านั้น",
  ActivityID: "กิจกรรม ID：",
  ProductList: "รายการสินค้ารูปแบบ:",
  Style1: "สไตล์ 1",
  Style2: "สไตล์ 2",
  Style3: "สไตล์ 3",
  Style4: "สไตล์ 4",
  floor2: "พื้น 2：",
  Deletefloor: "ลบชั้น",
  Addfloor: "เพิ่มชั้น",
  save: "บันทึก",
  cancel: "ยกเลิก",
  PageTitle: "ชื่อเพจ:",
  delTip: "ลิงก์จะถูกยกเลิกหลังจากลบ โปรดยืนยันการลบแคมเปญ？",
  savePreview: "บันทึกและดูตัวอย่าง",
  added: "เพิ่มสำเสร็จ",
  idError: "รหัสไม่ถูกต้อง",
  more10: "มากสุด 10 อัน",
  inputActId: "กรุณากรอก Activity  ID",
  inputfloorImg: "โปรดอัปโหลดรูปภาพ",
  inputIcon: "โปรดอัปโหลดไอคอนที่ไม่ใช้งาน",
  inputIcons: "โปรดอัปโหลดไอคอนที่ใช้งานอยู่",
  inputFloorEn: "กรุณากรอกชื่อภาษาอังกฤษ",
  inputFloorTh: "กรุณากรอกชื่อภาษาไทย",
  confirmCanelSave: "โปรดยืนยันยกเลิกการบันทึก",
  someShip: "ส่งฟรี ไม่รวมพื้นที่ห่างไกล",
  allShip: "จัดส่งฟรี",
  noShip: "ไม่ฟรีขนส่ง",
  sleWaterful: "กรุณาเลือกรายการสินค้า",
  活动商品类型: "ประเภทสินค้า",
  Youpik商品活动: "สินค้าYoupik",
  Lazada商品活动: "สินค้าลาซาด้า",
  Youpik商品: "สินค้าYoupik",
  Lazada商品: "สินค้าลาซาด้า",
  楼层名称: "ชื่อชั้น",
  Lazada瀑布流: "ชุดรายการสินค้า  Lazada",
  主会场展示排数: "แถวที่แสดงสินค้า",
  // youpik改版
  Lazada瀑布流ID: "รหัสสินค้า Lazada",
  savewaterfullFailed: "ไม่สามารถยืนยันได้ เนื่องจากไม่มีรายการสินค้า",
  waterfullNo: "ไม่มี ID รายการสินค้านี้ ",
  主会场展示排数提示: "หากกรอกเลข 0 จะแสดงสินค้าทั้งหมด ",

  //
  切换商品类型: "สลับประเภทสินค้า",
  "切换类型将不保存原类型下的设置内容，你还要继续吗？":
    "การสลับประเภทจะไม่บันทึกการตั้งค่าภายใต้ประเภทดั้งเดิมคุณต้องการดำเนินการต่อหรือไม่",
  "分会场Head banner": " โปรดอัปโหลดรูปภาพ Head banner",
  主会场楼层图片: "banner",
  "建议尺寸：图片宽度750像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。":
    "ขนาดที่แนะนำ: ความกว้างของรูปภาพคือ 750 Pixel และขนาดจำกัดที่ 2MB รองรับเฉพาะไฟล์ png, jpg, jpeg และ gif",
  inputfloorImg2: "โปรดอัปโหลดรูปภาพ Head banner",
  请输入楼层名称: "ชื่อชั้น",
  mainLineNum: "เลือกได้ 1-5 "
};
