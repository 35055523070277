export default {
  新人样品池配置: "Cấu hình mẫu cho người mới",
  保存: "Lưu",
  "保存后瀑布流ID将生效，你还要继续吗？":
    '<span style="color: red;">Sau khi lưu, ID dòng thác sẽ có hiệu lực.</span>, Bạn có muốn tiếp tục không?',
  确认保存: "Xác nhận lưu",
  "保存失败：瀑布流不存在！": "Lưu thất bại: Dòng thác không tồn tại!",
  "操作成功！": "Thao tác thành công!",
  继续: "Tiếp tục",
  取消: "Hủy bỏ"
};
