const user_icon =
  "https://file-th.ypkclient.com/system/20230915/16e33f578d3e42248903223e72623e4a.png";
const user_icon_active =
  "https://file-th.ypkclient.com/system/20230915/3b014a33cc044d8fb2fad601dccf28e2.png";

const operationRoute: IRouteProps[] = [
  {
    path: "/user-manage",
    name: "用户管理",
    meta: { icon: user_icon, activeIcon: user_icon_active },
    routes: [
      {
        path: "/user-manage/talent-gonghai",
        name: "达人公海",
        component: "./user-manage/talent-gonghai"
      },
      {
        path: "/user-manage/uchoice-user",
        name: "跟进达人",
        component: "./user-manage/uchoice-user"
      },
      {
        path: "/user-manage/uchoice-user-video",
        name: "用户视频列表",
        component: "./user-manage/uchoice-user-video",
        hideInMenu: true
      },
      {
        path: "/user-manage/tag-manage",
        name: "标签管理",
        component: "./user-manage/tag-manage"
      },
      {
        path: "/user-manage/anchor-link",
        name: "达人链接管理",
        component: "./user-manage/anchor-link"
      },
      {
        path: "/user-manage/anchor-link/detail",
        name: "达人链接管理详情",
        component: "./user-manage/anchor-link/detail",
        hideInMenu: true
      }
    ]
  }
];

export default operationRoute;
