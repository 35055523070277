export default {
  All: "全部",
  CouponName: "优惠券名称",
  PlaceInputName: "请输入优惠券名称",
  CouponCode: "优惠券编码",
  PlaceInputCode: "请输入优惠券编码",
  Status: "状态",
  NotStarted: "未开始",
  Ongoing: "进行中",
  Closed: "已结束",
  CouponFee: "优惠券面额",
  TermsOfUse: "使用条件",
  Man: "满",
  Use1: "泰铢可用",
  Time: "使用时间",
  CreateTime: "创建时间",
  Stocks: "发行数量",
  Limits: "每人限领数量",
  SendType: "发放类型",
  NoLimit: "不限",
  Limit: "限领",
  Times: "次",
  SystemRelease: "系统发放",
  CollectionRequired: "需领取",
  ViewProducts: "查看商品",
  EditCoupon: "编辑优惠券",
  AddCoupon: "添加优惠券",
  AddCouponSucc: "添加优惠券成功",
  AddCouponHandle: "添加优惠券成功，前往选择商品",
  Set: "优惠券设置",
  SeleteItem: "选择商品",
  FullReduction: "满减",
  TotalFee: "满减金额",
  CouponLessFee: "优惠金额",
  CouponType: "优惠券类型",
  AllMoney: "总金额",
  AfterCoupon: "领取当日起",
  AfterSend: "发放当日起",
  UseDays: "天内可用",
  ItemSet: "商品设置",
  userLimit: "用户限制",
  AllItem: "全店商品",
  DesignatedItem: "指定商品（请在提交后选择商品）",
  SeleteTime: "请选择使用时间",
  valideReject: "数据校验失败",
  RequireSave: "提交后优惠券可使用商品将无法更改, 是否继续?",
  NotSeleteItem: "还未选择商品，不可保存",
  MinZero: "输入值必须大于0",
  CouponFeeVal: "满减金额必须大于优惠金额",
  InputInt: "输入值必须为正整数",
  TotalFeeVal: "优惠金额必须小于满减金额",
  NotSelete: "还未选择",
  ConfirmDeleteSelete: "此操作将删除已选中的商品，还可再次添加, 是否继续?",
  AllPeople: "所有人",
  oldPeople: "老用户",
  onePeople: "指定用户",
  newPeople: "新用户",
  itemInfo: "商品信息",
  ItemPrice: "商品价格",
  Inventory: "库存",
  notNameEmpty: "名称不能为空",
  notTotalFeeEmpty: "满减金额不能为空",
  notCouponLessFeeEmpty: "优惠金额不能为空",
  notStocksEmpty: "库存不能为空",
  notDateEmpty: "有效时间不能为空",
  EditTip: "失焦/回车触发修改，内容类型为数字，不可为空",
  MinFee: "最小面额",
  MaxFee: "最大面额",
  FeeError: "搜索条件中面额输入大小有误",
  Fee: "面额",
  UserPickup: "用户领取",
  ID: "优惠券ID",
  PlaceInputID: "请输入优惠券ID",
  TotalNum: "总发放数",
  LimitNum: "剩余数量",
  SendRate: "领取率",
  UseNum: "使用数",
  UseRate: "使用率",
  CouponFee2: "优惠券成本",
  OrderFee: "优惠券促成金额",
  MemberName: "用户名称",
  MemberMobile: "手机号码",
  OrderNo: "订单号",
  UseStatus: "使用状态",
  UseTime: "使用时间",
  NotUse: "未使用",
  Used: "已使用",
  Expired: "已过期",
  Send: "发放",
  ConfirmSend: "确认发放吗？",
  RequireLimit: "请输入每人限领数量",
  LimitMin: "每人限领数量应大于零",
  RequireStocks: "请输入发行数量",
  StocksMin: "发行数量应大于零",
  COUPON_FOR_SHOP: "店主直邀用户且该直邀用户在平台完成首单，店主可获得优惠券",
  COUPON_FOR_MEMBER_LOGIN: "新用户下首单可获得优惠券",
  COUPON_FOR_REGISTER: "新用户注册成功获得优惠券",
  GetTime: "领取时间",
  SeleteGetTime: "请选择领取时间",
  InputDays: "请输入可用天数",
  InputUserId: "请输入用户ID",
  InputUserIds: "请输入用户ID,多个ID用“,”隔开",
  InputSellerId: "请输入商家ID",
  InputSellerIds: "请输入商家ID,多个ID用“,”隔开",
  NameTip: "建议描述优惠券提供的具体优惠。例如：满100元减5元",
  StockTip: "填写想要发放的优惠券数量，如不填写默认为不限",
  GetTimeTip: "用户可领取优惠券的时间",
  UseTimeTip: "用户可使用优惠券的时间",
  LimitTip: "每个用户总领券上限，如不填写默认为不限",
  RequireName: "请输入优惠券名称",
  RequireTotal: "请输入满减金额",
  RequireCouponFee: "请输入优惠券面额",
  UserLevel: "根据用户等级",
  UserId: "根据用户ID",
  RequireUserLevel: "请选择用户等级",
  RequireUserId: "请添加用户ID",
  RequireSellerId: "请添加商家ID",
  RequireGategory: "请添加类目",
  TimeCheck: "使用时间结束时间晚于领取结束时间",
  Promote: "推广",
  ConfirmPromote: "确认推广此优惠券吗？",
  SendLimits: "每人发行数量",
  RequireImportSellerId: "请上传用户ID文件",
  DownloadModel: "下载模板",
  ShippingCoupon: "运费券",
  NoDoor: "无门槛",
  NoDoorTip: "0表示无门槛",
  // ops优化新增需求
  all: "所有店铺",
  appoint: "指定店铺",
  appointAll: "指定店铺领取"
};
