export default {
  首页: "หน้าแรก",
  绩效管理: "การจัดการประสิทธิภาพ",
  "绩效管理.PIC绩效": "ประสิทธิภาพของ PIC",
  新增: "เพิ่ม",
  编辑: "แก้ไข",
  运营管理: "การจัดการการดำเนินงาน",
  "运营管理.首页配置": "การตั้งค่าโฮมเพจ",
  登录: "เข้าสู่ระบบ",
  "运营管理.添加": "เพิ่ม",
  "运营管理.编辑": "แก้ไข",
  "运营管理.详情": "รายละเอียด",
  用户管理: "การจัดการผู้ใช้งาน",
  "用户管理.uChoice用户": "บัญชีผู้ใช้ uChoice",
  "运营管理.瀑布流配置": "รายการสินค้า",
  "用户管理.达人公海": "ตลาดครีเอเตอร์",
  "用户管理.跟进达人": "ติดตามครีเอเตอร์",
  样品申请: "สินค้าตัวอย่าง",
  "订单管理.sampleApplication": "สินค้าตัวอย่าง",
  "运营管理.排行榜配置": "การจัดอันดับสินค้า",
  "运营管理.排行榜新增": "เพิ่ม / แก้ไข อันดับสินค้า",
  "运营管理.排行榜编辑": "เพิ่ม / แก้ไข อันดับสินค้า",
  "运营管理.新人样品池配置": "การจัดการสินค้าตัวอย่างสำหรับลูกค้าใหม่",
  "运营管理.banner配置": "แบนเนอร์หน้าหลัก",
  "运营管理.动态页配置": "การตั้งค่าหน้าแคมเปญ",
  商品管理: "การจัดการสินค้า",
  "商品管理.TT商品": "สินค้า Tiktok",
  "商品管理.普通活动": "กล่องแคมเปญทั่วไป",
  "商品管理.明星活动": "กล่องแคมเปญเซเลป",
  "商品管理.商品集": "กลุ่มสินค้า",
  "运营管理.投流管理": "วิดีโอโปรโมทสินค้า",
  "用户管理.标签管理": "การจัดการแท็ก",
  "用户管理.达人链接管理": "การตั้งค่าลิงก์สินค้าของครีเอเตอร์",
  "用户管理.达人链接管理详情": "การตั้งค่าลิงก์สินค้าของครีเอเตอร์",
  "运营管理.uChoice&TT样品申请门槛设置": "เกณฑ์ขอตัวอย่าง",
  销售报表: "รายงานยอดขาย",
  "销售报表.达人分析": "ข้อมูลครีเอเตอร์",
  "销售报表.商品分析": "ข้อมูลสินค้า",
  "销售报表.商家分析": "ข้อมูลร้านค้า",
  "销售报表.视频履约分析": "การวิเคราะห์ยอดขายจากคลิปวิดีโอ",
  // 供应链
  供应链管理: "การจัดการซับพลายเออร์",
  供应链管理新增: "การจัดการซัพพลายเออร์ - เพิ่ม",
  供应链管理编辑: "การจัดการซัพพลายเออร์ - แก้ไข",
  消息通知: "การแจ้งเตือนข้อความ",
  "消息通知.系统推送": "การแจ้งเตือนระบบ",
  "消息通知.自定义推送": "การแจ้งเตือนที่กำหนดเอง"
};
