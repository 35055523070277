export default {
  StartTime: "Start Time",
  Tip: "Tip",
  EndTime: "End Time",
  ConfirmDelete: "Confirm Delete",
  DeleteSuccess: "Successfully Delete",
  Canceled: "Cancelled",
  ActionSuccess: "Operation Successful!",
  ActionError: "Operation Failed!",
  ConfirmDeletes: "Confirm Batch Delete",
  ContentCopiedClipboard: "Copied to Clipboard",
  SorryCopyFailed: "Copy Failed",
  TemporarilyNoData: "No Data",
  uploadRejected: "Upload failed, please try again!",
  isDeleteImg: "Do you want to delete the picture?",
  goBeyondFilNum: "File limit exceeded!",
  imgBigLimit: "The picture cannot exceed 20M!",
  当前限制选择x个图片: "Currently limited to select {limit} images",
  refresh: "Refresh"
};
