export default {
  通知中心: "通知中心",
  新增: "新增",
  搜索: "搜索",
  序号: "序号",
  标题: "标题",
  时间范围: "时间范围",
  是否强提醒: "是否强提醒",
  发布时间: "发布时间",
  操作: "操作",
  编辑: "编辑",
  删除: "删除",
  是: "是",
  否: "否",
  删除后: "删除后用户侧也无法看到通知记录，且不可恢复是否确定删除？",
  确定: "确定",
  取消: "取消",
  内容: "内容",
  通知: "通知",
  详情: "详情",
  忽略: "忽略",
  状态: "状态",
  已读: "已读",
  未读: "未读",
  返回: "返回"
};
