export default {
  bannerOver: " เนื้อหาเกินจำนวนอักขระที่กำหนด",
  originBannerPosition: "ที่ตั้ง",
  bannerPosition: "ที่ตั้ง Banner",
  topBanner: "Top banner",
  middleBanner: "Middle banner",
  middleBannerFix: "Middle banner fix",
  middleLongBanner: "middle long banner",
  DailyRecommendation: "สินค้าแนะนำประจำวัน",
  lazadaLandingPage: "รูป Lazada Affiliate Banner ",
  bannerTime: "ระยะเวลาแสดง Banner",
  bannerSort: "ลำดับ Banner",
  displayLocation: "ตำแหน่ง",
  RotationBanner: "Slides banner",
  FixedUpperLeft: "วางด้านบน",
  FixedUpperRight: "วางด้ายล่าง",
  LazadaLandingPage: "หน้า Lazada Affiliate",

  originName: "ชื่อ Homepage",
  Name: "ชื่อ Homepage Banner",
  ActivityStatus: "ลิงก์ TB",
  JumpType: "ประเภท",
  All: "ทั้งหมด",
  Item: "ลิงก์สินค้า",
  Activity: "ลิงก์แคมเปญ",
  AlreadyShelves: "เปิดใช้งานแล้ว",
  ToShelved: "นำไปวางบนหิ้ง",
  RemovedShelves: "ปิดงานใช้งานแล้ว",
  Image: "รูปภาพ",
  ImgTip:
    "ขนาด : 702*202 ขนาดไม่เกิน 2 MB นามสกุลไฟล์ต้องเป็น png, jpg, jpeg, and gif เท่านั้น อัปโหลดได้เพียงหนึ่งรูป",
  ImgTipBanner:
    "ขนาด : 600*300 ขนาดไม่เกิน 2 MB นามสกุลไฟล์ต้องเป็น png, jpg, jpeg, and gif เท่านั้น อัปโหลดได้เพียงหนึ่งรูป",
  Sort: "ลำดับ Banner",
  ItemId: "SKUs ID",
  JumpUrl: "ลิงก์ TB",
  SortTip: "สามารถกรอกเลข 1-6 ถ้าจำนวนเลขน้อย ตำแหน่งยิ่งสูง จำนวนเลขมากตำแหน่งจะต่ำ",
  MiddleLongSortTips: "สามารถกรอกเลข 1-2 ถ้าจำนวนเลขน้อย ตำแหน่งยิ่งสูง จำนวนเลขมากตำแหน่งจะต่ำ",
  TopBannerSortTip: "สามารถกรอกเลข 1-12 ถ้าจำนวนเลขน้อย ตำแหน่งยิ่งสูง จำนวนเลขมากตำแหน่งจะต่ำ",
  MiddleBannerSortTips: "สามารถกรอกเลข 1-4 ถ้าจำนวนเลขน้อย ตำแหน่งยิ่งสูง จำนวนเลขมากตำแหน่งจะต่ำ",
  onTheShelfTips: " จำนวนแบนเนอร์ที่เปิดใช้งานในช่วงเวลานี้เต็มแล้ว ไม่สามารถเพิ่มได้",
  Uploaded: "ออนไลน์",
  Deleted: "ออฟไลน์",
  Address: "ที่อยู่",
  originBannerTime: "ระยะเวลาแสดง",
  originImage: "รูปภาพ",
  originSort: "ลำดับ",
  originItemId: "รหัสสินค้า",
  pageLink: "ลิงก์เพจ",
  h5Page: "หน้า H5",
  h5Link: "ลิงค์ H5",
  noValidTapId: "ไม่มี TAP ID ที่ถูกต้อง",
  success: "สำเร็จ",
  UrlOrID: "ข้ามไปที่หน้า/IDของสินค้า",
  AreYouSureToDeleteThisCampaignPage:
    "หลังจากลบแล้ว ลิงก์กิจกรรมจะใช้งานไม่ได้ คุณแน่ใจหรือไม่ที่จะลบหน้ากิจกรรมนี้",
  ConfirmDeleteBanner: "ยืนยันที่จะลบแบนเนอร์ใช่หรือไม่",
  InAppPage: "หน้าในแอป",
  InAppPageLink: "ลิงค์ไปยังหน้าในแอป",

  addBanner: "เพิ่ม Homepage Banner",
  editBanner: "แก้ไข Homepage Banner",
  文本宣传: "โปรโมชั่นข้อความ",
  图片宣传: "โปรโมชั่นภาพ",
  建议尺寸702x220:
    "ขนาดที่แนะนำ: 702*220 พิกเซล ขนาดจำกัดที่ 2MB; รองรับเฉพาะรูปแบบ png, jpg, jpeg, gif เท่านั้น สามารถอัปโหลดได้ครั้งละหนึ่งภาพเท่านั้น",
  "建议尺寸：宽度固定702像素，长度不限，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。":
    "ขนาดที่แนะนำ: ความกว้างคงที่ 702 พิกเซล ความยาวไม่จำกัด ขนาดจำกัดที่ 2MB รองรับเฉพาะรูปแบบ png, jpg, jpeg, gif เท่านั้น อัปโหลดได้ครั้งละ 1 ภาพเท่านั้น"
};
