export default {
  merchantpasses: "商家通过",
  operationalReviewRejected: "运营审核拒绝",
  operationalReviewApproved: "运营审核通过",
  pendingClassificationBtn: "有x个商品待确认分类",
  categoryLabels: "类目标签",
  wiseManPerStatus: "达人履约情况",
  totalNumSampleAppOrders: "申样总订单数",
  shippedOrderNum: "已发货订单数",
  pendingPerformanceNum: "待履约订单数",
  performanceNum: "履约率",
  mannerOfPerformance: "可履约方式",
  area: "地区",
  detailedAddress: "详细地址",
  pleaseDatailAdd: "请输入街道名称，公寓名称，房间号",
  ttSample: "Uchoice&TT样品申请",
  All: "全部",
  Shipping: "待发货",
  ToBeReviewed: "待审核",
  liveTimeTips: "直播时间不能为空",
  modifyTheNumberOfProducts: "修改商品数",
  inputNewSample: "请输入新的样品数",
  LiveAndVideo: "直播和视频",
  PerformanceToBereviewed: "履约待审核",
  PerformancePass: "履约审核通过",
  RefusalOfPerformance: "履约拒绝",
  MerchantReviewRejected: "商家拒绝",
  AutomatedReviewApproved: "机审通过",
  ShipmentRejected: "发货拒绝",
  Rejected: "拒绝",
  PerformanceStatus: "履约情况",
  PerformanceMethod: "履约方式",
  appUp: "app上传",
  orderBack: "订单回溯",
  PICConfirm: "PIC确认",
  视频回溯: "视频回溯 ",
  直播回溯: "直播回溯",
  直播视频回溯: "直播&视频回溯",
  promised: "已履约",
  noPromise: "未履约",
  promiseedReject: "该履约曾被拒绝过",
  videoid: "视频ID（选填）",
  videosId: "视频ID",
  upLoadVideo: "上传投流视频ID",
  confirmReject: "确认拒绝这次履约么",
  promiseFinish: "同意后进入已完成",
  clickLogOffWeb: "点击查看物流官网",
  FulfillRecords: "履约记录",
  inputPositiveInteger: "只能输入正整数",
  uploadedTheVideoPromotionID: "该订单已上传视频ID:",
  VideoIdExists: "视频ID已存在",
  liveId: "直播ID",
  liveLink: "直播链接",
  maxInput999Num: "最大只能输入999",
  inflowCloseSuccess: "关闭投流成功",
  ToSellerReviewed: "待商家审核",
  sellerAgree: "商家同意",
  agreeToSellerAgree: "同意后进入待商家同意状态",
  sellerAgreeToShipping: "同意后进入待发货状态",
  videoLink: "视频链接",
  Live: "直播",
  video: "视频",
  Delivered: "已发货",
  closeed: "已关闭",
  OrderNum: "订单号",
  NameOfTalent: "达人名称",
  // OfflineUsers: "线下用户",
  importApply: "导入样品申请",
  importShip: "导入发货信息",
  repeatApply: "重复申请",
  //
  StoreName: "店铺名称",
  ItemId: "商品ID",
  ItemName: "商品名称",
  TAPID: "TAP ID ",
  ApplyTime: "申请时间",
  lookRecord: "查看记录",
  Item: "商品",
  unitPrice: "单价",
  Commission: "佣金",
  tradingStatus: "交易状态",
  ScreenshotSales: "厨窗销售截图",
  TAPActivityInformation: "TAP活动信息",
  lastPage: "上一页",
  nextPage: "下一页",
  NumberOfFans: "粉丝数",
  AverageVideoPlayback: "平均视频播放量",
  CheckLogistics: "查看物流",
  CourierCompany: "快递公司",
  GoToFlashOfficialWebsite: "前往Flash官网查看",
  goToLogisticsOfficialWebsite: "前往物流官网查询",
  flashMessage: "物流信息",
  AwbNum: "快递单号",
  ship: "发货",
  orderStatusWillChang: "订单状态将会变为待发货",
  Select: "请选择",
  请选择原因: "请选择原因",
  "申诉失败原因会返回给用户，请根据实际情况填写": "申诉失败原因会返回给用户，请根据实际情况填写",
  其他原因说明: "其他原因说明",
  Lazada订单申诉失败原因: "Lazada订单申诉失败原因",
  otherReasons: "其他原因填写",
  actTip: "操作成功，请到“导入历史”里查看",
  toSelect: "请勾选待导出商品",
  useExcel: "通过Excel导入",
  selectFile: "选择文件",
  downloadModel: "下载模板",
  importModel: "导入模板",
  importHistory: "导入历史",
  exportHistory: "导出历史",
  UserName: "用户名称",
  time: "时间",
  fileName: "文件名",
  Status: "状态",
  Completed: "已完成",
  Failure: "失败",
  download: "下载",
  palceInputAllContent: "请填写全部内容!",
  receiver: "收货人",
  "uChoice&TT Sample Application Creator Threshold Setting": "Uchoice&TT样品申请达人门槛设置",

  申请渠道: "申请渠道",
  线上: "线上",
  线下: "线下",
  截图时间段: "截图时间段",
  "30天成交件数": "30天成交件数",
  成交件数档位: "成交件数档位",
  寄样信息: "寄样信息",
  新增订单: "新增订单",
  修改订单: "修改订单",
  订单号: "订单号",

  商品分类: "商品分类",
  创建商品集链接: "创建商品集链接",
  添加至已有商品集: "添加至已有商品集",
  请选择商品集: "请选择商品集",
  "商品集名称（泰文）": "商品集名称（泰文）",
  "推荐理由（泰文）": "推荐理由（泰文）",
  "商品集名称（越南）": "商品集名称（越南）",
  "推荐理由（越南）": "推荐理由（越南）",
  "近30天热销商品，下个爆款等你来选！批量免费样品吧等你来拿~":
    "uChoice เลือกสินค้าขายดี เพิ่มสินค้าคอมมิชชั่นสูง ดาวน์โหลดแอปเพื่อดูเพิ่มเติม!",
  创建商品集: "创建商品集",
  搜索商品集ID或名称: "搜索商品集ID或名称",
  创建集合: "创建集合",
  集合名称: "集合名称",
  创建人: "创建人",
  商品集ID: "商品集ID",
  名称: "名称",
  简介: "简介",
  有效商品数: "有效商品数",
  集合链接: "集合链接",
  分享: "分享",
  管理商品: "管理商品",
  分享商品集: "分享商品集",
  序号: "序号",
  请输入商品ID或商品名称: "请输入商品ID或商品名称",
  批量移除: "批量移除",
  添加商品: "添加商品",
  商品列表: "商品列表",
  全选本页: "全选",
  佣金范围: "佣金范围",
  最低库存量: "最低库存量",
  "近2周销售增长%": "近2周销售增长%",
  最低总销量: "最低总销量",
  近30天最低销量: "近30天最低销量",
  确认要生成商品集链接: "确认要生成商品集链接",
  继续: "继续",
  确认要添加商品至集合: "确认要添加商品至集合",
  "正在添加1个商品至【商品集名称】，你还要继续吗？":
    '正在添加 <span style="color: red">{num}</span> 个商品至 <span style="color: red">【{name}】</span>， 你还要继续吗？',
  添加成功: "添加成功",
  您可以前往商品集列表管理您的集合商品: "您可以前往商品集列表管理您的集合商品",
  去管理: "去管理",
  关闭: "关闭",
  已成功创建商品集链接: "已成功创建商品集链接",
  复制链接: "复制链接",
  "正在创建的商品集共包含10个有效商品，你还要继续吗？":
    '正在创建的商品集共包含 <span style="color: red">{num} 个有效商品</span>，你还要继续吗？',
  "您可以将链接粘贴至任何对话框。": "您可以将链接粘贴至任何对话框。",
  您已成功复制xxx商品集分享链接:
    '您已成功复制 <span style="color: red">{setName}</span> 商品集分享链接',
  确认删除商品集: "确认删除商品集",
  "删除成功！": "删除成功！",
  "您正在删除【商品集合名称】，删除后用户端分享链接将失效，你还要继续吗？":
    '您正在删除<span style="color: red">【{setName}】</span>，删除后用户端分享链接将失效，你还要继续吗？',
  Sort: "排序",
  移除: "移除",
  "25/100 个商品已添加至商品集，已失效商品（活动结束、商家下架、库存不足、平台下架）系统将会自动移除。":
    '<span style="color: #5245E5">{num}</span>/100 个商品已添加至商品集，已失效商品（活动结束、商家下架、库存不足、平台下架）系统将会自动移除。',
  确认移除商品: "确认移除商品",
  "您正在移除2个商品，你还要继续吗？":
    '您正在移除<span style="color: red">{num}个商品</span>，你还要继续吗？',
  "已选择 20/100 个商品": '已选择 <span style="color: #7f6ff2">{num}</span>/100 个商品',
  "添加失败，商品集最多可添加100个商品，剩余可添加1个商品。":
    '添加失败，商品集最多可添加100个商品，剩余可添加<span style="color: red">{num}</span>个商品。',
  等级数量: "等级数量",
  "限制类型：GMV": "限制类型：GMV",
  级: "级 {{level}}",
  样品数量: "样品数量",
  及以上: "及以上",
  确认销量范围: "确认销量范围",
  不能大于30: "不能大于30",
  "最低金额无法高于/等于最高金额": "最低金额无法高于/等于最高金额",
  明星活动: "明星活动",
  同步: "同步",
  更新: "更新",
  普通活动: "普通活动",
  普通活动列表: "普通活动列表",
  明星活动列表: "明星活动列表",
  更新中: "更新中",
  个活动待同步至普通活动列表: "个活动待同步至普通活动列表",
  个活动待同步至明星活动列表: "个活动待同步至明星活动列表",
  请填写tap佣金率: "请填写tap佣金率",
  同步中: "同步中",
  同步失败: "同步失败",
  再次发起: "再次发起",
  "固定佣金，不可调整": "固定佣金，不可调整",
  "限制类型：成交数量": "限制类型：成交件数",
  机审条件配置: "机审条件配置",
  样品金额: "样品金额≤",
  并且: "并且",
  达人账号履约率: "达人账号履约率≥",
  操作人: "操作人",
  时间: "时间",
  文件名: "文件名",
  成功商品数: "成功商品数",
  失败商品数: "失败商品数",
  导入状态: "导入状态",
  导入中: "导入中",
  完成: "完成",
  失败: "失败",
  导入商品: "导入商品",
  导入记录: "导入记录",
  下载模板: "下载模板",
  导入商品记录: "导入商品记录",
  "已导入100个商品！": "已导入{success}个商品！",
  "已导入99个商品，1个商品导入失败，请下载导入记录查看原因！":
    "已导入{success}个商品，{fail}个商品导入失败，请下载导入记录查看原因！",
  商品未挂橱窗: "商品未挂橱窗",
  已挂橱窗: "已挂橱窗",
  未挂橱窗: "未挂橱窗"
};
