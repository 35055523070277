export default {
  Reset: "รีเซ็ต",
  name: "ชื่อ",
  ImgTip: "ขนาด : 588*720 ขนาดไม่เกิน 2 MB นามสกุลไฟล์ต้องเป็น png, jpg, jpeg, and gif เท่านั้น อัปโหลดได้เพียงหนึ่งรูป",
  id: "ID",
  status: "สถานะ",
  ID: "ID",
  names: "ชื่อ",
  img: "รูปภาพ",
  time: "ระยะเวลาแสดงป๊อปอัป",
  IDUrl: "รหัสสินค้า/URL/ลิงก์",
  sort: "ลำดับ",
  popupMode: "ประเภท",
  popUpImg: "รูปป๊อปอัป",
  popUpTime: "ระยะเวลาแสดงป๊อปอัป",
  popUpMode: "ประเภท",
  popUpSort: "ลำดับ",
  OnceDay: "วันละ 1 ครั้ง",
  Once: "1 ครั้ง",
  always: "ขึ้นตลอด"
};
