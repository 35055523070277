export default {
  settle: "注册",
  settleIn: "Youpik商家注册",
  accountType: "账户类型",
  personalAccount: "个人卖家",
  personalAccountTip: "成为个人卖家需要年满18岁，并提供身份证",
  enterpriseAccount: "企业卖家",
  enterpriseAccountTip: "请确保你的营业执照有效,以便作为一家公司入驻Lazada",
  sellerType: "卖家类型",
  requireSellerType: "请选择卖家类型",
  CrossBorderSeller: "跨境卖家",
  OrdinarySeller: "普通卖家",
  BrandSeller: "品牌卖家",
  ShopLocation: "店铺位置",
  StoreName: "店铺名称",
  requireShopLocation: "请选择店铺位置",
  haveReferralCode: "我有推荐码",
  referralCodeOptional: "推荐码(选填)",
  ConfirmationInformation: "请同意协议与条款",
  AccountSettle: "卖家入驻",
  FillStorageInfo: "填写仓库信息",
  FillIdAndBank: "填写身份证以及银行信息",
  Password: "密码",
  ConfirmPassword: "确认密码",
  Email: "邮箱",
  MobileCode: "短信验证码",
  SlideToVerify: "滑动以验证",
  PrevStup: "上一步",
  NextStep: "下一步",
  AddressBook: "通讯录",
  CloneStorageAddress: "与仓库地址一致",
  Address: "地址",
  CountryRegion: "国家/地区",
  Province: "省",
  District: "所在地区",
  PostalCode: "邮政编码",
  exportAllTip: "操作成功，请查看导出记录"
};
