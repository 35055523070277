export default {
  bannerOver: "Content exceeds character limit ",
  originBannerPosition: "location",
  bannerPosition: "Banner location",
  topBanner: "Top banner",
  middleBanner: "Middle banner",
  middleBannerFix: "Middle banner fix",
  middleLongBanner: "middle long banner",
  DailyRecommendation: "Today's recommendation",
  lazadaLandingPage: "Lazada Affiliate Banner Picture",
  bannerTime: "Banner duration",
  bannerSort: "Sort",
  displayLocation: "Secondary location",
  RotationBanner: "Slides banner",
  FixedUpperLeft: "Fixed top",
  FixedUpperRight: "Fixed down",
  LazadaLandingPage: "Lazada Affiliate Page",

  originName: "Homepage Name",
  Name: "Homepage Banner Name",
  ActivityStatus: "Active Status",
  JumpType: "Types",
  All: "All",
  Item: "Produtcs link",
  Activity: "TB link",
  AlreadyShelves: "Active",
  ToShelved: "Pending to live",
  RemovedShelves: "Inactive",
  Image: "Image",
  ImgTip:
    "Recommended size: 702*202 Pixels, the size is limited to 2MB; only png, jpg, jpeg, and gif formats are supported. Only one can be uploaded at a time. ",
  ImgTipBanner:
    "Recommended size 600*300 Pixels, the size is limited to 2MB; only png, jpg, jpeg, and gif formats are supported. Only one can be uploaded at a time. ",
  Sort: "Sort",
  ItemId: "Item ID",
  JumpUrl: "TB link",
  SortTip:
    "The rank will be according to the number units, the less number digits will be shown on the top, you can fill in number from 1~6",
  MiddleLongSortTips:
    "The rank will be according to the number units, the less number digits will be shown on the top, you can fill in number from 1~2",
  TopBannerSortTip:
    "The rank will be according to the number units, the less number digits will be shown on the top, you can fill in number from 1~12",
  MiddleBannerSortTips:
    "The rank will be according to the number units, the less number digits will be shown on the top, you can fill in number from 1~4",
  onTheShelfTips:
    " The number of banners activated during the time period has reached the upper limit, and more cannot be added.",
  Uploaded: "Online",
  Deleted: "Offline",
  Address: "Address",
  originBannerTime: "Duration",
  originImage: "Image",
  originSort: "Sort",
  originItemId: "PDP",
  pageLink: "Page link",
  h5Page: "H5 Page",
  h5Link: "H5 link",
  noValidTapId: "No valid TAP ID",
  success: "Success",
  UrlOrID: "Jumping page/ Product ID",
  AreYouSureToDeleteThisCampaignPage:
    "The campaign link will be invalid after deletion. Are you sure to delete this campaign page?",
  ConfirmDeleteBanner: "Confirm delete banner？",
  InAppPage: "In-app page",
  InAppPageLink: "In-app page link",

  addBanner: "Add Homepage Banner",
  editBanner: "Edit Homepage Banner",
  文本宣传: "Text Promotion",
  图片宣传: "Image Promotion",
  建议尺寸702x220:
    "Suggested size: 702*220 pixels, size limit of 2MB; only supports png, jpg, jpeg, gif formats. Only one image can be uploaded at a time.",
  "建议尺寸：宽度固定702像素，长度不限，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。":
    "Recommended dimensions: Width fixed at 702 pixels, length unlimited, size limit 2MB; only supports png, jpg, jpeg, gif formats. Only one image can be uploaded at a time."
};
