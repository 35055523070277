export default {
  数据概览: "ภาพรวมข้อมูล",
  跟进表现: "ติดตามผลประกอบการ",
  按无新增挂链达人数: "ผู้สร้างที่ไม่มีลิงก์ใหม่เกิน 1 เดือน",
  按无成交达人数: "ผู้สร้างที่ไม่มีการขายเกิน 2 เดือน",
  跟进达人总数: "จำนวนครีเอเตอร์ทั้งหมด",
  超1个月无新增挂链达人数: "ผู้สร้างที่ไม่มีลิงก์ใหม่เกิน 1 เดือน",
  超2个月无成交达人数: "ผู้สร้างที่ไม่มีการขายเกิน 2 เดือน",
  达人: "ครีเอเตอร์",
  已合作商品数: "จำนวนสินค้าที่เคยรีวิว",
  已出单数: "จำนวนคำสั่งซื้อ",
  历史GMV: "ประวัติ GMV",
  最后一次挂链时间: "วันที่เพิ่มลิงก์ครั้งสุดท้าย",
  最后一次出单时间: "	วันที่ทำคำสั่งซื้อล่าสุด",
  超1个月未新增挂链达人: "ผู้สร้างที่ไม่มีลิงก์ใหม่เกิน 1 เดือน"
};
