const saleData_icon = "https://file-th.ypkclient.com/system/20230915/184d278afc0047c18a9bb44b58aedf76.png";
const saleData_icon_active =
  "https://file-th.ypkclient.com/system/20230915/7a60555e3926479e898f6608acda6929.png";

const salesDataRoute: IRouteProps[] = [
  {
    path: "/salesData",
    name: "销售报表",
    // component: "./salesData",
    meta: { icon: saleData_icon, activeIcon: saleData_icon_active },
    routes: [
      {
        path: "/salesData/creator",
        name: "达人分析",
        component: "./salesData/creator"
      },
      {
        path: "/salesData/goods",
        name: "商品分析",
        component: "./salesData/goods"
      },
      {
        path: "/salesData/shop",
        name: "商家分析",
        component: "./salesData/shop"
      },
      {
        path: "/salesData/videoFinishAgreement",
        name: "视频履约分析",
        component: "./salesData/videoFinishAgreement"
      }
    ]
  }
];

export default salesDataRoute;
