export default {
  新人样品池配置: "新人样品池配置",
  保存: "保存",
  "保存后瀑布流ID将生效，你还要继续吗？":
    '<span style="color: red;">保存后瀑布流ID将生效</span>，你还要继续吗？',
  确认保存: "确认保存",
  "保存失败：瀑布流不存在！": "保存失败：瀑布流不存在！",
  "操作成功！": "操作成功！",
  继续: "继续",
  取消: "取消"
};
