import http from "./instance";

interface RequestItemSetInfoParams {
  itemSetId: number;
}

enum RequestEffectiveItemSetProductParamsType {
  Remove = 0,
  PutOn
}

interface AnchorLinkListParams extends Partial<IBaseSearchConditionProps> {
  creatorOecuid: string;
  userName?: string;
  pic?: string;
}

type AnchorLinkListParamsResponse = {
  bindCount: number; // 合作链接数
  creatorOecuid: string; // 达人id
  expiredCount: number; // 活动过期商品数量
  isAuth: 0 | 1; // 是否授权uchoice: 0-未授权 1-已授权
  offlineCount: number; // 下架商品数量
  pic: string; // 跟进人
  totalCount: number; // 合作过商品总数
  unBindCount: number; // 被替换商品数量
  userName: string; // 达人昵称
};

interface AnchorLinkDetailListParams extends Partial<IBaseSearchConditionProps> {
  productId?: string;
  productName?: string;
  status?: string;
}
type LinkingAnchorParams = {
  isApplySample: number;
  latestFollowUpRecord: string;
  linkupChannel: string;
  memberAccountId: number;
};
type SaveFollowUpRecordsParams = {
  followUpRecord?: string;
  id: number;
  maxVideoQuotation?: number;
  minVideoQuotation?: number;
  memberAccountId?: number;
};

export interface AnchorLinkDetailListParamsResponse {
  campaignId: number; // 活动id，整数类型
  campaignName: string; // 活动名称，字符串类型
  campaignStatus: 0 | 1 | 2 | 3 | 4; // 活动状态：0 全部，1 待发布，2 即将开始，3 进行中，4 已结束
  cooperateCount: number; // 同商品合作次数，整数类型
  creatorCommissionPercentStr: string; // 达人佣金率，字符串类型
  image: string; // 商品图，字符串类型
  link: string; // 商品链接，字符串类型
  maxEarn: number; // 最高赚取，数字类型
  maxPrice: number; // 最高价，数字类型
  minEarn: number; // 最低赚取，数字类型
  minPrice: number; // 最低价，数字类型
  productId: number; // 商品id，整数类型
  productName: string; // 商品名称，字符串类型
  promotionEndTime: number; // 活动结束时间，整数类型
  promotionStartTime: number; // 活动开始时间，整数类型
  sourceLink: string; // 原始详情页链接（不一定是tap链接），字符串类型
  status: 1 | 2 | 3 | 4 | 5; // 链接状态：1-合作中，2-被替换，3-活动过期，4-商品下架，5-活动过期/商品下架
  userName: string; // 达人名称，字符串类型
  effectiveStartTime: number; //绑定时间
}

export default {
  getUchoiceUserList: (params: any): any => {
    return http.get<any>("/api-uchoice/uChoice/member/getUchoiceMemberByPage", params);
  },
  getLinkedTags: (params?: any): any => {
    return http.get<any>("/api-uchoice/label/labelLinkage", params);
  },
  getLinkedTagsByUserId: (params: any): any => {
    return http.get<any>("/api-uchoice/label/userByLabelId", params);
  },
  saveOrUpdateUserLabel: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/label/memberLabelSet", data);
  },
  getVideoListByPage: (params: any): any => {
    return http.get<any>("/api-uchoice/uChoice/member/getVideoListByPage", params);
  },
  getMemberVideoInfo: (params: any): any => {
    return http.get<any>("/api-uchoice/uChoice/member/getMemberVideoInfo", params);
  },
  uchoiceMemberListExport: (params: any): any => {
    return http.exportGet<any>("/api-uchoice/uChoice/member/uchoiceMemberListExport", params);
  },
  updateMemberFollower: (data: any): any => {
    return http.post<any>("/api-uchoice/tt/member/updateMemberFollower", data);
  },
  getTagList: (params: any): any => {
    return http.postJSON<any>("/api-uchoice/label/list", params);
  },
  // 更新商品分类
  updateCategory: (data: any): any => {
    return http.post<any>("/api-uchoice/tt/item/updateCategory", data);
  },
  //获取未分类商品的数量
  getUnClassifiedCount: (params: any): any => {
    return http.get<any>("/api-uchoice/tt/item/getUnClassifiedCount", params);
  },
  deleteTag: (tagId: any): any => {
    return http.get<any>("/api-uchoice/label/delete", { id: tagId });
  },
  saveOrUpdateTag: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/label/saveOrUpdate", data);
  },
  updateMemberInfo: (params: any): any => {
    return http.get<any>("/api-uchoice/uChoice/member/getCooperativeProductList", params);
  },
  addTargetMemberByHand: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/tt/member/addTargetMemberByHand", data);
  },
  reqUpdateMemberTelePhone: (data: any): any => {
    return http.post<any>("/api-uchoice/tt/member/updateMemberTelePhone", data);
  },
  verifyDisplayName: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/uChoice/member/verifyDisplayName", data);
  },
  updateDisplayName: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/uChoice/member/updateDisplayName", data);
  },
  getLabelNames: (data: any): any => {
    return http.get<any>("/api-member/label/getLabelNames", data);
  },
  requestSaveAnchorProducts: (params: any): any => {
    return http.get<any>("/api-uchoice/tt/category/getRecommendedProductTags", params);
  },
  requestSaveItemSet: (params: any): any => {
    return http.get<any>("/api-uchoice/itemSet/saveItemSet", params);
  },
  requestUpdateItemSet: (params: any): any => {
    return http.post<any>("/api-uchoice/itemSet/updateItemSetInfo", params);
  },
  requestGetItemSetList: (params: any): any => {
    return http.get<any>("/api-uchoice/itemSet/getItemSetList", params);
  },
  requestDeletedItemSet: (params: any): any => {
    return http.get<any>("/api-uchoice/itemSet/deletedItemSet", params);
  },
  requestItemSetProductDetail: (params: any): any => {
    return http.get<any>("/api-uchoice/itemSet/itemSetProductDetail", params);
  },
  requestUpdateItemSetProduct: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/itemSet/updateItemSetProduct", data);
  },
  requestUpdateItemSetProductSort: (data: any): any => {
    return http.postJSON<any>("/api-uchoice/itemSet/updateItemSetProductSort", data);
  },
  requestGetItemSetByQueryParam: (params: any): any => {
    return http.get<any>("/api-uchoice/itemSet/getItemSetByQueryParam", params);
  },
  requestUserNameByRole: (params: any): any => {
    return http.get<any>("/api-user/sys/member/getUserNameByRole", params);
  },

  // 获取商品集详情
  requestItemSetInfo: (params: {
    itemSetId: number;
  }): Promise<
    IResponseProps<{
      validItemCount: number;
      invalidItemCount: number;
      pendingItemCount: number;
      imageUrl: string;
      isBatchAdd: boolean;
    }>
  > => {
    return http.get<typeof params>("/api-uchoice/itemSet/itemSetInfo", params);
  },
  // 上架-移除商品
  requestEffectiveItemSetProduct: (data: {
    itemIdList: number[];
    itemSetId: number;
    type: RequestEffectiveItemSetProductParamsType;
  }): Promise<IResponseProps<any>> => {
    return http.postJSON<typeof data>("/api-uchoice/itemSet/effectiveItemSetProduct", data);
  },
  // 导出商品集商品
  exportItemSetProduct: (params: { itemSetId: number }): Promise<IResponseProps<any>> => {
    return http.exportGet<typeof params>("/api-uchoice/itemSet/exportItemSetProduct", params);
  },
  // 导出商品
  exportItem: (params: any): any => {
    return http.post<any>("/api-uchoice/tt/item/exportItem", params);
  },
  // OPS-获取商品集专题
  getItemSetTitle: (params: {
    itemSetId: number;
  }): Promise<
    IResponseProps<{
      itemSetName: string;
      description: string;
      titleCount: number;
      itemSetTaps: {
        id: string;
        itemSetTitleTh: string;
      }[];
      recommendType: number;
    }>
  > => {
    return http.get<typeof params>("/api-uchoice/itemSet/getItemSetTitle", params);
  },
  getItemSetTopicPage: (params: {
    itemSetId: number;
  }): Promise<
    IResponseProps<{
      tapStatus: number;
    }>
  > => {
    return http.get<typeof params>("/api-uchoice/itemSet/getItemSetTopicPage", params);
  },
  // 达人链接管理列表接口
  anchorLinkList: (
    params: AnchorLinkListParams
  ): Promise<IResponseListProps<AnchorLinkListParamsResponse>> => {
    return http.get<typeof params>("/api-uchoice/tt/anchorLink/list", params);
  },
  // 达人链接管理详情列表接口
  anchorLinkDetailList: (
    params: AnchorLinkDetailListParams
  ): Promise<IResponseListProps<AnchorLinkDetailListParamsResponse>> => {
    return http.get<typeof params>("/api-uchoice/tt/anchorLink/detail", params);
  },
  anchorLinkListExport: (params: AnchorLinkListParams): any => {
    return http.post<any>("/api-uchoice/tt/anchorLink/exportList", params);
  },
  anchorLinkDetailExport: (params: AnchorLinkDetailListParams): any => {
    return http.post<any>("/api-uchoice/tt/anchorLink/exportDetail", params);
  },
  //建联达人
  linkingAnchor: (params: LinkingAnchorParams): any => {
    return http.postJSON<any>("/api-uchoice/uChoice/member/linkingAnchor", params);
  },
  //保存跟进记录
  saveFollowUpRecords: (params: SaveFollowUpRecordsParams): any => {
    return http.postJSON<any>("/api-uchoice/uChoice/member/saveFollowUpRecords", params);
  },
  //保存视频报价
  saveVideoQuote: (params: SaveFollowUpRecordsParams): any => {
    return http.postJSON<any>("/api-uchoice/uChoice/member/saveVideoQuote", params);
  },
  //获取全平台类目
  getPlatformCategory: (): any => {
    return http.get<any>("/api-uchoice/uChoice/member/getPlatformCategory");
  }
};
