export default {
  StartTime: "Thời Gian Bắt Đầu",
  Tip: "Mẹo",
  EndTime: "Thời Gian Kết Thúc",
  ConfirmDelete: "Xác Nhận Xóa",
  DeleteSuccess: "Xóa Thành Công",
  Canceled: "Đã Hủy",
  ActionSuccess: "Thao Tác Thành Công!",
  ActionError: "Thao Tác Thất Bại!",
  ConfirmDeletes: "Xác Nhận Xóa Hàng Loạt",
  ContentCopiedClipboard: "Đã Sao Chép vào Clipboard",
  SorryCopyFailed: "Sao Chép Thất Bại",
  TemporarilyNoData: "Không Có Dữ Liệu Tạm Thời",
  uploadRejected: "Tải lên thất bại, vui lòng thử lại!",
  isDeleteImg: "Bạn có muốn xóa hình ảnh?",
  goBeyondFilNum: "Vượt quá giới hạn tệp!",
  imgBigLimit: "Hình ảnh không được vượt quá 20M!",
  当前限制选择x个图片: "Hiện đang giới hạn chọn {limit} hình ảnh",
  refresh: "Làm Mới"
};
