export default {
  openInflowReward: "开发投流奖励",
  lastModifyTime: "最后一次修改时间",
  setUpPersonner: "设置人员",
  inflowSet: "投流设置",
  numberOfProOrder: "商品出单数≥",
  GMV: "GMV≥",
  NumberOfVideoViews: "视频播放数≥",
  inflowAmount: "投流金额",
  saveModify: "保存修改",
  abandonModify: "放弃修改",
  confirmGiveUpTitle: "确定放弃保存修改信息",
  confirmGiveUpTips: "放弃后修改的内容将不会生效，",
  confirmSaveTitle: "确定保存修改信息",
  confirmSaveTips: "保存后APP端将同步修改后的内容展示投流奖励说明,",
  continueTip: "你还要继续吗？",
  continue: "继续",
  inflowed: "已投流"
};
