export default {
  Active: "Activity", // 活动
  BaseSet: "Basic Information Setting",
  RuleSet: "Basic Information Setting", // 报名规则设置
  InfoSet: "Registration rules setting", // 报名信息设置
  PageSet: "Campaign Page Settings",
  // 补贴
  CampaignSubsidy: "Campaign Subsidy",
  CommissionCompensationRote: "Commission% Subsidy",
  BatchCampaignSubsidies: "Batch set campaign subsidies",
  BatchCommissionCompensation: "Batch set commission subsidies",
  CampaignSubsidyAmountSettings: "Campaign Subsidy",
  CampaignSubsidyTip1:
      "1. The campaign subsidy refers to the order generated by the sale at the campaign price during the campaign, and the platform automatically compensates the sellers for the cost according to the set (subsidy amount * the quantity) of the product when the seller settles.",
  CampaignSubsidyTip2: "2. Set the amount as the subsidy fee for one SKU.",
  CampaignSubsidyTip3: "3. It cannot be edited after the campaign started.",
  SubsidyAmount: "Subsidy amount",
  CampaignSubsidyRule: "Subsidy amount must be greater than 0, up to 2 decimal places",
  SetCampaignSubsidies: "Set Campaign Subsidies",
  MoreThanPrice: "The amount of the campaign product subsidy exceeds the original selling price of the product,Do you want to continue? ",
  CampaignSubsidySuccessfully: "Set successfully",
  continue: "CONTINUE",
  CommissionCompensationRatioSetting: "Set commission% subsidy",
  CommissionCompensationTip1:
      "1. Commission Compensation refers to the order generated by the sale at the campaign price during the campaign, and the platform automatically compensates the seller for the fee according to the set (commission% subsidy * event price * quantity of items) when the seller settles.",
  CommissionCompensationTip2: "2. Set the amount as the subsidy fee for one SKU.",
  CommissionCompensationTip3: "3. It cannot be edited after the campaign started.",
  CommissionCompensation: "Commission Subsidy",
  CommissionCompensationRule: "Compensation must be greater than 0, less than 100, up to 2 decimal places",
  CommissionCompensationSuccessfully: "Set successfully",
  Set: "Set",
  Edit: "Revise",
  CommissionSet: "Commissioning rules settings",
  CommissionRate: "YPK Marketing commission",
  SelectProduct: "Select items",
  CommodityRemoval: "Are you sure you want to remove the item from this campaign??",
  NO: "No.",
  auditOngoing: "Deactive SC/Deactive Platform",
  auditpass: "On Shelf",
  Offshelf: "Deactive platform",
  sellerShelf: "Deactive SC",
  shoppRate: "Enter a value greater than 2",
  shoppMaxRate: "Enter value less than 100",
  Category: "Category",

  InfoSetTip: "The registration information viewed by the sc seller", // sc卖家端查看到的报名信息
  ActiveTitle: "Event Name",
  ActiveId: "Activity ID", // 活动ID
  ActiveStatu: "Campaign Status",
  ActivityType: "Campaign type",
  ActiveTime: "Campaign Time",
  CreateTime: "Created Time",
  ApplyTime: "Registration Time",
  All: "All",
  ActiveSeller: "Campaign Seller",
  ActiveGoods: "Campaign Products", // 活动商品
  NotLimit: "No Limit",
  SomeItem: "Designated product category", // 指定商品类目
  NotStarted: "Not Start Yet",
  Applying: "Registering",
  Ongoing: "Ongoing",
  Closed: "Closed",
  AddActive: "Add event", // 添加活动
  Id: "ID",
  ActiveTimeName: "Time period name",
  ActiveItemCount: "Number of items",
  IsOpen: "Are you sure you want to active items?",
  OpenTip: "Are you sure you want to active campaign?",
  CloseTip: "Are you sure you want to deactivate campaign?",
  InputItemid: "Please fill in product ID",
  InputSkuid: "Please fill in SKU ID",
  ItemId: "Product ID",
  Price: "Selling Price",
  Stock: "Available stock",
  Limit: "Limited purchase quantity",
  ActivePrice: "Activity price", // 活动价格
  ActiveStock: "Campaign Stock",
  SurplusStock: "Campaign Stock Left",
  ActiveGoodsCount: "Number of products that can be registered", // 可报名商品数
  Banner: "Banner Picture", // Banner图片
  BannerTip: "Suggested size: 350*210 pixels, size limit is 2MB; only supports png, jpg, jpeg, gif format", // 建议尺寸：350*210 像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式
  ApplyTip: "Notes for registration", // 报名须知
  BaseInfo: "Base Info",
  ActiveGoodsSet: "Campaign Products",
  ItemName: "Product Name",
  ShopName: "Shop Name",
  ItemCategory: "Category Name",
  PendingReview: "To Be Reviewed",
  Passed: "Approved",
  Failed: "Rejected",
  AddItem: "Add Product",
  ReviewStatus: "Approval status",
  From: "Source",
  AuditLogs: "Audit record", // 审核记录
  ConfirmPass: "Confirmed", // 确认通过
  AuditFaile: "Audit not passed", // 审核不通过
  AuditPass: "Pass",
  RequireReason: "Please fill in the reason", // 请填写原因
  BatchPass: "Batch pass", // 批量通过
  BatchFaile: "Bulk rejection", // 批量拒绝
  EditItemInfo: "Edit event product information", // 编辑活动商品信息
  PlaceChangeItem: "Please select the product to be reviewed", // 请选择待审核商品
  Operator: "Operator", // 操作人
  OperationTime: "Operating time", // 操作时间
  AuditResult: "Audit results", // 审核结果
  FaileReason: "Denial Reason", // 拒绝原因
  BatchSet: "Batch settings", // 批量设置
  ApplyAudit: "To Be Reviewed",
  ActivityModel: "Option",
  ChangeModel: "Change style",
  ToUse: "Use",
  PhoneView: "Preview",
  UploadTip: "Size : Width 750 px not exceed 2MB, Use png, jpg, jpeg and gif file",
  ImageTip: "Recommended size: 327*120 Pixels, the size is limited to 2MB; only png, jpg, jpeg, and gif formats are supported. Only one can be uploaded at a time. ",
  ShopStatus: "Seller Status",
  AuditType: "Platform Status",
  IsGift: "Youpik Choice",
  Sort: "Sort",
  Remove: "Delete",
  BatchRemove: "Batch Delete",
  ConfirmRemove: "Are you sure to remove this item?",
  ConfirmBatchRemove: "Are you sure to batch remove selected items?",
  RequireBanner: "Please upload the banner",
  ConfirmSave: "Confirm to save?",
  ConfirmCancel: "Confirm to cancel change？",
  Preview: "Preview",
  ActivityUrl: "Campaign Link",
  Selecting: "Selecting",
  ActivityDiscounts: "Campaign Discount",
  SomeSeller: "Selected Seller",
  RuleError: "Fill in wrong information",
  SelectCategory: "Product category is required",
  TimeError: "Campaign registration date should be over before campaign starts",
  SkuStr: "SKUs Variation Rules",
  Discount: "Campaign Discount",
  InputId: "Please enter seller ID, please use \"\",\"\" to seperate seller ID",
  PlaceId: "Please enter seller ID",
  LabelImage: "Campaign Stamp",
  ActivityCommission: "Campaign Commission",
  ActivityCommissionSet: "Youpik Marketing Commission Setting",
  ActivityCommissionRate: "% Commissions",
  ActivityCommissionTip: "After setting，commissions will change according to campaign in campaign period",
  ActivityCommissionRule: "Youpik Marketing commission Setting＜100%",
  ExtensionCommissionRate: "% YPK Marketing Commission",
  PlatformCommissionRate: "% Platform Commission",
  ConfirmUpdeteCommission: "Commissions cannot be edited after save, Are you sure to save?",
  SeeItmeList: "Check related items",
  SkuId: "SKU ID",
  Statistics: "Computing",
  ConfirmDeleteLog: "Are you sure to delete?",
  ActivityBannerImage: "Cover Photo",
  ActivityBannerImageTip: "Size :200*200 pixels not exceed 2MB, Use png, jpg, jpeg and gif file",
  downModel: "Download Version",
  UserId: "User ID",
  UserName: "User Name",
  UserLevel: "Youpikker level",
  superLevel: "VIP Level",
  Pcode: "Invite Code",
  ActivityCompletionStatus: "Campaign Status",
  ActivityRewardAmount: "Rewards",
  isSettle: "Payment Status",
  Settleed: "Paid",
  NoSettle: "Unpaid",
  SettlementTime: "Payment Time",
  NoEnd: "Not Completed",
  End: "Completed",
  // 活动页面配置
  PageTitle: "Activity title",
  Coupon: "Voucher ID：",
  NoCoupon: "Before the time when the coupon can be collected, the coupon will not be displayed, and only app can receive / use the coupon",
  CouponAdd: "Add",
  VoucherType: "Voucher type",
  VoucherName: "Voucher Name",
  Denomination: "Coupon denomination",
  ConditionsUse: "Conditions of Use",
  VStatus: "Status",
  CollectionTime: "Collection time",
  usageTime: "usage time",
  FreeShipping: "Free Shipping",
  saveYpcTip: "Confirm to save edited information?",
  cancelYpcTip: "Leave the current page, the information will not be saved",
  confirmItemInfo: "Please confirm the campaign product information",
  placeselect: "Please select",
  all: "All",
  select: "Selected",
  lookRecord: "History",
  actTip: "Succeed, please go to \"Import History\" to view",
  toSelect: "Please select",
  useExcel: "Import Excel file",
  selectFile: "Select file",
  downloadModel: "Download template",
  importModel: "Import template",
  tip1: "Import reminder:",
  tip2: "1. Please download the import template and fill in the data before importing;",
  tip3: "2. The import file size cannot exceed 2M;",
  tip4: "3. The number of imported products cannot exceed 1000 each time;",
  timeisend: "Registration time has ended",
  exportHistory: "Import history",
  createTime: "Creation Time",
  fileName: "File name",
  status: "Status",
  downloadFile: "Download original file",
  total: "Total",
  success: "Succeed",
  filled: "Failed",
  shopsku: "Shop SKU",
  reason: "Reason",
  exportHistory2: "Export history",
  callme: "Please contact us",
  closeTip2: "Confirm to close the campaign? Can't be reopened after closing",
  openTimeTip: "The Campaign start time is earlier than the current time ，please adjust",
  openLengthTip: "The campaign has already been opened once, it is not allowed to open it again",
  started: "Campaign time has started, editing is not supported",
  timeTip: "Start time cannot be earlier than current time",
  err20001: "Excel data is empty",
  err20002: "Template header error",
  err20003: "Error reading template data"
};
