export default {
  Campaign_Page_Management: "Campaign Page Management",
  Title_Name: "Title Name",
  _Campaign_Type: "Campaign Type",
  Campaign_Time: "Campaign Time",
  Add_New: "Add New",
  Thai: "Thai",
  Vnvi: "Vietnamese",
  English: "English",
  Banner_Image: "Banner Image",
  Configuration_Page_Link: "Configuration Page Link",
  Delete: "Delete",
  Confirm_Deletion: "Confirm Deletion",
  Are_you_sure_you_want_to_delete_this_campaign_page_:
    "Are you sure you want to delete this campaign page?",
  This_link_is_already_active_on_the_homepage__Please_turn_it_off_before_editing_or_deleting_:
    "This link is already active on the homepage. Please turn it off before editing or deleting.",
  Recommended_size__Width_750_pixels__size_limit_2MB__It_supports_only_png__jpg__jpeg__gif_formats__Only_one_image_can_be_uploaded_at_a_time_:
    "Recommended size: Width 750 pixels, size limit 2MB. It supports only png, jpg, jpeg, gif formats. Only one image can be uploaded at a time.",
  _Background_Color_: "Background Color:",
  Default_background_color___f5f5f5: "Default background color: #f5f5f5",
  Switch_Campaign_Type: "Switch Campaign Type",
  Switching_the_type_will_not_save_the_settings_of_the_original_type__Do_you_want_to_continue_:
    "Switching the type will not save the settings of the original type. Do you want to continue?",
  Cancel: "Cancel",
  Confirm: "Confirm",
  Product_List_Configuration: "Product List Configuration",
  _Product_List_ID_: "Product List ID:",
  Product_list_ID_can_be_found_in_the_Operation_Management___Product_List_:
    "Product list ID can be found in the Operation Management - Product List.",
  Save: "Save",
  Save_and_Preview: "Save and Preview",
  After_cancellation__the_content_you_edited_will_not_be_saved__Do_you_want_to_continue_:
    "After cancellation, the content you edited will not be saved. Do you want to continue?",
  Product_List: "Product List",
  Campaign_Image: "Campaign Image",
  Title: "Title",
  _Title__Thai__: "Title (Thai):",
  _Title__English__: "Title (English):",
  _Title__VN__: "Title (Vietnamese)：",
  Basic_Information_Setting: "Basic Information Setting",
  New_Edit_Campaign_Page: "New/Edit Campaign Page"
};
