import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Radio, Skeleton } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useIntl } from "umi";
import userManage from "@/api/userManage";
import styles from "./index.less";
import { getH5BaseUrl } from "@/utils/getH5BaseUrl";
import ImageUpload from "@/components/ImageUpload";
import Tip from "@/components/Tip";
import { isTHRegion, nationStorage } from "@/utils/storage";
import { RegionParam } from "@/utils/regionParam";

const { TextArea } = Input;

interface ICreateCollectionModalProps {
  visible: boolean;
  handleOk: (id: string, res: any) => void;
  handleCancel: () => void;
  selectProductIds: any;
  mode?: "no_products";
  modifyData?: {
    id: number;
    itemSetName: string;
    description: string;
  };
}

const CreateCollectionModal: React.FC<ICreateCollectionModalProps> = ({
  visible,
  handleOk,
  handleCancel,
  selectProductIds,
  mode,
  modifyData
}) => {
  const intl = useIntl();
  const [formRef] = Form.useForm<any>();
  const [modal, contextHolder] = Modal.useModal();

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [previewTitle, setPreviewTitle] = useState("");

  const [itemSetTaps, setItemSetTaps] = useState<
    {
      id: string;
      itemSetTitleTh: string;
    }[]
  >([]);

  // 推荐类型:1-文本宣传2-图片宣传
  const [recommendType, setRecommendType] = useState("1");

  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setConfirmLoading(false);
    formRef.resetFields();
  };

  useEffect(() => {}, [recommendType]);

  const onSyncPreview = (values: any) => {
    console.log("onSyncPreview", values);
    if (values[0].name[0] === "itemSetName") {
      setPreviewTitle(values[0].value);
    }

    let key = values[0].name[0];
    if (key === "imageUrl") {
      key = "description";
    }

    const iframes = document.getElementsByClassName("iframe");
    for (const iframe of iframes) {
      (iframe as any).contentWindow.postMessage(
        `ops_preview:${JSON.stringify({
          key,
          value: values[0].value
        })}`,
        "*"
      );
    }
  };

  const getItemSetTitle = () => {
    if (modifyData?.id) {
      setItemSetTaps([]);

      setLoading(true);
      userManage
        .getItemSetTitle({
          itemSetId: modifyData.id
        })
        .then((res) => {
          // 加几秒延迟，防止h5还没加载完成，无法通讯
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(res);
            }, 3000);
          });
        })
        .then((res) => {
          const { code, success, result } = res as IResponseProps<{
            itemSetName: string;
            description: string;
            titleCount: number;
            itemSetTaps: {
              id: string;
              itemSetTitleTh: string;
            }[];
            recommendType: number;
          }>;

          setLoading(false);

          if (code === 200 && success) {
            formRef.setFieldValue("itemSetName", result.itemSetName);

            setRecommendType(`${result.recommendType}`);

            if (result.recommendType == 1) {
              formRef.setFieldValue("description", result.description);
            } else {
              formRef.setFieldValue(
                "description",
                nationStorage.get() == "TH"
                  ? "uChoice เลือกสินค้าขายดี เพิ่มสินค้าคอมมิชชั่นสูง ดาวน์โหลดแอปเพื่อดูเพิ่มเติม!"
                  : "Sản phẩm bán chạy trong vòng 30 ngày gần đây, sẵn sàng để bạn lựa chọn! Hãy tải xuống ứng dụng và nhận mẫu miễn phí ngay bây giờ!"
              );
              formRef.setFieldValue("imageUrl", result.description);
            }

            // 为了标题联动
            setPreviewTitle(modifyData.itemSetName);
            // 和h5通讯
            onSyncPreview([
              {
                name: ["description"],
                value: modifyData.description
              }
            ]);

            onSyncPreview([
              {
                name: ["recommendType"],
                value: result.recommendType
              }
            ]);

            if (result.itemSetTaps) {
              // 主题数组
              setItemSetTaps(result.itemSetTaps);

              for (let index = 0; index < result.itemSetTaps.length; index++) {
                const { id, itemSetTitleTh } = result.itemSetTaps[index];

                formRef.setFieldValue(`itemSetTaps[${index}].id`, id);

                formRef.setFieldValue(`itemSetTaps[${index}].itemSetTitleTh`, itemSetTitleTh);
              }
            }
          }
        });
    }
  };

  useEffect(() => {
    if (!visible) {
      resetState();
    } else if (modifyData) {
      getItemSetTitle();
    } else {
      formRef.setFieldValue(
        "description",
        nationStorage.get() == "TH"
          ? "uChoice เลือกสินค้าขายดี เพิ่มสินค้าคอมมิชชั่นสูง ดาวน์โหลดแอปเพื่อดูเพิ่มเติม!"
          : "Sản phẩm bán chạy trong vòng 30 ngày gần đây, sẵn sàng để bạn lựa chọn! Hãy tải xuống ứng dụng và nhận mẫu miễn phí ngay bây giờ!"
      );

      setRecommendType("1");
    }
  }, [visible]);

  const saveItemSet = (res: any) => {
    const params = modifyData
      ? {
          id: modifyData.id,
          ...res
        }
      : res;

    setConfirmLoading(true);

    const api = modifyData ? userManage.requestUpdateItemSet : userManage.requestSaveItemSet;

    api({
      ...params,
      description: recommendType === "1" ? params.description : params.imageUrl,
      recommendType,
      itemIdList: selectProductIds.join(",")
    })
      .then((result: any) => {
        const { code, success } = result;
        if (code === 200 && success) {
          handleOk(result.result || "", res);
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  const handleModalOk = () => {
    formRef.validateFields().then((res: any) => {
      console.log(res);
      if (mode === "no_products") {
        saveItemSet(res);
      } else {
        modal.confirm({
          title: intl.formatMessage({ id: "ttSample.确认要生成商品集链接" }),
          icon: <ExclamationCircleOutlined />,
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: "ttSample.正在创建的商品集共包含10个有效商品，你还要继续吗？" },
                  { num: selectProductIds.length }
                )
              }}
            />
          ),
          okText: intl.formatMessage({ id: "ttSample.继续" }),
          cancelText: intl.formatMessage({ id: "button.Cancel" }),
          onOk: () => {
            saveItemSet(res);
          }
        });
      }
    });
  };

  return (
    <>
      <Modal
        open={visible}
        onOk={handleModalOk}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        width={modifyData ? 1300 : 550}
        okText={intl.formatMessage({ id: "button.Confirm" })}
        cancelText={intl.formatMessage({ id: "button.Cancel" })}
        confirmLoading={confirmLoading}
        title={
          modifyData
            ? intl.formatMessage({ id: "collection.编辑商品集" })
            : intl.formatMessage({ id: "ttSample.创建商品集" })
        }
      >
        <div style={{ display: "flex" }}>
          {modifyData && (
            <div style={{ paddingRight: "20px", width: "375px" }}>
              <h2 style={{ textAlign: "center", marginBottom: 0, color: "#ccc" }}>
                {intl.formatMessage({ id: "collection.专题页面预览" })}
              </h2>
              <h3 style={{ textAlign: "center", marginBottom: 0 }}>{previewTitle}</h3>
              <iframe
                className='iframe'
                src={`${getH5BaseUrl()}/pages/tiktok/selection?id=${
                  modifyData?.id
                }&section=1${RegionParam("&")}`}
                frameBorder='0'
                style={{ width: 375, height: 667 }}
              />
            </div>
          )}

          <div
            style={{
              width: "482px",
              height: modifyData ? "700px" : "380px",
              paddingLeft: "20px",
              overflowY: "auto"
            }}
          >
            <Skeleton loading={loading}>
              <Form
                className={styles.form_container}
                form={formRef}
                autoComplete='off'
                layout='vertical'
                onFieldsChange={onSyncPreview}
              >
                {modifyData && (
                  <h3>{intl.formatMessage({ id: "collection.编辑专题+列表页面" })}</h3>
                )}

                <Form.Item
                  label={
                    nationStorage.get() == "TH"
                      ? intl.formatMessage({ id: "ttSample.商品集名称（泰文）" })
                      : intl.formatMessage({ id: "ttSample.商品集名称（越南）" })
                  }
                  name='itemSetName'
                  rules={[
                    { required: true, message: intl.formatMessage({ id: "validate.Require" }) }
                  ]}
                >
                  <Input
                    maxLength={50}
                    showCount
                    placeholder={intl.formatMessage({ id: "common.pleaseInput" })}
                  />
                </Form.Item>

                <Radio.Group
                  style={{ marginBottom: 10 }}
                  value={recommendType}
                  onChange={(e) => {
                    setRecommendType(e.target.value);

                    onSyncPreview([
                      {
                        name: ["recommendType"],
                        value: parseInt(e.target.value)
                      }
                    ]);

                    onSyncPreview([
                      {
                        name: ["description"],
                        value: formRef.getFieldValue(
                          e.target.value === "1" ? "description" : "imageUrl"
                        )
                      }
                    ]);
                  }}
                >
                  <Radio value={"1"}>{intl.formatMessage({ id: "banner.文本宣传" })}</Radio>
                  <Radio value={"2"}>{intl.formatMessage({ id: "banner.图片宣传" })}</Radio>
                </Radio.Group>

                {recommendType === "1" ? (
                  <Form.Item
                    label={
                      nationStorage.get() == "TH"
                        ? intl.formatMessage({ id: "ttSample.推荐理由（泰文）" })
                        : intl.formatMessage({ id: "ttSample.推荐理由（越南）" })
                    }
                    name='description'
                    rules={[
                      { required: true, message: intl.formatMessage({ id: "validate.Require" }) }
                    ]}
                  >
                    <TextArea
                      maxLength={200}
                      showCount
                      placeholder={
                        nationStorage.get() == "TH"
                          ? "uChoice เลือกสินค้าขายดี เพิ่มสินค้าคอมมิชชั่นสูง ดาวน์โหลดแอปเพื่อดูเพิ่มเติม!"
                          : "Sản phẩm bán chạy trong vòng 30 ngày gần đây, sẵn sàng để bạn lựa chọn! Hãy tải xuống ứng dụng và nhận mẫu miễn phí ngay bây giờ!"
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={
                      nationStorage.get() == "TH"
                        ? intl.formatMessage({ id: "ttSample.推荐理由（泰文）" }) + ":"
                        : intl.formatMessage({ id: "ttSample.推荐理由（越南）" }) + ":"
                    }
                    name='imageUrl'
                    rules={[
                      { required: true, message: intl.formatMessage({ id: "validate.Require" }) }
                    ]}
                    extra={
                      <Tip
                        content={intl.formatMessage({
                          id: "banner.建议尺寸：宽度固定702像素，长度不限，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。单次只能上传一张。"
                        })}
                      />
                    }
                  >
                    <ImageUpload />
                  </Form.Item>
                )}

                {modifyData && itemSetTaps.length > 0 && (
                  <>
                    <h3 style={{ marginTop: 40 }}>
                      {intl.formatMessage({ id: "collection.仅编辑专题页面" })}
                    </h3>

                    {itemSetTaps.map(({ id }, i) => (
                      <Form.Item
                        noStyle
                        key={id}
                        name={`itemSetTaps[${i}].id`}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "validate.Require" })
                          }
                        ]}
                      >
                        <Input
                          style={{ display: "none" }}
                          maxLength={50}
                          showCount
                          placeholder={intl.formatMessage({ id: "common.pleaseInput" })}
                        />
                      </Form.Item>
                    ))}

                    {itemSetTaps.map(({ id }, i) => (
                      <Form.Item
                        key={id}
                        label={`${intl.formatMessage(
                          {
                            id: isTHRegion()
                              ? "collection.list主题（泰文）"
                              : "collection.list主题（越南）"
                          },
                          { index: `${i + 1}` }
                        )}：`}
                        name={`itemSetTaps[${i}].itemSetTitleTh`}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "validate.Require" })
                          }
                        ]}
                      >
                        <Input
                          maxLength={50}
                          showCount
                          placeholder={intl.formatMessage({ id: "common.pleaseInput" })}
                        />
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form>
            </Skeleton>
          </div>
          {modifyData && (
            <div style={{ paddingLeft: "20px", width: "375px" }}>
              <h2 style={{ textAlign: "center", marginBottom: 0, color: "#ccc" }}>
                {intl.formatMessage({ id: "collection.列表页面预览" })}
              </h2>
              <h3 style={{ textAlign: "center", marginBottom: 0 }}>{previewTitle}</h3>
              <iframe
                className='iframe'
                src={`${getH5BaseUrl()}/pages/tiktok/selection?id=${modifyData?.id}${RegionParam(
                  "&"
                )}`}
                frameBorder='0'
                style={{ width: 375, height: 667 }}
              />
            </div>
          )}
        </div>
      </Modal>
      {contextHolder}
    </>
  );
};

export default CreateCollectionModal;
