export default {
  Require: "จำเป็นต้องกรอก",
  requiredUploadpicture: "กรุณาอัปโหลดรูปภาพ",
  pleaseEnterCorrectFormat: "กรุณากรอกรูปแบบที่ถูกต้อง",
  requireName: "ต้องกรอกชื่อ",
  requireMobile: "ต้องกรอกหมายเลขโทรศัพท์",
  requireSellerType: "กรุณาเลือกประเภทผู้ขาย",
  requireStoreLocation: "กรุณาเลือกตำแหน่งที่ตั้งร้านค้า",
  requireAccountType: "กรุณาเลือกประเภทบัญชี",
  requireCheckRead: "กรุณาทำเครื่องหมายเพื่อยอมรับข้อกำหนดและเงื่อนไข",
  requireCategory: "กรุณาเลือกหมวดหมู่",
  requireItemId: "กรุณากรอกรหัสสินค้า",
  requireItemName: "กรุณากรอกชื่อสินค้า",
  requireSellerSKU: "กรุณากรอก Seller SKU",
  requireSotreSKU: "กรุณากรอก Shop SKU",
  requirePassword: "ต้องกรอกรหัสผ่าน",
  noEmptyUsername: "บัญชีไม่สามารถเว้นว่างได้",
  Select: "กรุณาเลือก",
  Please_input: "กรุณาใส่ข้อมูล",
  closeTip: "หากออกจากหน้านี้ จะไม่ถูกบันทึก",
  Tip: "เคล็ดลับ",
};
