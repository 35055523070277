import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import logo_new from "@/assets/logo_new.png";
import styles from "./index.less";

const HeaderTitleRender = () => {
  useEffect(() => {
    Sentry.init({
      dsn: "https://882bbe10aed5a907fefa9fffddf32804@o4506823062781952.ingest.sentry.io/4506823461699584",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false
        })
      ],
      environment: process.env.API_ENV,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/youpik\.test\.catike\.com/,
        /^https:\/\/api\.uchoice\.pro/
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }, []);

  return (
    <div className={styles.title_render_container}>
      <img src={logo_new} className={styles.logo_new} />
    </div>
  );
};

export default HeaderTitleRender;
