export default {
  logOut: "ล็อกเอาท์",
  homePage: "หน้าหลัก",
  thailand: "ประเทศไทย",
  vietnam: "เวียดนาม",
  logIn: "เข้าสู่ระบบ",
  username: "บัญชี",
  password: "รหัสผ่าน",
  fPwd: "ลืมรหัสผ่าน?",
  nation: "ชาติ"
};
