export default {
  数据概览: "数据概览",
  跟进表现: "跟进表现",
  按无新增挂链达人数: "按无新增挂链达人数",
  按无成交达人数: "按无成交达人数",
  跟进达人总数: "跟进达人总数",
  超1个月无新增挂链达人数: "超1个月无新增挂链达人数",
  超2个月无成交达人数: "超2个月无成交达人数",
  达人: "达人",
  已合作商品数: "已合作商品数",
  已出单数: "已出单数",
  历史GMV: "历史GMV",
  最后一次挂链时间: "最后一次挂链时间",
  最后一次出单时间: "	最后一次出单时间",
  超1个月未新增挂链达人: "超1个月未新增挂链达人"
};
