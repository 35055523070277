export default {
  反馈方向: "反馈方向",
  问题反馈: "问题反馈",
  功能建议: "功能建议",
  反馈标题: "反馈标题",
  "一句话概括您的反馈…": "一句话概括您的反馈…",
  内容超出字符数量上限: "内容超出字符数量上限",
  请输入反馈标题: "请输入反馈标题",
  上传附件: "上传附件",
  点击或将文件拖拽到这里上传: "点击或将文件拖拽到这里上传",
  "支持扩展名: .jpg .png .mp4": "支持扩展名: .jpg .png .mp4",
  "图片最多上传10张，视频仅支持1个": "图片最多上传10张，视频仅支持1个",
  所有文件大小不超过20MB: "所有文件大小不超过20MB",
  请上传至少1个附件: "请上传至少1个附件",
  "不可添加，最多上传10张图片": "不可添加，最多上传10张图片",
  "不可添加，图片和视频不可同时上传": "不可添加，图片和视频不可同时上传",
  "不可添加，最多上传1个视频": "不可添加，最多上传1个视频",
  "不可添加，仅支持 .jpg .png .mp4格式添加": "不可添加，仅支持 .jpg .png .mp4格式添加",
  "不可添加，文件超出大小，最大不超过20MB": "不可添加，文件超出大小，最大不超过20MB",
  反馈描述: "反馈描述",
  "系统问题或功能建议，描述越清楚能让开发者更快定位问题哦~":
    "系统问题或功能建议，描述越清楚能让开发者更快定位问题哦~",
  "联系电话/LINE": "联系电话/LINE",
  "请留下您的手机号或LINE ID吧~": "请留下您的手机号或LINE ID吧~",
  提交并继续反馈: "提交并继续反馈",
  待受理: "待受理",
  处理中: "处理中",
  已完结: "已完结",
  已接收: "已接收",
  新回复: "新回复",
  您还没提交任何反馈: "您还没提交任何反馈",
  我们乐意能听到您的反馈与建议: "我们乐意能听到您的反馈与建议",
  "您的反馈建议是我们前进的动力…": "您的反馈建议是我们前进的动力…",
  联系方式: "联系方式",
  附件: "附件",
  跟进一下: "跟进一下",
  反馈进度表: "反馈进度表",
  等待管理员接收: "等待管理员接收",
  等待反馈问题处理: "等待反馈问题处理",
  等待反馈问题完结: "等待反馈问题完结",
  等待管理员接收后处理反馈: "等待管理员接收后处理反馈",
  反馈跟进区: "反馈跟进区",
  "写下你的评论…": "写下你的评论…",
  添加图片: "添加图片",
  Ulive管理员: "Ulive管理员",
  我: "我",
  刚刚: "刚刚",
  "你确定要删除该评论吗？": "你确定要删除该评论吗？",
  "未填写内容。": "未填写内容。",
  反馈时间: "反馈时间",
  处理状态: "处理状态",
  "没有更多评论啦~": "没有更多评论啦~",
  提交反馈: "提交反馈",
  详情: "详情",
  Ulive团队: "Ulive团队"
};
