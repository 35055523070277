export default {
  Lazada页面: "Trang Lazada",
  跳转至: "Chuyển tới",
  // 活动页面配置
  search: "Tìm kiếm",
  Reset: "Đặt lại",
  Add: "Thêm",
  All: "Tất cả",
  NoStart: "Chưa bắt đầu",
  Ongoing: "Đang diễn ra",
  Closed: "Đóng",
  ID: "ID",
  Status: "Trạng thái",
  URL: "URL",
  Actions: "Thao tác",
  ViewDetail: "Xem chi tiết",
  delete: "Xóa",
  Edit: "Chỉnh sửa",
  PageTableName: "Tên trang",
  PageTableTime: "Thời gian chiến dịch",
  // 动态页面配置
  Voucher: "Cài đặt Voucher",
  ActiveProduct: "Cài đặt sản phẩm hoạt động",
  navigation: "Hiển thị thanh điều hướng tầng:",
  recommended: "Đề xuất sử dụng khi có 4 tầng hoặc nhiều hơn",
  floor: "tầng",
  iconDefault: "biểu tượng mặc định:",
  iconSelected: "biểu tượng được chọn：",
  iconNameEn: "Tên biểu tượng (Tiếng Anh):",
  iconNameThai: "Tên biểu tượng (Tiếng Thái):",
  Suggested:
    "Kích thước khuyến nghị: Chiều rộng hình ảnh là 750*150 pixel, kích thước giới hạn là 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg và gif.",
  ActivityID: "ID hoạt động:",
  ProductList: "Kiểu danh sách sản phẩm:",
  Style1: "Kiểu 1",
  Style2: "Kiểu 2",
  Style3: "Kiểu 3",
  Style4: "Kiểu 4",
  floor2: "Tầng 2:",
  Deletefloor: "Xóa tầng",
  Addfloor: "Thêm tầng",
  Basic: "Thông tin cơ bản",
  CampaignTime: "Thời gian chiến dịch:",
  PageName: "Tên trang:",
  BasicTips:
    "Kích thước khuyến nghị: Chiều rộng hình ảnh là 750 pixel, kích thước giới hạn là 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg và gif.",
  BasicBg: "Màu nền:",
  BasicDefaultBg: "Màu nền mặc định: #f5f5f5",
  save: "Lưu",
  cancel: "Hủy",
  PageTitle: "Tên trang:",
  delTip: "Liên kết sẽ hết hạn sau khi xóa, vui lòng xác nhận xóa chiến dịch?",
  savePreview: "Lưu và xem trước",
  added: "Thêm thành công",
  idError: "ID Voucher không chính xác.",
  more10: "Tối đa 10 tầng",
  inputActId: "Vui lòng nhập ID TB",
  inputfloorImg: "Vui lòng tải lên một banner tầng",
  inputIcon: "Vui lòng tải lên biểu tượng không hoạt động",
  inputIcons: "Vui lòng tải lên biểu tượng hoạt động",
  inputFloorEn: "Vui lòng nhập tên tiếng Anh của tầng",
  inputFloorTh: "Vui lòng nhập tên tầng bằng tiếng Thái",
  confirmCanelSave: "Vui lòng xác nhận hủy việc lưu",
  someShip: "Miễn phí vận chuyển, không bao gồm khu vực hẻo lánh",
  allShip: "Miễn phí vận chuyển",
  noShip: "Không miễn phí vận chuyển",
  sleWaterful: "Vui lòng chọn một danh sách sản phẩm",
  活动商品类型: "Loại sản phẩm chiến dịch",
  Youpik商品活动: "Chiến dịch sản phẩm Youpik",
  Lazada商品活动: "Chiến dịch sản phẩm Lazada",
  Youpik商品: "Sản phẩm Youpik",
  Lazada商品: "Sản phẩm Lazada",
  楼层名称: "Tên tầng",
  Lazada瀑布流: "Danh sách Sản phẩm Lazada",
  主会场展示排数: "Số hàng hiển thị chính:",
  切换商品类型: "Chuyển đổi loại chiến dịch sản phẩm",
  "切换类型将不保存原类型下的设置内容，你还要继续吗？":
    "Chuyển đổi loại sẽ không lưu cài đặt dưới loại hiện tại.",
  "分会场Head banner": "Head banner của chiến dịch phụ",
  主会场楼层图片: "Banner",
  "建议尺寸：图片宽度750像素，大小限制为2MB;仅支持png,jpg,jpeg,gif格式。":
    "Kích thước khuyến nghị: Chiều rộng hình ảnh là 750 pixel, kích thước giới hạn là 2MB; chỉ hỗ trợ định dạng png, jpg, jpeg và gif.",
  inputfloorImg2: "Banner của chiến dịch phụ, vui lòng tải lên một hình ảnh",
  请输入楼层名称: "Vui lòng nhập tên tầng",
  mainLineNum: "Bạn có thể nhập số nguyên từ 1 đến 5.",
  waterfullNo: "Không có danh sách Sản phẩm"
};
