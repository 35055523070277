const home_icon =
  "https://file-th.ypkclient.com/system/20230915/2779aef55e5b4da797a93fa24e32b3cd.png";
const home_icon_active =
  "https://file-th.ypkclient.com/system/20230915/d64b83a82e5b4dd7940043fc72b441f1.png";

const homeRoute: IRouteProps[] = [
  {
    path: "/home",
    name: "首页",
    meta: { icon: home_icon, activeIcon: home_icon_active },
    component: "./home",
    access: "tapMasterOrSuperAdmin"
  }
];

export default homeRoute;
