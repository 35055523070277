import React, { useState, useRef } from "react";
import { Dropdown, message } from "antd";
import type { MenuProps } from "antd";
import ImportHistoryModal from "../ImportHistoryModal";
import sampleApi from "@/api/sampleapp";
import { useIntl } from "umi";
import moment from "moment";

interface IImportButtonProps {
  current: any;
  handleImportSuccess: () => void;
}

function downloadFile(url: string, fileName: string) {
  // 使用 fetch 获取文件
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      // 创建一个临时链接
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // 设置下载文件的名称
      link.download = fileName;

      // 将链接添加到页面
      document.body.appendChild(link);

      // 模拟点击链接触发下载
      link.click();

      // 移除链接
      document.body.removeChild(link);
    })
    .catch(error => console.error("下载文件时出错:", error));
}

const templateUrl = "https://file.uchoice.pro/public/excel/product_list_template.xlsx";

const ImportButton: React.FC<IImportButtonProps> = (props) => {
  const intl = useIntl();
  const { current, handleImportSuccess } = props;
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const fileInputRef = useRef<any>(null);

  const items = [
    {
      key: "1",
      label: intl.formatMessage({ id: "ttSample.导入记录" }),
    },
    {
      key: "2",
      label: intl.formatMessage({ id: "ttSample.下载模板" }),
    },
  ];

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      return;
    }
    setImportLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      sampleApi.importProduct(formData, current.id).then((res) => {
        if (res && res.code === 200) {
          // type	类型：0-全部成功 1-个别成功
          const { failCount, successCount, type } = res.result || {};
          if (type === 0) {
            message.success(intl.formatMessage({ id: "ttSample.已导入100个商品！" }, { success: successCount }));
          }
          if (type === 1) {
            message.warning(intl.formatMessage({ id: "ttSample.已导入99个商品，1个商品导入失败，请下载导入记录查看原因！" }, { success: successCount, fail: failCount }));
          }
          if (handleImportSuccess) {
            handleImportSuccess();
          }
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setImportLoading(false);
      });
    }
  };

  const onMenuClick: MenuProps["onClick"] = e => {
    if (e.key === "1") {
      setOpen(true);
    } else if (e.key === "2") {
      downloadFile(templateUrl, `product_list_template_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
    }
  };

  const handleUploadFile = () => {
    fileInputRef.current.value = "";
    fileInputRef.current?.click();
  };

  return <>
    <Dropdown.Button loading={importLoading} onClick={handleUploadFile} type="primary" menu={{ items, onClick: onMenuClick }}>{intl.formatMessage({ id: "ttSample.导入商品" })}</Dropdown.Button>
    <ImportHistoryModal open={open} handleOk={handleOk} handleCancel={handleCancel} current={current} />
    <input
      type="file"
      ref={fileInputRef}
      style={{ display: "none" }}
      onChange={handleFileChange}
      accept=".xls, .xlsx"
    />
  </>;
};

export default ImportButton;
