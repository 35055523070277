export default {
  supplierTime: "Thời gian kiểm tra nhà cung cấp",
  deliveryTime: "Thời gian giao hàng",
  completeTime: "Thời gian hoàn thành",
  closeTime: "Thời gian đóng cửa",
  履约申请时间: "Thời gian yêu cầu hoàn thành",
  履约拒绝时间: "Thời gian từ chối hoàn thành",
  履约通过时间: "Thời gian thông qua hoàn thành",
  mcn: "mcn",

  NameOfTalent: "Tên người tạo nên",
  numOfTalent: "Tài khoản người tạo nên",
  TAPID: "ID TAP",
  tradingStatus: "Trạng thái giao dịch",
  ScreenshotSales: "Ảnh chụp màn hình doanh số của showcase Tiktok",
  TAPActivityInformation: "Thông tin TAP",
  lastPage: "Trang trước",
  nextPage: "Trang tiếp theo",
  CourierCompany: "Công ty vận chuyển",
  GoToFlashOfficialWebsite: "Đi đến trang web chính thức vận chuyển",
  flashMessage: "Vận chuyển",
  ScreenshotData: "Ảnh chụp màn hình doanh số",
  followUp: "Theo dõi",
  lineId: "ID Line",
  track: "Theo dõi",
  orderStatusWillChang: 'Trạng thái đơn hàng sẽ được thay đổi thành "Đang giao hàng".',
  ship: "Giao hàng",
  screenDataNo:
    "Chúng tôi xin lỗi vì dữ liệu doanh số của showcase của bạn không đáp ứng yêu cầu của nhà cung cấp. Vui lòng chọn sản phẩm khác hoặc mua mẫu trực tiếp.",
  InitialConditionSetting: "Thiết lập điều kiện ban đầu",
  AverageVideoPlayback: "Xem video trung bình",
  videoPlayback: "Xem video",
  accountCanOnlyApply: "Một tài khoản KOL-TT chỉ có thể áp dụng tối đa",
  oneSamples: "mẫu cùng một lúc;",
  afterTheApplication:
    "sau khi đơn đăng ký thành công, nếu doanh số bán hàng của đơn đặt hàng mẫu được đăng ký bởi chuyên gia trong nền TAP lớn hơn",
  sampleApplication: "một lượng đơn đặt hàng mẫu sẽ được khôi phục",
  ttStoreName: "Tên cửa hàng TT",
  sureModifyFans: "Chắc chắn sửa số lượng người theo dõi",
  sureModifyVideo: "Bạn có chắc chắn muốn sửa lượt xem trung bình của video?",
  Submitted: "đã gửi",
  followUpPerson: "Xác nhận để chỉnh sửa người theo dõi",
  goToLogisticsOfficialWebsite: "Đi đến trang web chính thức vận chuyển",

  YoupikCategore: "Danh mục Youpik",
  lazadaName: "Tên danh mục Lazada",
  youPikName: "Tên danh mục Youpik",
  buyingAttr: "Thuộc tính khi mua hàng",
  commonItem: "Sản phẩm thông thường",
  giftItem: "Sản phẩm Youpik Choice",
  activityItem: "Hàng hóa sự kiện",
  RefundID: "ID hoàn trả",
  RefundMethod: "Phương thức hoàn tiền",
  BankCardRefund: "Hoàn tiền qua thẻ ngân hàng",
  KsherRefund: "Hoàn tiền qua Ksher",
  remark: "Ghi chú",
  RefundTips:
    "Xác nhận chuyển tiếp, điều này có nghĩa là đã hoàn tiền cho người dùng và trạng thái đơn hàng sẽ chuyển thành Đã hoàn tiền.",
  salesAmount: "Số lượng bán ra",
  All: "Tất cả",
  Unpaid: "Chưa thanh toán",
  Wrapping: "Đóng gói",
  Shipping: "Đang vận chuyển",
  PenddingShip: "Chờ lấy hàng",
  InTransit: "Đang giao hàng",
  ProperDelivery: "Đã giao hàng",
  BuyerId: "ID Khách hàng",
  OrderTimeScope: "Khoảng thời gian đặt hàng",
  OrderNum: "Số đơn hàng",
  OrderType: "Loại đơn hàng",
  GeneralOrders: "Bình thường",
  VoucherOrders: "Đơn hàng phiếu mua hàng",
  PreOrders: "Đặt hàng trước",
  ToRtsPkg: "Bưu kiện RTS (đang chờ)",
  AlreadyRtsPkg: "Bưu kiện RTS (hoàn thành)",
  CreatePkg: "Tạo bưu kiện",
  BuyerName: "Tên khách hàng",
  Package: "Bưu kiện",
  TrackingOrderNum: "Số theo dõi",
  ShippingMethod: "Phương thức vận chuyển",
  RetailPrice: "Giá bán lẻ",
  Status: "Trạng thái",
  TotalPrice: "Tổng",
  ToDelivered: "Đến để giao hàng",
  ToPending: "Chờ xử lý",
  ShippingIncluded: "Bao gồm phí vận chuyển",
  CreationTime: "Thời gian tạo",
  DeliveryPrintFaceSheet: "Giao hàng và in nhãn",
  PrintingSurfaceSingle: "In nhãn",
  ToRecreateParcel: "Tạo lại bưu kiện",
  CheckLogistics: "Kiểm tra vận chuyển",
  CancelOrder: "Hủy đơn hàng",
  PackageNumber: "Số lượng bưu kiện",
  CancelItem: "Hủy mặt hàng",
  CancelReason: "Lý do hủy",
  To: "Gửi đến",
  Product: "Sản phẩm",
  Img: "Hình ảnh",
  PaymentMethod: "Phương thức thanh toán",
  ItemTotalAmount: "Tổng số lượng",
  ItemTotalNum: "Tổng số lượng sản phẩm",
  BuyerInfo: "Thông tin người mua",
  BuyersInfo: "Thông tin khách hàng",
  invitationCoce: "Mã mời",
  userID: "ID người dùng",
  Customer: "Khách hàng",
  TelephoneNumber: "Số điện thoại di động",
  TaxCode: "Mã số thuế",
  Subtotal: "Tổng phụ",
  TotalPlatformOffers: "Tổng ưu đãi từ nền tảng",
  TotalSellerDiscount: "Tổng giảm giá từ người bán",
  TotalShippingDiscount: "Tổng giảm giá phí vận chuyển",
  Cumulative: "Tổng cộng",
  TransactionInfo: "Thông tin giao dịch",
  RefundOfReturnCommission: "Số tiền hoàn trả hoa hồng từ đơn hàng đã trả lại",
  PaymentForGoods: "Thanh toán cho hàng hóa",
  PaymentOfServiceCharge: "Phí thanh toán",
  ReturnPayment: "Hoàn trả / Hoàn lại",
  LogistisFreight: "Phí vận chuyển",
  Commission: "Hoa hồng",
  BillingAddress: "Địa chỉ thanh toán",
  DeliveryAddress: "Địa chỉ giao hàng",
  DeliverAfterSellerCancle: "Người bán hủy sau khi giao hàng",
  LogisticsDistributionFailCancle: "Hủy bỏ việc phân phối logistics không thành công",
  LogisticsDistributionReturnCancle: "Hủy bỏ việc phân phối logistics",
  LogisticsDistributionTimeOutCancle: "Hủy bỏ do vượt quá thời gian phân phối logistics",
  LogisticsSolicitationCancle: "Hủy bỏ lấy hàng",
  SellerDeliverTimeOutCancle: "Hủy bỏ do vượt quá thời gian giao hàng của người bán",
  PlatformCancle: "Hủy bỏ bởi nền tảng",
  SellerCancle: "Hủy bỏ bởi người bán",
  UserCancle: "Hủy bỏ bởi người dùng",
  PayTimeOut: "Hết thời gian thanh toán",
  ToBepay: "Chưa thanh toán",
  ToBePacked: "Chưa đóng gói",
  ToBeDeliver: "Chưa giao hàng",
  Delivered: "Đang vận chuyển",
  SignedIn: "Đã giao hàng",
  OrderComplete: "Hoàn thành đơn hàng",
  transactionCancle: "Hủy giao dịch",
  title: "Danh sách đơn hàng",
  Date: "Ngày",
  TransactionType: "Loại giao dịch",
  CostAmount: "Chi phí",
  Number: "Số lượng",
  AccountToBill: "Tài khoản cần thanh toán",
  SellerLoginName: "Tên đăng nhập của người bán",
  ItemId: "ID Sản phẩm",
  SellerSku: "SKU của người bán",
  SKUNum: "Số SKU",
  ModeOfPerformance: "Phương thức thực hiện",
  Refund: "Hoàn trả",
  Promotion: "Khuyến mãi",
  Coupon: "Phiếu mua hàng",
  Freight: "Phí vận chuyển",
  DetailedHistory: "Lịch sử chi tiết",
  SKUCoding: "Mã SKU",
  Collected: "Đã lấy hàng",
  InTransit2: "Đang vận chuyển",
  InDelivery: "Đang giao hàng",
  Stranded: "Trì hoãn",
  DifficultPartsInHanlde: "Đang xử lý khó khăn",
  ReturnedItems: "Sản phẩm đã trả lại",
  AbnormalClosing: "Đóng bất thường",
  Rescinded: "Thu hồi",
  OrderDetail: "Chi tiết đơn hàng",
  ItemInfo: "Thông tin sản phẩm",
  platDiscountSubsidy: "Phiếu giảm giá từ nền tảng",
  platShippingSubsidy: "Phiếu miễn phí vận chuyển từ nền tảng",
  sellerDiscountSybsidy: "Phiếu giảm giá từ người bán",
  amountActuallPaid: "Số tiền thực tế đã thanh toán",
  storeName: "Tên cửa hàng của người bán",
  toBeDelivered: "Chờ giao hàng",
  packageId: "ID Gói hàng",
  number: "Số lượng",
  ProperDeliveryFailed: "Giao hàng thất bại",
  Type1010: "Hủy bỏ từ người bán sau khi giao hàng",
  Type1009:
    "Hủy bỏ do sự cố vận chuyển như không quét mã vạch, người giao hàng không đến lấy hàng, v.v.",
  Type1008: "Hủy bỏ do vận chuyển nhưng khách hàng yêu cầu trả hàng",
  Type1007:
    "Hủy bỏ do vận chuyển quá hạn, ví dụ: dịch vụ vận chuyển phải giao hàng trong 2 ngày, nhưng đã 7 ngày mà hàng chưa đến nơi",
  Type1006:
    "Hủy bỏ do việc lấy hàng muộn từ dịch vụ vận chuyển, ví dụ: không có nhân viên giao hàng đến lấy hàng đúng giờ",
  Type1005:
    "Hủy bỏ do người bán giao hàng muộn, ví dụ: hàng phải được giao cho người tiêu dùng trong vòng 7 ngày, nhưng bị hủy bỏ vì giao hàng muộn",
  Type1004: "Hủy bỏ từ nền tảng",
  Type1003: "Hủy bỏ từ người bán",
  Type1002: "Hủy bỏ từ khách hàng",
  Type1001: "Hủy bỏ do quá thời gian thanh toán",
  Type1000: "Chờ thanh toán",
  Type2001: "Chưa đóng gói",
  Type2002: "Chưa giao hàng",
  Type3000: "Đang giao hàng",
  Type4000: "Đã giao hàng",
  Type5000: "Hoàn thành",
  OrderCancel: "Hủy giao dịch",
  ShopName: "Tên cửa hàng",
  Remark: "Ghi chú hoàn trả",
  CSRemark: "Ghi chú CS",
  Select: "Vui lòng chọn",
  responseOption1: "Hết hàng",
  responseOption2: "Giá sai",
  responseOption3: "Lý do khác",
  CancelPkg: "Hủy gói hàng",
  CancelPkgSuccess: "Gói hàng đã được hủy thành công",
  CancelResponsiblePart: "Hủy từ",
  cancelResponsiblePart1: "Người dùng",
  cancelResponsiblePart2: "Người bán",
  cancelResponsiblePart3: "Nền tảng",
  cancelResponsiblePart4: "Trách nhiệm vận chuyển",
  cancelReasonTip: "Vui lòng chọn lý do",
  cancelFialedReason:
    "Lý do thất bại của khiếu nại sẽ được gửi đến người dùng, vui lòng điền theo tình hình thực tế",
  cancelFialedOtherReason:
    "Lý do thất bại của khiếu nại sẽ được gửi đến người dùng, vui lòng điền theo tình hình thực tế",
  cancelResponsiblePartTip: "Vui lòng chọn ai hủy đơn hàng",
  ConfirmCancelOrder: "Bạn có chắc muốn hủy đơn hàng không?",
  cancelOrderSuccess: "Hủy đơn hàng thành công",
  ConfirmCancelItem: "Bạn có chắc muốn hủy sản phẩm không?",
  ConfirmCancelPkg: "Bạn có chắc muốn hủy bưu kiện không?",
  CancelTime: "Hủy vào",
  CancelUser: "Bên hủy",
  Pending: "Đang chờ",
  Completed: "Đã hoàn thành",
  Reject: "Từ chối",
  RefundAmount: "Số tiền hoàn lại",
  RefundAmountStats: "Trạng thái thanh toán",
  Response: "Phản hồi",
  ApplyTime: "Thời gian tạo yêu cầu",
  SellerType: "Loại người bán",
  SendTo: "Gửi đến",
  ItemID: "ID Sản phẩm",
  LogisticsType: "Loại vận chuyển",
  Close: "Không DBS",
  HandDbs: "DBS thủ công",
  AutoDbs: "DBS tự động",
  LogStatu: "Trạng thái vận chuyển",
  LogType: "Chế độ vận chuyển",
  FlashExpress: "Flash Express",
  SeaStar: "SeaStar",
  Zambo: "LAZABAO",
  CenterGroup: "Nhóm Trung tâm",
  TS: "TS",
  Acommerce: "Acommerce",
  Interpid: "Interpid",
  DBSbyhand: "DBS thủ công",
  reason_app_1: "Thay đổi phương thức thanh toán",
  reason_app_2: "Thời gian giao hàng quá lâu",
  reason_app_3: "Đơn hàng trùng lặp",
  reason_app_4: "Thay đổi địa chỉ giao hàng",
  reason_app_5: "Thay đổi ý kiến",
  reason_app_6: "Quyết định mua sản phẩm khác",
  reason_app_7: "Phí vận chuyển quá cao",
  reason_app_8: "Tìm được giá tốt hơn ở nơi khác",
  SellerName: "Tên người bán",
  MakingMoney: "Đang xử lý thanh toán",
  Type_1001: "Thanh toán không hoàn thành đúng hạn",
  Type_1002: "Khách hàng hủy đơn hàng",
  Type_1003: "Người bán hủy đơn hàng",
  Type_1004: "Hủy đơn hàng từ nền tảng",
  Type_1005: "Người bán không giao hàng đúng hạn",
  Type_1006: "Nhân viên giao hàng không đến lấy hàng đúng hạn",
  Type_1007: "Nhân viên giao hàng không giao hàng đúng hạn",
  Type_1008: "Hủy đơn hàng do trả hàng từ vận chuyển",
  Type_1009: "Hủy đơn hàng do lỗi vận chuyển",
  Type_1010: "Hủy đơn hàng từ người bán sau khi giao hàng",
  Type_2000: "Hoàn tiền đang được xử lý",
  Type_2001: "Hoàn tiền từ hủy đơn hàng",
  Type_4000: "Hoàn tiền đã hoàn thành",
  Type_1012: "Hủy do vượt quá thời gian giao hàng",
  reason6: "Vượt quá thời gian giao hàng",
  User: "Người dùng",
  Seller: "Người bán",
  Ops: "OPS",
  Todo: "Đang chờ",
  CancelGetRespons: "Lý do hủy nhận hàng",
  flashReasponse9: "Thông tin địa chỉ giao hàng không phù hợp với định dạng",
  flashReasponse80: "Người bán hủy nhiệm vụ",
  flashReasponse83: "Không thể liên lạc với người bán",
  flashReasponse85: "Số điện thoại của người bán trống",
  flashReasponse90: "Gói hàng chưa sẵn sàng để thu thập",
  flashReasponse91: "Gói hàng không đáp ứng tiêu chuẩn vận chuyển",
  flashReasponse95: "Trễ lấy hàng do thiếu phương tiện / nhân công",
  flashReasponse98: "Địa chỉ lấy hàng sai",
  flashReasponse99: "Gói hàng không đáp ứng điều kiện thu thập: quá khổ",
  flashReasponse100: "Gói hàng không đáp ứng điều kiện thu thập: hàng cấm",
  flashReasponse105: "Bao bì bên ngoài bị hỏng",
  ToRts: "Đợi để RTS",
  Rtsed: "Đã RTS",
  Cancel: "Đã hủy",
  LossDamage: "Thiệt hại và mất mát",
  HasProduct: "Đã trả lại",
  Undone: "Giao hàng bị hủy",
  SelectOrderTime: "Vui lòng chọn khoảng thời gian đặt hàng",
  Countdown: "Đếm ngược",
  Down: "Số lượng SKUs sẽ được xóa, vui lòng bổ sung hàng tồn kho kịp thời;",
  ActualFreight: "Phí vận chuyển (Người bán trả)（VAT）",
  handlingCharges: "Thanh toán phí xử lý (VAT)",
  freightRefund: "Hoàn lại phí vận chuyển của người mua (VAT)",
  ActualRefund: "Phí vận chuyển ngược (Người bán trả)（VAT）",
  // 交易信息需求
  CommoditySales: "Giá bán hàng hóa",
  MerchantCoupon: "Phiếu giảm giá của người bán",
  freight: "Phí vận chuyển",
  freightBuy: "Người mua trả phí vận chuyển (VAT)",
  freightTrue: "Phí vận chuyển thực tế (VAT)",
  CampaignSubsidy: "Trợ cấp chiến dịch",
  CampaignCommissionCompensation: "Trợ cấp hoa hồng chiến dịch",
  YoupikFee: "Phí Youpik",
  PlatformCommission: "Hoa hồng nền tảng Youpik (Bao gồm 7% VAT)",
  OwnerCommission: "Hoa hồng tiếp thị Youpik (Bao gồm 7% VAT)",
  Paymenthandling: "Phí thanh toán（VAT）",
  ReturnRefund: "Số tiền hoàn trả khi trả hàng",
  ReverseCampaignSubsidy: "Hoàn ngược trợ cấp chiến dịch",
  ReverseCommissionCompensation: "Hoàn ngược trợ cấp hoa hồng",
  RefundSales: "Số tiền hoàn trả giá sản phẩm",
  MerchantCoupons: "Số tiền hoàn trả phiếu giảm giá của người bán",
  PlatformCommissionrefund: "Số tiền hoàn trả hoa hồng nền tảng (VAT)",
  OwnerCommissionrefund: "Số tiền hoàn trả hoa hồng tiếp thị của chủ sở hữu (VAT)",
  showDetail: "Chi tiết thu nhập hơn",
  hideDetail: "Thu gọn",
  // Down: 'The SKU Stock will be cleared, please replenish the inventory in time；
  refundStatus: "Trạng thái hoàn tiền",
  userSubmit: "Người dùng đã gửi",
  opsRefuse: "OPS từ chối trực tiếp",
  userCancelsApplicationSuccessfully: "Người dùng hủy đơn thành công",
  refundSuccess: "Hoàn tiền thành công",
  refundRejectQC: "Từ chối hoàn tiền (QC không thành công)",
  waitingForRetrun: "Đang chờ trả hàng",
  duringTheReturn: "Trong quá trình trả hàng, người bán đang đợi nhận hàng",
  waitingForBankCard: "Thẻ ngân hàng đang chờ",
  waitingForBankCardRetrun: "Đợi hoàn tiền thẻ tín dụng",
  // lazada
  unpaid: "Chưa thanh toán",
  paid: "Đã thanh toán",
  successfulInvestment: "Đã giao hàng",
  refund: "Hoàn tiền",
  unitPrice: "Giá mỗi sản phẩm",
  totalPriceOfGoods: "Tổng giá",
  viewCommission: "Xem hoa hồng",
  orderTimeRange: "Thời gian đặt hàng",
  // ...
  stateCirculation: "Quy trình trạng thái",
  submitOrder: "GỬI",
  buyerPayment: "Khách hàng đã thanh toán",
  paymentAmount: "Số tiền thanh toán",
  cancelPackageCreation: "Hủy gói hàng",
  sellerPackaging: "Đóng gói",
  sellerShipment: "Đã gửi hàng",
  logisticsOrderNo: "Số theo dõi #",
  buyerRefund: "Người mua khởi đầu yêu cầu hoàn tiền",
  refundOrderNo: "Số đơn hoàn tiền #",
  sellerCancelOrder: "Đã hủy bởi người bán",
  cancelForLogistics: "Hủy do vấn đề vận chuyển",
  sellerCancelsRefund: "Người bán hủy yêu cầu hoàn tiền của người mua",
  packageNotMactchOrderNo: "Sản phẩm trong gói hàng không khớp với số theo dõi.",
  addressExceptionCode: "Lỗi: Địa chỉ không hợp lệ",
  addressNotLockerStation: "Không có Trạm khoá tại địa chỉ này.",
  lockernoUse: "Không thể sử dụng khoá",
  noOpenLocker: "Không thể mở khoá",
  noPackage: "Không có gói hàng",
  packageCollectionFailed: "Việc lấy gói hàng không thành công",
  logisticsRefundCancel: "Hủy đơn hàng trả hàng và hoàn tiền vận chuyển (thêm 50% cho vận chuyển)",
  buyerInitiatedReturnRefund: "Người mua khởi đầu trả lại & hoàn tiền",
  platformRefuseRefund: "Nền tảng từ chối hoàn tiền",
  submitRefundApply: "Gửi yêu cầu trả hàng",
  buyerReturned: "Người mua đã trả lại",
  refundUnderReview: "Đã hủy, hoàn tiền đang được xem xét",
  refundPendingPayment: "Hoàn tiền chờ thanh toán",
  payPoints: "Thanh toán bằng điểm Youpik",
  logisticsProvider: "Đối tác vận chuyển",
  deliveryFailed: "Giao hàng thất bại",
  deliveryFailed_1: "Giao hàng thất bại: đang trên đường trả hàng.",
  deliveryFailed_2: "Giao hàng thất bại: trả về kho",
  rejectAndReturn: "Từ chối",
  userReject: "Khách hàng từ chối",
  lostPackage: "Gói hàng mất",
  abnormalLogistics: "Ngoại lệ vận chuyển",
  abnormalLogisticsConcat:
    "Vận chuyển có vấn đề. Nếu có bất kỳ câu hỏi nào, vui lòng liên hệ với công ty vận chuyển.",
  "预估佣金（佣金率）": "Ước lượng hoa hồng (tỷ lệ hoa hồng)",
  实际佣金: "Hoa hồng thực tế",
  店主预估佣金: "Ước lượng hoa hồng của Youpikker",
  店主实际佣金: "Hoa hồng thực tế của Youpikker",
  妥投时间: "Thời gian giao hàng",
  退款时间: "Thời gian hoàn trả",
  申述记录: "Hồ sơ khiếu nại",
  查看报告: "Xem báo cáo",
  "申述-待审核": "Khiếu nại - Đang chờ xem xét",
  "申述-失败": "Khiếu nại - Từ chối",
  "申述-成功": "Khiếu nại - Đã chấp nhận",
  审核人: "Người xem xét",
  审核意见: "Ý kiến xem xét",
  审核时间: "Thời gian xem xét",
  审核: "Xem xét",
  "审核通过后该订单状态将修改为已完成，会进行店主佣金结算。是否确认继续?":
    'Trạng thái của đơn hàng sẽ được chuyển sang "Hoàn thành" sau khi xem xét được phê duyệt và hoa hồng Youpikker sẽ được thanh toán. Bạn có chắc chắn muốn tiếp tục không?',
  请选择原因: "Vui lòng chọn một lý do",
  "申诉失败原因会返回给用户，请根据实际情况填写":
    "Lý do khiếu nại thất bại sẽ được gửi lại cho người dùng, vui lòng điền theo tình hình thực tế",
  其他原因说明: "Lý do khác",
  Lazada订单申诉失败原因: "Lý do từ chối khiếu nại đơn hàng Lazada",
  概览: "Tổng quan",
  仅显示已完成订单: "Chỉ hiển thị đơn hàng đã hoàn thành",
  退款率: "Tỷ lệ hoàn trả",
  异常订单率: "Tỷ lệ đơn hàng từ chối",
  刷单: "Đơn hàng bị từ chối - Đã hoàn thành",
  刷单率: "Tỷ lệ đơn hàng bị từ chối - Đã hoàn thành",
  申诉订单: "Đơn hàng khiếu nại",
  申诉率: "Tỷ lệ khiếu nại",
  买家: "người mua",
  全部订单: "Tất cả đơn hàng",
  异常订单: "Đơn hàng bị từ chối",
  订单金额: "Tổng giá đơn hàng",
  预估佣金: "Ước lượng hoa hồng",
  账号状态: "Trạng thái tài khoản",
  申诉记录: "Hồ sơ khiếu nại",
  申请订单: "Đặt hàng",
  凭证: "Chứng từ",
  联系人: "Liên hệ",
  联系方式: "Thông tin liên hệ",
  订单状态: "Trạng thái đơn hàng",
  成功: "Đã chấp nhận",
  失败: "Từ chối",
  不通过: "Không thông qua",
  待审核: "Đang chờ xem xét",
  订单数: "Số lượng đơn hàng",
  服务单号: "Số đơn dịch vụ",
  注销: "Đăng xuất",
  禁用: "Vô hiệu hóa",
  正常: "Bình thường",
  删除: "Xóa",
  税后: "Sau thuế",
  不含官方账号佣金: "Không bao gồm hoa hồng tài khoản chính thức",
  数量: "Số lượng",
  增补物流单号: "Mã đơn hàng bổ sung",
  商家补发: "Bổ sung từ người bán",
  已增补发货: "Đã gửi hàng bổ sung",
  增补发货: "Gửi hàng bổ sung",
  "Content ID": "ID Nội dung",
  视频链接: "Liên kết video",
  视频销量: "Doanh số video",
  视频GMV: "GMV video",
  视频订单数: "Số đơn hàng video",
  "Ads Code": "Mã quảng cáo",
  未投流: "Chưa được quảng cáo",
  已投流: "Đã được quảng cáo",
  未关联样品订单: "Không liên kết với đơn đặt hàng mẫu",
  订单关联状态: "Trạng thái liên kết đơn hàng",
  提交时间: "Thời gian gửi",
  选择时间范围: "Chọn khoảng thời gian"
};
