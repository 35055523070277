export default {
  pendingClassificationBtn: "มีสินค้า x รายการที่รอการจำแนกประเภท",
  categoryLabels: "หมวดหมู่สินค้า",
  wiseManPerStatus: "กรอกข้อมูลครีเอเตอร์ให้สมบูรณ์",
  totalNumSampleAppOrders: "จำนวนคำขอสินค้าตัวอย่าง",
  shippedOrderNum: "จัดส่งแล้ว",
  pendingPerformanceNum: "รอดำเนินการ",
  performanceNum: "% ความคืบหน้า",
  mannerOfPerformance: "วิธีการปฏิบัติ",
  area: "เขต",
  detailedAddress: "ที่อยู่",
  pleaseDatailAdd: "กรุณากรอกชื่อถนน อาคาร และหมายเลขห้อง",
  clickLogOffWeb: "คลิกเพื่อดูเว็บไซต์อย่างเป็นทางการของโลจิสติกส์",
  ttSample: "ตั้งค่าการขอสินค้าตัวอย่าง",
  All: "ทั้งหมด",
  Shipping: "กำลังจัดส่ง",
  ToSellerReviewed: "ร้านค้ากำลังพิจารณา",
  sellerAgree: "ร้านค้าอนุมัติคำขอ",
  agreeToSellerAgree: "หลังจากอนุมัติคำขอแล้ว ให้เปลี่ยนสถานะเป็นรอการอนุมัติจากร้านค้า",
  sellerAgreeToShipping: "หลังจากร้านค้าอนุมัติ ให้เปลี่ยนเป็นสถานะ กำลังจัดส่ง",
  videoLink: "ลิงก์วิดีโอ",
  Live: "ไลฟ์สด",
  video: "วิดีโอ",
  // 履约
  liveTimeTips: "ต้องระบุเวลาสด",
  modifyTheNumberOfProducts: "แก้ไขจำนวนสินค้าตัวอย่าง",
  inputNewSample: "กรุณากรอกจำนวนสินค้าตัวอย่าง",
  LiveAndVideo: "วิดีโอและไลฟ์สตรีมมิ่ง",
  PerformanceToBereviewed: "รอการตรวจสอบ",
  PerformancePass: "อนุมัติคำขอ",
  RefusalOfPerformance: "ปฏิเสธ",
  MerchantReviewRejected: "ผู้ขายปฏิเสธ",
  AutomatedReviewApproved: "การตรวจสอบอัตโนมัติผ่านแล้ว",
  ShipmentRejected: "การปฏิเสธการจัดส่ง",
  Rejected: "ปฏิเสธ",
  PerformanceStatus: "สถานะ",
  PerformanceMethod: "วิธีดำเนินการ",
  appUp: "อัปโหลดคำขอ",
  orderBack: "ฟีดแบ็คของคำสั่งซื้อ",
  PICConfirm: "ยืนยันจาก PIC ",
  视频回溯: "วิดีโอย้อนหลัง",
  直播回溯: "ถอดรหัสสด",
  直播视频回溯: "วิดีโอและถอดรหัสสด",
  promised: "ปฏิบัติงานแล้ว",
  noPromise: "ไม่ปฏิบัติงาน",
  promiseedReject: "อินฟลูเอนเซอร์ถูกปฏิเสธก่อนหน้านี้",
  videoid: "รหัสวิดีโอ (ไม่บังคับ)",
  videosId: "รหัสโปรโมทวิดีโอ",
  upLoadVideo: "อัปโหลดรหัสวิดีโอโปรโมทสินค้า",
  confirmReject: "ยืนยันการปฏิเสธการปฏิบัติงานนี้หรือไม่?",
  promiseFinish: "ยอมรับและดำเนินการไปที่เสร็จสิ้น",
  FulfillRecords: "บันทึกการดำเนินการ",
  inputPositiveInteger: "เฉพาะจำนวนเต็มบวกเท่านั้น",
  uploadedTheVideoPromotionID: "คำสั่งซื้ออยู่ในวิดีโอ ID:",
  VideoIdExists: "ไอดีวิดีโอมีอยู่แล้ว",
  liveId: "ไอดีสด",
  liveLink: "ลิงก์ถ่ายทอดสด",
  maxInput999Num: "สามารถป้อนข้อมูลได้สูงสุด 999 เท่านั้น",
  inflowCloseSuccess: "ปิดสตรีมสำเร็จ",
  importApply: "ยื่นคำขอสินค้าตัวอย่าง",
  importShip: "อัปโหลดข้อมูลการจัดส่ง",
  repeatApply: "คำขอนี้ถูกขอมาแล้ว",
  ToBeReviewed: "รอการตรวจสอบ",
  Delivered: "จัดส่งแล้ว",
  closeed: "คำขอถูกปฏิเสธ",
  OrderNum: "หมายเลขการสั่งซื้อ",
  NameOfTalent: "ชื่อครีเอเตอร์",
  StoreName: "ชื่อร้านค้า",
  ItemId: "ไอดีสินค้า",
  ItemName: "ชื่อสินค้า",
  TAPID: "TAP ID ",
  ApplyTime: "เวลาที่ยื่นคำร้อง",
  lookRecord: "ดูประวัติ",
  Item: "ลิงก์สินค้า",
  unitPrice: "ราคาสินค้า",
  Commission: "ค่าคอมมิชชั่นแพลตฟอร์ม",
  tradingStatus: "สถานะสินค้า",
  ScreenshotSales: "ภาพหน้าจอข้อมูลการขายใน Showcase",
  TAPActivityInformation: "TAP Info",
  lastPage: "ก่อนหน้า",
  nextPage: "ถัดไป",
  NumberOfFans: "ผู้ติดตาม",
  AverageVideoPlayback: "วิดีโอ",
  CourierCompany: "จัดส่งโดย",
  GoToFlashOfficialWebsite: "หมายเลขติดตามพัสดุ",
  goToLogisticsOfficialWebsite: "ไปที่เว็บไซต์ของบริษัทขนส่ง",
  flashMessage: "การจัดส่ง",
  AwbNum: "หมายเลขติดตามพัสดุ",
  ship: "การจัดส่ง",
  orderStatusWillChang: "สถานะคำสั่งซื้อจะเปลี่ยนเป็นกำลังจัดส่ง",
  Select: "กรุณาเลือก",
  请选择原因: "กรุณาเลือกเหตุผล",
  "申诉失败原因会返回给用户，请根据实际情况填写":
    "เหตุผลในการปฏิเสธคำขอจะถูกส่งไปยังผู้ใช้ โปรดเลือกตามสถานการณ์จริง",
  其他原因说明: "เหตุผลอื่นๆ ที่ต้องอธิบาย",
  Lazada订单申诉失败原因: "เหตุผลความล้มเหลวในการร้องเรียนคำสั่งซื้อของ Lazada",
  otherReasons: "other reasons",

  actTip: 'ดำเนินการสำเร็จ กรุณาไปที่  "นำเข้าประวัติ" เพื่อดู ',
  toSelect: "กรุณาเลือก ",
  useExcel: "นำเข้าจาก  Excel",
  selectFile: "กรุณาเลือกไฟล์",
  downloadModel: "ดาวน์โหลดแบบฟอร์ม",
  importModel: "นำเข้าแม่แบบ",
  importHistory: "นำเข้าจากประวัติ",
  exportHistory: "นำออกประวัติ",
  UserName: "ชื่อบัญชี",
  time: "ระยะเวลาแสดงป๊อปอัป",
  fileName: "ชื่อไฟล์",
  Status: "สถานะสินค้า",
  Completed: "สำเร็จแล้ว",
  Failure: "ล้มเหลว",
  download: "ดาวน์โหลด",
  palceInputAllContent: "กรุณากรอกข้อมูล!",
  receiver: "ผู้รับ",
  "uChoice&TT Sample Application Creator Threshold Setting":
    "การตั้งค่าเกณฑ์การขอสินค้าตัวอย่างของ uChoice&TT",

  申请渠道: "ช่องทางการส่งคำขอ:",
  线上: "ออนไลน์",
  线下: "ออฟไลน์",
  截图时间段: "เวลาการขาย",
  "30天成交件数": "จำนวนสินค้าที่ขายใน 30 วัน",
  成交件数档位: "ระดับจำนวนการทำธุรกรรม",
  寄样信息: "ข้อมูลการจัดส่ง",
  新增订单: "คำสั่งซื้อใหม่",
  修改订单: "แก้ไขคำสั่งซื้อ",
  订单号: "หมายเลขคำสั่งซื้อ #",

  商品分类: "หมวดหมู่สินค้า",
  创建商品集链接: "สร้างลิงก์กลุ่มสินค้า",
  添加至已有商品集: "เพิ่มไปยังกลุ่มสินค้าที่มีอยู่",
  请选择商品集: "กรุณาเลือกกลุ่มสินค้า",
  "商品集名称（泰文）": "ชื่อกลุ่มสินค้า (ภาษาไทย)",
  "推荐理由（泰文）": "คำอธิบายเพิ่มเติม (ภาษาไทย)",
  "商品集名称（越南）": "ชื่อรายการสินค้า (ภาษาเวียดนาม)",
  "推荐理由（越南）": "เหตุผลการแนะนำ (ภาษาเวียดนาม)",
  "近30天热销商品，下个爆款等你来选！批量免费样品吧等你来拿~":
    "uChoice เลือกสินค้าขายดี เพิ่มสินค้าคอมมิชชั่นสูง ดาวน์โหลดแอปเพื่อดูเพิ่มเติม!",
  创建商品集: "สร้างกลุ่มสินค้า",
  搜索商品集ID或名称: "ค้นหากลุ่มสินค้าด้วย ID หรือชื่อ",
  创建集合: "สร้างหมวดหมู่",
  集合名称: "ชื่อกลุ่มสินค้า",
  创建人: "PIC",
  商品集ID: "รหัสIDกลุ่มสินค้า",
  名称: "ชื่อ",
  简介: "คำอธิบาย",
  有效商品数: "จำนวนสินค้า",
  集合链接: "ลิงก์กลุ่มสินค้า",
  分享: "แชร์",
  管理商品: "จัดการสินค้า",
  分享商品集: "แชร์กลุ่มสินค้า",
  序号: "ลำดับที่",
  请输入商品ID或商品名称: "โปรดกรอกรหัสสินค้า หรือชื่อสินค้า",
  批量移除: "ลบทั้งหมด",
  添加商品: "เพิ่มสินค้า",
  商品列表: "รายการสินค้า",
  全选本页: "เลือก",
  佣金范围: "อัตราค่าคอมมิชชั่น",
  最低库存量: "จำนวนสินค้าคงคลังขั้นต่ำ",
  "近2周销售增长%": "ยอดขายที่เติบโตใน 2 สัปดาห์ล่าสุด%",
  最低总销量: "ยอดขายขั้นต่ำโดยรวม",
  近30天最低销量: "ยอดขายขั้นต่ำ 30 วันล่าสุด",
  确认要生成商品集链接: "ยืนยันการสร้างลิ้งก์กลุ่มสินค้า",
  继续: "ดำเนินการต่อ",
  确认要添加商品至集合: "ยืนยันการเพิ่มสินค้าในกลุ่มสินค้า",
  "正在添加1个商品至【商品集名称】，你还要继续吗？":
    'กำลังเพิ่ม <span style="color: red">{num}</span> รายการสินค้าไปยัง <span style="color: red">【{name}】</span>  คุณต้องการดำเนินการต่อ หรือไม่?',
  添加成功: "เพิ่มสำเร็จ",
  您可以前往商品集列表管理您的集合商品: "คุณสามารถจัดการกลุ่มสินค้าได้ในฟังก์ชั่นกลุ่มสินค้า",
  去管理: "จัดการ",
  关闭: "ปิด",
  已成功创建商品集链接: "สร้างลิงก์กลุ่มสินค้าสำเร็จ",
  复制链接: "คัดลอกลิงก์",
  "正在创建的商品集共包含10个有效商品，你还要继续吗？":
    'กลุ่มสินค้าที่คุณกำลังสร้างมีสินค้ารวมทั้งหมด <span style="color: red">{num} อย่างแล้ว</span> คุณต้องการดำเนินการต่อ หรือไม่??',
  "您可以将链接粘贴至任何对话框。": "คุณสามารถโพสต์ลิงก์ที่แชทไหนก็ได้",
  您已成功复制xxx商品集分享链接:
    'คุณคัดลอกลิงก์แชร์กลุ่มสินค้า <span style="color: red">{setName}</span> สำเร็จ',
  确认删除商品集: "ยืนยันการลบกลุ่มสินค้า",
  "删除成功！": "ลบสำเร็จ",
  "您正在删除【商品集合名称】，删除后用户端分享链接将失效，你还要继续吗？":
    'คุณกำลังทำการลบ <span style="color: red">【{setName}】</span> และหลังจากลบแล้ว ลิ้งก์แชร์สินค้าอาจพบปัญหา คุณต้องการดำเนินการต่อ หรือไม่?',
  Sort: "ลำดับ",
  移除: "ลบ",
  "25/100 个商品已添加至商品集，已失效商品（活动结束、商家下架、库存不足、平台下架）系统将会自动移除。":
    '<span style="color: #5245E5">{num}</span>/100 สินค้าถูกเพิ่มลงในกลุ่มสินค้าแล้ว อีกทั้งสินค้าที่มีปัญหา (หมดแคมเปญ, บัญชีร้านค้าไม่ถูกใช้งาน, สินค้าหมด, แพลตฟอร์มลบสินค้าออก) ก็ถูกระบบลบโดยอัตโนมัติด้วยเช่นกัน',
  确认移除商品: "ยืนยันการลบสินค้า",
  "您正在移除2个商品，你还要继续吗？":
    'คุณกำลังลบสินค้า <span style="color: red">{num} รายการ</span> คุณต้องการดำเนินการต่อ หรือไม่?',
  "已选择 20/100 个商品": 'เลือกสินค้าไปแล้ว <span style="color: #7f6ff2">{num}</span>/100 รายการ',
  "添加失败，商品集最多可添加100个商品，剩余可添加1个商品。":
    'เพิ่มสินค้าไม่สำเร็จ คุณสามารถเพิ่มสินค้าได้มากสุด 100 รายการ คุณยังเหลือสินค้าที่ให้เพื่มอีก <span style="color: red">{num}</span> รายการ',
  等级数量: "ระดับขั้น",
  "限制类型：GMV": "เกณฑ์การแบ่งประเภท",
  级: "ขั้นที่ {{level}}",
  样品数量: "ปริมาณสินค้าตัวอย่าง",
  及以上: "ขึ้นไป",
  确认销量范围: "ยืนยันยอด GMV",
  不能大于30: "ห้ามมากกว่า 30",
  "最低金额无法高于/等于最高金额": "จำนวนเงินขั้นต่ำต้องไม่สูงกว่าหรือเท่ากับจำนวนเงินสูงสุด",
  明星活动: "กล่องแคมเปญเซเลป",
  同步: "เชื่อมต่อ",
  更新: "อัปเดต",
  普通活动: "กล่องแคมเปญทั่วไป",
  普通活动列表: "รายการกล่องแคมเปญทั่วไป",
  明星活动列表: "รายการแคมเปญกล่องเซเลป",
  更新中: "กำลังอัปเดต",
  个活动待同步至普通活动列表: " รายการที่รอการเชื่อมต่อเข้าไปในกล่องแคมเปญทั่วไป",
  个活动待同步至明星活动列表: " รายการที่รอการเชื่อมต่อเข้าไปในกล่องแคมเปญเซเลป",
  请填写tap佣金率: "กรุณากรอกเรทค่าคอมมิชชั่น TAP",
  同步中: "อยู่ระหว่างการเชื่อมต่อ",
  同步失败: "เชื่อมต่อไม่สำเร็จ",
  再次发起: "เชื่อมต่ออีกครั้ง",
  "固定佣金，不可调整": "คอมมิชชั่นถูกกำหนดไว้แล้ว ไม่สามารถแก้ไขได้",
  "限制类型：成交数量": "ประเภทข้อจำกัด: จำนวนการซื้อขาย",
  机审条件配置: "การกำหนดค่าของเงื่อนไขการตรวจสอบอัตโนมัติ ",
  样品金额: "จำนวนเงินตัวอย่าง≤",
  并且: "และ",
  达人账号履约率: "อัตราการปฏิบัติตามของบัญชีผู้มีอิทธิพล≥",

  操作人: "ผู้รับผิดชอบ",
  时间: "ช่วงเวลา",
  文件名: "ชื่อไฟล์",
  成功商品数: "จำนวนสินค้าที่นำเข้าสำเร็จ",
  失败商品数: "จำนวนสินค้าที่นำเข้าล้มเหลว",
  导入状态: "สถานะการนำเข้าสินค้า",
  导入中: "อยู่ระหว่างการนำเข้าสินค้า",
  完成: "เสร็จสมบูรณ์",
  失败: "ล้มเหลว",
  导入商品: "นำเข้าสินค้า",
  导入记录: "ประวัติการนำเข้าสินค้า",
  下载模板: "ดาวน์โหลดเทอเพลต",
  导入商品记录: "ประวัติการนำเข้าสินค้า",
  "已导入100个商品！": "นำเข้าสินค้า {success} รายการสำเร็จแล้ว!",
  "已导入99个商品，1个商品导入失败，请下载导入记录查看原因！":
    "นำเข้าสินค้า {success} รายการแล้ว แต่ไม่สามารถนำเข้าสินค้าได้ {fail} รายการ กรุณาดาวน์โหลดประวัติการนำเข้าสินค้าเพื่อทำการตรวจสอบสาเหตุ",
  商品未挂橱窗: "สินค้าไม่อยู่ในโชว์เคส",
  已挂橱窗: "เพิ่มในโชว์เคสแล้ว",
  未挂橱窗: "ยังไม่ได้เพิ่มในโชว์เคส",
  operationalReviewApproved: "การตรวจสอบการดำเนินงานผ่านแล้ว",
  operationalReviewRejected: "การตรวจสอบการดำเนินงานถูกปฏิเสธ",
  merchantpasses: "ผู้ขายอนุมัติแล้ว"
};
